import { call, put, takeEvery } from 'redux-saga/effects'
import { DaoAPI } from '../../api/DaoAPI'
import { getNetworkConfig } from '../../const/networks'
import {
  FETCH_DAO_REQUEST,
  FETCH_DAO_SUCCESS,
  FETCH_DAO_ERROR,
  FETCH_SINGLE_DAO_REQUEST,
  FETCH_SINGLE_DAO_SUCCESS,
  FETCH_SINGLE_DAO_ERROR,
} from '../types/dao.types'

function* handleGetDaos({ limit, offset, chainId, connector }) {
  try {
    const daos = yield call(DaoAPI.getDaosFromGraph, limit, offset, chainId)

    for (const element of daos.records) {
      const network = getNetworkConfig(element.chainId)

      const library = { provider: network.params.rpcUrls[0] }

      const daoConfiguration = yield call(
        DaoAPI.getConfiguration,
        element.id,
        library
      )
      element.maxCap = daoConfiguration.maxCap
      element.depositRange = {
        minimum: daoConfiguration.minDeposit,
        maximum: daoConfiguration.maxDeposit,
      }
      const whitelistedUsers = yield call(
        DaoAPI.getWhitelistedUsers,
        element.id,
        library
      )
      element.isPublic = whitelistedUsers.length <= 0 ? true : false

      const lpPrices = yield call(DaoAPI.getLpPrices, element.id, library)
      element.lpPrices = lpPrices.data
    }

    yield put({ type: FETCH_DAO_SUCCESS, daos: daos })
  } catch (err) {
    yield put({ type: FETCH_DAO_ERROR, message: err.message })
  }
}

function* handleGetSingleDaos({ id, chainId }) {
  try {
    const dao = yield call(DaoAPI.getDaoById, id, chainId)

    yield put({ type: FETCH_SINGLE_DAO_SUCCESS, dao: dao, id: id })
  } catch (err) {
    yield put({ type: FETCH_SINGLE_DAO_ERROR, message: err.message })
  }
}

export function* watcherDaoSaga() {
  yield takeEvery(FETCH_DAO_REQUEST, handleGetDaos)
  yield takeEvery(FETCH_SINGLE_DAO_REQUEST, handleGetSingleDaos)
}

export default watcherDaoSaga
