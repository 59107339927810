/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';

// Material UI
import { Container, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { toUSD } from '../helpers/currencyHelper';
import error404 from '../assets/icons/icon-not-found.jpg';
import { PortfolioLoader } from './Loaders';
import { ErrorMessage } from './ErrorMessage/ErrorMessage';
import BigNumber from 'bignumber.js'

const useStyles = makeStyles({
  root: {
    boxShadow: '0px 51px 89px -49px rgba(196, 193, 232, 0.4)',
    borderRadius: 18,
    border: '1px solid #e0e0e0',
  },
  tabelTile: {
    fontSize: 13,
    fontWeight: 600,
    fontStyle: 'normal',
    fontFamily: 'Poppins',
    lineHeight: 4,
  },
  container: {
    borderBottom: '1px solid rgb(224 224 224)',
  },
  tableBodyCellGreen: {
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: 13,
    color: '#5DD293',
  },
  tableIcon: {
    width: 22,
    paddingRight: 5,
  },
  tableTotal: {
    background: '#8765F2',
    paddingTop: 15,
    paddingBottom: 15,
    display: 'flex',
    justifyContent: 'space-between',
    borderRadius: '0px 0px 18px 18px',
  },
  totalText: {
    color: 'white',
    fontSize: 18,
    fontFamily: 'Poppins',
    fontWeight: 600,
  },
  totalTextInfo: {
    fontFamily: 'Poppins',
    color: 'white',
    fontSize: 13,
    alignSelf: 'center',
    opacity: 0.59,
    paddingRight: 10,
  },
  margin:{
    marginLeft: '26px'
  }
})

const tableCellTitle = {
  fontSize: 11,
  fontWeight: 500,
  fontStyle: 'normal',
  fontFamily: 'Poppins',
  lineHeight: 1,
  color: '#4C4C66'
}

const tableBodyCell = {
  fontFamily: 'Poppins',
  fontWeight: 600,
  fontSize: 13
}

const tableBodyCellIcon = {
  display: 'flex',
  alignItems: 'self-end',
  fontFamily: 'Poppins',
  fontWeight: 600,
  fontSize: 13,
}

const tableIcon = {
  width: 22,
  paddingRight: 5
}

const tableBodyCellGreen = {
  fontFamily: 'Poppins',
  fontWeight: 600,
  fontSize: 13,
  color: '#5DD293'
}

export default function SingleFundTable({ assets, column, rates, lpTokenBalance } : { assets: any, column: any, rates: any, lpTokenBalance: any }) {
  const classes = useStyles();

  const [totalDepositedAmount, setTotalDeposited] = useState<any>(0)
  const [loader, setLoader] = useState(true)

  useEffect(() => {
    setTotalDeposited(assets?.reduce((sum, element) =>
      rates[element.symbol.toUpperCase()] ? sum + toUSD(element.balance, rates[element.symbol.toUpperCase()]) : sum, 0
    ))
  }, [assets])

  if (assets?.length === 0 && lpTokenBalance > 0){
    setTimeout(()=>{
    setLoader(false)
  },3000)
  }

  const calculatePercentage = (asset, total) => {
    if(isNaN(asset) || isNaN(total) || total === 0 || total === undefined) {
        return 0;
    }
    
    return new BigNumber(asset).multipliedBy(100).dividedBy(total).toFixed(2)
   
  }
  
  if(assets === undefined){
    return    <TableContainer className={classes.root} component={Paper}>
    <Container className={classes.container}><Typography className={classes.tabelTile}>Portfolio</Typography></Container>
     <PortfolioLoader/>
    </TableContainer>
  }
  if (assets?.length === 0 && lpTokenBalance > 0){
    return <TableContainer className={classes.root} component={Paper}>
    <Container className={classes.container}><Typography className={classes.tabelTile}>Portfolio</Typography></Container>
    <Table>
    { loader ? 
      <PortfolioLoader/>
      :
      <div className={classes.margin}>
        <ErrorMessage message="Something went wrong, please refresh the page"/>
      </div>
       }
    </Table>
    </TableContainer>
  }
  
  return (
    <TableContainer className={classes.root} component={Paper}>
      <Container className={classes.container}><Typography className={classes.tabelTile}>Portfolio</Typography></Container>
      <Table>
        <TableHead>
          <TableRow >
            {
              column.map((item: any, key:any) => <TableHeadItem key={key} item={item} />)
            }
          </TableRow>
        </TableHead>
        <TableBody>
          {
            assets?.map((item: any, key:any) => (
              <TableItem
                key={key}
                item={item}
                rates={rates}
                calculatePercentage={calculatePercentage}
                totalDepositedAmount={totalDepositedAmount} />
            ))
          }
        </TableBody>
      </Table>
      <Container className={classes.tableTotal}>
        <Typography className={classes.totalText}>
         Total in USD: ${totalDepositedAmount?.toFixed(5)}
        </Typography>
      </Container>
    </TableContainer>
  );
}

const TableHeadItem = ({ item }: { item: any }) => <TableCell align="left" style={tableCellTitle}>{item.heading}</TableCell>

const TableItem = ({ item, rates, calculatePercentage, totalDepositedAmount } : { item:any, rates: any, calculatePercentage: any, totalDepositedAmount: any }) => 

(
  <TableRow>
    <TableCell align="left" style={tableBodyCellIcon}>
      <img style={tableIcon} onError={({ currentTarget }) => {
       currentTarget.onerror = null;
        currentTarget.src=error404 }} 
        src={`https://cdn.jsdelivr.net/gh/atomiclabs/cryptocurrency-icons@1a63530be6e374711a8554f31b17e4cb92c25fa5/svg/color/${item.symbol}.svg`.toLowerCase()}
        alt='' />
      {item.symbol}
    </TableCell>

    <TableCell align="left" style={tableBodyCell}>
      {item.balance.toLocaleString('fullwide', { useGrouping: false })}
    </TableCell>

    <TableCell align="left" style={tableBodyCell}>
      ${(item.balance * (rates[(item.symbol).toUpperCase()]?.USD ?? 0)).toFixed(2)}
    </TableCell>

    <TableCell align="left" style={tableBodyCellGreen}>
      {
        calculatePercentage(item.balance * rates[(item.symbol).toUpperCase()]?.USD, totalDepositedAmount)
      }%
    </TableCell>
  </TableRow>
)
