import { useWeb3React } from "@web3-react/core"
import { useEffect, useState } from "react"
import { isChainSupported } from "../const/networks"

export const WalletGuard = ({ children, alt }) => {
    const { account, chainId } = useWeb3React()
    const [ componentInitialzied, setComponentInitialized ] = useState(false)

    useEffect(() => {
        const walletModalButton = document.querySelector('#wallet-connect-button') as HTMLElement
        setTimeout(() => {
          setComponentInitialized(true)
          if (account === undefined || account === null || chainId === undefined || !isChainSupported(chainId)) {
            if (walletModalButton) {
              walletModalButton.click()
            }
          }
        }, 1000)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        componentInitialzied && !account
          ? <div>{alt}</div>
          : <div>{children}</div>
    )
}