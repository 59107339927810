import { useState, useEffect } from 'react'
import { useLocation } from 'react-router'

// Material-UI imports
import {
  Card,
  CardContent,
  Grid,
  TextField
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import { DaoAPI } from '../../../api/DaoAPI'
import { decodeDaoConfigParams } from '../../../helpers/abiDecoder'
import { NumericFormat } from 'react-number-format'
import web3 from 'web3'
import { useWeb3React } from '@web3-react/core'

const useStyle = makeStyles((theme) => ({
  divider: {
    borderBottom: '1px solid rgb(224 224 224)'
  },
  cardTitle: {
    fontSize: 13,
    fontWeight: 600,
    fontStyle: 'normal',
    lineHeight: 4,
    margin: 0,
    paddingLeft: '16px'
  },
  cardContent: {
    borderRadius: '18px 0px 0px 18px',
    border: '1px solid rgba(214, 210, 242, 0.5)',
    boxShadow: '0px 51px 89px -49px rgba(196, 193, 232, 0.4)',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      borderRadius: '18px 18px 18px 18px',
    },
    '& .MuiCardContent-root': {
      padding: 0
    }
  },
  cardContentSecondary: {
    borderRadius: '0px 18px 18px 0px',
    border: '1px solid rgba(214, 210, 242, 0.5)',
    boxShadow: '0px 51px 89px -49px rgba(196, 193, 232, 0.4)',
    width: '100%',
    background: '#F7F6FC',
  },
  boldText: {
    letterSpacing: '0.4px',
    fontSize: '12px',
    color: '#4C4C66',
  },
  labelHelper: {
    fontWeight: 500,
    fontSize: '9px',
    color: '#958BAE',
  },
  radioContent: {
    display: 'block',
    margin: '10px 0',
    '& .MuiFormControlLabel-root': {
      marginLeft: '0px',
      marginRight: '40px'
    }
  },
  input: {
    width: '100%',
    margin: '10px 0',
    '& .MuiOutlinedInput-input': {
      padding: '12.5px 14px'
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderRadius: '10px'
    }
  }
}))

export const VoteForChangeSpendingLimits = (props: any) => {
  const classes = useStyle()
  const { hexToNumber } = web3.utils

  const location = useLocation();
  const { connector } = useWeb3React();
  const daoAddress = location.state.state.data.subgraph.id

  const [daoConfig, setDaoConfig] = useState<any>({})
  const [proposedSpendingLimit, setSpendingLimit] = useState<any>('')

  useEffect(() => {
    const decodeSpendingLimit = async () => {
      try {
        setDaoConfig(await DaoAPI.getConfiguration(daoAddress, connector))
        const result = decodeDaoConfigParams(props?.action)
        setSpendingLimit(hexToNumber(result.inputs[0]._hex))
      } catch (e) {
        console.log('Error message:', e)
      }
    }
    decodeSpendingLimit()
      .catch(console.error)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const limitedValue = (inputObj) => {
    const { value } = inputObj;
    if (value <= 100) return true;
    return false;
  }

  return (
    <Grid container>
      <Grid item lg={6} md={6} sm={6}>
        <Card className={classes.cardContent}>
          <div className={classes.divider}><p className={classes.cardTitle}>Proposed spending limit</p></div>
          <CardContent style={{ padding: '16px' }} >
            {/* <Grid item lg={12}>
              <label className={classes.labelHelper}>
                <b className={classes.boldText}>Set-up spending limits</b>
                &nbsp;% of all total value locked
              </label>
              <RadioGroup className={classes.radioContent} defaultValue="first">
                <FormControlLabel
                  value='true'
                  label="Yes"
                  control={<Radio />} />
                <FormControlLabel
                  value=''
                  label="No"
                  control={<Radio />} />
              </RadioGroup>
            </Grid> */}
            <Grid item lg={12}>
              <label className={classes.labelHelper}>
                <b className={classes.boldText}>Monthly</b>
                &nbsp;% of assets for investments which can be used monthly
              </label>
              <NumericFormat
                disabled
                value={proposedSpendingLimit}
                className={classes.input}
                customInput={TextField}
                variant="outlined"
                placeholder='50%'
                isAllowed={limitedValue}
                suffix={'%'}
                allowNegative={false} />
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <Grid item lg={6} md={6} sm={6}>
        <Card className={classes.cardContentSecondary}>
          <div className={classes.divider}><p className={classes.cardTitle}>Current spending limit</p></div>
          <CardContent style={{ padding: '16px' }} >
            {/* <Grid item lg={12}>
              <label className={classes.labelHelper}>
                <b className={classes.boldText}>Set-up spending limits</b>
                &nbsp;% of all total value locked
              </label>
              <RadioGroup className={classes.radioContent} defaultValue="first">
                <FormControlLabel
                  value='true'
                  label="Yes"
                  control={<Radio />} />
                <FormControlLabel
                  value=''
                  label="No"
                  control={<Radio />} />
              </RadioGroup>
            </Grid> */}
            <Grid item lg={12}>
              <label className={classes.labelHelper}>
                <b className={classes.boldText}>Monthly</b>
                &nbsp;% of assets for investments which can be used monthly
              </label>
              <NumericFormat
                disabled
                value={daoConfig?.spendingLimit}
                className={classes.input}
                customInput={TextField}
                variant="outlined"
                placeholder='50%'
                isAllowed={limitedValue}
                suffix={'%'}
                allowNegative={false} />
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  )
}
