import { useEffect, useState } from 'react'

// Material-UI imports
import {
  Card,
  CardContent,
  Grid,
  TextField,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { NumericFormat } from 'react-number-format'
import { useSelector } from 'react-redux'
import BigNumber from 'bignumber.js'

const useStyle = makeStyles((theme) => ({
  divider: {
    borderBottom: '1px solid rgb(224 224 224)',
  },
  cardTitle: {
    fontSize: 13,
    fontWeight: 600,
    fontStyle: 'normal',
    lineHeight: 4,
    margin: 0,
    paddingLeft: '16px',
  },
  cardContent: {
    borderRadius: '18px',
    border: '1px solid rgba(214, 210, 242, 0.5)',
    boxShadow: '0px 51px 89px -49px rgba(196, 193, 232, 0.4)',
    '& .MuiCardContent-root': {
      padding: 0,
    },
  },
  contentHelper:{
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '-25px',
    padding: '0 14px'
  },
  labelHelper: {
    fontWeight: 500,
    fontSize: '9px',
    color: '#958BAE',
  },
  contentHelper2:{
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    marginBottom: '-25px',
    padding: '0 14px'
  },
  boldText: {
    letterSpacing: '0.4px',
    fontSize: '12px',
    color: '#4C4C66',
  },
  spanTextColor:{
    color: '#BC65F2',
    fontSize: '12px',
    textAlign: 'right',
},
questionMark:{
    width: '20px'
  },
  link:{
    color:'#BC65F2'
},
inputContent: {
    "& .MuiInputBase-input":{
       padding:'10.5px 14px',
    background: '#FBFBFF',
    },
    "& .MuiInputBase-root":{
      borderRadius: '9px'
    },
    display: 'grid',
    fontSize: '12px',
    fontWeight: 500,
    padding: '15px 10px',
  },
  input: {
    marginTop: '15px',
  },
  exampleContent:{
    fontSize: '8px',
    height: '100%',
  },
  exampleText:{
    lineHeight: '22px',
    marginTop: '0px',
    color: '#958BAE',
    margin: '8px'
  },
  exampleTitle:{
      color: '#BC65F2',
      margin:'5px 8px'
  },
  alignItems:{
    alignItems: 'flex-end'
  },
}))

type State = {
  participationRequiredPct: number | null,
  supportRequiredPct: number | null,
  minRequiredTokenPct: number | null,
  minDuration: number | null,
  func: string
}

const InitialState: State = {
  participationRequiredPct: null,
  supportRequiredPct: null,
  minRequiredTokenPct: null,
  minDuration: null,
  func: 'changeVotingConfig'
}

export const ChangeVotingParameters = (props) => {
  const {setIsProposalValid, setProposalError, setProposalMsg} = props;
  const classes = useStyle()
  const votingAddress = useSelector((state:any) => state?.daos?.dao?.data?.subgraph?.daoCreationLog?.voting)
  
  const dao = useSelector((state:any) => {return state.daos.dao})
  const [votingConfig, setVotingConfig] = useState({...InitialState, votingAddress: votingAddress})
  const [quorumErr, setQuorumErr] = useState(false)
  const [minimumApprovalErr, setMinimumApprovalErr] = useState(false)
  const [votingDurationErr, setVotingDurationErr] = useState(false)
  const [minimumShareForStartVotingErr, setMinimumShareForStartVotingErr] = useState(false)
  const { minDuration, minRequiredTokenPct, participationRequiredPct, supportRequiredPct} = dao.data.subgraph.packages[0].pkg

  const minDurationParsed = new BigNumber(minDuration).dividedBy(60).dividedBy(60).toNumber();
  const minRequiredTokenPctParsed = new BigNumber(minRequiredTokenPct).dividedBy(10**16).toNumber()
  const participationRequiredPctParsed = new BigNumber(participationRequiredPct).dividedBy(10**16).toNumber()
  const supportRequiredPctParsed = new BigNumber(supportRequiredPct).dividedBy(10**16).toNumber()

  const inputValidation =
    (votingConfig.participationRequiredPct !== undefined && votingConfig.participationRequiredPct !== null && !isNaN(votingConfig.participationRequiredPct)) &&
    (votingConfig.supportRequiredPct !== undefined && votingConfig.supportRequiredPct !== null && !isNaN(votingConfig.supportRequiredPct)) &&
    (votingConfig.minRequiredTokenPct !== undefined && votingConfig.minRequiredTokenPct !== null && !isNaN(votingConfig.minRequiredTokenPct)) &&
    (votingConfig.minDuration !== undefined && votingConfig.minDuration !== null && votingConfig.minDuration !== 0 && votingConfig.minDuration! <= 720 && !isNaN(votingConfig.minDuration))

  const proposalValidation = 
    (votingConfig?.minDuration === minDurationParsed) &&
    (votingConfig?.minRequiredTokenPct === minRequiredTokenPctParsed) &&
    (votingConfig?.participationRequiredPct === participationRequiredPctParsed) &&
    (votingConfig?.supportRequiredPct === supportRequiredPctParsed)
  
  useEffect(() => {
    setIsProposalValid(inputValidation);
    if (proposalValidation) {
      setProposalError(true);
      setProposalMsg('You cannot create a proposal with the same voting parameters that is already present.')
    } else {
      setProposalError(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValidation, proposalValidation])

  const limitedValue = (inputObj) => {
    const { value } = inputObj
    if (value <= 100) return true
    return false
  }
  // parseInt(props?.package?.supportRequiredPct) / (10 ** 16)
  useEffect(() => {
    props.func(votingConfig)
  })

  return (
      <Card className={classes.cardContent}>
        <div className={classes.divider}>
          <p className={classes.cardTitle}>Change voting parameters</p>
        </div>
        <CardContent style={{ padding: '16px' }}>
          <Grid container className={classes.alignItems}>
          <Grid item lg={6} md={6} sm={6} xs={12}>
              <div className={classes.inputContent}>
                <label className={classes.labelHelper}>
                  <b className={classes.boldText}>Quorum, </b><span>all minted LP tokens</span>
                </label>
                <NumericFormat
                  customInput={TextField}
                  variant="outlined"
                  className={classes.input}
                  placeholder={`${isNaN(participationRequiredPctParsed) ? 0 : participationRequiredPctParsed}%`}
                  onChange={(e: any) => {setVotingConfig({ ...votingConfig, participationRequiredPct: parseInt(e.target.value.replace(/[%\s]/g, '')) })}}
                  onBlur={() => setQuorumErr(true)}
                  error={quorumErr && votingConfig.participationRequiredPct !== null && votingConfig.participationRequiredPct! >= 0 ? !quorumErr : quorumErr}
                  helperText={
                    quorumErr
                      ? votingConfig.participationRequiredPct! >= 0 && votingConfig.participationRequiredPct != null
                        ? ''
                        : 'This field is required'
                      : null
                  }
                  valueIsNumericString={true}
                  allowNegative = {false}
                  suffix={'%'}
                  isAllowed={limitedValue}
                />
              </div>
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <div className={classes.inputContent}>
                <label className={classes.labelHelper}>
                  <b className={classes.boldText}>Minimum Approval, </b><span>% of voted LP tokens</span>
                </label>
                <NumericFormat
                  customInput={TextField}
                  variant="outlined"
                  className={classes.input}
                  placeholder={`${isNaN(supportRequiredPctParsed) ? 0 : supportRequiredPctParsed}%`}
                  onChange={(e: any) => {setVotingConfig({ ...votingConfig, supportRequiredPct: parseInt(e.target.value.replace(/[%\s]/g, '')) })}}
                  onBlur={() => setMinimumApprovalErr(true)}
                  error={minimumApprovalErr && votingConfig.supportRequiredPct !== null && votingConfig.supportRequiredPct! >= 0 ? !minimumApprovalErr : minimumApprovalErr}
                  helperText={
                    minimumApprovalErr
                      ? votingConfig.supportRequiredPct! >= 0 && votingConfig.supportRequiredPct !== null
                        ? ''
                        : 'This field is required'
                      : null
                  }
                  valueIsNumericString={true}
                  allowNegative = {false}
                  suffix={'%'}
                  isAllowed={limitedValue}
                />
              </div>
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12}>
            <div className={classes.inputContent}>
              <label className={classes.labelHelper}>
                <b className={classes.boldText}>Voting Duration, </b><span> hours</span>
              </label>
              <NumericFormat
                customInput={TextField}
                variant="outlined"
                className={classes.input}
                placeholder={`${isNaN(minDurationParsed) ? 0 : minDurationParsed}`}
                onChange={(e: any) => {setVotingConfig({ ...votingConfig, minDuration: parseInt(e.target.value.replace(/[%\s]/g, '')) })}}
                onBlur={() => setVotingDurationErr(true)}
                error={votingDurationErr && votingConfig.minDuration !== null && votingConfig.minDuration! > 0 && votingConfig.minDuration! <= 720 ? !votingDurationErr : votingDurationErr}
                helperText={
                  votingDurationErr ?
                    (votingConfig.minDuration === null ? 'This field is required' : '') || 
                    (votingConfig.minDuration! > 0 ? "":"Please add value from 1 to 720" ) || 
                    (votingConfig.minDuration! <= 720 ? "":"Please add value from 1 to 720"): null 
                }
                valueIsNumericString={true}
                allowNegative={false}
              />
            </div>
          </Grid>
          <Grid item lg={6} md={6} sm={6} xs={12}>
              <div className={classes.inputContent}>
                <label className={classes.labelHelper}>
                  <b className={classes.boldText}>Minimum % of LP tokens to start Voting,<br/></b><span>% of all minted LP tokens</span>
                </label>
                <NumericFormat
                  customInput={TextField}
                  variant="outlined"
                  className={classes.input}
                  placeholder={`${isNaN(minRequiredTokenPctParsed) ? 0 : minRequiredTokenPctParsed}%`}
                  onChange={(e: any) => {setVotingConfig({ ...votingConfig, minRequiredTokenPct: parseInt(e.target.value.replace(/[%\s]/g, '')) })}}
                  onBlur={() => setMinimumShareForStartVotingErr(true)}
                  error={
                    minimumShareForStartVotingErr &&
                    votingConfig.minRequiredTokenPct !== null && votingConfig.minRequiredTokenPct! >= 0 ? !minimumShareForStartVotingErr : minimumShareForStartVotingErr
                  }
                  helperText={
                    minimumShareForStartVotingErr
                      ? votingConfig.minRequiredTokenPct! >= 0 && votingConfig.minRequiredTokenPct! !== null
                        ? ''
                        : 'This field is required'
                      : null
                  }
                  valueIsNumericString={true}
                  allowNegative={false}
                  suffix={'%'}
                  isAllowed={limitedValue}
                />
              </div>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
  )
}
