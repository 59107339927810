import { useEffect, useState } from 'react'

export const useWindowHeight = () => {
  const [windowHeight, setWindowHeight] = useState(window.innerHeight)

  useEffect(() => {
    const resizeListener = () => setWindowHeight(window.innerHeight)
    window.addEventListener('resize', resizeListener)

    return () => {
      window.removeEventListener('resize', resizeListener)
    }
  }, [])

  return windowHeight
}
