import {
  SWAP_REQUEST,
  SWAP_SUCCESS,
  SWAP_ERROR,
  APPROVE_REQUEST,
  APPROVE_SUCCESS,
  APPROVE_ERROR,
  CLAIM_REQUEST,
  CLAIM_SUCCESS,
  CLAIM_ERROR,
  LOCK_IN_SUCCESS,
  LOCK_IN_REQUEST,
  LOCK_IN_ERROR,
  RESET_ERROR,
  SET_ERROR,
} from './wallet.types'

export const swapRequest = ({
  userAddress,
  poolAddress,
  amount,
  provider,
  poolId,
  password,
}) => {
  return {
    type: SWAP_REQUEST,
    payload: {
      userAddress,
      poolAddress,
      amount,
      provider,
      poolId,
      password,
    },
  }
}

export const swapSuccess = ({ userAddress, poolAddress, provider }) => {
  return {
    type: SWAP_SUCCESS,
    payload: {
      userAddress,
      poolAddress,
      provider,
    },
  }
}

export const swapError = (message) => {
  return {
    type: SWAP_ERROR,
    payload: { message },
  }
}

export const approveRequest = ({
  userAddress,
  poolAddress,
  amount,
  provider,
}) => {
  return {
    type: APPROVE_REQUEST,
    payload: {
      userAddress,
      poolAddress,
      amount,
      provider,
    },
  }
}

export const approveSuccess = ({ userAddress, poolAddress, provider }) => {
  return {
    type: APPROVE_SUCCESS,
    payload: {
      userAddress,
      poolAddress,
      provider,
    },
  }
}

export const approveError = (message) => {
  return {
    type: APPROVE_ERROR,
    payload: {
      message,
    },
  }
}

export const claimRequest = ({ userAddress, poolAddress, provider }) => {
  return {
    type: CLAIM_REQUEST,
    payload: {
      userAddress,
      poolAddress,
      provider,
    },
  }
}

export const claimSuccess = ({ userAddress, poolAddress, provider }) => {
  return {
    type: CLAIM_SUCCESS,
    payload: {
      userAddress,
      poolAddress,
      provider,
    },
  }
}

export const claimError = (message) => {
  return {
    type: CLAIM_ERROR,
    payload: {
      message,
    },
  }
}

export const lockInRequest = ({ userAddress, amount, provider }) => {
  return {
    type: LOCK_IN_REQUEST,
    payload: {
      userAddress,
      amount,
      provider,
    },
  }
}

export const lockInSuccess = () => {
  return {
    type: LOCK_IN_SUCCESS,
  }
}

export const lockInError = (message) => {
  return {
    type: LOCK_IN_ERROR,
    payload: {
      message,
    },
  }
}

export const setError = (message) => {
  return {
    type: SET_ERROR,
    payload: {
      message,
    },
  }
}

export const resetError = () => {
  return {
    type: RESET_ERROR,
  }
}
