import { useWeb3React } from '@web3-react/core'

// Material Imports
import Card from '@material-ui/core/Card'
import Grid from '@material-ui/core/Grid'
import {
  CardContent,
  Chip,
  Badge,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import questionMark from '../../../assets/icons/questionMark.svg'
import { CopyToClipboardBtn } from '../../CopyToClipboard'
import { Button } from '../../Button'

const useStyles = makeStyles((theme) => ({
  content: {
    border: '1px solid rgba(214, 210, 242, 0.5)',
    boxShadow: '0px 51px 89px -49px rgba(196, 193, 232, 0.4)',
    borderRadius: '18px',
    marginBottom: '50px'
  },
  inputContent: {
    display: 'grid',
    fontSize: '12px',
    fontWeight: 500,
    padding: '15px 10px',
    "& .MuiInputBase-input":{
       padding:'10.5px 14px',
       background: '#FBFBFF',
       fontFamily: 'Poppins',
       fontSize: '14px',
       fontWeight: 500,
    },
    "& .Mui-disabled":{
      borderRadius: '10px',
      color: '#6F5E9A',
      background: 'rgba(226, 221, 237, 0.7)',
      borderColor:'transparent'
    },
    "& .MuiInputBase-root":{
      borderRadius: '9px'
    },
    '& .MuiFormHelperText-root.Mui-error':{
      fontSize: '10px',
      fontFamily:'Poppins',
      margin: '0 3px',
      position: 'absolute',
      bottom: '-18px'
    }
  },
  input: {
    marginTop: '15px',
  },
  boldText: {
    letterSpacing: '0.4px',
    fontSize: '12px',
    color: '#4C4C66',
  },
  labelHelper: {
    fontWeight: 500,
    fontSize: '9px',
    color: '#958BAE',
  },
  alignItems: {
    alignItems: 'flex-end',
  },
  contentHelper: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '-25px',
    padding: '0 14px',
  },
  spanText: {
    fontSize: '12px',
    textAlign: 'right',
    color: '#4C4C66',
    opacity: '0.31',
  },
  spanTextColor: {
    color: '#BC65F2',
    fontSize: '12px',
    textAlign: 'right',
  },
  link: {
    color: '#BC65F2',
  },
  questionMark: {
    width: '20px',
  },
  radioContent: {
    '& .MuiFormControlLabel-root':{
      background: '#FBFBFF;',
      borderRadius: '9px',
      border: '1px solid #FBFBFB',
      width: '90%',
      '& .MuiTypography-body1':{
        fontWeight: 500,
        fontSize: '13px',
        paddingBottom: '3px'
      }
    },
    width: '90%',
    padding: '0 15px',
    display: 'flex',
    margin: '10px 0',
    justifyContent: 'space-between'
  },
  radioButton: {
    '& .MuiFormControlLabel-root':{
      background: '#FBFBFF;',
      borderRadius: '9px',
      border: '1px solid #FBFBFB',
      width: '90%',
      '& .MuiTypography-body1':{
        fontWeight: 500,
        fontSize: '13px',
        paddingBottom: '3px'
      }
    },
    width: '50%',
    padding: '0 15px',
    display: 'flex',
    margin: '10px 0',
    justifyContent: 'space-between'
  },
  formControlLabel: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '13px',
    lineHeight: '21px',
    color: '#44435E',
    position: 'relative',
    left: '-15px',
  },
  managerWallet: {
    background: '#FBFBFF',
    border: '1px solid #F7F1FF',
    borderRadius: '9px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '40px',
    paddingLeft: '10px',
  },
  managerWalletChip: {
    color: '#8765F2',
    background: 'rgba(137, 99, 244, 0.07)',
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: 12,
    padding: 15,
    [theme.breakpoints.down('xs')] :{
      padding: 0
    }
  },
  comingSoon:{
    color: 'rgba(0, 0, 0, 0.38)',
    fontSize: '9px'
  },
  buttonMargin: {
    marginTop:'0px',
    [theme.breakpoints.down('xs')]: {
      marginTop: '16px'
    },
  }
}))

export const InvestManagerAppointement = (props) => {
  const classes = useStyles()
  const { account } = useWeb3React()

  const { handleBack, handleNext, stepperValues, setStepperValues } = props;
  
  const enableNextButton = 
    stepperValues.dealExecutionMethod !== undefined &&
    stepperValues.canChangeManager !== undefined
    
  return (
    <>
      <Card className={classes.content}>
        <CardContent>
          <Grid container>
            <label className={classes.labelHelper}>
              <b className={classes.boldText}>Deal execution method</b>&nbsp;
            </label>
            <span className={classes.spanTextColor}>
              <Badge overlap="rectangular">
                <img className={classes.questionMark} src={questionMark} alt="?"/>
              </Badge>{' '}
              <a href="!#" target="_blank" className={classes.link}>
                {' '}
                Learn more about execution method
              </a>
            </span>

            <Grid container>
              <Grid item lg={12} md={8} sm={8} xs={12}>
                <RadioGroup row className={classes.radioContent}>
                <Grid item lg={6} md={8} sm={8} xs={12}>
                <FormControlLabel
                    control={
                      <Radio 
                        value="investment manager"
                        checked={stepperValues.dealExecutionMethod === "investment manager"}
                        onChange={(e) => setStepperValues({...stepperValues, dealExecutionMethod: e.target.value})}/>
                    }
                    label="By investment manager" />
                    </Grid>
                  <Grid item lg={6} md={8} sm={8} xs={12}>
                  <FormControlLabel
                    disabled
                    control={
                      <Radio 
                        value="by voting"
                        onChange={(e) => setStepperValues({...stepperValues, dealExecutionMethod: e.target.value})}/>
                    }
                    label="By voting"
                  /><br/><span className={classes.comingSoon}>Will be available later</span></Grid>
                </RadioGroup>
              </Grid>
            </Grid>

            {/* investManagerWallet */}
            <Grid container>
              <Grid
                item
                lg={12}
                md={8}
                sm={8}
                xs={12}
                className={classes.managerWallet}
              >
                <Chip
                  className={classes.managerWalletChip}
                  label={account}
                ></Chip>

                <CopyToClipboardBtn props={account}/>
              </Grid>
            </Grid>
            {/* endOfInvestManagerWallet */}
            {/* abilityToChange */}

            <Grid container className={classes.alignItems}>
              <Grid item lg={12} md={8} sm={8} xs={12}>
                <label className={classes.labelHelper}>
                  <b className={classes.boldText}>
                    Ability to change investment manager,&nbsp;
                  </b>
                  based on voting results
                </label>
              </Grid>
              <Grid item lg={12} md={8} sm={8} xs={12}>
                <RadioGroup row className={classes.radioButton}>
                <Grid item lg={6} md={8} sm={8} xs={12}>
                  <FormControlLabel
                    control={
                      <Radio 
                        value="Yes"
                        checked={stepperValues.canChangeManager === 1}
                        onChange={(e) => setStepperValues({ ...stepperValues, canChangeManager: 1 })}/>
                      }
                    label="Yes"
                  /></Grid>
                  <Grid item lg={6} md={8} sm={8} xs={12}>
                   <FormControlLabel
                    control={
                     <Radio 
                      value="No"
                      checked={stepperValues.canChangeManager === 0}
                      onChange={(e) => setStepperValues({ ...stepperValues, canChangeManager: 0 })}/>
                    }
                    label="No"
                  /></Grid>
                </RadioGroup>
              </Grid>
            </Grid>
            {/* endOfAbilityToChange */}
          </Grid>
        </CardContent>
      </Card>
      <Grid container justifyContent='space-between'>
      <Grid item lg={2} md={3} sm={3} xs={12}>
      <Button  type="third" onClick={() => handleBack()}>Return</Button>
      </Grid>
      <Grid item container lg={2} md={3} sm={3} xs={12} justifyContent="flex-end">
      <Button type="primary"
        onClick={() => handleNext()}
        disabled={!enableNextButton}
        className={classes.buttonMargin}>Next</Button> 
      </Grid>
      </Grid>  
    </>
  )
}
