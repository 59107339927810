import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

import { ProgressComponent } from '../../components/CreateDaoStepper/ProgressComponent'
import { BackgroundImage } from '../../components/BackgroundImage'

import backgroundImg from '../../assets/images/createDatoBackground.svg'
import { Container } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  daoFundSingePage: {
    justifyContent: 'center',
  },
  spacing: {
    padding: '16px 0',
  },
  container: {
    paddingLeft: '24px',
    paddingRight: '24px',
  },
  '@media screen and (max-width: 1000px)': {
    container: {
      display: 'block',
    },
  },
  pageTitle: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '50px',
    lineHeight: '65px',
    color: '#351F8E',
  },
}))

export const DaoCreationPage = (props) => {
  const classes = useStyles(props)

  return (
    <Container>
      <BackgroundImage imageUrl={backgroundImg} />
      <Grid container>
        <h1 className={classes.pageTitle}>Create Vault</h1>
        <Grid item className={classes.spacing} xs={12} md={12} lg={12}>
          <ProgressComponent />
        </Grid>
      </Grid>
    </Container>
  )
}
