// Material-UI imports
import {
  Card,
  CardContent,
  Grid,
  TextField,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useWeb3React } from '@web3-react/core'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router'
import { DaoAPI } from '../../../../api/DaoAPI'


const useStyle = makeStyles((theme) => ({
  root: {
    height: 'fit-content',
    padding: '5px 8px 5px 5px',
    border: `1px solid ${theme.palette.twentySecond.main}`,
    borderRadius: 8,
    backgroundColor: theme.palette.common.white,
    justifyContent: 'space-between',
  },
  inputWrapper: {
    '& > *': {
      fontWeight: 'bold',
      lineHeight: '100%',
    },
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: 'calc(100% - 160px)',
  },
  input: {
    width: "100%",
    '& .MuiOutlinedInput-input':{
      padding:'12.5px 14px'
    },
    '& .MuiOutlinedInput-notchedOutline':{
      borderRadius: '10px'
    },
  },
  divider: {
    borderBottom: '1px solid rgb(224 224 224)'
  },
  cardTitle: {
    fontSize: 13,
    fontWeight: 600,
    fontStyle: 'normal',
    lineHeight: 4,
    margin: 0,
    paddingLeft: '16px'
  },
  cardContent: {
    borderRadius: '18px',
    border: '1px solid rgba(214, 210, 242, 0.5)',
    boxShadow: '0px 51px 89px -49px rgba(196, 193, 232, 0.4)',
    '& .MuiCardContent-root': {
      padding: 0
    }
  },
  fundManagerChip: {
    color: '#8765F2',
    background: 'rgba(137, 99, 244, 0.07)',
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: 15,
    padding: 20
  },
  chipContent: {
    padding: '4px',
    background: '#FDFBFF',
    border: '1px solid #EEE7F8',
    borderRadius: '9px'
  },
  copyAddressButton: {
    background: '#EBE9F0',
    width: '40px',
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: 12,
    color: '#958BAE',
    marginLeft: '10px'
  },
  justifyContent: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  title: {
    fontWeight: 500,
    margin: '14px 0 5px 0',
    fontSize: '1rem',
    color: 'rgba(0, 0, 0, 0.54)',
    lineHeight: '23.87px'
  }
}))

export const AppointNewFundManager = (props:any) => {
  const {func, setIsProposalValid, setProposalError, setProposalMsg} = props;

  const classes = useStyle()
  const location = useLocation();
  const { connector } = useWeb3React();

  const [currentManager, setCurrentManager] = useState<any>({})
  const [newFundManager, setNewFundManager] = useState<any>({
    fundManager: undefined,
    func: 'appointNewFundManager'
  })
  
  let paths = location.pathname.split('/');
  let daoId = paths[paths.length - 1];

  const inputValidation = 
    newFundManager?.fundManager !== undefined ||
    newFundManager?.fundManager ?
      newFundManager?.fundManager.length === 42
      : null
  
  const proposalValidation =
    newFundManager?.fundManager ?
      currentManager.toString().toLowerCase() === newFundManager?.fundManager.toString().toLowerCase()
      : false;
  
  useEffect(()=>{
    setIsProposalValid(inputValidation)
    if(proposalValidation){
      setProposalError(true);
      setProposalMsg('You cannot create a proposal with the same fund manager that is already present.')
    }else{
      setProposalError(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[inputValidation, newFundManager])

  useEffect(() => {
    const decodeAddresses = async () => {
      try {
        setCurrentManager(await DaoAPI.getManager(daoId, connector))
      } catch (e) {
        console.log('Error message:', e)
      }
    }
    decodeAddresses()
      .catch(console.error)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(()=>{
    func(newFundManager)
  })

  return (
      <Card className={classes.cardContent}>
        <div className={classes.divider}><p className={classes.cardTitle}>Appoint new fund manager</p></div>
        <CardContent style={{ padding: '16px' }}>
          <p className={classes.title}>Wallet address</p>
          <Grid container>
            <Grid item lg={12} xs={12}>
              <TextField
                className={classes.input}
                variant="outlined"
                type="text"
                onChange={(e: any) => setNewFundManager({ ...newFundManager, fundManager: e.target.value })}
                placeholder='Enter address'
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
  )
}
