import React, { useEffect } from 'react'

const Cookie3Script: React.FC = () => {
  useEffect(() => {
    const script = document.createElement('script')
    script.src =
      'https://cdn.cookie3.co/scripts/analytics/0.11.4/cookie3.analytics.min.js'
    script.integrity =
      'sha384-lzDmDdr/zEhMdlE+N04MgISCyL3RIWNCb9LjsrQeEFi8Gy5CKXIRI+u58ZV+ybYz'
    script.crossOrigin = 'anonymous'
    script.async = true
    script.dataset.siteId = '1362'
    script.dataset.chainTrackingEnabled = 'true'
    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [])

  return null
}

export default Cookie3Script
