import { useState, useEffect } from 'react'
import { useLocation } from 'react-router'
import web3 from 'web3'

// Material-UI imports
import {
  Card,
  CardContent,
  Grid,
  TextField,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { NumericFormat } from 'react-number-format'
import { decodeVotingParams } from '../../../helpers/abiDecoder'
import BigNumber from 'bignumber.js'
import { useSelector } from 'react-redux'

const useStyle = makeStyles(() => ({
  divider: {
    borderBottom: '1px solid rgb(224 224 224)',
  },
  cardTitle: {
    fontSize: 13,
    fontWeight: 600,
    fontStyle: 'normal',
    lineHeight: 4,
    margin: 0,
    paddingLeft: '16px',
  },
  cardContent: {
    borderRadius: '18px 0px 0px 18px',
    border: '1px solid rgba(214, 210, 242, 0.5)',
    boxShadow: '0px 51px 89px -49px rgba(196, 193, 232, 0.4)',
    '& .MuiCardContent-root': {
      padding: 0,
    },
  },
  contentHelper: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '-25px',
    padding: '0 14px'
  },
  labelHelper: {
    fontWeight: 500,
    fontSize: '9px',
    color: '#958BAE',
  },
  contentHelper2: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    marginBottom: '-25px',
    padding: '0 14px'
  },
  boldText: {
    letterSpacing: '0.4px',
    fontSize: '12px',
    color: '#4C4C66',
  },
  spanTextColor: {
    color: '#BC65F2',
    fontSize: '12px',
    textAlign: 'right',
  },
  questionMark: {
    width: '20px'
  },
  link: {
    color: '#BC65F2'
  },
  inputContent: {
    "& .MuiInputBase-input": {
      padding: '10.5px 14px',
      background: '#FBFBFF',
    },
    "& .MuiInputBase-root": {
      borderRadius: '9px'
    },
    display: 'grid',
    fontSize: '12px',
    fontWeight: 500,
    padding: '15px 10px',
  },
  input: {
    marginTop: '15px',
  },
  exampleContent: {
    fontSize: '8px',
    height: '100%',
  },
  exampleText: {
    lineHeight: '22px',
    marginTop: '0px',
    color: '#958BAE',
    margin: '8px'
  },
  exampleTitle: {
    color: '#BC65F2',
    margin: '5px 8px'
  },
  alignItems: {
    alignItems: 'flex-end'
  },
  cardContentSecondary: {
    borderRadius: '0px 18px  18px 0px',
    border: '1px solid rgba(214, 210, 242, 0.5)',
    boxShadow: '0px 51px 89px -49px rgba(196, 193, 232, 0.4)',
    width: '100%',
  },
}))

export const VoteForChangeVotingParameters = (props) => {
  const classes = useStyle()
  const votingAddress = useSelector((state:any) => state?.daos?.dao?.data?.subgraph?.daoCreationLog?.voting)
  
  const { hexToNumber } = web3.utils

  const location = useLocation();

  const [proposedVotingConfig, setProposedVotingConfig] = useState({
    participationRequiredPct: 0,
    supportRequiredPct: 0,
    minDuration: 0,
    minRequiredTokenPct: 0,
    votingAddress: votingAddress,
    func: 'changeVotingConfig'
  })

  useEffect(() => {
    const decodeVotingConfig = async () => {
      try {
        const result = decodeVotingParams(props?.action)
        setProposedVotingConfig({ 
          ...proposedVotingConfig,
          participationRequiredPct: hexToNumber(result.inputs[0] / (10 ** 16)),
          supportRequiredPct: hexToNumber(result.inputs[1] / (10 ** 16)),
          minRequiredTokenPct: hexToNumber(result.inputs[2] / (10 ** 16)),
          minDuration: (new BigNumber(hexToNumber(result.inputs[3]?._hex)).dividedBy(3600)).toNumber()
        })
      } catch (e) {
        console.log('Error message:', e)
      }
    }
    decodeVotingConfig()
      .catch(console.error)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const limitedValue = (inputObj) => {
    const { value } = inputObj
    if (value <= 100) return true
    return false
  }

  return (
    <>
      <Grid container>
        <Grid item lg={6} md={6} sm={6} xs={12}>
          <Card className={classes.cardContent}>
            <div className={classes.divider}>
              <p className={classes.cardTitle}>Proposed voting parameters</p>
            </div>
            <CardContent style={{ padding: '16px' }}>
              <Grid container className={classes.alignItems}>
                <Grid item lg={12} md={8} sm={8} xs={12}>
                  <div className={classes.inputContent}>
                    <label className={classes.labelHelper}>
                      <b className={classes.boldText}>Quorum, </b> all minted LP tokens
                    </label>
                    <NumericFormat
                      customInput={TextField}
                      disabled
                      value={proposedVotingConfig.participationRequiredPct}
                      variant="outlined"
                      className={classes.input}
                      placeholder='51%'
                      valueIsNumericString={true}
                      allowNegative={false}
                      suffix={'%'}
                      isAllowed={limitedValue}
                    />
                  </div>
                </Grid>
                <Grid item lg={12} md={8} sm={8} xs={12}>
                  <div className={classes.inputContent}>
                    <label className={classes.labelHelper}>
                      <b className={classes.boldText}>Minimum Approval, </b>  % of voted LP tokens
                    </label>
                    <NumericFormat
                      customInput={TextField}
                      disabled
                      value={proposedVotingConfig.supportRequiredPct}
                      variant="outlined"
                      className={classes.input}
                      placeholder='51%'
                      valueIsNumericString={true}
                      allowNegative={false}
                      suffix={'%'}
                      isAllowed={limitedValue}
                    />
                  </div>
                </Grid>
                <Grid item lg={12} md={8} sm={8} xs={12}>
                  <div className={classes.inputContent}>
                    <label className={classes.labelHelper}>
                      <b className={classes.boldText}>Voting Duration, </b> hours
                    </label>
                    <NumericFormat
                      disabled
                      value={proposedVotingConfig?.minDuration}
                      customInput={TextField}
                      variant="outlined"
                      className={classes.input}
                      placeholder='0'
                      valueIsNumericString={true}
                      allowNegative={false}
                    />
                  </div>
                </Grid>
                <Grid item lg={12} md={8} sm={8} xs={12}>
                  <div className={classes.inputContent}>
                    <label className={classes.labelHelper}>
                      <b className={classes.boldText}>Min % of LP tokens to start Voting,<br/> </b> % of all minted LP tokens
                    </label>
                    <NumericFormat
                      disabled
                      value={proposedVotingConfig.minRequiredTokenPct}
                      customInput={TextField}
                      variant="outlined"
                      className={classes.input}
                      placeholder='0'
                      valueIsNumericString={true}
                      allowNegative={false}
                      suffix={'%'}
                      isAllowed={limitedValue}
                    />
                  </div>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item lg={6} md={6} sm={6} xs={12}>
          <Card className={classes.cardContentSecondary}>
            <div className={classes.divider}>
              <p className={classes.cardTitle}>Current voting parameters</p>
            </div>
            <CardContent style={{ padding: '16px' }}>
              <Grid container className={classes.alignItems}>
                <Grid item lg={12} md={8} sm={8} xs={12}>
                  <div className={classes.inputContent}>
                    <label className={classes.labelHelper}>
                      <b className={classes.boldText}>Quorum, </b> all minted LP tokens
                    </label>
                    <NumericFormat
                      customInput={TextField}
                      disabled
                      value={location.state.state.data.subgraph.packages[0].pkg.participationRequiredPct / (10 ** 16)}
                      variant="outlined"
                      className={classes.input}
                      placeholder='51%'
                      valueIsNumericString={true}
                      allowNegative={false}
                      suffix={'%'}
                      isAllowed={limitedValue}
                    />
                  </div>
                </Grid>
                <Grid item lg={12} md={8} sm={8} xs={12}>
                  <div className={classes.inputContent}>
                    <label className={classes.labelHelper}>
                      <b className={classes.boldText}>Minimum Approval, </b>  % of voted LP tokens
                    </label>
                    <NumericFormat
                      customInput={TextField}
                      disabled
                      value={location.state.state.data.subgraph.packages[0].pkg.supportRequiredPct / (10 ** 16)}
                      variant="outlined"
                      className={classes.input}
                      valueIsNumericString={true}
                      allowNegative={false}
                      suffix={'%'}
                      isAllowed={limitedValue}
                    />
                  </div>
                </Grid>
                <Grid item lg={12} md={8} sm={8} xs={12}>
                  <div className={classes.inputContent}>
                    <label className={classes.labelHelper}>
                      <b className={classes.boldText}>Voting Duration, </b> hours
                    </label>
                    <NumericFormat
                      disabled
                      value={new BigNumber(location.state.state.data.subgraph.packages[0].pkg.minDuration).dividedBy(3600).toString()}
                      customInput={TextField}
                      variant="outlined"
                      className={classes.input}
                      placeholder='0'
                      valueIsNumericString={true}
                      allowNegative={false}
                    />
                  </div>
                </Grid>
                <Grid item lg={12} md={8} sm={8} xs={12}>
                  <div className={classes.inputContent}>
                    <label className={classes.labelHelper}>
                      <b className={classes.boldText}>Min % of LP tokens to start Voting,<br/> </b> % of all minted LP tokens
                    </label>
                    <NumericFormat
                      disabled
                      value={location.state.state.data.subgraph.packages[0].pkg.minRequiredTokenPct / (10 ** 16)}
                      customInput={TextField}
                      variant="outlined"
                      className={classes.input}
                      placeholder='0'
                      valueIsNumericString={true}
                      allowNegative={false}
                      suffix={'%'}
                      isAllowed={limitedValue}
                    />
                  </div>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  )
}
