/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router'
import { useWeb3React } from '@web3-react/core'

import { DaoAPI } from '../../api/DaoAPI'
import { useDefiProtocols } from '../../hooks/useChain'

import { ID, routes } from '../../const/routes'
import moment from 'moment'

// Material UI
import { makeStyles } from '@material-ui/core/styles'
import { Card, CardContent, Container, Grid } from '@material-ui/core'

//Assets Import
import walletConnectIcon from '../../assets/icons/walletconnect-icon.svg'
import wallet from '../../assets/icons/walletBlack.svg'
import PageBackground from '../../assets/images/singePageBackground.svg'

// Components
import { BackgroundImage } from '../../components/BackgroundImage'
import { NavigationBar } from '../../components/NavigationBar'
import { NetworkExamples } from '../../components/WalletConnectComponents/NetworkExamples'
import { TransferFund } from '../../components/WalletConnectComponents/TransferFund'
import { WalletConnectComponent } from '../../components/WalletConnectComponents/WalletConnect'
import { ErrorMessage } from '../../components/ErrorMessage/ErrorMessage'

const useStyle = makeStyles((theme) => ({
  mainCard: {
    border: '1px solid rgba(214, 210, 242, 0.5)',
    boxShadow: '0px 51px 89px rgba(196, 193, 232, 0.4)',
    borderRadius: '18px',
    margin: '30px 0',
    borderRight: '18px',
  },
  card: {
    border: '1px solid rgba(214, 210, 242, 0.5)',
    boxShadow: '0px 51px 89px rgba(196, 193, 232, 0.4)',
    borderRadius: '18px',
    height: '100%',
    cursor: 'grab',
    '&:hover': {
      borderColor: '#BC65F2',
    },
  },
  title: {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '13px',
    lineHeight: '217%',
    color: '#4C4C66',
    marginTop: '10px',
  },
  description: {
    fontWeight: 400,
    fontSize: '11px',
    lineHeight: '160%',
    color: '#979797',
  },
  custom: {
    fontWeight: 400,
    fontSize: '13px',
    lineHeight: '217%',
    color: '#4C4C66',
  },
  cardTitleContent: {
    padding: '16px',
    borderBottom: '1px solid rgba(214, 210, 242, 0.5)',
    '& p': {
      margin: 0,
      fontWeight: 600,
      fontSize: '16px',
      color: '#4C4C66',
    },
  },
  mainTitle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
    [theme.breakpoints.up('md')]: {
      padding: '0 19px',
    },
  },
  titleContent: {
    display: 'flex',
  },
  logo: {
    width: '65px',
    height: '65px',
    [theme.breakpoints.down('xs')]: {
      width: '55px',
      height: '55px',
    },
  },
  daoName: {
    fontSize: '50px',
    fontWeight: 600,
    color: '#351F8E',
    marginLeft: '12px',
    marginTop: 0,
    display: 'flex',
    alignItems: 'self-start',
    [theme.breakpoints.down('xs')]: {
      marginLeft: '5px',
      fontSize: '30px',
      wordBreak: 'break-all',
    },
  },
  badge: {
    background: '#8765F2',
    borderRadius: '57px',
    height: '100%',
    padding: '1px 10px',
    color: 'white',
    fontSize: '13px',
    fontWeight: 500,
  },
}))

export const CreateTransaction = () => {
  const classes = useStyle()
  const location = useLocation()
  const { account, chainId, connector } = useWeb3React()

  const pathname = window.location.pathname
  const paths = location.pathname.split('/')

  const { subgraph } = location?.state?.dao?.data

  const daoAddress = paths[paths.length - 1]

  const [trustedTransfers, setTrustedTransfers] = useState([])
  const [lowerCaseTrustedForwarders] = useState([])
  const [daoConfig, setDaoConfig] = useState()
  const [daoFinishDate, setDaoFinishDate] = useState(new Date())
  const todayDate = moment().format('YYYY/MM/DD')
  const formatedDaoFinishDate = moment(daoFinishDate).format('YYYY/MM/DD')

  const [supportedDefiProtocols, setSupportedDefiProtocols] = useState()
  const selectedDefiProtocols = useDefiProtocols()

  useEffect(() => {
    const fetchTrustedTransfers = async () => {
      try {
        setTrustedTransfers(
          await DaoAPI.getTrustedTransfers(subgraph?.id, connector)
        )
        setDaoConfig(await DaoAPI.getConfiguration(subgraph?.id, connector))
      } catch (e) {
        console.log('Error message:', e)
      }
    }
    setSupportedDefiProtocols(selectedDefiProtocols)
    setDaoFinishDate(new Date(daoConfig?.finishTime * 1000).toDateString())
    fetchTrustedTransfers()
  }, [daoConfig?.finishTime, subgraph?.id, selectedDefiProtocols, connector])

  const [walletConnect, setWalletConnect] = useState(false)
  const [transferFund, setTransferFund] = useState(false)

  const showWalletConnect = () => {
    trustedTransfers.forEach((element) => {
      lowerCaseTrustedForwarders.push(element.toLowerCase())
    })
    setWalletConnect(true)
    setTransferFund(false)
  }

  const DisableDirectTransfer = () => {
    if (moment().unix() >= daoConfig?.finishTime) {
      return (
        <Card className={classes.card} onClick={showDirectTransfer}>
          <CardContent>
            <Grid item lg={3}>
              <img src={wallet} width={40} alt="wallet connect" />
            </Grid>
            <p className={classes.title}>Transfer funds</p>
            <p className={classes.description}>
              Transfer DAO funds directly to someone’s personal wallet
            </p>
          </CardContent>
        </Card>
      )
    }
    return (
      <Card className={classes.card}>
        <CardContent>
          <Grid item lg={3}>
            <img src={wallet} width={40} alt="wallet connect" />
          </Grid>
          <p className={classes.title}>Transfer funds</p>
          <ErrorMessage message="Direct Transfer is disabled during fundraising phase" />
        </CardContent>
      </Card>
    )
  }

  const showDirectTransfer = () => {
    trustedTransfers.forEach((element) => {
      lowerCaseTrustedForwarders.push(element.toLowerCase())
    })
    setWalletConnect(false)
    setTransferFund(true)
  }

  return (
    <Container>
      <BackgroundImage imageUrl={PageBackground} />
      <NavigationBar
        link={routes.FUNDS.replace(ID, daoAddress)}
        pathName={pathname}
      />
      <Grid item lg={12} sm={12} md={12} xs={12}>
        <div className={classes.mainTitle}>
          <div className={classes.titleContent}>
            <img
              src={location?.state?.dao?.img}
              className={classes.logo}
              alt="daologo"
            />
            <h1 className={classes.daoName}>{subgraph.name}</h1>
            <span className={classes.badge}>{subgraph.token.symbol}</span>
          </div>
        </div>
      </Grid>
      <Card className={classes.mainCard}>
        <div className={classes.cardTitleContent}>
          <p>Transfer</p>
        </div>
        <CardContent>
          <Container>
            <p className={classes.custom}>Custom transaction(2)</p>
            <Grid container spacing={3}>
              <Grid item lg={3} md={6} sm={6} xs={12}>
                <Card className={classes.card} onClick={showWalletConnect}>
                  <CardContent>
                    <Grid item lg={3} md={6} sm={6} xs={12}>
                      <img
                        src={walletConnectIcon}
                        width={40}
                        alt="wallet connect"
                      />
                    </Grid>
                    <p className={classes.title}>Wallet Connect</p>
                    <p className={classes.description}>
                      Connect DAO to any dApp that supports{' '}
                    </p>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item lg={3} md={6} sm={6}>
                {/* <Card className={classes.card} onClick={showDirectTransfer}>
                  <CardContent>
                    <Grid item lg={3}>
                      <img src={wallet} width={40} alt="wallet connect" />
                    </Grid>
                    <p className={classes.title}>Transfer funds</p>
                    <p className={classes.description}>Transfer DAO funds directly to someone’s personal wallet</p>
                  </CardContent>
                </Card> */}
                <DisableDirectTransfer />
              </Grid>
            </Grid>
          </Container>
          {walletConnect === false && transferFund === false ? (
            <NetworkExamples chainId={chainId} />
          ) : null}
          {walletConnect && (
            <WalletConnectComponent
              trustedTransfers={lowerCaseTrustedForwarders}
              dao={location.state.dao}
              daoConfig={daoConfig}
              supportedDefiProtocols={supportedDefiProtocols}
            />
          )}
          {transferFund && (
            <TransferFund
              trustedTransfers={lowerCaseTrustedForwarders}
              account={account}
              dao={location.state.dao}
            />
          )}
        </CardContent>
      </Card>
    </Container>
  )
}
