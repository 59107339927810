import { useMemo } from 'react'

// Material UI
import { Container } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

// Components
import { NavigationBar } from '../../components/NavigationBar'
import { SectionPools } from '../../components/SectionPools'
import { Tabs } from '../../components'
import { BackgroundImage } from '../../components/BackgroundImage'

// Assets
import singlePageBackground from '../../assets/images/singePageBackground.svg'

import { PoolStatuses } from '../../const/poolStatuses'
import { usePools } from '../../hooks'
import { getPoolStatus } from '../../helpers/getPoolStatus'

const useStyles = makeStyles((theme) => ({
  tabs: {
    '@media screen and (max-width: 360px)': {
      margin: '0 0 40px 0',
    },
  },
  withoutPools: {
    marginTop: '0 !important',
  },
  sectionPoolsHeaderContent: {
    display: 'none',
  },
  sectionPoolBackground: {
    background: 'transparent',
  },
}))

const filterValues = [
  { value: 'Popular Funds' },
  { value: '24h growth' },
  { value: 'Total growth' },
  { value: 'Largest Funds' },
  { value: 'Max deposit' },
  { value: 'ETH' },
  { value: 'USDT' },
  { value: 'USDC' },
]

export const Pools = (props) => {
  const classes = useStyles(props)
  const pools = usePools()

  const pathname = window.location.pathname

  const closedPools = useMemo(() => {
    return pools.filter((pool) => getPoolStatus(pool) === PoolStatuses.Closed)
  }, [pools])

  return (
    <Container>
      <BackgroundImage imageUrl={singlePageBackground} />

      {closedPools.length > 0 && (
        <div className={classes.container}>
          <NavigationBar pathName={pathname} />
          <Tabs
            className={classes.tabs}
            title="Choose where to invest"
            filterValue={filterValues}
          />
          <SectionPools
            type={PoolStatuses.Closed}
            sectionPoolsHeaderContent={classes.sectionPoolsHeaderContent}
            sectionPoolBackground={classes.sectionPoolBackground}
          />
        </div>
      )}
    </Container>
  )
}
