import { useMemo } from 'react'
import { formatMillisecondsToDays } from '../utils/formatSeconds'

type Props = {
  date: Date,
}

export const InDays = ({date}: Props) => {
  const daysLeft = useMemo(() => {
    const nowTime = new Date().getTime()
    return nowTime >= date.getTime()
      ? formatMillisecondsToDays(0)
      : formatMillisecondsToDays(date.getTime() - nowTime)
  }, [date])

  return (
    <>{daysLeft}</>
  )
}
