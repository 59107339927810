import { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'

// Constants
import { PoolStatuses } from '../const/poolStatuses'
import { routes, SLUG } from '../const/routes'

// Material Ui
import { Box } from '@material-ui/core'
import { Container, LinearProgress } from '@material-ui/core'
import { Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import Typography from '@material-ui/core/Typography'

// Other imports
import { getDecimals, getDoTheMoonFactoryInstance } from '../api/wallet'
import { getPoolStatus } from '../helpers/getPoolStatus'
import { InDays } from './InDays'
import { moneyFormatter } from '../helpers/moneyFormatter'
import { Pool } from '../models/Pool'
import { useWeb3React } from '@web3-react/core'
import blockChainIcon from '../assets/icons/BTC.svg'
import {ChipLabel} from './Chip'

const useStyles = makeStyles(
  (theme) => ({
    root: {
      backgroundColor: theme.palette.poolCardBackground.main,
      padding: 24,
      borderRadius: 12,
      color: theme.palette.white.main,
      flex: '0 0 385px',
      transition: 'background-color 0.2s',
      height: '100%',

      '&:hover': {
        backgroundColor: theme.palette.poolCardBackgroundHover.main,
        cursor: 'pointer',
        backdropFilter: 'blur(4px)',
      },
    },
    '@media screen and (max-width: 425px)': {
      root: {
        flex: '0 0 277px',
      },
    },
    content: {
      margin: '100px 0 100px 0',
      display: 'flex',
      justifyContent: 'space-between',
      '@media screen and (max-width: 644px)': {
        margin: '50px 0 50px 0',
      },
      '@media screen and (max-width: 320px)': {
        margin: '36px 0 50px 0',
      },
    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
    title: {
      display: 'inline-grid',
      fontSize: 22,
      fontWeight: 'bold',
      marginLeft: '25px',
      width: '100%',
      fontFamily: 'Poppins',
    },
    pos: {
      marginBottom: 12,
    },
    primaryCard: {
      backgroundColor: '#FFFFFF',
      borderRadius: 12,
      width: '100%',
    },
    textSm: {
      fontSize: 12,
      fontWeight: 'bold',
      padding: 30,
      display: 'flex',
      justifyContent: 'flex-start',
      background: '#FCFAFF',
      borderBottom: '1px solid #EDEEFF',
    },
    FundSectionBackground: {
      backgroundColor: '#F5F7FE',
      paddingBottom: '20px',
    },
    title1: {
      fontFamily: theme.fontFamilies.fourth,
      color: theme.palette.primary.main,
      margin: '0 0 20px 0',
    },
    link: {
      display: 'flex',
      flexBasis: '140px',
      justifyContent: 'space-between',
      alignContent: 'center',
      alignItems: 'center',
      justify: 'center',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    footerContent: {
      padding: '0 0 64px 0',
      '& > a': {
        fontWeight: 'bold',
        fontSize: 18,
        lineHeight: '22px',
        color: theme.palette.primary.main,
        textDecoration: 'none',
      },
      justifyContent: 'center',
    },
    '@media screen and (max-width: 320px)': {
      footerContent: {
        padding: '0 0 44px 0',
      },
    },
    '@media screen and (max-width: 644px)': {
      footerContent: {
        padding: '0 0 44px 0',
      },
    },
    buttonPrimary: {
      fontFamily: theme.fontFamilies.fourth,
      color: theme.palette.primary.main,
      margin: '0 0 20px 0',
      border: '1px solid',
      borderColor: theme.palette.primary.main,
    },
    textSmDisplay: {
      display: 'inline-grid',
      padding: 0,
      fontFamily: 'Poppins',
    },
    spanText: {
      fontSize: 23,
      fontWeight: 'bold',
    },
    fontSize12: {
      color: 'rgba(0, 0, 0, 0.54)',
      fontSize: 10,
    },
    descriptionTitle: {
      fontSize: 10,
      paddingBottom: 5,
      fontFamily: 'Poppins',
    },
    progressBar: {
      height: '6px',
      background: '#F5EEFD',
      borderRadius: '53px',
      margin: '5px 15px'
    },
    progressContainer: {
      margin: '0 27px 27px',
      background: '#FDFBFF',
      border: '1px solid #F7F1FF',
      borderRadius: 9,
      fontFamily:'Poppins'
    },
    progressText: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: 15,
    },
    progressPercentage: {
      color: '#8765F2',
      fontWeight: 800,
      fontSize: 12,
      fontFamily: 'Poppins',
    },
    progressTitle: {
      fontSize: 10,
      marginTop: 10,
      marginLeft: 15,
      fontFamily:'Poppins'
    },
    section1: {
      justifyContent: 'space-between',
      alignItems: 'center',
      '& > img': {
        height: 48,
      },
    },
    section2: {
      fontSize: 28,
      lineHeight: '34px',
      fontWeight: 'bold',
      margin: '20px 0 0 0',
    },
    info: {
      display: 'flex',
      flexDirection: 'column',
    },
    detailsSection: {
      justifyContent: 'space-between',
      paddingTop: '20px',
    },
    time: {
      display: 'inline-block',
      color: theme.palette.eighth.main,
      fontWeight: 600,
      fontSize: 12,
      lineHeight: '15px',
      backgroundColor: theme.palette.twelfth.main,
      padding: '5px 8px 5px 8px',
      borderRadius: 6,
    },
    infoTitle: {
      fontWeight: 500,
      fontSize: 14,
      lineHeight: '17px',
      margin: '0 0 6px 0',
    },
    infoValue: {
      fontWeight: 600,
      fontSize: 22,
      lineHeight: '29px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    accessValue: {
      '&:first-letter': {
        textTransform: 'uppercase',
      },
    },
    button: {
      margin: '24px 0 0 0',
      width: 'inherit',
    },
    blockChainIconContent: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignSelf: 'self-end',
      marginBottom: '6px',
    },
    blockChainImg: {
      width: '20px',
      height: '20px',
    },
    text: {
      fontSize: 12,
      fontWeight: 'bold',
      padding: 30,
      display: 'flex',
      justifyContent: 'flex-start',
    },
    changeText: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: '0 30px',
    },
    separator: {
      border: '1px solid #EDEEFF',
      marginTop: 25,
      width: '86%',
    },
    description: {
      padding: '20px 30px',
    },
    descriptionContent: {
      fontSize: 9,
      fontFamily: 'Poppins',
      fontWeight: 500,
    },
    poolCardWidth:{
      width:'100%'
    }
  }),
  { name: 'PoolCard' }
)

type Props = {
  className?: string
  access: 'Public' | 'Private'
  pool: Pool
}

export const PoolCard = ({ pool }: Props) => {
  const classes = useStyles()
  const navigate = useNavigate()
  const { connector } = useWeb3React()
  const value = 30;

  const [totalStaked, setTotalStaked] = useState<string>('TBD')

  const {
    address,
    iconSrc,
    investFinishTime,
    name,
    projectDescription,
    slug,
    userMaxInvest,
    userMinInvest,
  } = pool

  useEffect(() => {
    fetchTotalStaked(address, connector)
  }, [connector, address])

  async function fetchTotalStaked(poolAddress: string, userConnector: any) {
    if (!userConnector || !poolAddress) {
      return
    }
    const poolInstance = getDoTheMoonFactoryInstance(poolAddress, userConnector)
    try {
      const value = await poolInstance.totalStaked()
      const depositTokenAddress = await poolInstance.depositToken()
      const decimals = await getDecimals(depositTokenAddress, userConnector)
      const totalStaked = value.toString() / Math.pow(10, decimals)
      setTotalStaked(totalStaked.toString())
    } catch (e) {
      console.log('Error message:', e)
      setTotalStaked('TBD')
    }
  }

  const status = useMemo(() => getPoolStatus(pool), [pool])

  const getPoolStatusLabel = () => {
    if (status === PoolStatuses.Upcoming && pool.address) {
      return (
        <span className={classes.time}>
          in <InDays date={new Date(investFinishTime)} /> days
        </span>
      )
    }
    if (status === PoolStatuses.Upcoming && !pool.address) {
      return <span className={classes.time}>in TBA</span>
    }
    if (status === PoolStatuses.Live) {
      return <span className={classes.time}>active</span>
    }
    return null
  }

  return (
    <Grid item lg={4} md={6} sm={6}>
      <div onClick={() => navigate(routes.POOL.replace(SLUG, slug))} className={classes.poolCardWidth}>
        <Card
          className={classes.root && classes.primaryCard}
          variant="outlined"
        >
          <Box display="flex" className={classes.textSm}>
            <img src={iconSrc} alt="card logo" width={75} />
            {getPoolStatusLabel()}
            <Typography variant="h4" component={'div'} className={classes.title}>
              {name}
              <ChipLabel
                size="medium"
                type="public"
                label="Public"
              ></ChipLabel>
            </Typography>
            <div className={classes.blockChainIconContent}>
              <img
                src={blockChainIcon}
                className={classes.blockChainImg}
                alt="icon"
              />
            </div>
          </Box>
          <Box display="flex" className={classes.text}>
            <Container className={classes.textSmDisplay}>
              <Typography component={'div'} className={classes.fontSize12} color="textSecondary">
                Funding Target
              </Typography>
              <span
                className={classes.spanText}
                title={totalStaked?.toString()}
              >
                {totalStaked}
              </span>
            </Container>
            <Container className={classes.textSmDisplay}>
              <Typography component={'div'} color="textSecondary" className={classes.fontSize12}>
                Deposit Range{' '}
              </Typography>
              <span className={classes.spanText}>
                ${moneyFormatter(userMinInvest)} - ${moneyFormatter(userMaxInvest)}
              </span>
            </Container>
          </Box>
          <div className={classes.progressContainer}>
            <Typography component={'div'} className={classes.progressTitle} color="textSecondary">
              Progress
            </Typography>
            <Box>
              <LinearProgress
                value={value}
                className={classes.progressBar}
                variant="determinate"
              />
            </Box>
            <div className={classes.progressText}>
              <div>
                <Typography component={'div'} className={classes.progressPercentage}>
                  30%
                </Typography>
              </div>
              <div>
                <Typography component={'div'} style={{ fontFamily: 'Poppins', fontSize: '12px' }}>
                  <b>$500k</b> from $1.5 min
                </Typography>
              </div>
            </div>
          </div>
          <div className={classes.changeText}>
            <Typography component={'div'} className={classes.fontSize12}>
              Change 24h:{' '}
              <ChipLabel
                size="small"
                type="change24h"
                label="+9.43%"
              ></ChipLabel>
            </Typography>
            <Typography component={'div'} className={classes.fontSize12}>
              Total Change:{' '}
              <ChipLabel
                size="small"
                type="totalChange"
                label="-4.64%"
              ></ChipLabel>
            </Typography>
          </div>
          <hr className={classes.separator}></hr>
          <div className={classes.description}>
            <Typography
              component={'div'}
              className={classes.descriptionTitle}
              color="textSecondary"
            >
              Description
            </Typography>
            <Typography component={'div'} className={classes.descriptionContent}>
              {projectDescription}
            </Typography>
          </div>
        </Card>
      </div>
    </Grid>
  )
}