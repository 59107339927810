/* eslint-disable no-throw-literal */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router'

// Material UI imports
import {
  Card,
  CardContent,
  Container,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  Select,
  TextField,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

// Web3 Imports
import { useWeb3React } from '@web3-react/core'

// API
import { VotingAPI } from '../../../api/VotingAPI'
import { DaoAPI } from '../../../api/DaoAPI'

// Image imports
import backgroundImg from '../../../assets/images/proposalBackground.svg'
import CreateProposalImg from '../../../assets/images/createDaoModal.svg'

// Component imports
import { BackgroundImage } from '../../BackgroundImage'
import { Button } from '../../Button'
import { NavigationBar } from '../../NavigationBar'

import { AddNewAllowlistedAddress } from './components/AddNewAllowlistedAddress'
import { AppointNewFundManager } from './components/AppointNewFundManager'
import { ChangeDepositLimits } from './components/ChangeDepositLimit'
import { ChangeEntranceFee } from './components/ChangeEntranceFee'
import { ChangeManagerFundFee } from './components/ChangeFundManagerFee'
import { ChangeVotingParameters } from './components/ChangeVotingParameters'
import { ChangePerformanceFee } from './components/ChangePerformanceFee'
import { ChangeRageQuitFine } from './components/ChangeRageQuitFine'
import { ChangeSpendingLimits } from './components/ChangeSpendingLimits'
import { ChangeTokenAvailableForDeposit } from './components/ChangeTokenForDeposit'
import { RemoveTokenForDeposit } from './components/RemoveTokenForDeposit'
import { RemoveTokenForTrading } from './components/RemoveTokenForTrading'
import { AllowlisedWalletsToDeposit } from './components/AddAllowlistedWalletsToDeposit'
import { RemoveAllowlistedWalletsToDeposit } from './components/RemoveAllowlistedWalletsToDeposit'
import { ChangeTokenLockUpPeriod } from './components/ChangeTokenLockUpPeriod'
import { ChangeWithdrawalWindows } from './components/ChangeWithdrawalWindows'
import { DeleteAllowlistedAddress } from './components/DeleteAllowlistedAddress'
import { CreateAdHocTransaction } from './components/CreateAdHocTransaction'
// import { AllowlisedWalletsToDeposit } from './components/AllowlistedWalletsToDeposit'
// import { ChangeVotingPowerType } from './components/ChangeVotingPower'
// import { CreateStakingInvestment } from './components/CreateStakingInvestment'
// import { DismissCurrentFundManager } from './components/DismissCurrentFundManager'

// Modals
import { ModalSuccess } from '../../ModalSuccess/ModalSuccess'
import { ModalLoading } from '../../ModalLoading/ModalLoading'

// Constants
import { routes, ID } from '../../../const/routes'
import {
  managersProposals,
  membersProposals,
  canChangeManagerManagersProposals,
  canChangeManagerMembersProposals,
} from '../../../const/votingProposalPermissions'

import Swal from 'sweetalert2'
import { hoursToUnix } from '../../../helpers/formatDate'
import { useSelector } from 'react-redux'
import { ChangeTokenAvailableForTrading } from './components/ChangeTokenForTrading'
import { ChangeFundraisingPhase } from './components/ChangeFundraisingPhase'
import { UpgradeDaoContract } from './components/UpgradeDaoContract'

const useStyles = makeStyles((theme) => ({
  input: {
    width: '100%',
   '& .MuiOutlinedInput-input':{
     padding: '12.5px 14px'
   },
   '& .MuiOutlinedInput-inputMultiline':{
     padding: 0
   },
   '& .MuiOutlinedInput-notchedOutline':{
     borderRadius: '10px'
   }
  },
  inputSelect:{
    width: '100%',
    "& .MuiOutlinedInput-root":{
      borderRadius: '10px',
      background: '#8765F2'
    },
    "& .Mui-focused" :{
      borderRadius: '10px 10px 0px 0px'
    }
  },
  labelContent:{
      margin: '20px 0 8px 0',
      fontFamily:'Poppins'
  },
  content:{
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    borderRadius: '18px',
    border: '1px solid rgba(214, 210, 242, 0.5)',
    boxShadow: '0px 51px 89px -49px rgba(196, 193, 232, 0.4)',
    marginLeft: '10px',
    height:'100%',
    [theme.breakpoints.down('sm')]:{
      margin: '15px 0'
    }
  },
  titleContent: {
    display: 'flex'
  },
  title: {
    fontSize: '50px',
    color: '#351F8E',
    marginLeft: '25px',
    [theme.breakpoints.down('sm')]:{
      fontSize: '26px',
      marginLeft: '12px',
      wordBreak:'break-all'
    }
  },
  badge:{
    marginBottom: '30px',
    marginLeft: '10px',
    [theme.breakpoints.down('xs')]:{
      marginLeft: '6px',
      width:'40px'
    }
  },
  proposalTitle:{
    margin: '0px 135px',
    color: '#8765F2',
    fontSize: '40px'
  },
  chooseProposal:{
    fontSize: '20px'
  },
  cardContent:{
    borderRadius: '18px',
    border: '1px solid rgba(214, 210, 242, 0.5)',
    boxShadow: '0px 51px 89px -49px rgba(196, 193, 232, 0.4)',
    '& .MuiCardContent-root':{
      padding: 0
    },
  },
  divider:{
    borderBottom: '1px solid rgb(224 224 224)'
  },
  descriptionTitle:{
    fontSize: 13,
    fontWeight: 600,
    fontStyle: 'normal',
    lineHeight: 4,
    margin: 0,
    paddingLeft: '16px'
  },
  buttonContent:{
   marginTop: '30px',
   paddingBottom: '30px'
  },
  daoLogoContent:{
    display: 'flex',
    height: '100%',
    alignSelf: 'center'
  },
  logo:{
    width:'100px',
    [theme.breakpoints.down('sm')]:{
      width: '70px'
    }
  },
  errorMessage:{
    display:'flex',
    justifyContent: 'center',
    color: '#B6002C',
    fontSize: '.85rem',
    marginBottom: '16px'
  },
    tokenBadge:{
      color: 'white',
      height: '100%',
      padding: '1px 10px',
      fontSize: '13px',
      background: '#8765F2',
      fontWeight: 500,
      borderRadius: '57px',
    },
})
)

export const CreateProposal = (props: any) => {

  // WEB 3
  const { account, connector } = useWeb3React()

  const classes = useStyles()

  const votingAddress = useSelector((state:any) => state?.daos?.dao?.data?.subgraph?.daoCreationLog?.voting)

  const [creatingProposal, setCreatingProposal] = useState(false)
  const [isSelected, setIsSelected] = useState(true)
  const [proposalCreated, setProposalCreated] = useState(false)

  const [proposal,setProposal] = useState('')
  const [proposalTitle, setProposalTitle] = useState('')
  const [proposalDescription, setProposalDescription] = useState('')

  const [formData, setFormData] = useState<any>()
  const [manager, setManager] = useState<any>()

  // Error validations
  const [proposalTitleErr, setProposalTitleErr] = useState(false)
  const [proposalDescriptionErr, setProposalDescriptionErr] = useState(false)

  const [daoConfig, setDaoConfig] = useState<any>()

  const [isProposalValid, setIsProposalValid] = useState(true);
  const [proposalError, setProposalError] = useState(false);
  const [proposalMsg, setProposalMsg] = useState('');
  
  const navigate = useNavigate()
  const location = useLocation()

  const pathname = window.location.pathname;
  let paths = location.pathname.split('/')
  let daoId = paths[paths.length - 1]

  useEffect(() => {
    const getAbilityToChangeManager = async () => {
      try {
        setDaoConfig(await DaoAPI.getConfiguration(daoId, connector))
      } catch (e) {
        console.log('Error message:', e)
      }
    }
    getAbilityToChangeManager()
    const fetchManager = async () => {
      try {
        setManager(await DaoAPI.getManager(daoId, connector))
      } catch (e) {
        console.log('Error message:', e)
      }
    }
    fetchManager()
    .catch(console.error)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const closeCreateProposalModal = () => setProposalCreated(false)

  const handleProposalSelect=(e)=>{
    setProposal(e.target.value)
    setIsSelected(false);
  }

  const handleProposalTitle = (e) =>{
    setProposalTitle(e.target.value)
  }

  const handleProposalDescription = (e) =>{
    setProposalDescription(e.target.value)
  }

  const handleFormData = (params) => {
    setFormData(params)
  }

  const isCompliant = () => {
    if(isProposalValid && account){
      return true
    }else{
      return false
    }
  }

  const createProposal = async () => {
    try {
      if (proposalError) {
        Swal.fire({
          icon: 'error',
          text: `${proposalMsg}`
        })
      }else if (parseInt(props?.lpTotalSupply) === 0) {
        Swal.fire({
          icon: 'error',
          text: 'You are not eligible to create a proposal'
        })
      } else {
        setCreatingProposal(true)

        if (proposalTitle === '') {
          setProposalTitleErr(true)
          throw 'Proposal title is required!'
        }
        if (proposalDescription === '') {
          setProposalDescriptionErr(true)
          throw 'Proposal description is required!'
        }

        const proposal = await VotingAPI[formData?.func]({
          ...formData,
          daoAddress: props?.dao?.id,
        }, connector)

        const newVote = await VotingAPI.newVote({
          address: votingAddress,
          script: proposal,
          metadata: { proposalTitle, proposalDescription },
          account,
        }, connector)
        if (proposal) {
          setCreatingProposal(false)
          setProposalCreated(true)
          navigate(`/funds/${daoId}`)
          console.log(proposal)
        }
      }
    }
    catch (error) {
      setCreatingProposal(false)
      if (proposal === '') {
        Swal.fire({
          icon: 'error',
          text: 'Please choose a proposal type'
        })
      }
      if (typeof (error) === 'string') {
        Swal.fire({
          icon: 'error',
          text: error
        })
      }
    }
  }

  return (
    <div>
      <BackgroundImage imageUrl={backgroundImg} props={undefined} />
      <Grid container spacing={2}>
        <Grid item lg={6} md={6} sm={6} xs={12}>
          <NavigationBar link={routes.FUNDS.replace(ID, daoId)} pathName={pathname} />
        </Grid>
        <Grid item lg={12} md={12}>
          <div className={classes.titleContent}>
            <div className={classes.daoLogoContent}>
              <img src={props.logo} alt='daologo' className={classes.logo} />
            </div>
            <h1 className={classes.title}>{props.dao.name}</h1>
            <span className={classes.tokenBadge}>{props?.dao?.token?.symbol}</span>
          </div>
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <p>
            Create proposal for {props?.dao?.name} <span>${props?.dao?.token?.symbol}</span>{' '}
          </p>
          <FormControl className={classes.inputSelect}>
            <Select
              variant="outlined"
              native
              defaultValue="none"
              onChange={handleProposalSelect} >
              <option value="none" disabled>
                Choose proposal type
              </option>
              
              {
                account === manager && daoConfig?.canChangeManager &&
                  managersProposals.map((proposal) => {
                    return (
                      <option key={proposal.value} value={proposal.value}>{proposal.value}</option>
                      )
                  })
                } 
                {
                  account === manager && !daoConfig?.canChangeManager &&
                    canChangeManagerManagersProposals.map((proposal) => {
                      return (
                        <option key={proposal.value} value={proposal.value}>{proposal.value}</option>
                      )
                    })
                }
                {
                  account !== manager && daoConfig?.canChangeManager &&
                    membersProposals.map((proposal) => {
                      return (
                        <option key={proposal.value} value={proposal.value}>{proposal.value}</option>
                      )
                    })
                }
                {
                  account !== manager && !daoConfig?.canChangeManager &&
                    canChangeManagerMembersProposals.map((proposal) => {
                      return (
                        <option key={proposal.value} value={proposal.value}>{proposal.value}</option>
                      )
                    })
                }
              
            </Select>
          </FormControl>
        </Grid>
        <Grid item lg={12} md={12} sm={12}></Grid>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <Card className={classes.cardContent}>
            <div className={classes.divider}>
              <p className={classes.descriptionTitle}>Description</p>
            </div>
            <CardContent style={{ padding: '16px' }}>
              <Grid item lg={12}>
                <InputLabel className={classes.labelContent}>
                  Enter Text Header
                </InputLabel>
                <TextField
                  className={classes.input}
                  variant="outlined"
                  type="text"
                  value={proposalTitle}
                  onChange={handleProposalTitle}
                  error={proposalTitleErr}
                  onFocus={() => setProposalTitleErr(false)}
                  helperText={proposalTitleErr ? "This field is required" : ""}
                />
              </Grid>
              <Grid item lg={12}>
                <InputLabel className={classes.labelContent}>
                  Enter text Description
                </InputLabel>
                <TextField
                  className={classes.input}
                  variant="outlined"
                  onChange={handleProposalDescription}
                  value={proposalDescription}
                  error={proposalDescriptionErr}
                  onFocus={() => setProposalDescriptionErr(false)}
                  helperText={proposalDescriptionErr ? "This field is required" : ""}
                />
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          {
            isSelected && (
              <Card className={classes.content}>
                <CardContent>
                  <div>
                    <h1 className={classes.proposalTitle}>
                      Proposal specification
                    </h1>
                    <br />
                    <p className={classes.chooseProposal}>
                      Choose proposal type
                    </p>
                  </div>
                </CardContent>
              </Card>
            )
          }
          <div>
            {
              (() => {
                switch (proposal) {
                  case 'Add allowed DEXes/Protocols':
                    return (
                      <AddNewAllowlistedAddress func={handleFormData}
                        setIsProposalValid={setIsProposalValid}
                        setProposalError={setProposalError}
                        setProposalMsg={setProposalMsg}
                      />
                    )
                  case 'Delete allowed DEXes/Protocols':
                    return (
                      <DeleteAllowlistedAddress func={handleFormData}
                        daoId={props?.dao?.id}
                        setIsProposalValid={setIsProposalValid}
                      />
                    )
                  case 'Appoint new fund manager':
                    return (
                      <AppointNewFundManager func={handleFormData} 
                        setIsProposalValid={setIsProposalValid}
                        setProposalError={setProposalError}
                        setProposalMsg={setProposalMsg}
                      />
                    )
                  // case 'Dismiss current fund manager':
                  //   return <DismissCurrentFundManager />
                  // case 'Change spending limits':
                  //   return (
                  //     <ChangeSpendingLimits
                  //       title={'Change spending limits'}
                  //       cardContentClass={undefined}
                  //       func={handleFormData}
                  //     />
                  //   ) // commented for this version
                  // case 'Create staking / farming investment':
                  //   return <CreateStakingInvestment />
                  case 'Change deposit limits':
                    return (
                      <ChangeDepositLimits
                        title={'Change deposit limits'}
                        cardContentClass={undefined}
                        func={handleFormData}
                        setIsProposalValid={setIsProposalValid}
                        setProposalError={setProposalError}
                        setProposalMsg={setProposalMsg}
                      />
                    )
                  case 'Add tokens available for depositing':
                    return (
                      <ChangeTokenAvailableForDeposit
                        title={'Add tokens available for depositing'}
                        cardContentClass={undefined}
                        func={handleFormData}
                        supportedTokens={props?.supportedTokens}
                        setIsProposalValid={setIsProposalValid}
                        setProposalError={setProposalError}
                        setProposalMsg={setProposalMsg}
                      />
                    )
                  case 'Remove tokens available for depositing':
                    return (
                      <RemoveTokenForDeposit
                        title={'Remove tokens available for depositing'}
                        cardContentClass={undefined}
                        func={handleFormData}
                        supportedTokens={props?.supportedTokens}
                        setIsProposalValid={setIsProposalValid}
                        setProposalError={setProposalError}
                        setProposalMsg={setProposalMsg}
                      />
                    )
                    case 'Add tokens available for trading':
                      return (
                        <ChangeTokenAvailableForTrading
                          title={'Add tokens available for trading'}
                          cardContentClass={undefined}
                          func={handleFormData}
                          method={'changeTokensForTrading'}
                          supportedTokens={props?.supportedTokens}
                          setIsProposalValid={setIsProposalValid}
                          setProposalError={setProposalError}
                          setProposalMsg={setProposalMsg}
                        />
                      )
                    case 'Remove tokens available for trading':
                      return (
                        <RemoveTokenForTrading
                          title={'Remove tokens available for trading'}
                          cardContentClass={undefined}
                          func={handleFormData}
                          method={'changeTokensForTrading'}
                          supportedTokens={props?.supportedTokens}
                          setIsProposalValid={setIsProposalValid}
                          setProposalError={setProposalError}
                          setProposalMsg={setProposalMsg}
                        />
                      )
                  case 'Add allowlisted wallets to deposit':
                    return (
                      <AllowlisedWalletsToDeposit func={handleFormData}
                        setIsProposalValid={setIsProposalValid}
                        setProposalError={setProposalError}
                        setProposalMsg={setProposalMsg}
                      />
                    )
                  case 'Remove allowlisted wallets to deposit':
                    return (
                      <RemoveAllowlistedWalletsToDeposit func={handleFormData}
                        title={"Remove allowlisted wallets to deposit"}
                        setIsProposalValid={setIsProposalValid}
                        setProposalError={setProposalError}
                        setProposalMsg={setProposalMsg}
                        daoId={props?.dao?.id}
                      />
                    )
                  case 'Change lock-up period':
                    return (
                      <ChangeTokenLockUpPeriod func={handleFormData}
                        setIsProposalValid={setIsProposalValid}
                        setProposalError={setProposalError}
                        setProposalMsg={setProposalMsg}
                      />
                    )
                  case 'Change withdrawal windows':
                    return (
                      <ChangeWithdrawalWindows func={handleFormData}
                        setIsProposalValid={setIsProposalValid}
                        setProposalError={setProposalError}
                        setProposalMsg={setProposalMsg}
                      />
                    )
                  case 'Change rage quit fine':
                    return (
                      <ChangeRageQuitFine func={handleFormData}
                        setIsProposalValid={setIsProposalValid}
                        setProposalError={setProposalError}
                        setProposalMsg={setProposalMsg}
                      />
                    )
                  case 'Change entrance fee':
                    return (
                      <ChangeEntranceFee func={handleFormData}
                        setIsProposalValid={setIsProposalValid}
                        setProposalError={setProposalError}
                        setProposalMsg={setProposalMsg}
                      />
                    )
                  case 'Change fund management fee':
                    return (
                      <ChangeManagerFundFee func={handleFormData}
                        setIsProposalValid={setIsProposalValid}
                        setProposalError={setProposalError}
                        setProposalMsg={setProposalMsg}
                      />
                    )
                  case 'Change performance fee':
                    return (
                      <ChangePerformanceFee
                        func={handleFormData}
                        setIsProposalValid={setIsProposalValid}
                        setProposalError={setProposalError}
                        setProposalMsg={setProposalMsg}
                      />
                    )
                  case 'Change voting parameters':
                    return (
                      <ChangeVotingParameters
                        func={handleFormData}
                        package={props?.dao?.packages[0]?.pkg}
                        setIsProposalValid={setIsProposalValid}
                        setProposalError={setProposalError}
                        setProposalMsg={setProposalMsg}
                      />
                    )
                  case 'Start new fundraising phase':
                    return (
                      <ChangeFundraisingPhase 
                        func={handleFormData}
                        setIsProposalValid={setIsProposalValid}
                        setProposalError={setProposalError}
                        setProposalMsg={setProposalMsg}
                      />
                    )
                  case 'Upgrade fund contract': 
                    return (
                      <UpgradeDaoContract 
                        func={handleFormData}
                        setIsProposalValid={setIsProposalValid}
                        setProposalError={setProposalError}
                        setProposalMsg={setProposalMsg}
                      />
                    )
                  // case 'Change voting power type':
                  //   return <ChangeVotingPowerType />
                  default:
                    return null
                }
              })()
            }
          </div>
        </Grid>
        <Grid container justifyContent="center" className={classes.buttonContent}>
          <Grid item lg={2} md={3} sm={6} xs={12}>
            <Button type="primary" disabled={!isCompliant()} onClick={createProposal}>
              Create Proposal
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Container className={classes.errorMessage}>{!account ? "Wallet is locked, please unlock it first" : ""}</Container>
      <ModalLoading
        open={creatingProposal}
        onCancel={undefined}
        modalImg={CreateProposalImg}
        title={'Proposal creation in progress'}
        subTitle={'Please be patient until Proposal will be created'} />
      <ModalSuccess
        open={proposalCreated}
        onCancel={closeCreateProposalModal}
        modalImg={CreateProposalImg}
        title={'Proposal has been created'}
        subTitle={'You can start to explore new opportunities'} />
    </div>
  )
}
