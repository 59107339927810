import ContentLoader from "react-content-loader"
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import { Box, Container} from '@material-ui/core';
import Skeleton from '@mui/material/Skeleton';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  title: {
    marginLeft: '25px',
    width: '100%',
  },
  primaryCard: {
    borderRadius: 12,
    width: '100%',
  },
  textSm: {
    fontSize: 12,
    fontWeight: 'bold',
    padding: 30,
    display: 'flex',
    justifyContent: 'flex-start',
    background: '#FCFAFF',
    borderBottom: '1px solid #EDEEFF',
    maxHeight: '55px'
  },
  text: {
    fontSize: 12,
    fontWeight: 'bold',
    padding: 30,
    display: 'flex',
    justifyContent: 'flex-start',
  },
  textSmDisplay: {
    display: 'inline-grid',
    padding: 0,
    fontFamily: 'Poppins'
  },
  spanText: {
    fontSize: '24px',
    fontWeight: 'bold',
    [theme.breakpoints.down('sm')]: {
      fontSize: '19px'
    },
  },
  fontSize12: {
    fontSize: 10,
    fontFamily: 'Poppins',
    color: 'rgba(0, 0, 0, 0.54)'
  },
  descriptionTitle: {
    fontSize: 10,
    paddingBottom: 5,
    fontFamily: 'Poppins'
  },
  progressContainer: {
    margin: '0 27px 27px',
    background: '#FDFBFF',
    border: '1px solid #F7F1FF',
    borderRadius: 9
  },
  progressText: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: 15
  },
  progressTitle: {
    fontSize: 10,
    marginTop: 10,
    marginLeft: 15
  },
  description: {
    padding: '20px 30px'
  },
  separator: {
    border: '1px solid #EDEEFF',
    marginTop: 25,
    width: '86%'
  },
}));

export const ButtonsLoader = (props) => (
    <ContentLoader 
    speed={1}
    width={'100%'}
    height={84}
    backgroundColor="#f6f6ef"
    foregroundColor="#e8e8e3"
    {...props}
  >
      <rect x="0" y="18" rx="0" ry="0" width="100%" height="40"/> 
    </ContentLoader>
  )
  
  export const PortfolioLoader = (props) => (
    <ContentLoader 
    speed={1}
    width={'100%'}
    height={40}
    backgroundColor="#f6f6ef"
    foregroundColor="#e8e8e3"
    {...props}
  >
     <circle cx="30" cy="21" r="8" />
      <rect x="45" y="15" rx="5" ry="5" width="75" height="12" />
      <rect x="180" y="15" rx="6" ry="3" width="80" height="12" />
      <rect x="330" y="15" rx="3" ry="3" width="80" height="12" />
      <rect x="480" y="15" rx="3" ry="3" width="80" height="12" />
    </ContentLoader>
  )
  export const FundManagerLoader = (props) => (
    <ContentLoader 
    speed={1}
    width={'100%'}
    height={40}
    backgroundColor="#f6f6ef"
    foregroundColor="#e8e8e3"
    {...props}
  >
      <rect x="15" y="15" rx="5" ry="5" width="270" height="12" />
    </ContentLoader>
  )

  export const FundCardLoader = () => {
    const classes = useStyles()

    return (
    <Card className={classes.primaryCard} variant="outlined">
    <Box className={classes.textSm}>
      <Skeleton
        animation="wave"
        variant="circular"
        width={80}
        height={60}
      />
      <Typography
        variant="h4"
        component={'div'}
        className={classes.title}
      >
        <Skeleton animation="wave" height={20} width="45%" />
        <Skeleton animation="wave" height={25} width="25%" />
      </Typography>
    </Box>
    <Box display="flex" className={classes.text}>
      <Container className={classes.textSmDisplay}>
        <Typography
          className={classes.fontSize12}
          component={'div'}
          color="textSecondary"
        >
          Funding Target
        </Typography>
        <span className={classes.spanText}>
          <Skeleton animation="wave" height={35} width="70%" />
        </span>
      </Container>
      <Container className={classes.textSmDisplay}>
        <Typography
          component={'div'}
          color="textSecondary"
          className={classes.fontSize12}
        >
          Deposit Range
        </Typography>
        <span className={classes.spanText}>
          <Skeleton animation="wave" height={35} width="70%" />
        </span>
      </Container>
    </Box>
    <div className={classes.progressContainer}>
      <Typography
        className={classes.progressTitle}
        color="textSecondary"
        component={'div'}
      >
        Progress
      </Typography>
      <Box style={{ marginLeft: '15px' }}>
        <Skeleton animation="wave" height={18} width="96%" />
      </Box>
      <div className={classes.progressText}>
        <Typography style={{ width: '50%' }} component={'div'}>
          <Skeleton animation="wave" height={25} width="35%" />
        </Typography>
        <div
          style={{
            justifyContent: 'flex-end',
            width: '50%',
            display: 'flex',
          }}
        >
          <Skeleton animation="wave" height={25} width="70%" />
        </div>
      </div>
    </div>
    <hr className={classes.separator} />
    <div className={classes.description}>
      <Typography
        component={'div'}
        className={classes.descriptionTitle}
        color="textSecondary"
      >
        Description
      </Typography>
      <Skeleton animation="wave" height={10} width="70%" />
      <Skeleton animation="wave" height={10} width="40%" />
      <Skeleton animation="wave" height={10} width="60%" />
    </div>
  </Card>
  )
}