/* eslint-disable @typescript-eslint/no-unused-vars */
import { Key, useEffect, useRef, useState } from 'react'

import 'react-datepicker/dist/react-datepicker.css'
import { NumericFormat } from 'react-number-format'
import DatePicker from 'react-datepicker'

import moment from 'moment'
import Papa from 'papaparse';
import web3 from 'web3';

// Material Imports
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import Grid from '@material-ui/core/Grid'
import InfoIcon from '@material-ui/icons/Info';
import {
  Badge,
  Box,
  CardContent,
  FormControl,
  FormControlLabel,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  Switch,
  TextField
} from '@material-ui/core'

import { Button } from '../../Button'
import { ChipLabel } from '../../Chip'

import { useAspisSupportedTokens } from '../../../hooks/useChain'

import questionMark from '../../../assets/icons/questionMark.svg'
import USDC from '../../../assets/icons/USDC.svg'
import error404 from '../../../assets/icons/icon-not-found.jpg'

import { MAX_UINT } from '../../../const/ethConst'

const useStyles = makeStyles((theme) => ({
  content: {
    border: '1px solid rgba(214, 210, 242, 0.5)',
    boxShadow: '0px 51px 89px -49px rgba(196, 193, 232, 0.4)',
    borderRadius: '18px',
    marginBottom: '50px',
  },
  inputContent: {
    display: 'grid',
    fontSize: '12px',
    fontWeight: 500,
    padding: '15px 10px',
    '& .MuiInputBase-input': {
      padding: '10.5px 14px',
      background: '#FBFBFF',
      fontFamily: 'Poppins',
      fontSize: '14px',
      fontWeight: 500,
      display: 'flex',
      alignItems: 'center',
    },
    '& .Mui-disabled': {
      borderRadius: '10px',
      color: '#6F5E9A',
      background: 'rgba(226, 221, 237, 0.7)',
      borderColor: 'transparent',
    },
    '& .MuiInputBase-root': {
      borderRadius: '9px',
    },
    '& .react-datepicker__input-container': {
      display: 'grid',
    },
    '& .MuiFormHelperText-root.Mui-error': {
      fontSize: '10px',
      fontFamily: 'Poppins',
      margin: '0 3px',
      position: 'absolute',
      bottom: '-18px',
    },
  },
  input: {
    marginTop: '10px',
  },
  inputCSV: {
    width: '100%',
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: 'transparent',
      background: 'rgb(236 157 243 / 7%)',
      borderRadius: '10px',
    },
    '& .MuiInputBase-root.Mui-disabled': {
      borderRadius: '10px',
      color: '#6F5E9A',
      background: 'rgba(226, 221, 237, 0.7)',
      borderColor: 'transparent',
    },
    '& .MuiOutlinedInput-root': {
      fontSize: '14px',
      lineHeight: '20px'
    }
  },
  csvContent: {
    display: 'flex',
    marginTop: '35px',
    marginBottom: '15px',
    [theme.breakpoints.down('xs')]: {
      display: 'block'
    }
  },
  boldText: {
    letterSpacing: '0.4px',
    fontSize: '12px',
    color: '#4C4C66',
  },
  labelHelper: {
    fontWeight: 500,
    fontSize: '9px',
    color: '#958BAE',
  },
  selectDropdown: {
    '&:focus': {
      backgroundColor: 'transparent',
      borderRadius: '8px',
    },
    display: 'flex',
    alignItems: 'center',
    border: '1px solid rgba(0, 0, 0, 0.23)',
    borderRadius: '8px',
    paddingLeft: '16px',
  },
  img: {
    alignSelf: 'center',
    display: 'inline-flex',
    width: '15px',
    marginRight: '10px',
  },
  labelDepositLimit: {
    color: '#4C4C66',
    fontSize: '12px',
  },
  alignItems: {
    alignItems: 'flex-end',
  },
  contentHelper: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '-25px',
    padding: '0 14px',
    [theme.breakpoints.down('xs')]: {
      display: 'block'
    }
  },
  spanText: {
    fontSize: '12px',
    textAlign: 'right',
    color: '#4C4C66',
    opacity: '0.31',
  },
  spanTextColor: {
    color: '#BC65F2',
    fontSize: '12px',
    textAlign: 'right',
  },
  link: {
    color: '#BC65F2',
  },
  questionMark: {
    width: '20px',
  },
  contentHelper2: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    marginBottom: '-8px',
    padding: '0 10px',
    [theme.breakpoints.down('xs')]: {
      display: 'block'
    }
  },
  exampleContent: {
    fontSize: '8px',
    height: '100%',
    marginTop: '-15px',
    [theme.breakpoints.down('xs')]: {
      marginTop: '0px'
    }
  },
  exampleText: {
    lineHeight: '22px',
    marginTop: '0px',
    color: '#958BAE',
    margin: '8px',
  },
  exampleTitle: {
    color: '#BC65F2',
    margin: '5px 8px',
  },
  delayLabel: {
    color: '#BC65F2',
    margin: '9px 8px',
  },
  radioContent: {
    width: '90%',
    padding: '0 15px',
    background: '#FBFBFF',
    borderRadius: '7px',
    display: 'block ',
    margin: '10px 0',
  },
  formControlLabel: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '13px',
    lineHeight: '21px',
    color: '#44435E',
    position: 'relative',
    top: '1px',
  },
  switch: {
    '& .Mui-disabled': {
      background: 'transparent',
      color: 'white',
    },
  },
  formContent: {
    width: '100%',
  },
  contentPadding: {
    padding: '10px',
  },
  datePicker: {
    borderRadius: '8px',
    padding: '10.5px 14px',
    border: '1px solid #c1c1c4',
    fontSize: '12.5px',
    fontFamily: 'Poppins',
  },
  alignItemsBaseline: {
    display: 'flex',
    alignItems: 'baseline',
  },
  infoIcon: {
    width: '20px',
    color: 'black',
    position: 'relative',
    top: '6px',
    left: '2px'
  },
  walletAddressesDisplay: {
    background: '#fcf7fe',
    paddingTop: '10px',
    borderRadius: '18px',
    paddingBottom: '10px',
    fontSize: '14px',
    '&limaker': {
      background: 'red',
      color: 'yellow'
    }
  },
  errorMessage: {
    fontSize: '14px',
    color: '#da3e63',
    margin: '0'
  },
  buttonMargin: {
    marginTop: '0px',
    [theme.breakpoints.down('xs')]: {
      marginTop: '16px'
    },
  },
  multiselect: {
    minWidth: '180px'
  },
  multiselectItem: {
    border: '0.5px solid #E9EEF4',
    height: '45px',
    '&.Mui-selected': {
      backgroundColor: 'white'
    },
    fontWeight: 'bold',
    transition: 'none'
  },
  multiselectItemCheckbox: {
    marginLeft: 'auto'
  },
  icon:{
    width: '25px',
    marginRight: '5px'
  }
}))

export const LpAgreement = (props) => {
  const MAX_VALUE = 100

  const { handleBack, handleNext } = props
  const { stepperValues, setStepperValues } = props

  const classes = useStyles()
  const csvInputRef = useRef(null);
  const aspisSupportedTokens: any = useAspisSupportedTokens([])

  const [dateForUI, setDateUI] = useState<any>()
  const [selectedTokens, setSelectedTokens] = useState<any>()
  const [formatedDate, setFormatedDate] = useState<any>()

  // Error validations
  const [allowListedWalletsErr, setAllowListedWalletsErr] = useState(false)
  const [calculationTokenErr, setCalculationTokenErr] = useState(false)
  const [depositLimitMaximumErr, setDepositLimitMaximumErr] = useState(false)
  const [depositLimitMinimumErr, setDepositLimitMinimumErr] = useState(false)
  const [entranceFeeErr, setEntranceFeeErr] = useState(false)
  const [freezePeriodErr, setFreezePeriodErr] = useState(false)
  const [fundManagementFeeErr, setFundManagementFeeErr] = useState(false)
  const [fundRaisingErr, setFundRaisingErr] = useState(false)
  const [fundTargetErr, setFundTargetErr] = useState(false)
  const [lockUpHoursErr, setLockUpHoursErr] = useState(false)
  const [minimumApprovalErr, setMinimumApprovalErr] = useState(false)
  const [minimumShareForStartVotingErr, setMinimumShareForStartVotingErr] = useState(false)
  const [performanceFeeErr, setPerformanceFeeErr] = useState(false)
  const [quorumErr, setQuorumErr] = useState(false)
  const [rageQuitFineErr, setRageQuitFineErr] = useState(false)
  const [startDateUI, setStartDateUI] = useState<any>()
  const [tokensAvailableErr, setTokenAvailableErr] = useState(false)
  const [votingDurationErr, setVotingDurationErr] = useState(false)
  const [withdrawalWindowErr, setWithdrawalWindowErr] = useState(false)


  const limitedValue = (inputObj) => {
    const { value } = inputObj
    if (value <= MAX_VALUE) return true
    return false
  }

  const handleFundRaisingStartDate = (date) => {
    setDateUI(date)
    setFormatedDate(moment(dateForUI).format('MMMM Do YYYY, h:mm:ss a'))
    setStartDateUI(moment(startDateUI).format('MMMM Do YYYY'))
    setStepperValues({
      ...stepperValues, startTime: moment(date).unix(),
      startDateUi: moment(date).format('MMM Do YYYY')
    })
  }

  const handleFundraisingEndDate = (e) => {
    let endDate = moment(dateForUI, 'MMMM Do YYYY, h:mm:ss a').add(parseInt(e.target.value), 'days').unix()
    setStepperValues({
      ...stepperValues, endDateUi: parseInt(e.target.value.replace(/,/g, "")),
      finishTime: endDate
    })
  }

  const loadCSV = (event) => {
    Papa.parse(event.target.files[0], {
      header: false,
      skipEmptyLines: true,
      complete: function (results) {
        let addresses = results.data
        setStepperValues({
          ...stepperValues,
          whiteListedAddresses: addresses
            .toString()
            .replace(/\n/g, "")
            .toLowerCase()
            .split(',')
            .filter((elem, index, self) => index === self.indexOf(elem))
        })
        setAllowListedWalletsErr(false)
        //@ts-ignore
        csvInputRef.current.value = null;
      }
    })
  }

  const disableWithdrawalWindow = (event) => {
    setStepperValues({
      ...stepperValues,
      withdrawlWindow: 0,
      freezePeriod: 0,
      rageQuitFee: 0,
      isWithdrawalDisabled: event.target.checked,
    })
    setWithdrawalWindowErr(false)
    setFreezePeriodErr(false)
    if (stepperValues.isWithdrawalDisabled === false) {
      setWithdrawalWindowErr(true)
      setFreezePeriodErr(true)
    }
  }

  const enableAnyoneCanDeposit = (event) => {
    setStepperValues({
      ...stepperValues,
      whiteListedAddresses: [],
      isAllowlistedToDepositDisabled: event.target.checked,
      hasWhitelist: event.target.checked ? 0 : 1
    })
    setAllowListedWalletsErr(false)
    if (stepperValues.isAllowlistedToDepositDisabled) {
      setAllowListedWalletsErr(true)
    }
  }
  const enableNoDepositLimit = (event) => {
    setStepperValues({
      ...stepperValues,
      maxDeposit: MAX_UINT,
      minDeposit: 0,
      isDepositLimitDisabled: event.target.checked,
    })
    setDepositLimitMaximumErr(false)
    setDepositLimitMinimumErr(false)
    if (stepperValues.isDepositLimitDisabled) {
      setStepperValues({
        ...stepperValues,
        maxDeposit: 1,
        minDeposit: 1,
        isDepositLimitDisabled: event.target.checked,
      })
      setDepositLimitMaximumErr(true)
    }
  }
  const setAllowlistedWallets = (e) => {
    setStepperValues({
      ...stepperValues,
      whiteListedAddresses: e.target.value
        .toLowerCase()
        .replace(/\s+/g, '')
        .split(',')
        .filter((elem, index, self) => index === self.indexOf(elem))
    })
  }

  const enabledNextButton =
    (stepperValues.maxCap !== undefined && !isNaN(stepperValues.maxCap) && stepperValues.maxCap !== 0 && stepperValues.maxCap <= 100000000000) &&
    (stepperValues.endDateUi !== undefined && !isNaN(stepperValues.endDateUi) && stepperValues.endDateUi !== 0 && stepperValues.endDateUi <= 365) &&
    (stepperValues.startTime !== undefined && !isNaN(stepperValues.startTime)) &&
    (stepperValues.calculationToken !== undefined) &&
    (stepperValues.minDeposit !== undefined && !isNaN(stepperValues.minDeposit)) &&
    (stepperValues.maxDeposit !== undefined && !isNaN(stepperValues.maxDeposit) && stepperValues.maxDeposit >= stepperValues.minDeposit) &&
    (stepperValues.supportedDepositTokens.length !== 0) &&
    (stepperValues.lockLimit !== undefined && !isNaN(stepperValues.lockLimit)) &&
    (stepperValues.rageQuitFee !== undefined && !isNaN(stepperValues.rageQuitFee)) &&
    (stepperValues.entranceFee !== undefined && !isNaN(stepperValues.entranceFee)) &&
    (stepperValues.fundManagementFee !== undefined && !isNaN(stepperValues.fundManagementFee)) &&
    (stepperValues.performanceFee !== undefined && !isNaN(stepperValues.performanceFee)) &&
    (stepperValues.quorum !== undefined && !isNaN(stepperValues.quorum)) &&
    (stepperValues.isAllowlistedToDepositDisabled === true || stepperValues.whiteListedAddresses.length > 0) &&
    (stepperValues.minimumApproval !== undefined && !isNaN(stepperValues.minimumApproval)) &&
    (stepperValues.votingDuration !== undefined && !isNaN(stepperValues.votingDuration) && stepperValues.votingDuration !== 0 && stepperValues.votingDuration <= 720) &&
    (stepperValues.minLPTokenShare !== undefined && !isNaN(stepperValues.minLPTokenShare)) &&
    (stepperValues.withdrawlWindow !== undefined && !isNaN(stepperValues.withdrawlWindow) && stepperValues.withdrawlWindow <= 365) &&
    (stepperValues.freezePeriod !== undefined && !isNaN(stepperValues.freezePeriod) && stepperValues.freezePeriod <= 365)

  const isFormValid = () => {
    if (enabledNextButton && !freezePeriodErr && !depositLimitMinimumErr && !allowListedWalletsErr && !withdrawalWindowErr) {
      return true
    } else {
      return false
    }
  }

  const depositLimitError = () => {
    if (stepperValues.minDeposit < 0 && stepperValues.maxDeposit <= stepperValues.minDeposit && (stepperValues.minDeposit !== undefined && !isNaN(stepperValues.minDeposit))) {
      setDepositLimitMinimumErr(true)
    }
    else { setDepositLimitMinimumErr(false) }
  }

  const freezePeriodError = () => {
    if (
      stepperValues.freezePeriod <= 0 && (stepperValues.freezePeriod !== undefined && !isNaN(stepperValues.freezePeriod))
    ) {
      setFreezePeriodErr(true)
    }
    else { setFreezePeriodErr(false) }
  }

  const allowlistedWalletsError = () => {
    if (stepperValues.whiteListedAddresses.length <= 0) {
      setAllowListedWalletsErr(true)
    }
    else { setAllowListedWalletsErr(false) }
  }
  const withdrawalWindowError = () => {
    if (stepperValues.withdrawlWindow <= 0 && (stepperValues.withdrawlWindow !== undefined && !isNaN(stepperValues.withdrawlWindow))) {
      setWithdrawalWindowErr(true)
    }
    else { setWithdrawalWindowErr(false) }
  }

  const getNamesFromAddresses = (tokenAddresses) => {
    return tokenAddresses.map(address => {
      return aspisSupportedTokens.find(t => t.address === address)?.symbol;
    }).join(', ')
  }

  return (
    <>
      <Card className={classes.content}>
        <CardContent>
          <ChipLabel type="chipTitle" label="Capital Onboarding" />
          <Grid container className={classes.alignItems}>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <div className={classes.inputContent}>
                <label className={classes.labelHelper}>
                  <b className={classes.boldText}>Fundraising target,&nbsp;</b>{' '} in USD equivalent
                </label>
                <NumericFormat
                  customInput={TextField}
                  variant="outlined"
                  required
                  thousandSeparator=','
                  valueIsNumericString={true}
                  allowNegative={false}
                  value={stepperValues.maxCap}
                  onChange={(e) => setStepperValues({ ...stepperValues, maxCap: parseInt(e.target.value.replace(/,/g, "")) })}
                  onBlur={() => setFundTargetErr(true)}
                  error={fundTargetErr && stepperValues.maxCap > 0 && stepperValues.maxCap <= 100000000000 ? !fundTargetErr : fundTargetErr}
                  helperText={
                    fundTargetErr ?
                      (stepperValues.maxCap === undefined ? 'This field is required' : '') ||
                      (stepperValues.maxCap <= 100000000000 ? "" : "Please add value between 1 and 100000000000") ||
                      (stepperValues.maxCap > 0 ? "" : "Please add value between 1 and 100000000000") : null
                  }
                  className={classes.input}
                  placeholder="1 500 000$"
                />
              </div>
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <div className={classes.inputContent}>
                <b className={classes.boldText}>
                  Fundraising start date,&nbsp;
                </b>
                <DatePicker
                  className={classes.datePicker}
                  selected={dateForUI ? dateForUI : null}
                  onChange={(value) => handleFundRaisingStartDate(value)}
                  minDate={new Date(new Date().getTime())}
                  placeholderText="mm/dd/yyy"
                  value={stepperValues.startDateUi}
                />
              </div>
            </Grid>
            <Grid container className={classes.alignItems}>
              <Grid item lg={6} md={6} sm={6} xs={12}>
                <div className={classes.inputContent}>
                  <label className={classes.labelHelper}>
                    <b className={classes.boldText}>Duration of fundraising phase,&nbsp;</b>days
                  </label>
                  <NumericFormat
                    customInput={TextField}
                    variant="outlined"
                    className={classes.input}
                    placeholder="31"
                    valueIsNumericString={true}
                    allowNegative={false}
                    disabled={stepperValues.startTime ? false : true}
                    value={stepperValues.endDateUi}
                    onChange={handleFundraisingEndDate}
                    onBlur={() => setFundRaisingErr(true)}
                    error={fundRaisingErr && stepperValues.endDateUi > 0 && stepperValues.endDateUi <= 365 ? !fundRaisingErr : fundRaisingErr}
                    helperText={
                      fundRaisingErr ?
                        (stepperValues.endDateUi === undefined ? 'This field is required' : '') ||
                        (stepperValues.endDateUi > 0 ? '' : 'Please add value between 1 and 365') ||
                        (stepperValues.endDateUi <= 365 ? '' : 'Please add value between 1 and 365')
                        : null
                    }
                  />
                </div>
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={12}>
                <div className={classes.inputContent}>
                  <label className={classes.labelHelper}>
                    <b className={classes.boldText}>
                      Base currency <a href='https://info.aspis.finance/features-description/dao-creation/calculating-token-currency' target="_blank" rel="noreferrer"><InfoIcon className={classes.infoIcon} /></a>
                    </b>
                  </label>
                  <Select
                    classes={{ root: classes.selectDropdown }}
                    disableUnderline
                    displayEmpty
                    value={stepperValues.calculationToken}
                    label="selectToken"
                    onChange={(e) => setStepperValues({ ...stepperValues, calculationToken: e.target.value })}
                    className={classes.input}
                    onBlur={() => setCalculationTokenErr(true)}
                    error={
                      calculationTokenErr && !stepperValues.calculationToken
                    }
                  >
                    <MenuItem value="USDC">
                      <img className={classes.img} src={USDC} alt="usdc" />
                      USDC
                    </MenuItem>
                  </Select>
                </div>
              </Grid>
            </Grid>
            <Grid container className={classes.alignItems}>
              <Grid item lg={4} md={6} sm={6} xs={12}>
                <div className={classes.inputContent}>
                  <label className={classes.labelHelper}>
                    <b className={classes.boldText}>Deposit limits,&nbsp;</b> in
                    USD equivalent
                  </label>
                  Min
                  <NumericFormat
                    customInput={TextField}
                    variant="outlined"
                    placeholder="150$"
                    value={stepperValues.minDeposit}
                    disabled={stepperValues.isDepositLimitDisabled}
                    className={classes.input}
                    onChange={(e) => setStepperValues(
                      { ...stepperValues, minDeposit: parseInt(e.target.value.replace(/,/g, "")) }
                    )}
                    onBlur={depositLimitError}
                    error={
                      depositLimitMinimumErr &&
                        stepperValues.minDeposit > 0 ? !depositLimitMinimumErr : depositLimitMinimumErr // TODO
                    }
                    helperText={
                      depositLimitMinimumErr
                        ? (stepperValues.minDeposit > 0 ? '' : 'This field is required')
                        : null}
                    thousandSeparator=","
                    valueIsNumericString={true}
                    allowNegative={false}
                  />
                </div>
              </Grid>
              <Grid item lg={4} md={6} sm={6} xs={12}>
                <div className={classes.inputContent}>
                  <div>
                    <label className={classes.labelDepositLimit}>
                      No deposit limits
                    </label>
                    <Switch
                      checked={stepperValues.isDepositLimitDisabled}
                      onClick={enableNoDepositLimit}
                    />
                  </div>
                  Max
                  <NumericFormat
                    customInput={TextField}
                    variant="outlined"
                    disabled={stepperValues.isDepositLimitDisabled}
                    placeholder="5 000$"
                    className={classes.input}
                    value={stepperValues.maxDeposit === MAX_UINT ? 0 : stepperValues.maxDeposit}
                    onChange={(e) => setStepperValues({ ...stepperValues, maxDeposit: parseInt(e.target.value.replace(/,/g, "")) })}
                    onBlur={() => setDepositLimitMaximumErr(true)}
                    error={
                      depositLimitMaximumErr &&
                        stepperValues.maxDeposit < MAX_UINT && stepperValues.maxDeposit > stepperValues.minDeposit ? !depositLimitMaximumErr : depositLimitMaximumErr
                    }
                    helperText={
                      depositLimitMaximumErr ?
                        (stepperValues.maxDeposit < MAX_UINT ? '' : 'This field is required') ||
                        (stepperValues.maxDeposit > stepperValues.minDeposit ? '' : 'Provide a value greater than minimum')
                        : null
                    }
                    thousandSeparator=","
                    valueIsNumericString={true}
                    allowNegative={false}
                  />
                </div>
              </Grid>
              <Grid item lg={4} md={12} sm={12} xs={12}>
                <div className={classes.inputContent}>
                  <label className={classes.labelHelper}>
                    <b className={classes.boldText}>
                      Tokens available for depositing
                    </b>
                  </label>
                  <FormControl className={classes.formContent}>
                    <Select
                      className={classes.multiselect}
                      multiple
                      required
                      name={selectedTokens}
                      value={stepperValues.supportedDepositTokens}
                      error={tokensAvailableErr && stepperValues.supportedDepositTokens.length !== 0 ? !tokensAvailableErr : tokensAvailableErr}
                      onBlur={() => setTokenAvailableErr(true)}
                      onChange={(e) => {
                        setStepperValues({ ...stepperValues, supportedDepositTokens: e.target.value })
                        setSelectedTokens(e.target.name)
                      }}
                      input={<OutlinedInput />}
                      renderValue={getNamesFromAddresses}
                    >
                      {aspisSupportedTokens.map((item) =>
                        <MenuItem value={item.address} key={item.address} data-token={item.address} className={classes.multiselectItem}>
                          <img src={`https://cdn.jsdelivr.net/gh/atomiclabs/cryptocurrency-icons@1a63530be6e374711a8554f31b17e4cb92c25fa5/svg/color/${item.symbol}.svg`.toLowerCase()} 
                              className={classes.icon} alt="" title={item.symbol}
                              onError={({ currentTarget }) => { currentTarget.onerror = null; currentTarget.src=error404 }}  />
                          {item.symbol}
                          <Radio checked={stepperValues.supportedDepositTokens.indexOf(item.address) > -1} className={classes.multiselectItemCheckbox}/>
                        </MenuItem>
                      )}
                    </Select>
                  </FormControl>
                </div>
              </Grid>
            </Grid>

            <Grid container className={classes.alignItems}>
              <div className={classes.contentHelper}>
                <Grid item lg={5} md={12}>
                  <div className={classes.labelHelper}>
                    <b className={classes.boldText}>
                      Allowlisted wallets to deposit
                    </b><br />
                    Divide wallets by " , "
                  </div>
                </Grid>
                <Grid item lg={2} md={8} sm={4} xs={12}>
                  <div className={classes.inputContent}>
                    <Button
                      type="CSVBtn"
                      disabled={stepperValues.isAllowlistedToDepositDisabled}
                      component="label"
                    >
                      Load CSV
                      <input type="file" ref={csvInputRef} accept=".csv" hidden onChange={loadCSV} />
                    </Button>
                  </div>
                </Grid>
                <Grid item lg={3}>
                  <span className={classes.spanText}>Anyone Can Deposit</span>
                  <Switch
                    checked={stepperValues.isAllowlistedToDepositDisabled}
                    onClick={enableAnyoneCanDeposit}
                  />
                </Grid>
              </div>
              <div className={classes.csvContent}>
                <Grid item lg={6} md={8} sm={12} xs={12}>
                  <TextField
                    variant="outlined"
                    multiline
                    minRows={3}
                    maxRows={5}
                    className={classes.inputCSV}
                    value={stepperValues.whiteListedAddresses}
                    disabled={stepperValues.isAllowlistedToDepositDisabled}
                    onChange={setAllowlistedWallets}
                    onBlur={allowlistedWalletsError}
                    error={allowListedWalletsErr && stepperValues.whiteListedAddresses.length === 0}
                    helperText={
                      allowListedWalletsErr
                        ? stepperValues.whiteListedAddresses.length === 0
                          ? 'Please add list of wallets allowed to deposit into your DAO or choose “Anyone can deposit"'
                          : ''
                        : null
                    }
                  />
                </Grid>
                <Grid item lg={6} md={8} sm={12} xs={12}>
                  <div className={classes.exampleContent}>
                    <p className={classes.exampleTitle}>
                      List of wallets that would be able to deposit into your DAO.
                    </p>
                    <p className={classes.exampleText}>
                      You can create a private DAO and specify list of wallets that would be able to make a deposit in your DAO.
                      You can remove and add more wallets via voting after the DAO created.
                      In case you're making private DAO you can do your own KYC or use an external provider to comply with existing
                      regulations in your country if you want to make a fully regulated DAO
                    </p>
                  </div>
                </Grid>
              </div>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <ol className={classes.walletAddressesDisplay}>
                  {
                    stepperValues.whiteListedAddresses.map((address: string, key: Key | null | undefined) =>
                      <li key={key}>
                        {
                          web3.utils.isAddress(address) ?
                            ([address])
                            :
                            <p className={classes.errorMessage}>Invalid wallet address</p>
                        }
                      </li>
                    )
                  }
                </ol>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <div className={classes.contentHelper2}>
                  <div className={classes.labelHelper}>
                    <b className={classes.boldText}>Token lock-up period, </b> hours
                  </div>
                </div>
              </Grid>
              <Grid item lg={6} md={12} sm={12} xs={12}>
                <div className={classes.inputContent}>
                  <NumericFormat
                    customInput={TextField}
                    variant="outlined"
                    className={classes.input}
                    placeholder="Hours"
                    value={stepperValues.lockLimit}
                    onChange={(e) => setStepperValues({ ...stepperValues, lockLimit: parseInt(e.target.value.replace(/,/g, "")) })}
                    onBlur={() => setLockUpHoursErr(true)}
                    error={lockUpHoursErr && stepperValues.lockLimit >= 0 ? !lockUpHoursErr : lockUpHoursErr}
                    helperText={
                      lockUpHoursErr
                        ? stepperValues.lockLimit >= 0
                          ? ''
                          : 'This field is required'
                        : null
                    }
                    thousandSeparator=","
                    valueIsNumericString={true}
                    allowNegative={false}
                  />
                </div>
              </Grid>
              <Grid item lg={6} md={12} sm={12} xs={12}>
                <div className={classes.exampleContent}>
                  <p className={classes.exampleTitle}>
                    Time after which user will be able to withdraw his deposit.
                  </p>
                  <p className={classes.exampleText}>
                    This field is needed to protect your DAO from arbitragers.<br />
                  </p>
                </div>
              </Grid>
            </Grid>
            <ChipLabel type="chipTitle" label="Capital Offboarding" />
            <Grid container>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <div className={classes.contentHelper2}>
                  <div className={classes.labelHelper}>
                    <b className={classes.boldText}>Withdrawal windows,</b>
                    &nbsp;hours
                    <Switch
                      checked={stepperValues.isWithdrawalDisabled}
                      onClick={disableWithdrawalWindow}
                    />
                  </div>
                  <span className={classes.spanTextColor}>
                    <Badge overlap="rectangular">
                      <img
                        className={classes.questionMark}
                        src={questionMark}
                        alt="?"
                      />
                    </Badge>{' '}
                    <a
                      href="https://info.aspis.finance/features-description/dao-creation/withdrawal-windows-and-rage-quit"
                      className={classes.link}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {' '}
                      Learn more about withdrawal windows{' '}
                    </a>
                  </span>
                </div>
              </Grid>
            </Grid>

            <Grid container>
              <Grid item lg={6} md={6} sm={6} xs={12}>
                <Box className={classes.inputContent}>
                  <div className={classes.contentHelper2}>
                    <b className={classes.boldText}>Freeze period,</b>&nbsp;
                    <div className={classes.labelHelper}>days</div>
                  </div>
                  <NumericFormat
                    customInput={TextField}
                    variant="outlined"
                    disabled={!stepperValues.isWithdrawalDisabled}
                    placeholder="13"
                    value={stepperValues.freezePeriod}
                    className={classes.input}
                    thousandSeparator=","
                    valueIsNumericString={true}
                    allowNegative={false}
                    onChange={(e) => setStepperValues({ ...stepperValues, freezePeriod: parseInt(e.target.value.replace(/,/g, "")) })}
                    onBlur={freezePeriodError}
                    error={freezePeriodErr && stepperValues.freezePeriod > 0 && stepperValues.freezePeriod <= 365 ? !freezePeriodErr : freezePeriodErr}
                    helperText={
                      freezePeriodErr ?
                        (stepperValues.freezePeriod === undefined ? 'This field is required' : '') ||
                        (stepperValues.freezePeriod <= 365 ? "" : "Please add value between 1 and 365") ||
                        (stepperValues.freezePeriod > 0 ? "" : "Please add value between 1 and 365") : null
                    }
                  />
                </Box>
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={12}>
                <Box className={classes.inputContent}>
                  <div className={classes.contentHelper2}>
                    <b className={classes.boldText}>Window,</b>&nbsp;
                    <div className={classes.labelHelper}>days</div>
                  </div>
                  <NumericFormat
                    customInput={TextField}
                    variant="outlined"
                    disabled={!stepperValues.isWithdrawalDisabled}
                    placeholder="2"
                    value={stepperValues.withdrawlWindow}
                    className={classes.input}
                    thousandSeparator=","
                    valueIsNumericString={true}
                    allowNegative={false}
                    onChange={(e) => setStepperValues({ ...stepperValues, withdrawlWindow: parseInt(e.target.value.replace(/,/g, "")) })}
                    onBlur={withdrawalWindowError}
                    error={withdrawalWindowErr && stepperValues.withdrawlWindow > 0 && stepperValues.withdrawlWindow <= 365 ? !withdrawalWindowErr : withdrawalWindowErr}
                    helperText={
                      withdrawalWindowErr ?
                        (stepperValues.withdrawlWindow === undefined ? 'This field is required' : '') ||
                        (stepperValues.withdrawlWindow <= 365 ? "" : "Please add value between 1 and 365") ||
                        (stepperValues.withdrawlWindow > 0 ? "" : "Please add value between 1 and 365") : null
                    }
                  />
                </Box>
              </Grid>
            </Grid>

            <Grid container>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <div className={classes.contentHelper2}>
                  <div className={classes.labelHelper}>
                    <b className={classes.boldText}>
                      Rage quit fine,&nbsp;&nbsp;
                    </b>
                    % of withdrawal&nbsp;
                  </div>
                  <span className={classes.spanTextColor}>
                    <Badge overlap="rectangular">
                      <img
                        className={classes.questionMark}
                        src={questionMark}
                        alt="?"
                      />
                    </Badge>{' '}
                    <a
                      href="https://info.aspis.finance/features-description/dao-creation/withdrawal-windows-and-rage-quit"
                      target="_blank"
                      className={classes.link}
                      rel="noreferrer"
                    >
                      {' '}
                      Learn more about rage quit
                    </a>
                  </span>
                </div>
              </Grid>
              <Grid item lg={6} md={12} sm={12} xs={12}>
                <div className={classes.inputContent}>
                  <NumericFormat
                    customInput={TextField}
                    variant="outlined"
                    className={classes.input}
                    placeholder="10%"
                    disabled={!stepperValues.isWithdrawalDisabled}
                    isAllowed={limitedValue}
                    value={stepperValues.rageQuitFee}
                    onChange={(e) => setStepperValues({ ...stepperValues, rageQuitFee: parseInt(e.target.value.replace(/[%\s]/g, '')) })}
                    onBlur={() => setRageQuitFineErr(true)}
                    error={rageQuitFineErr && stepperValues.rageQuitFee >= 0 ? !rageQuitFineErr : rageQuitFineErr}
                    helperText={
                      rageQuitFineErr
                        ? stepperValues.rageQuitFee >= 0
                          ? ''
                          : 'This field is required'
                        : null
                    }
                    suffix={'%'}
                    valueIsNumericString={true}
                    allowNegative={false}
                  />
                </div>
              </Grid>
              <Grid item lg={6} md={12} sm={12} xs={12}>
                <div className={classes.exampleContent}>
                  <p className={classes.exampleTitle}>
                    Fine for removing capital outside of withdrawal window
                  </p>
                  <p className={classes.exampleText}>
                    Example: To align the interests of the manager and the investors, 
                    there are 2 periods following each other: 
                    “Withdrawal period” and “Freeze period”, 
                    which are constantly following each other. 
                    If the user wants to burn his LP tokens during the “Freeze period”, 
                    he needs to pay “Rage Quit Fine”. If the user burns his LP tokens in the “Withdrawal period”, 
                    a rage quit fine will not be applied.
                  </p>
                </div>
              </Grid>
            </Grid>
            <Grid container>
              <div className={classes.alignItemsBaseline}>
                <ChipLabel type="chipTitle" label="Fees" />{' '}
                <span className={classes.spanTextColor}>
                  <Badge overlap="rectangular">
                    &nbsp;&nbsp;
                    <img
                      className={classes.questionMark}
                      src={questionMark}
                      alt="?"
                    />
                  </Badge>{' '}
                  <a href="!#" target="_blank" className={classes.link}>
                    {' '}
                    Learn more how our fees work{' '}
                  </a>
                </span>
              </div>
            </Grid>
            {/* entranceFee */}
            <Grid container className={classes.alignItems}>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <div className={classes.contentHelper2}>
                  <div className={classes.labelHelper}>
                    <b className={classes.boldText}>Entrance fee,&nbsp;</b>% of deposit in LP tokens
                  </div>
                </div>
              </Grid>
              <Grid item lg={6} md={12} sm={12} xs={12}>
                <div className={classes.inputContent}>
                  <NumericFormat
                    customInput={TextField}
                    variant="outlined"
                    className={classes.input}
                    placeholder="5%"
                    isAllowed={limitedValue}
                    value={stepperValues.entranceFee}
                    onChange={(e) => setStepperValues({ ...stepperValues, entranceFee: parseInt(e.target.value.replace(/[%\s]/g, '')) })}
                    onBlur={() => setEntranceFeeErr(true)}
                    error={entranceFeeErr && stepperValues.entranceFee >= 0 ? !entranceFeeErr : entranceFeeErr}
                    helperText={
                      entranceFeeErr
                        ? stepperValues.entranceFee >= 0
                          ? ''
                          : 'This field is required'
                        : null
                    }
                    suffix={'%'}
                    valueIsNumericString={true}
                    allowNegative={false}
                  />
                </div>
              </Grid>
              <Grid item lg={6} md={12} sm={12} xs={12}>
                <div className={classes.exampleContent}>
                  <p className={classes.exampleTitle}>
                    Fee which is taken from each deposit in LP tokens right
                    after deposit and added to the manager’s balance
                  </p>
                  <p className={classes.exampleText}>
                    Example: User deposits 100 USDT and should get 10 LP tokens.
                    If entrance fee is 1% then user will get 9.9 LP tokens and
                    manager will get 0.1 LP token
                  </p>
                </div>
              </Grid>
            </Grid>
            {/* endOfEntranceFee */}

            {/* fundManagementFee */}
            <Grid container className={classes.alignItems}>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <div className={classes.contentHelper2}>
                  <div className={classes.labelHelper}>
                    <b className={classes.boldText}>
                      Fund management fee,&nbsp;
                    </b>{' '}
                    % from AUM in LP tokens for 365 days
                  </div>
                </div>
              </Grid>
              <Grid item lg={6} md={12} sm={12} xs={12}>
                <div className={classes.inputContent}>
                  <NumericFormat
                    customInput={TextField}
                    suffix={'%'}
                    valueIsNumericString={true}
                    allowNegative={false}
                    variant="outlined"
                    className={classes.input}
                    placeholder="5%"
                    isAllowed={limitedValue}
                    value={stepperValues.fundManagementFee}
                    onChange={(e) => setStepperValues({ ...stepperValues, fundManagementFee: parseInt(e.target.value.replace(/[%\s]/g, '')) })}
                    onBlur={() => setFundManagementFeeErr(true)}
                    error={
                      fundManagementFeeErr && stepperValues.fundManagementFee >= 0 ? !fundManagementFeeErr : fundManagementFeeErr
                    }
                    helperText={
                      fundManagementFeeErr
                        ? stepperValues.fundManagementFee >= 0
                          ? ''
                          : 'This field is required'
                        : null
                    }
                  />
                </div>
              </Grid>
              <Grid item lg={6} md={12} sm={12} xs={12}>
                <div className={classes.exampleContent}>
                  <p className={classes.exampleTitle}>
                    Fee is calculated as a share of average AUM over the 1 year
                    period and taken in LP tokens on withdrawal
                  </p>
                  <p className={classes.exampleText}>
                    Example: If average 1 year AUM is $1 000 000, user has 10%
                    of LP tokens in average and stays in the fund for 100 days,
                    user will pay $1 000 000 * 10% * 5% * (100 / 365) of avg.
                    AUM
                  </p>
                </div>
              </Grid>
            </Grid>
            {/* endOfFundManagementFee */}

            {/* performanceFee */}
            <Grid container className={classes.alignItems}>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <div className={classes.contentHelper2}>
                  <div className={classes.labelHelper}>
                    <b className={classes.boldText}>Perfomance fee,&nbsp;</b> % of total profit in LP tokens
                  </div>
                </div>
              </Grid>
              <Grid item lg={6} md={12} sm={12} xs={12}>
                <div className={classes.inputContent}>
                  <NumericFormat
                    customInput={TextField}
                    variant="outlined"
                    className={classes.input}
                    suffix={'%'}
                    placeholder="10%"
                    isAllowed={limitedValue}
                    value={stepperValues.performanceFee}
                    onChange={(e) => setStepperValues({ ...stepperValues, performanceFee: parseInt(e.target.value.replace(/[%\s]/g, '')) })}
                    onBlur={() => setPerformanceFeeErr(true)}
                    error={performanceFeeErr && stepperValues.performanceFee >= 0 ? !performanceFeeErr : performanceFeeErr}
                    helperText={
                      performanceFeeErr
                        ? stepperValues.performanceFee >= 0
                          ? ''
                          : 'This field is required'
                        : null
                    }
                    valueIsNumericString={true}
                    allowNegative={false}
                  />
                </div>
              </Grid>
              <Grid item lg={6} md={12} sm={12} xs={12}>
                <div className={classes.exampleContent}>
                  <p className={classes.exampleTitle}>
                    Fee which is calculated from the fund’s profit and taken in
                    LP tokens on withdrawal{' '}
                  </p>
                  <p className={classes.exampleText}>
                    Example: User deposited 100 USDT. During fund performance
                    value of his LP tokens increased to 160 USDT. User will pay
                    20% from (160 - 100) USDT on withdrawal in LP tokens
                  </p>
                </div>
              </Grid>
            </Grid>
            {/* endOfPerformanceFee */}
            <Grid container>
              <div className={classes.alignItemsBaseline}>
                <ChipLabel type="chipTitle" label="Voting power type" />{' '}
                <span className={classes.spanTextColor}>
                  <Badge overlap="rectangular">
                    &nbsp;&nbsp;
                    <img
                      className={classes.questionMark}
                      src={questionMark}
                      alt="?"
                    />
                  </Badge>{' '}
                  <a href="!#" target="_blank" className={classes.link}>
                    {' '}
                    Learn more about voting types{' '}
                  </a>
                </span>
              </div>
            </Grid>
            {/* simpleVoting */}

            <Grid container>
              <Grid item lg={6} md={8} sm={8} xs={12}>
                <RadioGroup className={classes.radioContent} name="choose">
                  <FormControlLabel
                    value="choose"
                    control={<Radio checked />}
                    label=""
                  />{' '}
                  <span className={classes.formControlLabel}>
                    Simple Voting
                  </span>
                </RadioGroup>
              </Grid>
              <Grid item lg={6} md={8} sm={8} xs={12}>
                {/* CoomingSoon */}
                {/* <RadioGroup className={classes.radioContent} name="choose">
                    <FormControlLabel disabled value="choose" control={<Radio/>} label=""
                      /> <span className={classes.formControlLabel}>Lazy governance: in case of contradictions</span>
          </RadioGroup> */}
              </Grid>
            </Grid>
            {/* endOfSimpeVoting */}
            <Grid container>
              <div className={classes.alignItemsBaseline}>
                <ChipLabel type="chipTitle" label="Voting rules" />{' '}
                <span className={classes.spanTextColor}>
                  <Badge overlap="rectangular">
                    &nbsp;&nbsp;
                    <img
                      className={classes.questionMark}
                      src={questionMark}
                      alt="?"
                    />
                  </Badge>{' '}
                  <a href="!#" target="_blank" className={classes.link}>
                    {' '}
                    Learn more about voting rules{' '}
                  </a>
                </span>
              </div>
            </Grid>
            {/* quorum */}
            <Grid container className={classes.alignItems}>
              <Grid item lg={6} md={6} sm={6} xs={12}>
                <div className={classes.inputContent}>
                  <label className={classes.labelHelper}>
                    <b className={classes.boldText}>Quorum, </b>all minted LP tokens
                  </label>
                  <NumericFormat
                    customInput={TextField}
                    variant="outlined"
                    className={classes.input}
                    placeholder='51%'
                    suffix={'%'}
                    isAllowed={limitedValue}
                    onChange={(e) => setStepperValues({ ...stepperValues, quorum: parseInt(e.target.value.replace(/[%\s]/g, '')) })}
                    onBlur={() => setQuorumErr(true)}
                    value={stepperValues.quorum}
                    error={quorumErr && stepperValues.quorum >= 0 ? !quorumErr : quorumErr}
                    helperText={
                      quorumErr
                        ? stepperValues.quorum >= 0
                          ? ''
                          : 'This field is required'
                        : null
                    }
                    valueIsNumericString={true}
                    allowNegative={false}
                  />
                </div>
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={12}>
                <div className={classes.inputContent}>
                  <label className={classes.labelHelper}>
                    <b className={classes.boldText}>Minimum Approval, </b>% of voted LP tokens
                  </label>
                  <NumericFormat
                    customInput={TextField}
                    variant="outlined"
                    className={classes.input}
                    suffix={'%'}
                    placeholder='50%'
                    isAllowed={limitedValue}
                    onChange={(e) => setStepperValues({ ...stepperValues, minimumApproval: parseInt(e.target.value.replace(/[%\s]/g, '')) })}
                    value={stepperValues.minimumApproval}
                    onBlur={() => setMinimumApprovalErr(true)}
                    error={minimumApprovalErr && stepperValues.minimumApproval >= 0 ? !minimumApprovalErr : minimumApprovalErr}
                    helperText={
                      minimumApprovalErr
                        ? stepperValues.minimumApproval >= 0
                          ? ''
                          : 'This field is required'
                        : null
                    }
                    valueIsNumericString={true}
                    allowNegative={false}
                  />
                </div>
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={12}>
                <div className={classes.inputContent}>
                  <label className={classes.labelHelper}>
                    <b className={classes.boldText}>Voting Duration, </b>hours
                  </label>
                  <NumericFormat
                    customInput={TextField}
                    variant="outlined"
                    className={classes.input}
                    placeholder='1'
                    decimalSeparator=""
                    value={stepperValues.votingDuration}
                    onChange={(e) => setStepperValues({ ...stepperValues, votingDuration: parseInt(e.target.value) })}
                    onBlur={() => setVotingDurationErr(true)}
                    error={votingDurationErr && stepperValues.votingDuration > 0 && stepperValues.votingDuration <= 720 ? !votingDurationErr : votingDurationErr}
                    helperText={
                      votingDurationErr ?
                        (stepperValues.votingDuration === undefined ? 'This field is required' : '') ||
                        (stepperValues.votingDuration > 0 ? "" : "Please add value from 1 to 720") ||
                        (stepperValues.votingDuration <= 720 ? "" : "Please add value from 1 to 720") : null
                    }
                    valueIsNumericString={true}
                    allowNegative={false}
                  />
                </div>
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={12}>
                <div className={classes.inputContent}>
                  <label className={classes.labelHelper}>
                    <b className={classes.boldText}>Minimum share of LP tokens to start voting, </b>% of all minted LP tokens
                  </label>
                  <NumericFormat
                    customInput={TextField}
                    variant="outlined"
                    className={classes.input}
                    suffix={'%'}
                    placeholder='5%'
                    isAllowed={limitedValue}
                    value={stepperValues.minLPTokenShare}
                    onChange={(e) => setStepperValues({
                      ...stepperValues,
                      minLPTokenShare: parseInt(e.target.value.replace(/[%\s]/g, '')),
                    })}
                    onBlur={() => setMinimumShareForStartVotingErr(true)}
                    error={
                      minimumShareForStartVotingErr &&
                        stepperValues.minLPTokenShare >= 0 ? !minimumShareForStartVotingErr : minimumShareForStartVotingErr
                    }
                    helperText={
                      minimumShareForStartVotingErr
                        ? stepperValues.minLPTokenShare >= 0
                          ? ''
                          : 'This field is required'
                        : null
                    }
                    valueIsNumericString={true}
                    allowNegative={false}
                  />
                </div>
              </Grid>
            </Grid>

            {/* endOfQuorum */}
          </Grid>
        </CardContent>
      </Card>
      <Grid container justifyContent='space-between'>
        <Grid item lg={2} md={3} sm={3} xs={12}>
          <Button type="third" onClick={() => handleBack()}>Return</Button>
        </Grid>
        <Grid item container lg={2} md={3} sm={3} xs={12} justifyContent="flex-end">
          <Button type="primary"
            onClick={() => handleNext()}
            disabled={!isFormValid()}
            className={classes.buttonMargin}>Next</Button>
        </Grid>
      </Grid>
    </>
  )
}
