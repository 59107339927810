import { Modal } from '../Modal'
import { makeStyles } from '@material-ui/core/styles'
import { Grid } from '@material-ui/core'

import { Button } from '../Button'

const useStyles = makeStyles((theme) => ({
  modalBodyClass: {
    maxWidth: '658px',
    width: '100%',
    marginTop: 148,
    // // padding: '0 30px',
    '@media screen and (max-width: 700px)': {
      padding: '0 30px',
      margin: '0 20px',
    },
    '@media screen and (max-width: 360px)': {
      padding: '0 20px',
      margin: '0 20px',
    },
  },
  root: {
    width: '100%',
    maxWidth: '538px',
    margin: '0 auto',
    padding: '45px 0 37px 0',
    '@media screen and (max-width: 540px)': {
      padding: '40px 0 33px 0',
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
  },
  item: {
    fontSize: 14,
    display: 'flex',
    justifyContent: 'center',
    width: '100%',

    '@media screen and (max-width: 540px)': {
      flexDirection: 'column',
    },
    '&:nth-child(1)': {
      borderRadius: '12px',
      border: '2px solid #939393',
      color: '#939393',
      margin: '7px 0',
    },
    '&:nth-child(2)': {
      borderRadius: '12px',
      border: '2px solid #FFA800',
      color: '#FFA800',
      margin: '7px 0',
    },
    '&:nth-child(3)': {
      borderRadius: '12px',
      border: '2px solid #0CF607',
      color: '#0CF607',
      margin: '7px 0',
    },
    '&:nth-child(4)': {
      borderRadius: '12px',
      border: '2px solid #9209FE',
      color: '#9209FE',
      margin: '7px 0',
    },
    '&:nth-child(5)': {
      margin: '7px 0',
      borderRadius: '12px',
      border: '2px solid #0E09FE',
      color: '#0E09FE',
    },
  },
  paragraphContainer: {
    height: '80px',
    display: 'flex',
    flexDirection: 'raw',
    justifyContent: 'start',
    alignItems: 'center',
    width: '80%',
    '@media screen and (max-width: 540px)': {
      width: 'auto',
      height: 'auto',
      padding: '5px 18px 22px 18px',
      justifyContent: 'center',
    },
  },
  spanContainer: {
    height: '80px',
    width: '15%',
    display: 'flex',
    flexDirection: 'raw',
    justifyContent: 'center',
    alignItems: 'center',
    '@media screen and (max-width: 540px)': {
      width: 'auto',
      height: 'auto',
      padding: '14px 0 0 18px',
      justifyContent: 'start',
    },
  },
  title: {
    fontSize: 24,
    lineHeight: '29px',
    color: theme.palette.primary.main,
    fontFamily: theme.fontFamilies.fourth,
    fontWeight: 400,
    width: '100%',
    textAlign: 'center',
    margin: '0 0 25px 0',
    '@media screen and (max-width: 360px)': {
      textAlign: 'left',
    },
  },
  itemTitle: {
    fontWeight: 700,
    lineHeight: '22px',
    margin: 0,
    width: 'auto',
    height: 'auto',
  },
  itemBody: {
    padding: '0 27px 0 23px',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '21px',
    margin: 0,
    color: theme.palette.twentyFifth.main,
    '@media screen and (max-width: 540px)': {
      padding: '0',
    },
  },
  button: {
    marginTop: '21px',
    width: '100%',
    '@media screen and (max-width: 360px)': {
      marginTop: '38px',
    },
  },
}))

export const ModalGrades = ({ onClose, ...rest }) => {
  const classes = useStyles()
  const windowWidth = window.innerWidth
  const isMobile = () => {
    return windowWidth < 720
  }
  return (
    <Modal
      modalBodyClass={classes.modalBodyClass}
      marginTop={isMobile() ? '30px' : '120px'}
      {...rest}
    >
      <Grid container className={classes.root}>
        <h3 className={classes.title}>Grades</h3>
        <Grid container className={classes.content}>
          <Grid container className={classes.item}>
            <div className={classes.spanContainer}>
              <span className={classes.itemTitle}>Earth</span>
            </div>
            <div className={classes.paragraphContainer}>
              <p className={classes.itemBody}>
                Temporary grade with the right to participate in the lottery for
                $1000 allocation for all accounts until the DTM is issued.
              </p>
            </div>
          </Grid>
          <Grid container className={classes.item}>
            <div className={classes.spanContainer}>
              <span className={classes.itemTitle}>Tropo</span>
            </div>
            <div className={classes.paragraphContainer}>
              <p className={classes.itemBody}>
                Staking 3000 DTM - the right to participate in the lottery for
                $500 allocation
              </p>
            </div>
          </Grid>
          <Grid container className={classes.item}>
            <div className={classes.spanContainer}>
              <span className={classes.itemTitle}>Strato</span>
            </div>
            <div className={classes.paragraphContainer}>
              <p className={classes.itemBody}>
                Staking 6000 DTM - the right to participate in the lottery for
                $1000 allocation
              </p>
            </div>
          </Grid>

          <Grid container className={classes.item}>
            <div className={classes.spanContainer}>
              <span className={classes.itemTitle}>Meso</span>
            </div>
            <div className={classes.paragraphContainer}>
              <p className={classes.itemBody}>
                Staking 9000 DTM - the right to participate in the lottery for
                $2000 allocation
              </p>
            </div>
          </Grid>

          <Grid container className={classes.item}>
            <div className={classes.spanContainer}>
              <span className={classes.itemTitle}>Thermo</span>
            </div>
            <div className={classes.paragraphContainer}>
              <p className={classes.itemBody}>
                Staking 20000 DTM - guaranteed allocation for 2000 $
              </p>
            </div>
          </Grid>
          <Grid className={classes.item}>
            <Button className={classes.button} onClick={onClose}>
              {' '}
              Got it
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Modal>
  )
}
