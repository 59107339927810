import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import * as accountActions from '../redux/actions/account.actions'
import { useWeb3React } from '@web3-react/core'
import { getCurrentConnectionName } from '../helpers/wallet'

export const useAccount = () => {
  const { account, isActive } = useWeb3React()
  const dispatch = useDispatch()

  useEffect(() => {
    const connectionName = getCurrentConnectionName()
    if (!account && !isActive && !connectionName) {
      dispatch(accountActions.logOut())
    }
  }, [account, isActive, dispatch])
}
