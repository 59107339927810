import USDT from '../assets/icons/USDT.svg'
import USDC from '../assets/icons/USDC.svg'
import DAI from '../assets/icons/DAI.svg'
import ETH from '../assets/icons/ETH.svg'
import BNB from '../assets/icons/bnbIcon.png'
import BUSD from '../assets/icons/BUSD.svg'
import BTC from '../assets/icons/BTC.svg'
import MATIC from '../assets/icons/polygon.svg'

const BSCTokens = [
  {
    symbol: 'BNB',
    address: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE',
    decimals: 18,
    icon: BNB,
  },
  {
    symbol: 'WBNB',
    address: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    decimals: 18,
    icon: BNB,
  },
  {
    symbol: 'USDC',
    address: '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d',
    decimals: 18,
    icon: USDC,
  },
  {
    symbol: 'USDT',
    address: '0x55d398326f99059fF775485246999027B3197955',
    decimals: 18,
  },
  {
    symbol: 'ETH',
    address: '0x2170Ed0880ac9A755fd29B2688956BD959F933F8',
    decimals: 18,
    icon: ETH,
  },
  {
    symbol: 'BTC',
    address: '0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c',
    decimals: 18,
    icon: BTC,
  },
  {
    symbol: 'BUSD',
    address: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
    decimals: 18,
    icon: BUSD,
  },
  {
    symbol: 'BCH',
    address: '0x8fF795a6F4D97E7887C79beA79aba5cc76444aDf',
    decimals: 18,
  },
  {
    symbol: 'LTC',
    address: '0x4338665CBB7B2485A8855A139b75D5e34AB0DB94',
    decimals: 18,
  },
  {
    symbol: 'CAKE',
    address: '0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82',
    decimals: 18,
  },
  {
    symbol: 'DODO',
    address: '0x67ee3Cb086F8a16f34beE3ca72FAD36F7Db929e2',
    decimals: 18,
  },
  {
    symbol: '1INCH',
    address: '0x111111111117dC0aa78b770fA6A738034120C302',
    decimals: 18,
  },
  {
    symbol: 'SUSHI',
    address: '0x947950BcC74888a40Ffa2593C5798F11Fc9124C4',
    decimals: 18,
  },
  {
    symbol: 'RDNT',
    address: '0xf7DE7E8A6bd59ED41a4b5fe50278b3B7f31384dF',
    decimals: 18,
  },
  {
    symbol: 'AVAX',
    address: '0x1CE0c2827e2eF14D5C4f29a091d735A204794041',
    decimals: 18,
  },
  {
    symbol: 'KNC',
    address: '0xfe56d5892BDffC7BF58f2E84BE1b2C32D21C308b',
    decimals: 18,
  },
  {
    symbol: 'LINK',
    address: '0xF8A0BF9cF54Bb92F17374d9e9A321E6a111a51bD',
    decimals: 18,
  },
  {
    symbol: 'FIL',
    address: '0x0D8Ce2A99Bb6e3B7Db580eD848240e4a0F9aE153',
    decimals: 18,
  },
  {
    symbol: 'XRP',
    address: '0x1D2F0da169ceB9fC7B3144628dB156f3F6c60dBE',
    decimals: 18,
  },
  {
    symbol: 'agEUR',
    address: '0x12f31B73D812C6Bb0d735a218c086d44D5fe5f89',
    decimals: 18,
  },
  {
    symbol: 'DOT',
    address: '0x7083609fCE4d1d8Dc0C979AAb8c869Ea2C873402',
    decimals: 18,
  },
  {
    symbol: 'YFI',
    address: '0x88f1A5ae2A3BF98AEAF342D26B30a79438c9142e',
    decimals: 18,
  },
  {
    symbol: 'TRX',
    address: '0xCE7de646e7208a4Ef112cb6ed5038FA6cC6b12e3',
    decimals: 6,
  },
  {
    symbol: 'LINA',
    address: '0x762539b45A1dCcE3D36d080F74d1AED37844b878',
    decimals: 18,
  },
  {
    symbol: 'UNI',
    address: '0xBf5140A22578168FD562DCcF235E5D43A02ce9B1',
    decimals: 18,
  },
  {
    symbol: 'WOO',
    address: '0x4691937a7508860F876c9c0a2a617E7d9E945D4B',
    decimals: 18,
  },
  {
    symbol: 'ATOM',
    address: '0x0Eb3a705fc54725037CC9e008bDede697f62F335',
    decimals: 18,
  },
  {
    symbol: 'PAXG',
    address: '0x7950865a9140cB519342433146Ed5b40c6F210f7',
    decimals: 18,
  },
  {
    symbol: 'YFII',
    address: '0x7F70642d88cf1C4a3a7abb072B53B929b653edA5',
    decimals: 18,
  },
  {
    symbol: 'DOGE',
    address: '0xbA2aE424d960c26247Dd6c32edC70B295c744C43',
    decimals: 8,
  },
  {
    symbol: 'EOS',
    address: '0x56b6fB708fC5732DEC1Afc8D8556423A2EDcCbD6',
    decimals: 18,
  },
  {
    symbol: 'WING',
    address: '0x3CB7378565718c64Ab86970802140Cc48eF1f969',
    decimals: 9,
  },
  {
    symbol: 'ONT',
    address: '0xFd7B3A77848f1C2D67E05E54d78d174a0C850335',
    decimals: 18,
  },
  {
    symbol: 'CFX',
    address: '0x045c4324039dA91c52C55DF5D785385Aab073DcF',
    decimals: 18,
  },
  {
    symbol: 'COMP',
    address: '0x52CE071Bd9b1C4B00A0b92D298c512478CaD67e8',
    decimals: 18,
  },
  {
    symbol: 'SOL',
    address: '0x570A5D26f7765Ecb712C0924E4De545B89fD43dF',
    decimals: 18,
  },
  {
    symbol: 'XTZ',
    address: '0x16939ef78684453bfDFb47825F8a5F714f12623a',
    decimals: 18,
  },
  {
    symbol: 'LIT',
    address: '0xb59490aB09A0f526Cc7305822aC65f2Ab12f9723',
    decimals: 18,
  },
  {
    symbol: 'REEF',
    address: '0xF21768cCBC73Ea5B6fd3C687208a7c2def2d966e',
    decimals: 18,
  },
  {
    symbol: 'AAVE',
    address: '0xfb6115445Bff7b52FeB98650C87f44907E58f802',
    decimals: 18,
  },
  {
    symbol: 'MATIC',
    address: '0xCC42724C6683B7E57334c4E856f4c9965ED682bD',
    decimals: 18,
    icon: MATIC,
  },
  {
    symbol: 'MASK',
    address: '0x2eD9a5C8C13b93955103B9a7C167B67Ef4d568a3',
    decimals: 18,
  },
  {
    symbol: 'ALPACA',
    address: '0x8F0528cE5eF7B51152A59745bEfDD91D97091d2F',
    decimals: 18,
  },
  {
    symbol: 'FET',
    address: '0x031b41e504677879370e9DBcF937283A8691Fa7f',
    decimals: 18,
  },
  {
    symbol: 'INJ',
    address: '0xa2B726B1145A4773F68593CF171187d8EBe4d495',
    decimals: 18,
  },
  {
    symbol: 'VET',
    address: '0x6FDcdfef7c496407cCb0cEC90f9C5Aaa1Cc8D888',
    decimals: 18,
  },
  {
    symbol: 'KAVA',
    address: '0x5F88AB06e8dfe89DF127B2430Bba4Af600866035',
    decimals: 6,
  },
  {
    symbol: 'BSW',
    address: '0x965F527D9159dCe6288a2219DB51fc6Eef120dD1',
    decimals: 18,
  },
  {
    symbol: 'SXP',
    address: '0x47BEAd2563dCBf3bF2c9407fEa4dC236fAbA485A',
    decimals: 18,
  },
  {
    symbol: 'FXS',
    address: '0xe48A3d7d0Bc88d552f730B62c006bC925eadB9eE',
    decimals: 18,
  },
  {
    symbol: 'NEAR',
    address: '0x1Fa4a73a3F0133f0025378af00236f3aBDEE5D63',
    decimals: 18,
  },
  {
    symbol: 'C98',
    address: '0xaEC945e04baF28b135Fa7c640f624f8D90F1C3a6',
    decimals: 18,
  },
  {
    symbol: 'HIGH',
    address: '0x5f4Bde007Dc06b867f86EBFE4802e34A1fFEEd63',
    decimals: 18,
  },
  {
    symbol: 'XLM',
    address: '0x43C934A845205F0b514417d757d7235B8f53f1B9',
    decimals: 18,
  },
  {
    symbol: 'SHIB',
    address: '0x2859e4544C4bB03966803b044A93563Bd2D0DD4D',
    decimals: 18,
  },
  {
    symbol: 'XVS',
    address: '0xcF6BB5389c92Bdda8a3747Ddb454cB7a64626C63',
    decimals: 18,
  },
  {
    symbol: 'rBTCB',
    address: '0x34d4F4459c1b529BEbE1c426F1e584151BE2C1e5',
    decimals: 18,
  },
  {
    symbol: 'rUSDT',
    address: '0x4Ff2DD7c6435789E0BB56B0553142Ad00878a004',
    decimals: 18,
  },
  {
    symbol: 'rBUSD',
    address: '0x89d763e8532D256a3e3e60c1C218Ac71E71cF664',
    decimals: 18,
  },
  {
    symbol: 'rUSDC',
    address: '0x3bDCEf9e656fD9D03eA98605946b4fbF362C342b',
    decimals: 18,
  },
  {
    symbol: 'rWETH',
    address: '0x455a281D508B4e34d55b31AC2e4579BD9b77cA8E',
    decimals: 18,
  },
  {
    symbol: 'rWBNB',
    address: '0x58b0BB56CFDfc5192989461dD43568bcfB2797Db',
    decimals: 18,
  },
]

const GoerliTokens = [
  {
    symbol: 'USDC',
    address: '0x7a04986ea1103712c3e066d0608dbef7490deffe',
    icon: USDC,
    decimals: 6,
    link: 'https://goerli.etherscan.io/address/0x7a04986ea1103712c3e066d0608dbef7490deffe',
  },
  {
    symbol: 'USDT',
    address: '0xAd2A5257b0846e952ee2B58328a01a67E722CdA6',
    icon: USDT,
    decimals: 6,
    link: 'https://goerli.etherscan.io/address/0xad2a5257b0846e952ee2b58328a01a67e722cda6',
  },
  {
    symbol: 'ETH',
    address: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE',
    icon: ETH,
    decimals: 18,
  },
  {
    symbol: 'WETH',
    address: '0xB4FBF271143F4FBf7B91A5ded31805e42b2208d6',
    icon: ETH,
    decimals: 18,
  },
  {
    symbol: 'WBTC',
    address: '0xC04B0d3107736C32e19F1c62b2aF67BE61d63a05',
    icon: ETH,
    decimals: 18,
  },
  {
    symbol: 'LINK',
    address: '0x326C977E6efc84E512bB9C30f76E30c160eD06FB',
    icon: ETH,
    decimals: 18,
  },
]

const ArbitrumTokens = [
  {
    symbol: 'ETH',
    address: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE',
    decimals: 18,
    icon: ETH,
  },
  {
    symbol: 'ARB',
    address: '0x912CE59144191C1204E64559FE8253a0e49E6548',
    decimals: 18,
  },
  {
    symbol: 'USDC',
    address: '0xaf88d065e77c8cC2239327C5EDb3A432268e5831',
    decimals: 6,
    icon: USDC,
  },
  {
    symbol: 'USDT',
    address: '0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9',
    decimals: 6,
    icon: USDT,
  },
  {
    symbol: 'DAI',
    address: '0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1',
    decimals: 18,
    icon: DAI,
  },
  {
    symbol: 'WBTC',
    address: '0x2f2a2543B76A4166549F7aaB2e75Bef0aefC5B0f',
    decimals: 8,
  },
  {
    symbol: 'USDD',
    address: '0x680447595e8b7b3Aa1B43beB9f6098C79ac2Ab3f',
    decimals: 18,
  },
  {
    symbol: 'TUSD',
    address: '0x4D15a3A2286D883AF0AA1B3f21367843FAc63E07',
    decimals: 18,
  },
  {
    symbol: 'agEUR',
    address: '0xFA5Ed56A203466CbBC2430a43c66b9D8723528E7',
    decimals: 18,
  },
  {
    symbol: 'AAVE',
    address: '0xba5DdD1f9d7F570dc94a51479a000E3BCE967196',
    decimals: 18,
  },
  {
    symbol: 'BAL',
    address: '0x040d1EdC9569d4Bab2D15287Dc5A4F10F56a56B8',
    decimals: 18,
  },
  {
    symbol: 'COMP',
    address: '0x354A6dA3fcde098F8389cad84b0182725c6C91dE',
    decimals: 18,
  },
  {
    symbol: 'CRV',
    address: '0x11cDb42B0EB46D95f990BeDD4695A6e3fA034978',
    decimals: 18,
  },
  {
    symbol: 'DODO',
    address: '0x69Eb4FA4a2fbd498C257C57Ea8b7655a2559A581',
    decimals: 18,
  },
  {
    symbol: 'FXS',
    address: '0x9d2F299715D94d8A7E6F5eaa8E654E8c74a988A7',
    decimals: 18,
  },
  {
    symbol: 'GMX',
    address: '0xfc5A1A6EB076a2C7aD06eD22C90d7E710E35ad0a',
    decimals: 18,
  },
  {
    symbol: 'GNS',
    address: '0x18c11FD286C5EC11c3b683Caa813B77f5163A122',
    decimals: 18,
  },
  {
    symbol: 'JOE',
    address: '0x371c7ec6D8039ff7933a2AA28EB827Ffe1F52f07',
    decimals: 18,
  },
  {
    symbol: 'KNC',
    address: '0xe4DDDfe67E7164b0FE14E218d80dC4C08eDC01cB',
    decimals: 18,
  },
  {
    symbol: 'LDO',
    address: '0x13Ad51ed4F1B7e9Dc168d8a00cB3f4dDD85EfA60',
    decimals: 18,
  },
  {
    symbol: 'LINK',
    address: '0xf97f4df75117a78c1A5a0DBb814Af92458539FB4',
    decimals: 18,
  },
  {
    symbol: 'PENDLE',
    address: '0x0c880f6761F1af8d9Aa9C466984b80DAb9a8c9e8',
    decimals: 18,
  },
  {
    symbol: 'RDNT',
    address: '0x3082CC23568eA640225c2467653dB90e9250AaA0',
    decimals: 18,
  },
  {
    symbol: 'RPL',
    address: '0xB766039cc6DB368759C1E56B79AFfE831d0Cc507',
    decimals: 18,
  },
  {
    symbol: 'SPELL',
    address: '0x3E6648C5a70A150A88bCE65F4aD4d506Fe15d2AF',
    decimals: 18,
  },
  {
    symbol: 'SUSHI',
    address: '0xd4d42F0b6DEF4CE0383636770eF773390d85c61A',
    decimals: 18,
  },
  {
    symbol: 'UNI',
    address: '0xFa7F8980b0f1E64A2062791cc3b0871572f1F7f0',
    decimals: 18,
  },
  {
    symbol: 'WOO',
    address: '0xcAFcD85D8ca7Ad1e1C6F82F651fA15E33AEfD07b',
    decimals: 18,
  },
  {
    symbol: 'YFI',
    address: '0x82e3A8F066a6989666b031d916c43672085b1582',
    decimals: 18,
  },
  {
    symbol: 'aArbUSDT',
    address: '0x6ab707Aca953eDAeFBc4fD23bA73294241490620',
    decimals: 6,
  },
  {
    symbol: 'aArbDAI',
    address: '0x82E64f49Ed5EC1bC6e43DAD4FC8Af9bb3A2312EE',
    decimals: 18,
  },
  {
    symbol: 'aArbUSDC',
    address: '0x625E7708f30cA75bfd92586e17077590C60eb4cD',
    decimals: 6,
  },
  {
    symbol: 'aArbLINK',
    address: '0x191c10Aa4AF7C30e871E70C95dB0E4eb77237530',
    decimals: 18,
  },
  {
    symbol: 'aArbWBTC',
    address: '0x078f358208685046a11C85e8ad32895DED33A249',
    decimals: 8,
  },
  {
    symbol: 'aArbWETH',
    address: '0xe50fA9b3c56FfB159cB0FCA61F5c9D750e8128c8',
    decimals: 18,
  },
  {
    symbol: 'aArbAAVE',
    address: '0xf329e36C7bF6E5E86ce2150875a84Ce77f477375',
    decimals: 18,
  },
  {
    symbol: 'aArbARB',
    address: '0x6533afac2E7BCCB20dca161449A13A32D391fb00',
    decimals: 18,
  },
  {
    symbol: 'aArbLUSD',
    address: '0x8ffDf2DE812095b1D19CB146E4c004587C0A0692',
    decimals: 18,
  },
]

const LocalhostTokens = [
  {
    symbol: 'USDC',
    address: '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
    icon: USDC,
    decimals: 6,
    link: 'https://goerli.etherscan.io/address/0x7a04986ea1103712c3e066d0608dbef7490deffe',
  },
  {
    symbol: 'USDT',
    address: '0xdAC17F958D2ee523a2206206994597C13D831ec7',
    icon: USDT,
    decimals: 6,
    link: 'https://goerli.etherscan.io/address/0xad2a5257b0846e952ee2b58328a01a67e722cda6',
  },
]

const PolygonTokens = [
  {
    symbol: 'MATIC',
    address: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE',
    decimals: 18,
    icon: MATIC,
  },
  {
    symbol: 'WMATIC',
    address: '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
    decimals: 18,
    icon: MATIC,
  },
  {
    symbol: 'USDT',
    address: '0xc2132D05D31c914a87C6611C10748AEb04B58e8F',
    decimals: 6,
    icon: USDT,
  },
  {
    symbol: 'USDC',
    address: '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174',
    decimals: 6,
    icon: USDC,
  },
  {
    symbol: 'TUSD',
    address: '0x2e1AD108fF1D8C782fcBbB89AAd783aC49586756',
    decimals: 18,
  },
  {
    symbol: 'DAI',
    address: '0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063',
    decimals: 18,
  },
  {
    symbol: 'ETH',
    address: '0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
    decimals: 18,
    icon: ETH,
  },
  {
    symbol: 'WBTC',
    address: '0x1BFD67037B42Cf73acF2047067bd4F2C47D9BfD6',
    decimals: 8,
  },
  {
    symbol: '1INCH',
    address: '0x9c2C5fd7b07E95EE044DDeba0E97a665F142394f',
    decimals: 18,
  },
  {
    symbol: 'AAVE',
    address: '0xD6DF932A45C0f255f85145f286eA0b292B21C90B',
    decimals: 18,
  },
  {
    symbol: 'agEUR',
    address: '0xE0B52e49357Fd4DAf2c15e02058DCE6BC0057db4',
    decimals: 18,
  },
  {
    symbol: 'AVAX',
    address: '0x2C89bbc92BD86F8075d1DEcc58C7F4E0107f286b',
    decimals: 18,
  },
  {
    symbol: 'BAL',
    address: '0x9a71012B13CA4d3D0Cdc72A177DF3ef03b0E76A3',
    decimals: 18,
  },
  {
    symbol: 'BAT',
    address: '0x3Cef98bb43d732E2F285eE605a8158cDE967D219',
    decimals: 18,
  },
  {
    symbol: 'COMP',
    address: '0x8505b9d2254A7Ae468c0E9dd10Ccea3A837aef5c',
    decimals: 18,
  },
  {
    symbol: 'FXS',
    address: '0x1a3acf6D19267E2d3e7f898f42803e90C9219062',
    decimals: 18,
  },
  {
    symbol: 'GNS',
    address: '0xE5417Af564e4bFDA1c483642db72007871397896',
    decimals: 18,
  },
  {
    symbol: 'KNC',
    address: '0x1C954E8fe737F99f68Fa1CCda3e51ebDB291948C',
    decimals: 18,
  },
  {
    symbol: 'LINK',
    address: '0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39',
    decimals: 18,
  },
  {
    symbol: 'PAXG',
    address: '0x553d3D295e0f695B9228246232eDF400ed3560B5',
    decimals: 18,
  },
  {
    symbol: 'MANA',
    address: '0xA1c57f48F0Deb89f569dFbE6E2B7f46D33606fD4',
    decimals: 18,
  },
  {
    symbol: 'SAND',
    address: '0xBbba073C31bF03b8ACf7c28EF0738DeCF3695683',
    decimals: 18,
  },
  {
    symbol: 'SNX',
    address: '0x50B728D8D964fd00C2d0AAD81718b71311feF68a',
    decimals: 18,
  },
  {
    symbol: 'SUSHI',
    address: '0x0b3F868E0BE5597D5DB7fEB59E1CADBb0fdDa50a',
    decimals: 18,
  },
  {
    symbol: 'UNI',
    address: '0xb33EaAd8d922B1083446DC23f610c2567fB5180f',
    decimals: 18,
  },
  {
    symbol: 'WOO',
    address: '0x1B815d120B3eF02039Ee11dC2d33DE7aA4a8C603',
    decimals: 18,
  },
  {
    symbol: 'YFI',
    address: '0xDA537104D6A5edd53c6fBba9A898708E465260b6',
    decimals: 18,
  },
  {
    symbol: 'aPolDAI',
    address: '0x82E64f49Ed5EC1bC6e43DAD4FC8Af9bb3A2312EE',
    decimals: 18,
  },
  {
    symbol: 'aPolLINK',
    address: '0x191c10Aa4AF7C30e871E70C95dB0E4eb77237530',
    decimals: 18,
  },
  {
    symbol: 'aPolUSDC',
    address: '0x625E7708f30cA75bfd92586e17077590C60eb4cD',
    decimals: 6,
  },
  {
    symbol: 'aPolWBTC',
    address: '0x078f358208685046a11C85e8ad32895DED33A249',
    decimals: 8,
  },
  {
    symbol: 'aPolWETH',
    address: '0xe50fA9b3c56FfB159cB0FCA61F5c9D750e8128c8',
    decimals: 18,
  },
  {
    symbol: 'aPolUSDT',
    address: '0x6ab707Aca953eDAeFBc4fD23bA73294241490620',
    decimals: 6,
  },
  {
    symbol: 'aPolAAVE',
    address: '0xf329e36C7bF6E5E86ce2150875a84Ce77f477375',
    decimals: 18,
  },
  {
    symbol: 'aPolWMATIC',
    address: '0x6d80113e533a2C0fe82EaBD35f1875DcEA89Ea97',
    decimals: 18,
  },
  {
    symbol: 'aPolSUSHI',
    address: '0xc45A479877e1e9Dfe9FcD4056c699575a1045dAA',
    decimals: 18,
  },
  {
    symbol: 'aPolBAL',
    address: '0x8ffDf2DE812095b1D19CB146E4c004587C0A0692',
    decimals: 18,
  },
  {
    symbol: 'aPolAGEUR',
    address: '0x8437d7C167dFB82ED4Cb79CD44B7a32A1dd95c77',
    decimals: 18,
  },
]

export const SUPPORTED_TOKENS = {
  5: GoerliTokens,
  56: BSCTokens,
  137: PolygonTokens,
  31337: LocalhostTokens,
  42161: ArbitrumTokens,
}
