import { createStore, applyMiddleware } from 'redux'
import createSagaMiddleware from 'redux-saga'
import { composeWithDevTools } from 'redux-devtools-extension'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist-indexeddb-storage'
import { reducers } from './reducers'
import { sagasWatcher } from './sagas'
// import reduxLogger from 'redux-logger'

const persistConfig = {
  key: 'main-root',
  storage: storage('aspis-redux-persist'),
}

const persistedReducer = persistReducer(persistConfig, reducers)

const sagaMiddleware = createSagaMiddleware()

export const store = createStore(
  //reducers,
  persistedReducer,
  composeWithDevTools(applyMiddleware(sagaMiddleware))
)

const Persistor = persistStore(store)
export { Persistor }

sagaMiddleware.run(sagasWatcher)
