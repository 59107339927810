import { useRef, useState } from 'react'

// Material-UI
import { Button } from '../Button'
import { Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Select, MenuItem } from '@material-ui/core'

import clsx from 'clsx'

import { DaoAPI } from '../../api/DaoAPI'
import { NumericFormat } from 'react-number-format'
import BigNumber from 'bignumber.js'
import { useWeb3React } from '@web3-react/core'

const useStyles = makeStyles((theme) => ({
  root: {
    height: 'fit-content',
    padding: '5px 8px 5px 5px',
    border: `1px solid ${theme.palette.twentySecond.main}`,
    borderRadius: 8,
    backgroundColor: theme.palette.common.white,
    justifyContent: 'space-between',
  },
  inputWrapper: {
    '& > *': {
      lineHeight: '100%',
    },
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: 'calc(100% - 160px)',
  },
  inputLabel: {
    color: '#4C4C66',
    opacity: '0.67',
    fontSize: 12,
    lineHeight: '217%',
    margin: '0 0 12px 0',
    '@media screen and (max-width: 460px)': {
      margin: '0 0 8px 0',
    },
  },
  input: {
    fontFamily: theme.fontFamilies.secondary,
    height: 28,
    width: '100%',
    fontSize: 14,
    paddingLeft: '10px',
    border: 'none',
    background: 'none',
    '&::placeholder': {
      color: theme.palette.twentyThird.main,
    },
    '&:focus': {
      outline: 'none',
    },
    '@media screen and (max-width: 460px)': {
      fontSize: 22,
      height: 22,
    },
  },
  addons: {
    display: 'flex',
    alignItems: 'center',
  },
  balance: {
    display: 'inline-block',
    padding: '6px 8px 6px 8px',
    color: theme.palette.white.main,
    background: theme.palette.twentyFirst.main,
    borderRadius: 24,
    fontWeight: 600,
    fontSize: 12,
    lineHeight: '18px',
    margin: '0 0 6px 9px',
  },
  maxAndCurrencyWrapper: {
    display: 'flex',
    color: theme.palette.twentieth.main,
    fontWeight: 600,
    fontSize: 14,
    lineHeight: '150%',
    alignItems: 'center',
    marginRight: 10,
  },
  maxButton: {
    height: 28,
    padding: 5,
  },
  select: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: 56,
    cursor: 'pointer',
  },
  balanceContent: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'start',
  },
  currencyIcon: {
    width: 20,
    marginRight: 6,
  },
  approveButtonContent: {
    display: 'flex',
    alignItems: 'center',
    padding: 15,
  },
  approveButton: {
    background: '#8765F2',
    borderRadius: 8,
    padding: '20px 32px 20px 32px',
  },
  selectButton: {
    borderRadius: '8px',
    border: '1px solid #dddfeb',
    height: '30px',
    '& .MuiSelect-select.MuiSelect-select': {
      alignItems: 'center',
      paddingRight: '18px',
      display: 'flex',
      marginLeft: '3px',
    },
    '& .MuiSelect-select:focus': {
      backgroundColor: 'transparent',
    },
  },
}))

export const SwapCardInput = ({
  title,
  account,
  handleDepositAmount,
  className,
  value,
  onChange,
  onMaxClick,
  balance,
  swapCurrency,
  disabled,
  tokens,
  handleDepositToken,
  toggleMaxAmountToDeposit,
  fundraisingFinished,
}) => {
  const { connector } = useWeb3React()
  const classes = useStyles()

  const [currency, setCurrency] = useState('')
  const [inputWrapperStyle] = useState({})

  const [walletBalance, setWalletBalance] = useState(0)
  const [depositAmount, setDepositAmount] = useState(0)
  const [toggleMaxButton, setToggleMaxButton] = useState(false)

  const ref = useRef(null)

  const changeDepositAmount = (event) => {
    if (event.target.value === '') {
      handleDepositAmount(0, walletBalance)
    } else {
      handleDepositAmount(parseFloat(event.target.value), walletBalance)
    }
  }

  const handleMaxDepostiAmount = async () => {
    setDepositAmount(await toggleMaxAmountToDeposit())
  }

  const changeDepositToken = async (event) => {
    const decimals = event.currentTarget.id
    const token = event.currentTarget.dataset.token
    const symbol = event.currentTarget.dataset.value

    setCurrency(event.currentTarget.dataset.value)
    handleDepositToken(token, decimals, symbol)
    setToggleMaxButton(true)
    setWalletBalance(
      await DaoAPI.getTokenBalance(token, account, decimals, connector)
    )
  }

  return (
    <div>
      <Grid container>
        {/* <Grid item xs={6} md={6} lg={6}>
       <span className={classes.inputLabel}>{title} ({swapCurrency})</span>
      </Grid> */}
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <div className={classes.balanceContent}>
            <span className={classes.inputLabel}>Wallet Balance:</span>
            {toggleMaxButton ? (
              <span className={classes.balance}>
                {new BigNumber(walletBalance).toFixed(3)} {currency}
              </span>
            ) : null}
          </div>
        </Grid>
      </Grid>
      <div style={{ display: 'flex' }}>
        <Grid container className={clsx([classes.root, className])}>
          <Grid className={classes.inputWrapper} style={inputWrapperStyle}>
            <NumericFormat
              className={classes.input}
              value={toggleMaxButton ? value : 'Select asset'}
              placeholder="Select asset"
              disabled={toggleMaxButton ? false : true}
              onChange={changeDepositAmount}
              step=".0000000000000001"
            />
          </Grid>
          <Grid ref={ref} className={classes.addons}>
            <Grid className={classes.maxAndCurrencyWrapper}>
              {toggleMaxButton ? (
                <Button
                  className={classes.maxButton}
                  type="fourth"
                  disabled={disabled}
                  value={depositAmount}
                  onClick={handleMaxDepostiAmount}
                >
                  Max
                </Button>
              ) : null}
            </Grid>
            <Grid>
              <Select
                className={classes.selectButton}
                disableUnderline
                displayEmpty
                defaultValue="test"
                disabled={!account || fundraisingFinished}
                value={currency}
              >
                {tokens &&
                  tokens.map((item) => {
                    return (
                      <MenuItem
                        sx={{ display: 'flex', alignItems: 'self-end' }}
                        selected={true}
                        value={item.symbol}
                        key={item.address}
                        data-token={item.address}
                        onClick={changeDepositToken}
                        id={item.decimals}
                      >
                        <img
                          src={item.icon}
                          className={classes.currencyIcon}
                          alt=""
                        />
                        {item.symbol}
                      </MenuItem>
                    )
                  })}
              </Select>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  )
}
