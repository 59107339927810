import React from 'react'
import { Box, Chip } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'

const useStyle = makeStyles((theme) => ({
  public: {
    background: 'rgba(255, 140, 237, 0.3)',
    color: '#E51BC5',
    fontSize: 12,
    fontWeight: 'bold',
    width: 80,
    height: 25
  },
  private:{
    color:'#8438FF',
    fontWeight: 600,
    fontSize: '12px',
    width: 80,
    height: 25,
    background: 'rgba(190, 159, 255, 0.3)'
  },
  change24h:{
    background: '#E4FCEF',
    fontSize: 10,
    fontFamily: 'Poppins',
    color:'#00B654',
    fontWeight: 'bold'
  },
  totalChange:{
    background: '#FCE4F0',
    fontSize: 11,
    fontFamily: 'Poppins',
    color: '#B6002C',
    fontWeight: 'bold'
  },
  fundManagerChip:{
    color: '#8765F2',
    background: 'rgba(137, 99, 244, 0.07)',
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: 15,
    padding: 20,
  },
  chipInfo:{
    textDecoration:'none',
    margin: 3,
    fontWeight: 'bold',
    cursor: 'pointer'
  },
  chipTitle:{
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '12px',
    lineHeight: '18px',
    color: '#4C4C66',
    marginBottom: '6px',
  },
  active:{
    background: '#8765F2',
    color:'white',
    fontSize: '9px',
    fontWeight: 600,
    fontFamily: 'Poppins',
    margin: '10px 0px 10px 0px',
    width:'100%',
    cursor: 'pointer'
   },
   unVoted:{
    background: '#BC65F2',
    color:'white',
    fontSize: '9px',
    fontWeight: 600,
    fontFamily: 'Poppins',
    margin: '10px 0px 10px 0px',
    width: '100%',
    cursor: 'pointer'
   },
   accepted:{
    background: '#45BEB6',
    color:'white',
    fontSize: '9px',
    fontWeight: 600,
    fontFamily: 'Poppins',
    margin: '10px 0px 10px 0px',
    width:'100%',
    cursor: 'pointer'
   },
   customLabel:{
    paddingLeft: "0px",
    paddingRight:'0px',
    [theme.breakpoints.down("md")]: {
      overflow: "hidden",
      textOverflow: 'ellipsis' 
    }
   },
}))

export const ChipLabel = ({ type, className, children, disabledColor, label, ...rest }: any) => {
  const classes = useStyle();

  const buttonClass = classes[type] || classes.public

  // if (type === 'link') {
  //   return (
  //     <span
  //       className={className ? `${buttonClass} ${className}` : buttonClass}
  //       {...rest}
  //     >
  //       {children}
  //     </span>
  //   )
  // }

  const CustomLabel = ({label}) => {
    return(
      <Box className={classes.customLabel}>
        {label}
      </Box>
    )
  }

  return (
    <Chip
      className={clsx([buttonClass, className])}
      label={<CustomLabel label={label}/>}
      {...rest}
    >
      {children}
    </Chip>
  )
}
