import { useState } from 'react'

// Material UI
import { withStyles } from '@material-ui/core/styles'
import { Button } from '../Button'
import copySvg from '../../assets/icons/copyicon.svg'
import Tooltip from '@material-ui/core/Tooltip'

//styledTooltip
export const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#edecec',
    color: 'black',
    boxShadow: theme.shadows[2],
    fontSize: 11,
    fontWeight: 400,
    fontFamily: 'Poppins',
    padding: 10,
  },
}))(Tooltip)

export const CopyToClipboardBtn = ({
  props,
  size = { img: 18, circle: 40 },
}) => {
  const [copySuccess, setCopySuccess] = useState('Copy Address')

  const copyToClipBoard = async (copyMe) => {
    try {
      await navigator.clipboard.writeText(copyMe)
      setCopySuccess('Copied!')
      setTimeout(() => {
        setCopySuccess('Copy Address')
      }, 2000)
    } catch (err) {
      setCopySuccess('Failed to copy!')
    }
  }

  return (
    <Button
      type="copyAddress"
      style={{ width: size.circle, height: size.circle }}
      onClick={() => copyToClipBoard(props)}
    >
      <LightTooltip title={copySuccess}>
        <img src={copySvg} alt="copyImage" width={size.img} height={size.img} />
      </LightTooltip>
    </Button>
  )
}
