import React, { useCallback, useState } from 'react'
import { Link } from 'react-router-dom'
import { Grid, Modal, Slide } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import closeButtonSrc from '../../assets/icons/close-button.svg'
import { routes } from '../../const/routes'

import { Account, ModalWallet } from '../'
import { useWeb3React } from '@web3-react/core'
import { WalletConnectorNames } from '../../const/walletConnectorNames'
import {
  getInjectedConnector,
  getWalletConnectConnector,
  setCurrentConnectionName,
} from '../../helpers/wallet'
import { Logo } from '../Logo/index'
import CreateDao from '../../assets/icons/CreateDAO.svg'
import Cabinet from '../../assets/icons/Cabinet.svg'
import DaoPad from '../../assets/icons/DAOPad.svg'
import Exchange from '../../assets/icons/Exchange.svg'
import AboutAspis from '../../assets/icons/AboutASPIS.svg'

import { UnlockWalletButton } from '../'

const useStyles = makeStyles((theme) => ({
  modal: {
    background: 'rgba(255, 255, 255, 0.94)',
    backdropFilter: 'blur(22px)',
    overflow: 'scroll',
  },
  modalBody: {
    flexDirection: 'column',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: 58,
    margin: '7px',
    borderBottom: `1px solid ${theme.palette.twentyNinth.main}`,
    '&:focus': {
      outline: 'none',
    },
  },
  closeButton: {
    margin: '0 27px 0 0',
  },
  menu: {
    display: 'flex',
    flexDirection: 'column',
    margin: '49px 0 29px 0',
    width: '100%',
    '& > a, & > span': {
      fontSize: 20,
      letterSpacing: 0.5,
      fontFamily: 'Poppins',
      borderBottom: '1px solid rgba(111, 108, 153, 0.13)',
      fontWeight: 500,
      color: '#6F6C99',
      margin: '0 0 38px 0',
      textDecoration: 'none',
      cursor: 'pointer',
      paddingBottom: 25,
      display: 'flex',
      alignItems: 'center',
      marginLeft: '15px',
      marginRight: '15px',
      // '&:last-child': {
      //   margin: 0,
      // },
    },
  },
  button: {
    width: '100%',
    maxWidth: 400,
    height: 48,
  },
  account: {
    width: '55%',
    maxWidth: 400,
    height: 48,
    [theme.breakpoints.down('xs')]: {
      width: '85%',
    },
  },
  logo: {
    width: '87px',
    height: '34px',
  },

  buttonWallet: {
    background: '#8765F2',
    borderRadius: 8,
    width: '90%',
    display: 'flex',
    margin: 'auto',
  },
  img: {
    marginRight: '30px',
    marginLeft: '10px',
  },
}))

export const MobileMenu = ({ isOpen, onClose, role, openWalletModal }) => {
  const classes = useStyles()
  const { account } = useWeb3React()
  const rootRef = React.useRef(null)

  const onRouteClick = useCallback(onClose, [onClose])

  const [isModalOpen, setIsModalOpen] = useState(false)

  const onModalClose = useCallback(() => {
    setIsModalOpen(false)
  }, [])

  const onConnectorChange = async (connectorName, networkParams) => {
    let connector
    if (connectorName === WalletConnectorNames.Metamask) {
      connector = getInjectedConnector()
    } else if (connectorName === WalletConnectorNames.WalletConnect) {
      connector = getWalletConnectConnector()
    }

    connector.activate(parseInt(networkParams.chainId))
    setCurrentConnectionName(connectorName)
  }

  return (
    <Slide direction={'left'} in={isOpen} mountOnEnter unmountOnExit>
      <Modal
        disablePortal
        open={true}
        aria-labelledby="server-modal-title"
        aria-describedby="server-modal-description"
        className={classes.modal}
        container={() => rootRef.current}
        hideBackdrop
      >
        <>
          <header className={classes.header}>
            <Grid item sm={5}>
              {!account ? (
                <UnlockWalletButton
                  type="success"
                  className={classes.button}
                  setIsModalOpen={setIsModalOpen}
                  onConnectorChange={onConnectorChange}
                />
              ) : (
                <Account
                  className={classes.account}
                  setIsModalOpen={setIsModalOpen}
                />
              )}
              <ModalWallet
                isOpen={isModalOpen}
                onClose={onModalClose}
                onConnectorChange={onConnectorChange}
              />
            </Grid>
            <Grid item sm={6}>
              <Logo className={classes.logo} onClick={onRouteClick} />
            </Grid>
            <Grid item sm={1}>
              <img
                className={classes.closeButton}
                onClick={onClose}
                src={closeButtonSrc}
                alt="close button"
              />
            </Grid>
          </header>
          <Grid container className={classes.modalBody}>
            <nav className={classes.menu}>
              {/* <Link to={routes.ALL_POOLS} onClick={onRouteClick}>
                Pools
              </Link>
              {
                role !== UserRoles.Unlogged ?
                  <Link to={routes.ACCOUNT}>Account</Link>
                  :
                  <span onClick={openWalletModal}>Account</span>
              } */}
              <Link to="/#">
                <img className={classes.img} src={DaoPad} alt="daoPad" />
                Vaults
              </Link>
              <Link to="!#">
                <img className={classes.img} src={Cabinet} alt="cabiet" />
                Cabinet
              </Link>
              <Link to={routes.CREATE_DAO} onClick={onClose}>
                <img className={classes.img} src={CreateDao} alt="createDao" />
                Create Vault
              </Link>
              <Link to="!#">
                <img className={classes.img} src={Exchange} alt="exchange" />
                Exchange
              </Link>
              <a
                href={'https://info.aspis.finance/introduction/what-is-aspis'}
                target="_blank"
                rel="noreferrer"
              >
                <img
                  className={classes.img}
                  src={AboutAspis}
                  alt="aboutAspis"
                />
                About ASPIS
              </a>
            </nav>
          </Grid>
        </>
      </Modal>
    </Slide>
  )
}
