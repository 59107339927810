import { useState, useEffect } from 'react'
import { useLocation } from 'react-router'

// Material-UI imports
import {
  Card,
  CardContent,
  Grid,
  FormControl,
  Select,
  OutlinedInput,
  MenuItem
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import { DaoAPI } from '../../../api/DaoAPI'
import { decodeDaoConfigParams } from '../../../helpers/abiDecoder'
import { useAspisSupportedTokens } from '../../../hooks/useChain'
import { useWeb3React } from '@web3-react/core'

const useStyle = makeStyles((theme) => ({
  divider: {
    borderBottom: '1px solid rgb(224 224 224)',
  },
  cardTitle: {
    fontSize: 13,
    fontWeight: 600,
    fontStyle: 'normal',
    lineHeight: 4,
    margin: 0,
    paddingLeft: '16px',
  },
  cardContent: {
    borderRadius: '18px 0px 0px 18px',
    border: '1px solid rgba(214, 210, 242, 0.5)',
    boxShadow: '0px 51px 89px -49px rgba(196, 193, 232, 0.4)',
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      borderRadius: '18px 18px 18px 18px',
    }
  },
  cardContentSecondary: {
    borderRadius: '0px 18px  18px 0px',
    border: '1px solid rgba(214, 210, 242, 0.5)',
    boxShadow: '0px 51px 89px -49px rgba(196, 193, 232, 0.4)',
    width: '100%',
    background: '#F7F6FC',
  },
  helperText: {
    fontSize: '9px',
    color: '#878799',
  },
  radioContent: {
    display: 'block',
  },
  formContent: {
    width: '100%'
  },
  title: {
    margin: 0,
    fontSize: '11px',
    fontWeight: 500,
    lineHeight: '23.87px'
  },
  selectInput: {
    '& .MuiOutlinedInput-input': {
      padding: '10.5px 14px'
    }
  }
}))

export const VoteForRemoveTokenForDeposit = (props: any) => {
  const classes = useStyle()

  const location = useLocation()
  const { connector } = useWeb3React()
  const daoAddress = location.state.state.data.subgraph.id

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [tokenDetails, setTokenDetails] = useState<any>([])

  const [supportedTokens, setSupportedTokens] = useState<any>([])
  const [mergeToken, setMergeToken] = useState<any>({})

  useEffect(() => {
    const fetchTokenData = async () => {
      setSupportedTokens(await DaoAPI.getDepositTokens(daoAddress, connector))
      const result = decodeDaoConfigParams(props?.action)
      const tokens = result?.inputs[0]
      // Because the SC returns the address without '0x' (in the begining) 
      // we iterate through each element and add '0x' at the begining of each one => this is the value of mergeToken
      const modifiedArray = tokens.map(element => '0x' + element);
      setMergeToken({token: modifiedArray});
    }

    fetchTokenData()
      .catch(console.error)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const acceptedCurrencies = useAspisSupportedTokens(supportedTokens)
  const acceptedTokenSymbols = acceptedCurrencies.map(token=>token.symbol)
  const proposedTokens = useAspisSupportedTokens(mergeToken.token)
  const proposedtTokenSymbols = proposedTokens.map(token=>token.symbol)

  return (
    <>
      <Grid container>
        <Grid item lg={6} md={6} sm={6} xs={12}>
          <Card className={classes.cardContent}>
            <div className={classes.divider}>
              <p className={classes.cardTitle}>Proposed tokens to remove from depositing</p>
            </div>
            <CardContent style={{ padding: '16px' }}>
              <Grid container>
                <Grid item lg={12} sm={12} md={12} xs={12}>
                  <p className={classes.title}>​​Tokens to remove from depositing </p>
                  <FormControl className={classes.formContent}>
                    <Select
                      multiple
                      name={'tokenName'}
                      className={classes.selectInput}
                      value={proposedtTokenSymbols}
                      renderValue={(proposedtTokenSymbols:any)=>proposedtTokenSymbols.join(', ')}
                      input={<OutlinedInput />} >
                      {
                        proposedTokens?.map((token) => (
                          <MenuItem value={token.address}
                            key={token.address}
                            data-token={token.address}>
                            {token.symbol}
                          </MenuItem>
                        ))
                      }
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item lg={6} md={6} sm={6} xs={12}>
          <Card className={classes.cardContentSecondary}>
            <div className={classes.divider}>
              <p className={classes.cardTitle}>Current tokens available for depositing</p>
            </div>
            <CardContent style={{ padding: '16px' }}>
              <Grid container>
                <Grid item lg={12} sm={12} md={12} xs={12}>
                  <p className={classes.title}>​​Tokens available for depositing </p>
                  <FormControl className={classes.formContent}>
                    <Select
                      multiple
                      className={classes.selectInput}
                      name={'tokenName'}
                      value={acceptedTokenSymbols}
                      renderValue={(acceptedTokenSymbols:any)=>acceptedTokenSymbols.join(', ')}
                      input={<OutlinedInput />} >
                      {
                        acceptedCurrencies?.map((token) => (
                          <MenuItem value={token.address}
                            key={token.address}
                            data-token={token.address}>
                            {token.symbol}
                          </MenuItem>
                        ))
                      }
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  )
}
