import { DAO, ERC20, IMajorityVoting, AspisConfiguration } from '../abi'
import InputDataDecoder from 'ethereum-input-data-decoder'

export const decodeDAOParams = (action) => {
  const decoder = new InputDataDecoder(DAO)
  return decoder.decodeData(action)
}

export const decodeDaoConfigParams = (action) => {
  const decoder = new InputDataDecoder(AspisConfiguration)
  return decoder.decodeData(action)
}

export const decodeVotingParams = (action) => {
  const decoder = new InputDataDecoder(IMajorityVoting)
  return decoder.decodeData(action)
}

export const decodeApprove = (action) => {
  const decoder = new InputDataDecoder(ERC20)
  return decoder.decodeData(action)
}
