import { makeStyles } from '@material-ui/core/styles'
import { Row } from './Row'

const useStyles = makeStyles((theme) => ({
  grid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(8, 1fr)',
    border: '1px solid rgba(216, 207, 242, 0.2)',
    borderRadius: '18px 18px 18px 18px',
    backgroundColor: '#FFFFFF',
    paddingBottom: '96px',

    '&>:nth-child(8n)': {
      borderRadius: '0 13px 13px 0',
      marginRight: '10px',
      paddingRight: '14px',
    },
    '&>:nth-child(8n + 1)': {
      borderRadius: '13px 0 0 13px',
      marginLeft: '10px',
      paddingLeft: '14px',
    },
    '&>:nth-child(1)': {
      marginLeft: 0,
      borderRadius: 0,
    },
    '&>:nth-child(8)': {
      marginRight: 0,
      borderRadius: 0,
    },
    '&>div': {
      display: 'flex',
      flexFlow: 'row nowrap',
      justifyContent: 'center',
      alignItems: 'center',
      borderBottom: '1px solid rgba(216, 207, 242, 0.2)',
      padding: '12px 0',
      fontWeight: 600,
      marginBottom: '1px',
      // backgroundColor: 'rgba(247, 246, 251, 0.4)',
      borderRight: '1px solid rgba(216, 207, 242, 0.2)',
      borderLeft: '1px solid rgba(216, 207, 242, 0.2)',
    },
  },
  header: {
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'center',
    alignItems: 'center',
    height: '50px',
    borderBottom: '1px solid #ddd',
    fontSize: '11px',
    color: '#4C4C66',
    padding: '0 !important',
    marginBottom: '14px !important',
  },
}))

export const FundsTable = ({ daos }) => {
  const classes = useStyles()

  return (
    <div className={classes.grid}>
      <div className={classes.header}>Fund name</div>
      <div className={classes.header}>Chain</div>
      <div className={classes.header}>Deposit Range</div>
      <div className={classes.header}>Accepted Tokens</div>
      <div className={classes.header}>Smart Token Price</div>
      <div className={classes.header}>Change 24h/Total</div>
      <div className={classes.header}>Withdrawal Windows</div>
      <div className={classes.header}>Fundraising</div>
      {daos?.pages.map((page) =>
        page.records?.map((item: any, index: number) => <Row item={item} />)
      )}
    </div>
  )
}
