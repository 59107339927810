// Material UI imports
import {
  Card,
  CardContent,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import CreateImg from '../assets/icons/Create.svg'
import CreateImgHover from '../assets/icons/CreateHover.svg'

const useStyles = makeStyles((theme) => ({
  card: {
    cursor: 'pointer',
    height: '100%',
    display: 'grid',
    borderRadius: '10px',
    boxShadow: '0px 17px 26px rgba(215, 190, 255, 0.05)',
    [theme.breakpoints.down('xs')]: {
      height: '400px'
    }
  },
  cardContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    background: '#F0F0FF',
    boxShadow: '0px 17px 26px rgba(215, 190, 255, 0.05)',
    borderRadius: '10px',
    border: '1px solid #8765F2',
    borderStyle: 'dashed',
    margin:'16px',
    '&:hover': {
      background: 'rgba(188, 101, 242, 0.15)',
      border: '1px dashed #BC65F2',
      '& $title': {
        color: '#BC65F2'
      },
      '& $content': {
        backgroundImage: `url(${CreateImgHover})`,
      }
    },
    '& .MuiCardContent-root': {
      padding: 0,
    },
  },
  title: {
    color: '#8765F2',
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: 700,
    marginTop: '95px',
    paddingTop: '16px'
  },
  content: {
    backgroundImage: `url(${CreateImg})`,
    height: '100%',
    width: '100%',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center'

  },
}))

export const CreateCard = ({ daoId, title }) => {
  
  const classes = useStyles()

  return (
    <Card className={classes.card}>
      <CardContent className={classes.cardContent}>
        <div className={classes.content}>
          <br/>
          <p className={classes.title}>{title}</p>
        </div>
      </CardContent>
    </Card>
  )
}
