import * as React from 'react';
import {Table,TableBody,TableCell,TableContainer,TableHead,TableRow} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import polkaLokr from '../../assets/icons/polkaLokr.svg'
import dlTechnologies from '../../assets/icons/dlTechnologies.svg'
import visionFund from '../../assets/icons/VisionFund.svg'
import iciCapital from '../../assets/icons/ICICapital.svg'
import metaLand from '../../assets/images/metaLand.svg'
import USDT from '../../assets/icons/USDT.svg'
import governance from '../../assets/icons/governance.svg'


const useStyles = makeStyles(
    (theme) => ({
        table:{
            background:'#FCFAFF',
            borderRadius: '0px 0px 18px 18px',
            border:'1px solid #e0e0e0'
        },
        tableTitle:{
            padding: '15px',
            fontWeight: 600,
            fontSize: '13px',
            border: '1px solid #e0e0e0',
            background: 'white',
            borderRadius: '18px 18px 0px 0px',
        },
        total:{
            color: 'white',
            padding: '16px',
            background: '#8765F2',
            borderRadius: '0 0 18px 18px',
            fontSize: '18px',
            fontWeight: 600,
        },
        rowText:{
          fontSize: '11px',
          fontWeight: 500,
          lineHeight:'23.87px',
          color: '#4C4C66'
        },
        cellText:{
          fontFamily:'Poppins',
          fontWeight: 600,
          fontSize: '13px',
          color:'#2F2F4D',
          padding:'10px'
        },
        icon:{
          display: 'inline-flex',
          verticalAlign: 'middle',
          marginRight: '10px'
        },
        redText:{
          fontFamily:'Poppins',
          fontWeight: 600,
          fontSize: '13px',
          color:'#CD4B6C',
          padding:'10px'
        },
        greenText:{
          fontFamily:'Poppins',
          fontWeight: 600,
          fontSize: '13px',
          color:'#5DD293',
          padding:'10px'
        }
    }))

function createData(key,icon, asset, balance, value, inPool, change24h, changeTotal, governance) {
  return {key, icon, asset, balance, value, inPool, change24h, changeTotal, governance};
}

const rows = [
  createData("0",polkaLokr,'PolkaLokr $POLKR', '10 875.31', '65 833.12', '3.46%', '+5.54%', '+17.51%', governance),
  createData("1",visionFund, 'Vision Fund $VISN', '135.08', '12 2525.15', '61.21%', '+3.22%', '+12.12%', governance),
  createData("2",iciCapital, 'ICI Capital $ICICA', '145.08', '12 2525.15', '61.21%', '+3.22%', '+12.12%', governance),
  createData("3",metaLand, 'Metaland $MLAND', '145.08', '12 2525.15', '61.21%', '+3.22%', '+12.12%', governance),
  createData("4",dlTechnologies, 'DLTechnologies $DLTT', '156.08', '12 2525.15', '61.21%', '+3.22%', '+12.12%', governance),
  createData("5",USDT,'Tether $USDT', '157.08', '12 2525.15', '61.21%', '+3.22%', '+12.12%', governance),
];

export default function PortfolioTable() {
    const classes = useStyles();
  return (
    <TableContainer>
        <div className={classes.tableTitle}>Portfolio</div>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell className={classes.rowText}>Asset</TableCell>
            <TableCell className={classes.rowText}>Balance</TableCell>
            <TableCell className={classes.rowText}>$&nbsp;Value</TableCell>
            <TableCell className={classes.rowText}>%&nbsp;in pool</TableCell>
            <TableCell className={classes.rowText}>Change&nbsp;24h</TableCell>
            <TableCell className={classes.rowText}>Change&nbsp;Total</TableCell>
            <TableCell className={classes.rowText}>Governance</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.key} >
              <TableCell className={classes.cellText}>
                <img src={row.icon} className={classes.icon} alt="icon"/>{row.asset}
              </TableCell>
              <TableCell className={classes.cellText}>{row.balance}</TableCell>
              <TableCell className={classes.cellText}>{row.value}</TableCell>
              <TableCell className={classes.redText}>{row.inPool}</TableCell>
              <TableCell className={classes.greenText}>{row.change24h}</TableCell>
              <TableCell className={classes.greenText}>{row.changeTotal}</TableCell>
              <TableCell className={classes.cellText}><img src={row.governance} alt="icon"/></TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <div className={classes.total}>Total: $1 789 782</div>
    </TableContainer>
  );
}