export const SLUG = ':id'
export const ID = ':id'
export const VID = ':id'

export const routes = {
  MAIN: '/AllVaults',
  POOLS: '/vault',
  ALL_POOLS: 'projects',
  POOL: `/vault/${SLUG}`,
  CLOSED_POOLS: '/dao/projects',
  ACCOUNT: '/account',
  FUNDS: `/funds/${ID}`,
  CREATE_DAO: '/create_vault/0x00000',
  RULES: `/vaultrules/${ID}`,
  ALL_FUNDS: 'funds',
  CREATE_VOTING: `/voting/create-proposal/${ID}`,
  VOTING: `/voting/${ID}/${VID}`,
  CABINET: '/cabinet',
  PROPOSALS: `/proposals/${ID}`,
  WALLETCONNECT: `/create_transaction/${ID}`,
}
