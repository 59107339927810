import moment from 'moment'
import { useCountdown } from '../hooks/useCountdown'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  flex: {
    display: 'flex',
    marginTop: 5,
  },
  live: {
    color: '#8765F2',
  },
  pulse: {
    background: 'rgb(147, 116, 243)',
    borderRadius: '50%',
    marginTop: 10,
    marginRight: 5,
    height: 11,
    width: 11,
    boxShadow: '0 0 0 0 rgba(0, 0, 0, 1)',
    transform: 'scale(1)',
    animation: '$pulse 1.5s infinite',
  },
  '@keyframes pulse': {
    '0%': {
      transform: 'scale(0.95)',
      boxShadow: '0 0 0 0 rgb(147, 116, 243)',
    },
    '75%': {
      transform: 'scale(1)',
      boxShadow: '0 0 0 7px rgba(0, 0, 0, 0)',
    },
    '100%': {
      transform: 'scale(0.95)',
      boxShadow: '0 0 0 0 rgba(0, 0, 0, 0)',
    },
  },
})

const Executed = () => {
  return <span>Finished</span>
}

const Live = () => {
  const classes = useStyles()

  return (
    <div className={classes.flex}>
      <div className={classes.pulse}></div>
      <span className={classes.live}>Live now</span>
    </div>
  )
}

const ShowCounter = ({ days, hours, minutes, seconds }) => {
  return (
    <div className="show-counter">
      {days}d {hours}h {minutes}m {seconds}s
    </div>
  )
}

const CountdownTimer = ({ startDate, endDate }) => {
  const now = moment().format()
  const [days, hours, minutes, seconds] = useCountdown(startDate)

  if (now > startDate && now < endDate) {
    return <Live />
  }
  if (days + hours + minutes + seconds <= 0) {
    return <Executed />
  } else {
    return (
      <ShowCounter
        days={days}
        hours={hours}
        minutes={minutes}
        seconds={seconds}
      />
    )
  }
}

export default CountdownTimer
