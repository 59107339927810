export const managersProposals = [
  { value: 'Appoint new fund manager' }, // missing from SC
  // { value: 'Dismiss current fund manager' }, // missing from SC and need geter for geting fund manager
  { value: 'Add allowed DEXes/Protocols' },
  { value: 'Delete allowed DEXes/Protocols' },
  // { value: 'Create staking / farming investment' }, // missing SC
  { value: 'Change deposit limits' },
  // { value: 'Change spending limits' }, //commentent for this version
  { value: 'Add tokens available for depositing' }, // need list of supported tokens from smart contract
  { value: 'Remove tokens available for depositing' },
  { value: 'Add tokens available for trading' },
  { value: 'Remove tokens available for trading' },
  { value: 'Add allowlisted wallets to deposit' }, // needs design to be fixed
  { value: 'Remove allowlisted wallets to deposit' },
  { value: 'Change lock-up period' },
  { value: 'Change withdrawal windows' },
  { value: 'Change rage quit fine' },
  { value: 'Change entrance fee' },
  { value: 'Change fund management fee' },
  { value: 'Change performance fee' },
  { value: 'Change voting parameters' },
  { value: 'Start new fundraising phase' },
  { value: 'Upgrade fund contract' },
  // { value: 'Change voting power type'} //missing
]

export const membersProposals = [
  { value: 'Appoint new fund manager' },
  // { value: 'Change spending limits' }, //commentent for this version
  { value: 'Delete allowed DEXes/Protocols' },
  { value: 'Remove tokens available for trading' },
]

export const canChangeManagerManagersProposals = [
  // { value: 'Dismiss current fund manager' }, // missing from SC and need geter for geting fund manager
  { value: 'Add allowed DEXes/Protocols' },
  { value: 'Delete allowed DEXes/Protocols' },
  // { value: 'Create staking / farming investment' }, // missing SC
  { value: 'Change deposit limits' },
  // { value: 'Change spending limits' }, //commentent for this version
  { value: 'Add tokens available for depositing' }, // need list of supported tokens from smart contract
  { value: 'Remove tokens available for depositing' },
  { value: 'Add tokens available for trading' },
  { value: 'Remove tokens available for trading' },
  { value: 'Add allowlisted wallets to deposit' }, // needs design to be fixed
  { value: 'Remove allowlisted wallets to deposit' },
  { value: 'Change lock-up period' },
  { value: 'Change withdrawal windows' },
  { value: 'Change rage quit fine' },
  { value: 'Change entrance fee' },
  { value: 'Change fund management fee' },
  { value: 'Change performance fee' },
  { value: 'Change voting parameters' },
  { value: 'Start new fundraising phase' },
  { value: 'Upgrade fund contract' },
  // { value: 'Change voting power type'} //missing
]

export const canChangeManagerMembersProposals = [
  // { value: 'Change spending limits' }, //commentent for this version
  { value: 'Delete allowed DEXes/Protocols' },
]
