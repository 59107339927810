import React from 'react'
import { Button as MuiButton } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'

const useStyle = makeStyles((theme) => ({
  primary: {
    color: theme.palette.white.main,
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: 'rgba(0,0,0,0.3)'
    },
    '&:disabled':{
      background: '#E2DDED',
      color: '#6F5E9A',
    }
  },
  primaryOutlined:{
    color: theme.palette.primary.main,
    borderRadius: '86px',
    border: '1px solid',
    borderColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: 'rgba(0,0,0,0.3)'
    }
  },
  secondary: {
    color: theme.palette.white.main,
    backgroundColor: theme.palette.secondary.main,
    '&:hover': {
      backgroundColor: 'rgba(0,0,0,0.3)'
    },
  },
  secondaryOutlined:{
    color: theme.palette.secondary.main,
    borderRadius: '86px',
    border: '1px solid',
    borderColor: theme.palette.secondary.main,
    '&:hover': {
      backgroundColor: 'rgba(0,0,0,0.3)'
    }
  },
  copyAddress:{
    background: '#EBE9F0',
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: 12,
    color: '#958BAE',
    width: '45px',
    borderRadius: '100px',
    marginBottom: '2px'
  },
  third: {
    backgroundColor: theme.palette.third.main,
    color: '#6F5E9A',
    '&:hover': {
      backgroundColor: 'rgba(255,255,255,0.9)',
    },
    '&:disabled':{
      background: '#E2DDED',
      color: '#6F5E9A',
    }
  },
  disabled: {
    background: theme.palette.disabled.main,
  },
  CSVBtn:{
    background: 'rgba(232, 99, 244, 0.07)',
    border: '1px solid #BC65F2',
    borderRadius: '9px',
    color: '#BC65F2',
  },
}))

export const Button = ({ type, className, children, disabledColor, ...rest }: any) => {
  const classes = useStyle()

  const buttonClass = classes[type] || classes.primary

  // if (type === 'link') {
  //   return (
  //     <span
  //       className={className ? `${buttonClass} ${className}` : buttonClass}
  //       {...rest}
  //     >
  //       {children}
  //     </span>
  //   )
  // }

  return (
    <MuiButton
      className={clsx([buttonClass, className])}
      {...rest}
    >
      {children}
    </MuiButton>
  )
}
