/* eslint-disable @typescript-eslint/no-unused-vars */
import { useMemo, useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { moneyFormatter } from '../helpers/moneyFormatter'
import { NumericFormat } from 'react-number-format'
import { routes, ID } from '../const/routes'
import { convert, toUSD } from '../helpers/currencyHelper'
import CountdownTimer from '../helpers/countdownTimer'

// Material UI
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import {
  Box,
  Container,
  Grid,
  LinearProgress,
} from '@material-ui/core'
import { Button } from './Button'

// Assets
import closeModalIcon from '../assets/icons/closeModal.svg'
import burnLPModalImg from '../assets/images/burnLPmodal.svg'
import error404 from '../assets/icons/icon-not-found.jpg'

// Components
import { ChipLabel } from './Chip'
import { ErrorMessage } from './ErrorMessage/ErrorMessage'
import { Modal } from './Modal/Modal'
import { ModalLoading } from './ModalLoading/ModalLoading'
import { ModalSuccess } from './ModalSuccess'

// Web 3 imports
import { useWeb3React } from '@web3-react/core'
import { DaoAPI } from '../api/DaoAPI'

import moment from 'moment'
import { TransactionHash, ViewOnExplorer } from './ExplorerLinks/ExplorerLinks'
import { useAspisSupportedTokens } from '../hooks/useChain'
import BigNumber from 'bignumber.js'
import { useSelector } from 'react-redux'
import { ETH_TOKEN_ADDRESS, MAX_UINT } from '../const/ethConst'
import { getNetworkConfig } from '../const/networks'

const useStyles = makeStyles((theme) => (
  {
    content: {
      margin: '100px 0 100px 0',
      display: 'flex',
      justifyContent: 'space-between',
      '@media screen and (max-width: 644px)': {
        margin: '50px 0 50px 0',
      },
      '@media screen and (max-width: 320px)': {
        margin: '36px 0 50px 0',
      },
    },
    title: {
      display: 'inline-grid',
      fontSize: 34,
      fontWeight: 'bold',
      marginLeft: '25px',
      [theme.breakpoints.down('xs')]:{
        fontSize: 24,
        marginLeft: '14px',
      },
    },
    daoFundSingePage: {
      paddingBottom: '30px',
      display: 'flex',
    },
    primaryCard: {
      backgroundColor: '#FFFFFF',
      boxShadow: '0px 51px 89px -49px rgba(196, 193, 232, 0.4)',
      borderRadius: 18,
    },
    textSm: {
      fontSize: 12,
      fontWeight: 'bold',
      padding: '6px 0px 0px 0px',
      display: 'inline-flex',
      justifyContent: 'flex-start',
      width: '100%',
      [theme.breakpoints.down('xs')]:{
        padding:'6px 0px 6px 0px'
      }
    },
    FundSectionBackground: {
      backgroundColor: '#F5F7FE',
      paddingBottom: '20px',
    },
    link: {
      // margin: '36px 0 0 0',
      display: 'flex',
      flexBasis: '140px',
      justifyContent: 'space-between',
      alignContent: 'center',
      alignItems: 'center',
      justify: 'center',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    '@media screen and (max-width: 425px)': {
      link: {
        margin: '34px 0 0 0',
      },
    },
    footerContent: {
      justifyContent: 'center',
    },
    '@media screen and (max-width: 320px)': {
      footerContent: {
        padding: '0 0 44px 0',
      },
    },
    '@media screen and (max-width: 644px)': {
      footerContent: {
        padding: '0 0 44px 0',
      },
    },
    textSmDisplay: {
      display: 'inline-grid',
      padding: 0,
      fontFamily: 'Poppins',
    },
    spanText: {
      fontSize: 24,
      fontWeight: 'bold',
      fontFamily: 'Poppins',
    },
    fontSize10: {
      fontSize: 10,
      fontFamily: 'Poppins',
    },
    descriptionTitle: {
      fontSize: 14,
    },
    progressBar: {
      height: '7px',
      top:'7px',
      margin: '0 12px',
      background: '#F5EEFD',
      borderRadius: '53px',
    },
    progressContainer: {
      marginTop: 10,
      marginBottom: 20,
      background: '#FDFBFF',
      border: '1px solid rgba(214, 210, 242, 0.5)',
      borderRadius: 5,
    },
    progressText: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: 15,
    },
    progressPercentage: {
      color: '#8765F2',
      fontWeight: 800,
      fontSize: 12,
      fontFamily: 'Poppins',
    },
    progressTitle: {
      fontSize: 10,
      marginTop: 10,
      marginLeft: 15,
    },
    chipInside: {
      textDecoration:'none',
      margin: 3,
      fontWeight: 'bold',
      cursor: 'pointer',
    },
    padding0: {
      paddingLeft: 0,
      paddingRight: 0,
    },
    apyContent:{
      display: 'flex',
      flexWrap: 'wrap',
    },
    apy24h: {
      color: '#4BCD87',
      fontWeight: 'bold',
      fontSize: 24,
    },
    total:{
      color: '#B6002C',
      fontWeight:'bold',
      fontSize: 24,
      marginLeft:'8px'
    },
    startsIn: {
      fontSize: 24,
      fontWeight: 'bold',
      fontFamily: 'Poppins',
    },
    buttonsDisplay: {
      justifyContent: 'space-between',
      display: 'flex',
      paddingLeft: 0,
      paddingRight: 0,
    },
  
    //BurnLp Modal Design
    modalBodyClass: {
      border: '1px solid rgba(214, 210, 242, 0.5)',
      boxShadow: '0px 51px 104px -80px rgba(196, 193, 232, 0.4)',
      borderRadius: '18px',
      padding: 0,
      maxWidth: '350px',
      marginTop: 'auto',
      marginBottom: 'auto',
    },
    modalTitle: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      borderRadius: '18px 18px 0px 0px',
      background: '#FCFAFF',
      padding: '13px 12px 13px',
      borderBottom: '1px solid #dddfeb',
      alignItems:'flex-end',
      fontSize: '13px',
      color: '#4C4C66',
      fontWeight: 600
    },
    tokenBalance: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      borderRadius: '18px 18px 0px 0px',
      background: '#FCFAFF',
      padding: '16px 12px 0px',
    },
    balance: {
      display: 'inline-block',
      padding: '6px 8px 6px 8px',
      color: theme.palette.white.main,
      background: theme.palette.twentyFirst.main,
      borderRadius: 16,
      fontWeight: 600,
      fontSize: 12,
      lineHeight: '12px',
      margin: '0 0 6px 9px',
    },
    selectInputModal: {
      padding: 10,
      '& .MuiInput-root': {
        display: 'none',
      },
    },
    assetsBody: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: '0px 10px',
      listStyleType: 'none',
      borderRadius: '9px',
      border: '1px solid #dddfeb',
      alignItems: 'center',
      margin: '8px 0',
    },
    firstChild: {
      marginRight: '10px',
      marginTop: '5px',
    },
    secondChild: {
      width: '100%',
      textAlign: 'left',
    },
    thirdChild: {
      width: '100%',
      textAlign: 'right',
      color: '#B8B7D5',
    },
    paddingContent: {
      width: '100%',
      padding: '10px',
    },
    flexTitle: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    assetsTitle: {
      fontSize: '10px',
      color: '#4C4C66',
    },
    divider: {
      border: '1px solid #D6D2F280',
    },
    number: {
      fontSize: '11px',
      color: '#8765F2',
      fontWeight: 700,
    },
    text: {
      fontWeight: 300,
      fontSize: '11px',
      color: '#4C4C66',
      lineHeight: '20.52px',
    },
    helperText: {
      opacity: '0.9',
      fontWeight: 300,
      fontSize: '11px',
      color: '#4C4C66',
    },
    modalCenterMargin: {},
    modalSuccessBody: {
      padding: 0,
      maxWidth: '400px',
      borderRadius: '20px',
    },
    successImg: {
      width: '100%',
    },
    successTitle: {
      textAlign: 'center',
      fontSize: '21px',
      fontWeight: 500,
      color: '#8765F2',
    },
    successText: {
      textAlign: 'center',
      fontSize: '11px',
      lineHeight: '20.52px',
      color: '#4C4C66',
      margin: '20px 5px',
    },
    modalSuccessContent: {
      margin: '15px 20px',
    },
    errorBox: {
      padding: '16px',
    },
    modalLoadingBody: {
      textAling: 'cetner',
    },
    btnDisabled:{
        background: '#E2DDED',
        color: '#6F5E9A',
    },
    inputBurnLp: {
      height: '25px',
      padding: '6px 11px 6px 11px',
      border: `1px solid ${theme.palette.twentySecond.main}`,
      borderRadius: 8,
      backgroundColor: theme.palette.common.white,
      justifyContent: 'space-between',
      margin: '18px 10px',
      color:'#44435E',
      fontFamily:'Poppins',
      fontWeight: 500,
      fontSize: '21px'
    },
    space:{
      width:'10px'
    },
    buttonContent:{
      margin: '10px',
      width:'100%'
    },
    currenciesDisplay:{
      display:'flex',
      marginTop: '10px',
      maxWidth: '210px',
      overflow: 'scroll'
    },
    icon:{
      width:'25px',
      marginRight:'5px'
    },
    inputWithButton :
    {
      position: 'relative',
      width: '87%',
     '& input':{
        width: '100%',
        height: '30px',
    },
    '& button' :{
      background: 'rgba(209, 209, 232, 0.6)',
      borderRadius: '25px',
      top: '24px',
      right: '-25px',
      width: '16%',
      height: '40%',
      position: 'absolute'
    }
  },
  erroBox:{
    border: '1px solid #ebecf2',
    borderRadius: '8px',
    padding: '5px'
  }
  }
))

export const SingleSectionFunds = (props: any) => {
  const classes = useStyles(props)
  const location = useLocation()
  const navigate = useNavigate()

  // WEB 3
  const { account, connector, isActive, chainId } = useWeb3React()
  
  let myDeposits: any[] = []

  const {
    daoId,
    dao,
    lpTokenBalance,
    supportedTokens
  } = props

  const {
    fund,
    logo,
    rates
  } = dao
   
  const [daoConfiguration, setDaoConfig] = useState<any>({})
  const [fees, setFees] = useState<any>({})

  const [lastDeposit, setLastDeposit] = useState<any>()
  const [txnHash, setTxnHash] = useState<any>()

  const [burnAmount, setBurnAmount] = useState(0)
  const [percentage, setPercentage] = useState<any>(0)
  const [totalAssetsValue, setTotalAssetsValue] = useState(0)
  const [totalLpTokenSupply, setLpSupply] = useState<any>(0)

  const [burned, setBurned] = useState(false)
  const [claiming, setClaiming] = useState(false)
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(false)
  const [succesModalOpen, setSuccessModalOpen] = useState(false)
  const [successClaim, setSuccessClaim] = useState(false)

  // Error handling
  const [insufficientBalance, setInsufficientBalance] = useState(false)
  const [lockupError, setLockupError] = useState(false)
  const [whitelistedUsers, setWhitelistedUsers] = useState<any>([])

  const deposits = useSelector((state: any) => {
    return state?.daos?.dao?.data?.subgraph?.deposits
  })

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const calculateTotalDeposits = (deposits, rates) => {
    let totalDeposits = new BigNumber(0) 
    deposits.map(deposit => {
      const decimals = deposit?.token?.id === ETH_TOKEN_ADDRESS ? '18' : deposit?.token?.decimals;
      const symbol = deposit?.token?.id === ETH_TOKEN_ADDRESS ? getNetworkConfig(dao.chainId).params.nativeCurrency.symbol : deposit?.token?.symbol;
      return totalDeposits = totalDeposits.plus(toUSD(new BigNumber(deposit.amount).dividedBy('1e'+decimals).toNumber(), rates[symbol.toUpperCase()]))
    });
    return totalDeposits.toNumber()
  }

  const calculateTotalDepositsPercentage = (totalAssetsValue, maxCap) => {
    if(totalAssetsValue === undefined || maxCap === undefined){
      return 0
    }else{
      return new BigNumber(totalAssetsValue).multipliedBy(100).dividedBy(maxCap).toNumber()
    }
  }

  useEffect(() => {
    if (!dao) return

    const network = getNetworkConfig(dao.chainId)
    const library = { provider: network.params.rpcUrls[0] }

    const fetchSubgraphData = async () => {
      // Dao Config
      setDaoConfig(await DaoAPI.getConfiguration(daoId, library))
      // White List
      setWhitelistedUsers(await DaoAPI.getWhitelistedUsers(daoId, library))
    }

    // LP Supply
    setLpSupply(convert(props?.supply, props?.dao?.token?.decimals))

    fetchSubgraphData().catch(console.error)
    toggleMaxBurnAmount()
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props, dao, daoId]);

  useEffect(() => {
    return () => {
      if(deposits !== undefined) {
      setPercentage(calculateTotalDepositsPercentage(totalAssetsValue, daoConfiguration?.maxCap))
      setTotalAssetsValue(calculateTotalDeposits(deposits, rates))
    }
    }
  }, [deposits, daoConfiguration, totalAssetsValue, props, calculateTotalDeposits, rates])
  
  const handleOpen = async () => {
    if(lpTokenBalance === 0) {
      setInsufficientBalance(true)
    }
    const fees = await DaoAPI.getDaoFees(daoId, connector)
    setFees(fees)
    setLastDeposit(getLastDeposit())
    setOpen(true)
  } 
  const handleClose = () => setOpen(false)

  const handleSuccessClose = () => setSuccessModalOpen(false)

  const handleSuccessCloseComission = () => setSuccessClaim(false)

  const tokens = useAspisSupportedTokens(supportedTokens, dao.chainId)
  const acceptedCurrencies = supportedTokens?.length ? tokens : []

  const handleBurnAmount = (e) => {
    let value = parseFloat(e.target.value)
    
    if (value > lpTokenBalance || (value === 0 || isNaN(value))) {
      setInsufficientBalance(true)
      return
    }
    if (daoConfiguration?.lockLimit > moment().diff(moment.unix(parseInt(lastDeposit?.createdAt)), 'hours')) {
      setLoading(false)
      setLockupError(true)
      setInsufficientBalance(false)
      return
    }
    setBurnAmount(value)
    setLockupError(false)
    setInsufficientBalance(false)
  }

  const accountErrorMessage = useMemo(() => {
    if (!account) return 'Wallet is locked, please unlock first'
  }, [account])

  const burnLpToken = async () => {
    if (!account || chainId !== Number(dao?.chainId)) {
      connector.activate(parseInt(dao?.chainId))
      return
    }
    
    setLoading(true)
    try {
      if (daoConfiguration?.lockLimit > moment().diff(moment.unix(parseInt(lastDeposit?.createdAt)), 'hours')) {
        setLoading(false)
        setLockupError(true)
        setInsufficientBalance(false)
        return
      }

      const burn = await DaoAPI.burn(daoId, account, connector)
        if (burn) {
          setTxnHash(burn.transactionHash)
          setBurned(true)
          setBurnAmount(0)
          setLoading(false)
          setSuccessModalOpen(true)
          setOpen(false)
          }
    } catch (error) {
      setLoading(false)
      setOpen(false)
    }
  }

  const joinDao = () => {
    console.log('chain | dao.chain', chainId, Number(dao?.chainId))

    if (!account || chainId !== Number(dao?.chainId)) {
      connector.activate(parseInt(dao?.chainId))
      return
    }

    navigate(routes.RULES.replace(ID, daoId) + '?chainId=' + dao.chainId, { state: singleFundparams })
  }

  const getLastDeposit = () => {
    if (!account || chainId !== Number(dao?.chainId)) {
      connector.activate(parseInt(dao?.chainId))
      return
    }

    for (const deposit of deposits) {
      if(deposit.sender.toLowerCase() === account.toLowerCase()) {
        myDeposits.push(deposit)
      }
    }
    myDeposits.sort(function(x, y) {
      return y.createdAt - x.createdAt
    })
    return myDeposits[0]
  }

  const toggleMaxBurnAmount = () => {
      if (lpTokenBalance > 0) {
        setBurnAmount(lpTokenBalance)
        setInsufficientBalance(false)
        return
      }
  }

  const singleFundparams = {
    supply: props?.supply, 
    token: props?.dao?.token,
    chainId: dao?.chainId,
    acceptedCurrencies: acceptedCurrencies,
    totalBalance: totalAssetsValue
  }

  return (
    <Card className={classes.primaryCard} variant="outlined">
      <CardContent>
        <Box display="flex" className={classes.textSm}>
          <Container className={classes.textSm}>
          <img src={logo} alt='daologo' width={75}/>
            <Typography variant="h4" component="h2" className={classes.title}>
              {props?.dao?.name.length > 15
                ? `${props.dao?.name.substring(0, 15)}...`
                : props.dao?.name}
               {whitelistedUsers && whitelistedUsers.length === 0 ?  
              <ChipLabel
                size="medium"
                type="public"
                label="Public"
              />
               :
              <ChipLabel
              size="medium"
              type="private"
              label="Private"
            /> 
            }
            </Typography>
          </Container>
        </Box>

        <ViewOnExplorer chainId={dao.chainId} daoId={daoId}/>

        <Box display="flex" className={classes.textSm}>
          <Container className={classes.textSmDisplay}>
            <Typography className={classes.fontSize10} color="textSecondary">
              {' '}
              Funding Target
            </Typography>
            <span className={classes.spanText}>$ {moneyFormatter(daoConfiguration?.maxCap)}</span>
          </Container>
          <Container className={classes.textSmDisplay}>
            <Typography color="textSecondary" className={classes.fontSize10}>
              Deposit Range{' '}
            </Typography>
            {
              daoConfiguration?.minDeposit === '0' && daoConfiguration?.maxDeposit === MAX_UINT ?
              <span className={classes.spanText}>Any</span>
                :
                <span className={classes.spanText}>
                ${moneyFormatter(daoConfiguration?.minDeposit)} - ${moneyFormatter(daoConfiguration?.maxDeposit)}
              </span>
            }
          </Container>
        </Box>
        <Box display="flex" className={classes.textSm}>
          <Container className={classes.padding0}>
            <Typography className={classes.fontSize10} color="textSecondary">
              {' '}
              Accepted Currencies
            </Typography>
            <div className={classes.currenciesDisplay}>
              {
                acceptedCurrencies?.map((item, key) => (
                  <div key={item?.symbol}>
                    <img src={`https://cdn.jsdelivr.net/gh/atomiclabs/cryptocurrency-icons@1a63530be6e374711a8554f31b17e4cb92c25fa5/svg/color/${item?.symbol}.svg`.toLowerCase()} 
                      className={classes.icon} alt="" title={item?.symbol}
                      onError={({ currentTarget }) => { currentTarget.onerror = null; currentTarget.src=error404 }}  />
                  </div>
                ))
              }
              </div>
          </Container>
          <Container className={classes.textSmDisplay}>
            <Typography color="textSecondary" className={classes.fontSize10}>
              Change 24h (Total)
            </Typography>
            <div className={classes.apyContent}>
              { fund?.APies?.length > 0 && fund?.APies[fund?.APies.length - 1].twentyFourHours > 0 ? 
              <Typography className={classes.apy24h}>{`+${fund?.APies?.length > 0 ? Number(fund?.APies[fund?.APies.length - 1].twentyFourHours).toFixed(2) : <span className={classes.apy24h}>'0'</span>}%`} </Typography> 
              :
              <Typography className={classes.total}>{`${fund?.APies?.length > 0 ? Number(fund?.APies[fund?.APies.length - 1].twentyFourHours).toFixed(2) : '0'}%`} </Typography>
              }

              { fund?.APies?.length > 0 && fund?.APies[fund?.APies.length - 1].total > 0 ? 
            <Typography className={classes.apy24h}>{`(+${fund?.APies?.length > 0 ? Number(fund?.APies[fund?.APies.length - 1].total).toFixed(2) : '0'}%) `}</Typography>
            :
            <Typography className={classes.total}>{`(${fund?.APies.length > 0 ? Number(fund?.APies[fund?.APies.length - 1].total).toFixed(2) : '0'}%)`}</Typography>
            }
            </div>
          </Container>
        </Box>
        <hr className={classes.divider} />
        <Box display="flex" className={classes.textSm}>
          <Container className={classes.padding0}>
            <Typography className={classes.fontSize10} color="textSecondary">
              {' '}
              Starts in
            </Typography>
              <span className={classes.startsIn}>
                {
                  daoConfiguration?.startTime ? 
                  <CountdownTimer 
                    startDate={moment.unix(daoConfiguration?.startTime).format()} 
                    endDate={moment.unix(daoConfiguration?.finishTime).format()} /> 
                  :
                  null
                }
              </span>
          </Container>
        </Box>
        <div className={classes.progressContainer}>
          <Typography className={classes.progressTitle} color="textSecondary">
            Progress
          </Typography>
          <Box>
            {
              percentage > 100 ? (
                <LinearProgress
                  value={100}
                  className={classes.progressBar}
                  variant="determinate"
                />) 
                : (
                <LinearProgress
                  value={percentage}
                  className={classes.progressBar}
                  variant="determinate"
                />)
            }
          </Box>
          <div className={classes.progressText}>
            <div>
              <Typography className={classes.progressPercentage}>
                {percentage > 100 ? '100%' : `${percentage.toFixed(2)}%`}
              </Typography>
            </div>
            <div>
              <Typography style={{ fontFamily: 'Poppins', fontSize: '12px' }}>
                <b>${moneyFormatter(totalAssetsValue.toFixed(2))}</b>
                {' '} from ${moneyFormatter(daoConfiguration?.maxCap)}
              </Typography>
            </div>
          </div>
        </div>
        <Box>
          <Container className={classes.buttonsDisplay}>
            <ModalLoading
              open={claiming}
              onCancel={undefined}
              modalImg={burnLPModalImg}
              title={'Claiming fees...'}
              subTitle={'Please wait for the fees to be claimed'} />
              <ModalSuccess
              open={successClaim}
              onCancel={handleSuccessCloseComission}
              title={'Comission claimed successfully'}
              subTitle={<TransactionHash chainId={dao.chainId} txnHash={txnHash}/>}
              modalImg={burnLPModalImg}
            />
            <div className={classes.space}/>

            {/* Burn LP tokens */}
            <Button type='third' onClick={handleOpen}>
              Burn LP
            </Button>
            <Modal
              open={open}
              onClose={handleClose}
              className={undefined}
              modalBodyClass={classes.modalBodyClass}
              marginTop={classes.modalCenterMargin}>
              <div className={classes.modalTitle}>
                Burn LP
                <img src={closeModalIcon} alt="closeModal" onClick={handleClose}/>
              </div>
              <div className={classes.tokenBalance}>
                <span className={classes.text}>Number of LP</span>
                <span className={classes.balance}>{lpTokenBalance} {props?.dao?.token?.symbol}</span>
              </div>
              {/* <div className={classes.paddingContent}> */}
              <div className={classes.inputWithButton}>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                   <NumericFormat 
                    className={classes.inputBurnLp}
                    valueIsNumericString={true}
                    allowNegative={false} 
                    value={burnAmount}
                    disabled
                    onChange={handleBurnAmount}
                    placeholder="Enter Amount" 
                 />
                </Grid>
                <Grid>
                <Button
                    type="fourth"
                    disabled={lpTokenBalance <= 0}
                    onClick={toggleMaxBurnAmount}
                  >
                    Max
                  </Button>
                  </Grid>
              </div>
              
              <div className={classes.paddingContent}>
                <p className={classes.assetsTitle}>
                  You'll get:
                </p>
                <hr className={classes.divider} />

                <div className={classes.flexTitle}>
                  <p className={classes.assetsTitle}>Asset</p>
                  <p className={classes.assetsTitle}>Value</p>
                </div>
                {
                  props?.assets?.map((asset: any) => (
                    <ul className={classes.assetsBody} key={asset.symbol}>
                      <li className={classes.firstChild}>
                        <img alt='' 
                        onError={({ currentTarget }) => {
                        currentTarget.onerror = null;
                        currentTarget.src=error404 }} 
                        width={27} src={`https://cdn.jsdelivr.net/gh/atomiclabs/cryptocurrency-icons@1a63530be6e374711a8554f31b17e4cb92c25fa5/svg/color/${asset?.symbol}.svg`.toLowerCase()}/>
                      </li>
                      <li className={classes.secondChild}>
                        {asset?.symbol}
                      </li>
                      <li className={classes.thirdChild}>
                        {((burnAmount * asset?.balance) / totalLpTokenSupply).toFixed(2)}
                      </li>
                    </ul>
                  ))
                }
                {
                  props?.assets?.length <= 0 && lpTokenBalance > 0 &&
                  <Box className={classes.errorBox}>
                  <ErrorMessage message="Something went wrong, please refresh the page"  />
                 </Box>
                }
                <p className={classes.text}>
                  Included: <br />
                  <span className={classes.number}>{(fees?.fundManagementFee / 100) + '%'}</span> Fund-management fee{' '}
                  <span className={classes.helperText}>(% of all assets)</span>
                  <br />
                  <span className={classes.number}>{(fees?.performanceFee / 100) + '%'}</span> Performance fee{' '}
                  <span className={classes.helperText}>
                    (% of total profit)
                  </span>
                  {
                    moment().format() > moment.unix(daoConfiguration?.startTime).format() &&
                    <>
                      <br />
                      <span className={classes.number}>{(fees?.rageQuitFee / 100) + '%'}</span> Rage quit fee{' '}
                      <span className={classes.helperText}>
                        (% of withdrawal)
                      </span>
                    </>
                  }
                </p>
              </div>
              <div className={classes.buttonContent}>
              <Button
                type="secondary"
                onClick={burnLpToken}
                disabled={!account || burnAmount === 0 || loading || insufficientBalance || lockupError}
                classes={{ disabled: classes.btnDisabled }}
              >
                Burn LP
              </Button>
              {
                insufficientBalance && (
                  <ErrorMessage message={'Insufficient LP tokens'}/>
                )
              }
              {
                lockupError && (
                  <ErrorMessage message={'Lock-up period has not finished!'}/>
                )
              }
              </div>
              {
                accountErrorMessage && (
                  <Box my={1} className={classes.errorBox}>
                    <ErrorMessage message={accountErrorMessage} />
                  </Box>
                )
              }
            </Modal>
            <ModalSuccess
              open={succesModalOpen}
              onCancel={handleSuccessClose}
              title={'Tokens burned sucessfully'}
              subTitle={<TransactionHash chainId={dao.chainId} txnHash={txnHash} />}
              modalImg={burnLPModalImg}
            />
            <div className={classes.space}/>

            {/* Dao Rules */}
            <Button
              type='primary' 
              onClick={joinDao}>
              Join
            </Button>
            <ModalLoading
              open={loading}
              onCancel={undefined}
              modalImg={burnLPModalImg}
              title={'Burning in progress'}
              subTitle={'Please be patient until burning is completed'} />
          </Container>
        </Box>
      </CardContent>
    </Card>
  )
}
