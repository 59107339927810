/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { getAllDaos, getSingleDao } from '../redux/actions/dao.actions'
import { defaultChainId } from '../const/networks'

export const useDaos = (page, limit, chainId, library) => {
  const dispatch = useDispatch()

  const daos = useSelector((state) => state.daos.daos)
  const loading = useSelector((state) => state.daos.loading)

  useEffect(() => {
    if (chainId) {
      dispatch(getAllDaos(page, limit, chainId, library))
    } else {
      dispatch(getAllDaos(page, limit, defaultChainId, library))
    }
  }, [chainId])

  return { daos, loading }
}

export const useSingleDao = (id, chainId) => {
  const dispatch = useDispatch()
  const dao = useSelector((state) => {
    return state.daos.dao
  })

  useEffect(() => {
    if (chainId) {
      dispatch(getSingleDao(id, chainId))
    } else {
      dispatch(getSingleDao(id, defaultChainId))
    }
  }, [id, chainId])

  return dao
}
