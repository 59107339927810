import React, { useState } from 'react'
import { useLocation } from 'react-router'
import { makeStyles } from '@material-ui/core/styles'
import { Grid, TextField, InputAdornment } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import { Container } from '@material-ui/core'

import { routes } from '../../const/routes'
import clsx from 'clsx'
import { NavLink } from 'react-router-dom'
import { Button } from '../Button'
// import Button from '@mui/material/Button'

const useStyles = makeStyles((theme) => ({
  container: {
    paddingLeft: 16,
    paddingRight: 16,
  },
  tabs: {
    fontWeight: 600,
    fontSize: 14,
    lineHeight: '21px',
    margin: '17px 0 17px 0',
    display: 'flex',
    color: 'white',
    width: '100%',
    '& > a': {
      background: '#F3EFFF',
      border: '1px solid #E3DBFB',
      borderRadius: '7px',
      width: '100%',
      textDecoration: 'none',
      fontWeight: 500,
      color: 'black',
      marginRight: '10px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '85%',
      '&:last-child': {
        margin: 0,
      },
      '&:visited': {
        background: '#F3EFFF',
        border: '1px solid #E3DBFB',
        height: '85%',
      },
      '&.active': {
        border: 'none',
        background: '#8765F2',
        color: 'white',
        height: '50px',
      },
    },
  },
  tabsFilter: {
    height: '85%',
    width: '100%',
    background: '#F8EFFF',
    fontFamily: 'Poppins',
    fontSize: '14px',
    fontWeight: 500,
    borderRadius: '7px',
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#CDA5D0',
    },
  },
  icon: {
    width: '12px',
    height: '12px',
    paddingRight: '7px',
  },
  filter: {
    '& .MuiMenuItem-root': {
      fontFamily: 'Poppins',
      fontSize: '14px',
      fontWeight: 500,
    },
  },
  searchField: {
    width: '100%',
    background: 'white',
    borderRadius: '100px',
    padding: '0px',
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
    '& .MuiInputBase-input': {
      fontFamily: 'Poppins',
      fontSize: '13px',
      padding: '13px',
    },
    '& .MuiOutlinedInput-adornedEnd': {
      padding: '0px',
    },
  },
  display: {
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  searchIcon: {
    borderRadius: '100px',
  },
}))

export const Tabs = ({ className, title, filterValue, filterDaos }) => {
  const classes = useStyles()
  const location = useLocation()

  const [value, setValue] = useState('')

  const handleButtonClick = () => {
    filterDaos(value)
  }

  const handleInputChange = (event) => {
    setValue(event.target.value)
  }

  return (
    <Container className={clsx([classes.container, className, title])}>
      <div>{title}</div>
      <Grid container className={classes.display}>
        <Grid item lg={5} md={6} sm={12} xs={12} className={classes.tabs}>
          <NavLink
            className={location.pathname === routes.ALL_FUNDS ? 'active' : ''}
          >
            Funds
          </NavLink>
          {/* When projects will be added will use this  */}
          {/* <NavLink
              to={routes.CLOSED_POOLS}
              className={
                location.pathname === routes.CLOSED_POOLS ? 'active' : ''
              }
            >
              Projects
            </NavLink> */}
          <NavLink
            style={{
              background: '#F3EFFF',
              border: '1px solid #E3DBFB',
              color: '#8765F2',
            }}
          >
            Projects
          </NavLink>
          {/* <Select variant="outlined" className={classes.tabsFilter}>
              {
                filterValue.map((item) => {
                  return <MenuItem
                    className={classes.filter}
                    selected={true}
                    value={item.value}
                    onClick={handleChange}>
                    {item.value}
                  </MenuItem>
                })
              }
            </Select> */}
        </Grid>
        <Grid item lg={6} md={5} sm={12} xs={12}>
          <TextField
            className={classes.searchField}
            placeholder="Find fund by name"
            onChange={handleInputChange}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                handleButtonClick(e.target.value)
              }
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Button
                    type={'primary'}
                    className={classes.searchIcon}
                    onClick={(e) => handleButtonClick(e.target.value)}
                  >
                    Search
                    <SearchIcon />
                  </Button>
                </InputAdornment>
              ),
            }}
            variant="outlined"
          />
        </Grid>
      </Grid>
    </Container>
  )
}
