import { useEffect, useRef, useState } from 'react'

// Material-UI imports
import {
  Card,
  CardContent,
  Grid,
  Switch,
  TextField
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { NumericFormat } from 'react-number-format'
import { DaoAPI } from '../../../../api/DaoAPI'
import { useLocation } from 'react-router'
import { useWeb3React } from '@web3-react/core'
import { MAX_UINT } from '../../../../const/ethConst'

const useStyle = makeStyles((theme) => ({
    divider:{
        borderBottom: '1px solid rgb(224 224 224)'
      },
      cardTitle:{
        fontSize: 13,
        fontWeight: 600,
        fontStyle: 'normal',
        lineHeight: 4,
        margin: 0,
        paddingLeft: '16px'
      },
      cardContent:{
    borderRadius: '18px',
    border: '1px solid rgba(214, 210, 242, 0.5)',
    boxShadow: '0px 51px 89px -49px rgba(196, 193, 232, 0.4)',
    '& .MuiCardContent-root':{
      padding: 0
    }
  },
  helperText:{
    fontSize: '9px',
    color: '#878799'
  },
  radioContent:{
      display: 'block'
  },
  alignItems:{
    alignItems: 'flex-end'
  },
  inputContent: {
    "& .MuiInputBase-input":{
       padding:'10.5px 14px',
    background: '#FBFBFF',
    },
    "& .MuiInputBase-root":{
      borderRadius: '9px'
    },
    display: 'grid',
    fontSize: '12px',
    fontWeight: 500,
    padding: '15px 10px',
  },
  labelHelper: {
    fontWeight: 500,
    fontSize: '9px',
    color: '#958BAE',
  },
  boldText: {
    letterSpacing: '0.4px',
    fontSize: '12px',
    color: '#4C4C66',
  },
  input: {
    marginTop: '15px',
  },
  depositLimitContent:{
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'end'
  },
  labelDepositLimit:{
    color: '#4C4C66',
    fontSize: '12px'
  },
}))

export const ChangeDepositLimits = (props) => {
  const {title, cardContentClass, func, setIsProposalValid, setProposalError, setProposalMsg} = props
  
  const classes = useStyle()
  const location = useLocation();
  const { connector } = useWeb3React();

  const [checked, setChecked] = useState(false);
  const [daoConfig, setDaoConfig] = useState<any>({})
  const [isDepositLimitDisabled, setDepositLimitDisabled] = useState(false)
  const [depositLimit, setDepositLimit] = useState<any>({
    minimumDeposit: undefined,
    maximumDeposit: undefined,
    func: 'changeDepositLimits'
  })
  let inputRef = useRef({ minimumDeposit: depositLimit?.minimumDeposit, maximumDeposit: depositLimit?.maximumDeposit });

  let paths = location.pathname.split('/');
  let daoId = paths[paths.length - 1];

  const inputValidation = 
    depositLimit?.minimumDeposit !== undefined &&
    depositLimit?.maximumDeposit !== undefined &&
    !isNaN(depositLimit?.minimumDeposit) &&
    !isNaN(depositLimit?.maximumDeposit) &&
    parseInt(depositLimit?.minimumDeposit) === -1 && parseInt(depositLimit?.maximumDeposit) === -1 ?
      true :
      depositLimit?.minimumDeposit < depositLimit?.maximumDeposit
  
  const proposalValidation =
    parseInt(daoConfig.minDeposit) === depositLimit?.minimumDeposit &&
    parseInt(daoConfig.maxDeposit) === depositLimit?.maximumDeposit

  useEffect(() => {
    const decodeConfiguration = async () => {
      try {
        setDaoConfig(await DaoAPI.getConfiguration(daoId, connector))
      } catch (e) {
        console.log('Error message:', e)
      }
    }
    decodeConfiguration()
      .catch(console.error)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  
  useEffect(()=>{
    if(daoConfig.minDeposit === '0' && daoConfig.maxDeposit === MAX_UINT) {
      setChecked(true);
      setDepositLimitDisabled(!isDepositLimitDisabled);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[daoConfig])

  useEffect(()=>{
    setIsProposalValid(inputValidation)
    if(proposalValidation){
      setProposalError(true);
      setProposalMsg('You cannot create a proposal with the same deposit limit that is already present.')
    }else{
      setProposalError(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[inputValidation, depositLimit])
  
  const disableDepositLimit = () => {
    setDepositLimitDisabled(!isDepositLimitDisabled);
    if (!isDepositLimitDisabled) {
      setDepositLimit(depositLimit => ({ ...depositLimit, minimumDeposit: 0, maximumDeposit: MAX_UINT }))
      setChecked(true);
    } else {
      setDepositLimit({
        ...depositLimit,
        minimumDeposit: inputRef.current.minimumDeposit,
        maximumDeposit: inputRef.current.maximumDeposit
      })
      setChecked(false);
    }
  }
  
  useEffect(() => {
   if(func !== '') {
     func(depositLimit)
   }
  })

  return (
      <Card className={`${classes.cardContent} ${cardContentClass}`}>
        <div className={classes.divider}><p className={classes.cardTitle}>{title}</p></div>
        <CardContent> 
          <Grid container className={classes.alignItems}>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <div className={classes.inputContent}>
                <label className={classes.labelHelper}>
                  <b className={classes.boldText}>Deposit limits,</b> in $USDT equivalent
                </label>
                <NumericFormat
                  customInput={TextField}
                  variant="outlined"
                  value={`${isNaN(depositLimit.minimumDeposit) ? 0 : depositLimit.minimumDeposit}`}
                  disabled={isDepositLimitDisabled}
                  className={classes.input}
                  onChange={(e) => setDepositLimit({ ...depositLimit, minimumDeposit: parseInt(e.target.value.replace(/[%\s]/g, ''))})}
                  inputRef={(e: any) => inputRef.current.minimumDeposit = (parseInt(e?.value.replace(/[%\s]/g, '')))}
                  valueIsNumericString={true}
                  allowNegative = {false}
                />
              </div>
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <div className={classes.inputContent}>
                <div className={classes.depositLimitContent}>
                  <label className={classes.labelDepositLimit}>
                    No deposit limits
                  </label><Switch checked={checked} onClick={disableDepositLimit} />
                </div>
                <NumericFormat
                 customInput={TextField}
                  variant="outlined"
                  disabled={isDepositLimitDisabled}
                  value={`${isNaN(depositLimit.maximumDeposit) ? 0 : depositLimit.maximumDeposit === MAX_UINT ? 0 : depositLimit.maximumDeposit }`}
                  className={classes.input}
                  onChange={(e) => setDepositLimit({ ...depositLimit, maximumDeposit: parseInt(e.target.value.replace(/[%\s]/g, ''))})}
                  inputRef={(e: any) => inputRef.current.maximumDeposit = (parseInt(e?.value.replace(/[%\s]/g, '')))}
                  valueIsNumericString={true}
                  allowNegative = {false}
                />
              </div>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
  )
}
