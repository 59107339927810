import { fork } from 'redux-saga/effects'
import { poolsWatcher } from './pools.saga'
import { loadingWatcher } from './loading.saga'
import { walletWatcher } from '../wallet/wallet.saga'
import { userWatcher } from './user.saga'
import watcherDaoSaga from './dao.saga'

export function* sagasWatcher() {
  yield fork(poolsWatcher)
  // yield fork(timersWatcher)
  yield fork(loadingWatcher)
  yield fork(walletWatcher)
  yield fork(userWatcher)
  yield fork(watcherDaoSaga)
}
