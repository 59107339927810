import { useEffect, useState } from 'react'
import { useLocation } from 'react-router'

import web3 from 'web3'

// Material-UI imports
import {
  Card,
  CardContent,
  Grid,
  Switch,
  TextField
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import { DaoAPI } from '../../../api/DaoAPI'
import { decodeDaoConfigParams } from '../../../helpers/abiDecoder'
import { NumericFormat } from 'react-number-format'
import { useWeb3React } from '@web3-react/core'
import { MAX_UINT } from '../../../const/ethConst'

const useStyle = makeStyles((theme) => ({
  cardContent2: {
    width: '100%',
    borderRadius: '18px 0 0 18px',
    boxShadow: '0px 51px 89px -49px rgba(196, 193, 232, 0.4)',
    '& .MuiCardContent-root': {
      padding: 0,
      borderRight: '1px solid #f4e0fa'
    }
  },
  cardContentSecondary: {
    background: '#F7F6FC'
  },
  cardTitle: {
    fontSize: 13,
    fontWeight: 600,
    fontStyle: 'normal',
    lineHeight: 4,
    margin: 0,
    paddingLeft: '16px',
    borderBottom: '1px solid #eee5f9',
    background: 'white'
  },
  cardContent: {
    borderRadius: '0 18px 18px 0',
    background: '#F7F6FC',
    boxShadow: '0px 51px 89px -49px rgba(196, 193, 232, 0.4)',
    '& .MuiCardContent-root': {
      padding: 0
    }
  },
  helperText: {
    fontSize: '9px',
    color: '#878799'
  },
  radioContent: {
    display: 'block'
  },
  alignItems: {
    alignItems: 'flex-end'
  },
  inputContent: {
    "& .MuiInputBase-input": {
      padding: '10.5px 14px',
      background: '#FBFBFF',
    },
    "& .MuiInputBase-root": {
      borderRadius: '9px'
    },
    display: 'grid',
    fontSize: '12px',
    fontWeight: 500,
    padding: '0 10px 15px',
  },
  labelHelper: {
    fontWeight: 500,
    fontSize: '9px',
    color: '#958BAE',
  },
  boldText: {
    letterSpacing: '0.4px',
    fontSize: '12px',
    color: '#4C4C66',
  },
  input: {
    marginTop: '15px',
  },
  depositLimitContent: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'end'
  },
  labelDepositLimit: {
    color: '#4C4C66',
    fontSize: '12px'
  },
  cardBorders: {
    border: '1px solid #eee5f9',
    borderRadius: '18px'
  },
  contentHelper: {
    padding: '15px 15px 0'
  }
}))

export const VoteForChangeDepositLimit = (props) => {
  const classes = useStyle()

  const { hexToNumber } = web3.utils
  const { connector } = useWeb3React()

  const location = useLocation();
  const daoAddress = location.state.state.data.subgraph.id

  const [daoConfig, setDaoConfig] = useState<any>({})
  const [depositLimit, setDepositLimit] = useState({
    minimumDeposit: 0,
    maximumDeposit: 0,
  })

  useEffect(() => {
    const decodeDepositLimits = async () => {
      try {
        setDaoConfig(await DaoAPI.getConfiguration(daoAddress, connector))
        const result = decodeDaoConfigParams(props?.action)
        setDepositLimit(
          {
            ...depositLimit,
            maximumDeposit: hexToNumber(result.inputs[1]._hex),
            minimumDeposit: hexToNumber(result.inputs[0]._hex)
          })
      } catch (e) {
        console.log('Error message:', e)
      }
    }
    decodeDepositLimits()
      .catch(console.error)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div>
      <Grid container className={classes.cardBorders}>
        <Grid item lg={6} md={6} sm={6}>
          <Card className={classes.cardContent2}>
            <p className={classes.cardTitle}>Proposed Deposit Limit</p>
            <CardContent>
              <Grid container className={classes.alignItems}>
                <Grid item lg={12} md={12}>
                  <div className={classes.contentHelper}>
                    <label className={classes.labelHelper}>
                      <b className={classes.boldText}>Deposit limits,</b> in $USDT equivalent
                    </label>
                    <div className={classes.depositLimitContent}>
                      <label className={classes.labelDepositLimit}>
                        No deposit limits
                      </label>
                      <Switch checked={(depositLimit.minimumDeposit === 0 && depositLimit.maximumDeposit.toString() === MAX_UINT) ? true : false} />
                    </div>
                  </div>
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <div className={classes.inputContent}>
                    <NumericFormat
                      customInput={TextField}
                      variant="outlined"
                      value={depositLimit.minimumDeposit}
                      disabled
                      className={classes.input}
                      thousandSeparator=","
                      valueIsNumericString={true}
                      allowNegative={false}
                    />
                  </div>
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <div className={classes.inputContent}>
                    <NumericFormat
                      customInput={TextField}
                      variant="outlined"
                      value={(depositLimit.minimumDeposit === 0 && depositLimit.maximumDeposit.toString() === MAX_UINT) ? '0' : depositLimit.maximumDeposit}
                      disabled
                      className={classes.input}
                      thousandSeparator=","
                      valueIsNumericString={true}
                      allowNegative={false}
                    />
                  </div>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item lg={6} md={6} sm={6}>
          <Card className={classes.cardContent}>
            <p className={classes.cardTitle}>Current Deposit Limit</p>
            <CardContent>
              <Grid container className={classes.alignItems}>
                <Grid item lg={12} md={12} sm={12}>
                  <div className={classes.contentHelper}>
                    <label className={classes.labelHelper}>
                      <b className={classes.boldText}>Deposit limits,</b> in $USDT equivalent
                    </label>
                    <div className={classes.depositLimitContent}>
                      <label className={classes.labelDepositLimit}>
                        No deposit limits
                      </label>
                      <Switch checked={(daoConfig?.minDeposit === '0' && daoConfig?.maxDeposit === MAX_UINT) ? true : false} />
                    </div>
                  </div>
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <div className={classes.inputContent}>
                    <NumericFormat
                      customInput={TextField}
                      variant="outlined"
                      value={daoConfig?.minDeposit}
                      disabled
                      className={classes.input}
                      thousandSeparator=","
                      valueIsNumericString={true}
                      allowNegative={false}
                    />
                  </div>
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <div className={classes.inputContent}>
                    <NumericFormat
                      customInput={TextField}
                      variant="outlined"
                      value={(daoConfig?.minDeposit === '0' && daoConfig?.maxDeposit === MAX_UINT) ? '0' : daoConfig?.maxDeposit}
                      disabled
                      className={classes.input}
                      thousandSeparator=","
                      valueIsNumericString={true}
                      allowNegative={false}
                    />
                  </div>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  )
}
