/* eslint-disable no-unused-vars */
// Defi Assets
import inch from '../assets/icons/1inch.svg'
import odos from '../assets/icons/odos.png'
import sushi from '../assets/icons/sushi.svg'
import alpacaFinance from '../assets/icons/alpacaFinance.svg'
import barnBridge from '../assets/icons/barnBridge.svg'
import dodo from '../assets/icons/dodo.svg'
import harvestFinance from '../assets/icons/harvestFinance.svg'
import kyberNetwork from '../assets/icons/kyberNetwork.svg'
import onx from '../assets/icons/onx.svg'
import pancakeSwap from '../assets/icons/pancakeSwap.svg'
import uniSwap from '../assets/icons/UNI.svg'
import balancer from '../assets/icons/balancer.png'
import curve from '../assets/icons/curve.png'
import mesh from '../assets/icons/mesh.svg'
import quickswap from '../assets/icons/quickswap.png'
import zeroX from '../assets/icons/0x.webp'
import paraSwap from '../assets/icons/paraSwap.png'
import biSwap from '../assets/icons/biSwap.png'
import thena from '../assets/icons/thena.webp'
import wombat from '../assets/icons/wombat-exchange.webp'

export const PROTOCOLS_METADATA = [
  {
    icon: uniSwap,
    title: 'Uniswap',
    description:
      'Swap, earn, and build on the leading decentralized crypto trading protocol',
    link: 'https://app.uniswap.org/#/swap',
  },
  {
    icon: pancakeSwap,
    title: 'PancakeSwap',
    description: 'Decentralized platform',
    link: 'https://pancakeswap.finance/swap',
  },
  {
    icon: inch,
    title: '1Inch',
    description: 'The most efficient defi aggregator',
    link: 'https://app.1inch.io/#/{chainId}/advanced/swap',
  },
  {
    icon: sushi,
    title: 'Sushi',
    description:
      'Be a DeFi Chef with Sushi. Swap, earn, stack yields, lend, borrow, etc...',
    link: 'https://app.sushi.com/swap?inputCurrency=ETH&outputCurrency=0x6B3595068778DD592e39A122f4f5a5cF09C90fE2',
  },
  {
    icon: barnBridge,
    title: 'Barn Bridge',
    description: 'Derivatives platform',
    link: 'https://app.barnbridge.com/fixed-yield/pools/',
  },
  {
    icon: kyberNetwork,
    title: 'Kyber Network',
    description: 'Trading platform',
    link: 'https://kyberswap.com/swap?utm_source=KN+site&utm_medium=Launch+App%2FStart+Trading%2FStart+Now&utm_campaign=KN-KyberSwap&utm_id=KN-KyberSwap&utm_content=KN-KyberSwap',
  },
  {
    icon: alpacaFinance,
    title: 'Alpaca Finance',
    description: 'Yield farming platform',
    link: 'https://app.alpacafinance.org/lend',
  },
  {
    icon: onx,
    title: 'OnX',
    description: 'Farming and lending platforn',
    link: 'https://app.onx.finance/vaults',
  },
  {
    icon: harvestFinance,
    title: 'Harvest Finance',
    description: 'Farming protocol',
    link: 'https://app.harvest.finance/',
  },
  {
    icon: dodo,
    title: 'DODO',
    description: 'Decentralized exchange / farm / bridgel',
    link: 'https://app.dodoex.io/',
  },
  {
    icon: odos,
    title: 'ODOS',
    description: 'All liquidity in one place + staking on AAVE and others',
    link: 'https://app.odos.xyz/',
  },
]

const EthereumMainnetDefiProtocols = [
  {
    id: 1,
    icon: uniSwap,
    protocolName: 'Uniswap v3',
    protocolAddress: '0xEf1c6E67703c7BD7107eed8303Fbe6EC2554BF6B',
  },
  {
    id: 2,
    icon: uniSwap,
    protocolName: 'Uniswap v2',
    protocolAddress: '0x7a250d5630b4cf539739df2c5dacb4c659f2488d',
  },
  {
    id: 3,
    icon: zeroX,
    protocolName: '0x',
    protocolAddress: '0xdef1c0ded9bec7f1a1670819833240f027b25eff',
  },
  {
    id: 4,
    icon: inch,
    protocolName: '1Inch',
    protocolAddress: '0xa88800cd213da5ae406ce248380802bd53b47647',
  },
  {
    id: 5,
    icon: inch,
    protocolName: '1Inch',
    protocolAddress: '0x1111111254fb6c44bac0bed2854e76f90643097d',
  },
  {
    id: 6,
    icon: inch,
    protocolName: '1Inch',
    protocolAddress: '0x11111112542D85B3EF69AE05771c2dCCff4fAa26',
  },
  {
    id: 7,
    icon: inch,
    protocolName: '1Inch',
    protocolAddress: '0x111111125434b319222CdBf8C261674aDB56F3ae',
  },
  {
    id: 7,
    icon: inch,
    protocolName: '1Inch',
    protocolAddress: '0x11111254369792b2Ca5d084aB5eEA397cA8fa48B',
  },
  {
    id: 7,
    icon: paraSwap,
    protocolName: 'Paraswap',
    protocolAddress: '0xdef171fe48cf0115b1d80b88dc8eab59176fee57',
  },
  {
    id: 8,
    icon: balancer,
    protocolName: 'Balancer',
    protocolAddress: '0xba12222222228d8ba445958a75a0704d566bf2c8',
  },
  {
    id: 9,
    icon: dodo,
    protocolName: 'Dodo',
    protocolAddress: '0xa356867fdcea8e71aeaf87805808803806231fdc',
  },
  {
    id: 10,
    icon: sushi,
    protocolName: 'Sushiswap',
    protocolAddress: '0xd9e1ce17f2641f24ae83637ab66a2cca9c378b9f',
  },
]

const PolygonDefiProtocols = [
  {
    id: 1,
    icon: uniSwap,
    protocolName: 'Uniswap',
    protocolAddress: '0xec7BE89e9d109e7e3Fec59c222CF297125FEFda2',
    dependencies: ['0x000000000022d473030f116ddee9f6b43ac78ba3'],
  },
  {
    id: 2,
    icon: inch,
    protocolName: '1Inch',
    protocolAddress: '0x111111125421ca6dc452d289314280a0f8842a65',
  },
  {
    id: 3,
    icon: odos,
    protocolName: 'Odos',
    protocolAddress: '0x4E3288c9ca110bCC82bf38F09A7b425c095d92Bf',
  },
]

const GoerliDefiProtocols = [
  {
    id: 1,
    icon: uniSwap,
    protocolName: 'Uniswap',
    protocolAddress: '0x3fC91A3afd70395Cd496C647d5a6CC9D4B2b7FAD',
    dependencies: ['0x000000000022d473030f116ddee9f6b43ac78ba3'],
  },
  {
    id: 3,
    icon: sushi,
    protocolName: 'Sushiswap',
    protocolAddress: '0x1b02dA8Cb0d097eB8D57A175b88c7D8b47997506',
  },
]

const ArbitrumDefiProtocols = [
  {
    id: 1,
    icon: uniSwap,
    protocolName: 'Uniswap',
    protocolAddress: '0x5E325eDA8064b456f4781070C0738d849c824258',
    dependencies: ['0x000000000022d473030f116ddee9f6b43ac78ba3'],
  },
  {
    id: 2,
    icon: inch,
    protocolName: '1Inch',
    protocolAddress: '0x111111125421ca6dc452d289314280a0f8842a65',
  },
  {
    id: 3,
    icon: odos,
    protocolName: 'Odos',
    protocolAddress: '0xa669e7A0d4b3e4Fa48af2dE86BD4CD7126Be4e13',
  },
]

const BSCMainnetDefiProtocols = [
  {
    id: 1,
    icon: uniSwap,
    protocolName: 'Uniswap',
    protocolAddress: '0x4Dae2f939ACf50408e13d58534Ff8c2776d45265',
    dependencies: ['0x000000000022d473030f116ddee9f6b43ac78ba3'],
  },
  {
    id: 2,
    icon: inch,
    protocolName: '1Inch',
    protocolAddress: '0x111111125421ca6dc452d289314280a0f8842a65',
  },
  {
    id: 3,
    icon: odos,
    protocolName: 'Odos',
    protocolAddress: '0x89b8AA89FDd0507a99d334CBe3C808fAFC7d850E',
  },
]

export const SUPPORTED_PROTOCOLS = {
  1: EthereumMainnetDefiProtocols,
  5: GoerliDefiProtocols,
  56: BSCMainnetDefiProtocols,
  137: PolygonDefiProtocols,
  31337: EthereumMainnetDefiProtocols,
  42161: ArbitrumDefiProtocols,
}
