import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Grid, Typography } from '@material-ui/core'


const useStyles = makeStyles(
  (theme) => ({
    root: {
      margin: '0 0 36px 0',
      '@media screen and (max-width: 360px)': {
        margin: '0 0 32px 0',
      },
    },
    tableName: {
      padding: 0,
      margin: 0,
      color: theme.palette.primary.main,
      fontSize: 32,
      lineHeight: '38px',
      fontFamily: theme.fontFamilies.fourth,
      fontWeight: 400,
    },
    comingSoon: {
      marginLeft: theme.spacing(2),
      color: theme.palette.twentyFourth.main,
      alignSelf: 'center',
    },
  }),
  {name: 'SectionTitle'},
)

export const SectionTitle = ({title, subtitle}: { title: string, subtitle?: string }) => {
  const classes = useStyles()
  return (
    <Grid container className={classes.root}>
      <h3 className={classes.tableName}>{title}</h3>


      {subtitle && <Typography variant="body2" className={classes.comingSoon}>{subtitle}</Typography>}
    </Grid>
  )
}
