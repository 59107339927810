import { makeStyles } from '@material-ui/core/styles'
import { Card, CardContent, Grid, Container } from '@material-ui/core'

import { SUPPORTED_PROTOCOLS, PROTOCOLS_METADATA } from '../../const/aspisSupportedProtocolsList'

const useStyle = makeStyles((theme) => ({
  card: {
    border: '1px solid rgba(214, 210, 242, 0.5)',
    boxShadow: '0px 51px 89px rgba(196, 193, 232, 0.4)',
    borderRadius: '18px',
    cursor: 'grab',
    height: '100%',
    '&:hover': {
      borderColor: '#BC65F2',
    },
  },
  title: {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '13px',
    lineHeight: '217%',
    color: '#4C4C66',
    marginTop: '10px',
  },
  description: {
    fontWeight: 400,
    fontSize: '11px',
    lineHeight: '160%',
    color: '#979797',
  },
  custom: {
    fontWeight: 400,
    fontSize: '13px',
    lineHeight: '217%',
    color: '#4C4C66',
    margin: '25px 0',
  },
  link: {
    textDecoration: 'none',
  },
}))

export const NetworkExamples = (props: any) => {
  const classes = useStyle()

  const { chainId } = props

  return (
    <Container>
      <p className={classes.custom}>
        Integrations
      </p>
      <Grid container spacing={3}>
        {
          chainId &&
          SUPPORTED_PROTOCOLS[chainId].map((item, key) => {
            const metadata = PROTOCOLS_METADATA.find(m => m.title === item.protocolName);
            if (metadata) {
              return (
                <Grid item lg={3} md={4} sm={4} xs={12} key={key}>
                  <a
                    className={classes.link}
                    href={metadata.link.replace('{chainId}', chainId)}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Card className={classes.card}>
                      <CardContent>
                        <Grid item lg={3}>
                          <img src={metadata.icon} width={40} alt="wallet connect" />
                        </Grid>
                        <p className={classes.title}>{metadata.title}</p>
                        <p className={classes.description}>{metadata.description}</p>
                      </CardContent>
                    </Card>
                  </a>
                </Grid>
              )
            }
            else return null
          })
        }
      </Grid>
      </Container>
  )
}
