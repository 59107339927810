import Web3 from 'web3';
import { AbiItem } from 'web3-utils'
import { FACTORY } from '../abi/index'
import { NetworkId } from '../models/Network';

export const useEnvSmartContract = (chainId: number | undefined) => {

    let DaoRegistry: object | undefined

    const web3 = new Web3(Web3.givenProvider)

    switch (chainId) {
        case NetworkId.GoerliTestNetwork:
            DaoRegistry = new web3.eth.Contract(
                FACTORY as AbiItem[],
                process.env.REACT_APP_GOERLI_FACTORY_ADDRESS
            )
            break
        case NetworkId.LocalhostTestNetwork:
            DaoRegistry = new web3.eth.Contract(
                FACTORY as AbiItem[],
                process.env.REACT_APP_LOCALHOST_FACTORY_ADDRESS
            )
            break
        case NetworkId.BSCMainnet:
            DaoRegistry = new web3.eth.Contract(
                FACTORY as AbiItem[],
                process.env.REACT_APP_BNB_FACTORY_ADDRESS
            )
            break
        case NetworkId.Arbitrum:
            DaoRegistry = new web3.eth.Contract(
                FACTORY as AbiItem[],
                process.env.REACT_APP_ARBITRUM_FACTORY_ADDRESS
            )
            break
        case NetworkId.Polygon:
            DaoRegistry = new web3.eth.Contract(
                FACTORY as AbiItem[],
                process.env.REACT_APP_POLYGON_FACTORY_ADDRESS
            )
            break
    }
    return { DaoRegistry }
}
