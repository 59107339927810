import { createContext, useContext, useState } from 'react';

const ModalContext = createContext<any | null>(null);

const ModalProvider = ({ children }) => {
  const [modalComponent, setModalComponent] = useState(null);

  const value = {
    modalComponent,
    setModalComponent
  };

  return (
    <ModalContext.Provider value={value}>{children}</ModalContext.Provider>
  );
};

const useModalContext = () => {
  const context = useContext(ModalContext);
  if (context === undefined) {
    throw new Error('useWallet must be used within a WalletProvider');
  }
  return context;
};

export { ModalContext, ModalProvider, useModalContext };
