export class DebugLog {

  _name: string
  _enabled: boolean

  constructor(name: string, enabled: boolean = true) {
    this._name = name;
    this._enabled = enabled
  }

  log(...args: any) {
    if (this._enabled) {
      console.log(`%c${this._name}`, 'padding: 0.25em 0.5em; border-radius: 0.25em; color: #fff; background: #888; font-size: 0.9em', ...args)
    }
  }
}
