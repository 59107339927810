import { useNavigate } from 'react-router-dom'
import { useLocation } from 'react-router';

// Material UI imports
import { makeStyles } from '@material-ui/core/styles'
import { Box, Typography } from '@material-ui/core'
import {
  Card,
  CardContent,
  Grid,
} from '@material-ui/core'
import LinearProgress from '@material-ui/core/LinearProgress'
import { ChipLabel } from '../../Chip';

import { routes, ID, VID } from '../../../const/routes'
import { CreateCard } from '../../../components/CreateCard'
import moment from 'moment';
import CountdownTimer from '../../../helpers/countdownTimer';
import BigNumber from 'bignumber.js';
import { useWeb3React } from '@web3-react/core';
const useStyles = makeStyles({
  card:{
    height:'100%',
    cursor: 'pointer',
    borderRadius: '10px',
    boxShadow: '0px 17px 26px rgba(215, 190, 255, 0.05)',
  },
  cardContent:{
    background: '#FFFFFF',
    borderRadius: '10px',
    '& .MuiCardContent-root':{
      padding: 0
    }
  },
  titleContent: {
    display: 'flex',
    alignItems:'center'
  },
  title: {
    fontSize: '9px',
    marginLeft: '12px',
    color: '#8173AC'
  },
  boldText:{
      fontSize:'9px',
      fontWeight: 'bold',
      marginTop: '7px' 
  },
  logo: {
      width: '45px',
  },
   proposalTitle:{
       fontSize:'12px',
       fontWeight: 700,
       lineHeight: '20.52px',
       color: '#4C4C66',
       wordBreak: 'break-all'
   },
   proposalDescription:{
       fontSize:'9px',
       fontWeight: 400,
       lineHeight: '14.22px',
       wordBreak: 'break-all'
   },
   votingTimer:{
       fontSize: '10px',
       fontWeight: 500,
       color:'#4C4C66',
       opacity: 0.67
   },
   timer:{
       color:'#2F2F4D',
       fontWeight: 600,
       fontSize: '20px'
   },
   progressBarYes:{
    height: '6px',
    borderRadius: '53px',
    '& .MuiLinearProgress-barColorPrimary':{
        background: '#4DCAC2',
    }
  },
  progressBarNo:{
    height: '6px',
    borderRadius: '53px',
    '& .MuiLinearProgress-barColorPrimary':{
        background: '#DA418F',
    }
  },
  progressBarQuorum:{
    height: '6px',
    borderRadius: '53px',
  },
  progressText:{
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '17px'
  },
  progressPercentage:{
    color: '#988DBC',
    fontWeight:800,
    fontSize: 12,
    fontFamily:'Poppins',
    opacity: 0.9
  },
  progressTitle:{
    fontSize: 10,
    marginTop: 10
  },
  content:{
    marginBottom: '45px'
  },
  spacing:{
    padding: '8px'
  }
})

export const ProposalCard = (props: any) => {

  const classes = useStyles()
  const navigate = useNavigate()
  const location = useLocation()
  const { account } = useWeb3React()

  const { manager, lpTokenBalance, lpTotalSupply, requiredTokenBalance, supportedTokens, dao } = props

  const subgraph = props?.dao?.data?.subgraph;
  
  let paths = location.pathname.split('/');
  let daoId = paths[paths.length - 1];

  const votingParams = {
    state: props?.dao,
    lpBalance: props?.lpTokenBalance
  }
  
  const sortedProposals = subgraph?.proposals?.sort((a:any, b:any) => {
    return new Date(parseInt(b.createdAt)).valueOf() - new Date(parseInt(a.createdAt)).valueOf();
  });

  const gridItem = (
    <Grid item style={{ height: dao?.data?.subgraph?.proposals.length === 0 ? '450px' : '' }} className={classes.spacing} lg={3} md={4} sm={6} xs={12}>
      <div style={{ height: '100%' }} onClick={() => navigate(routes.CREATE_VOTING.replace(ID, daoId), { state: {supportedTokens, lpTotalSupply}})}>
        <CreateCard title='Create Proposal' daoId={daoId} />
      </div>
    </Grid>
  );
 
  return (
    <Grid container className={classes.content}>
      {
        sortedProposals && sortedProposals?.slice(0, props?.proposalsToShow).map((proposal: any) => {
          return (
            <Grid item className={classes.spacing} lg={3} md={4} sm={6} xs={12} key={proposal.voteId}>
              <Card
                className={classes.card}
                onClick={() => navigate(routes.VOTING.replace(ID, daoId).replace(VID, proposal?.voteId), { state: votingParams })}>
                  <CardContent>
                    <Grid item  lg={12} md={12} sm={12} xs={12}>
                      <div className={classes.titleContent}>
                        <img className={classes.logo} src={props?.dao?.img} alt='logoDao' />
                        <h1 className={classes.title}>{proposal.dao.name} by </h1>
                        <p className={classes.boldText}>
                          &nbsp;{proposal.creator.slice(0, 5) + '...' + proposal.creator.slice(28, 41)}
                        </p>
                      </div>
                    </Grid>
                  <Grid>
                    <Grid container spacing={1}>
                      <Grid item lg={4} md={4} sm={4} xs={4}>
                        <ChipLabel type="active"
                          label={
                            proposal.executed ? 
                              'Executed'
                            : 'Not Executed'
                          }/>
                      </Grid>
                      <Grid item lg={4} md={4} sm={4} xs={4}>
                      <ChipLabel type="unVoted"
                        label={
                          proposal.voters.find(obj => obj.voter.id === account?.toLowerCase()) ?
                          'Voted' 
                          : 
                          'Not Voted'
                          }/>
                      </Grid>
                      <Grid item lg={4} md={4} sm={4} xs={4}>
                      <ChipLabel type="accepted"
                        label={
                          ((
                            new BigNumber(proposal.yea)
                              .dividedBy(proposal.votingPower)
                              .multipliedBy(100)
                              .gt(new BigNumber(proposal.supportRequiredPct).dividedBy('1e16'))) &&
                            (
                              new BigNumber(0)
                                .plus(proposal.yea)
                                .plus(proposal.nay)
                                .plus(proposal.abstain)
                                .dividedBy(proposal.votingPower)
                                .multipliedBy(100)
                                .gt(new BigNumber(proposal.participationRequiredPct)
                                  .dividedBy('1e16')))
                          ) ?
                            'Passed' :
                            ((
                              new BigNumber(proposal.yea)
                                .dividedBy(proposal.votingPower)
                                .multipliedBy(100)
                                .gt(new BigNumber(proposal.supportRequiredPct).dividedBy('1e16'))) &&
                              (
                                new BigNumber(0)
                                  .plus(proposal.yea)
                                  .plus(proposal.nay)
                                  .plus(proposal.abstain)
                                  .dividedBy(proposal.votingPower)
                                  .multipliedBy(100)
                                  .gt(new BigNumber(proposal.participationRequiredPct)
                                    .dividedBy('1e16')))
                            ) ?
                            'Rejected':
                              (
                              moment()
                                .isBetween(
                                  moment.unix(proposal.startDate),
                                  moment.unix(proposal.endDate)
                                )) ?
                                (new BigNumber(0)
                                      .plus(proposal.yea)
                                      .plus(proposal.nay)
                                      .plus(proposal.abstain)
                                      .dividedBy(proposal.votingPower)
                                      .multipliedBy(100)
                                      .eq(100)) ?
                                    "Rejected":
                                    "Pending":
                                  'Rejected'
                        } />
                        </Grid>
                    </Grid>
                    {
                      proposal?.details !== undefined ?
                        <Grid item  lg={12} md={12} sm={12} xs={12}>
                          <h1 className={classes.proposalTitle}>{proposal?.details?.title}</h1>
                          <p className={classes.proposalDescription}>{proposal?.details?.description}</p>
                        </Grid>
                        :
                        null
                    }
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <div>
                        <h3 className={classes.votingTimer}>Voting:</h3>
                        <span className={classes.timer}>
                          {/* {
                            proposal.executed ?
                              "Finished"
                              :
                              !((
                                new BigNumber(0)
                                .plus(proposal.yea)
                                .plus(proposal.nay)
                                .plus(proposal.abstain)
                                .dividedBy(proposal.votingPower)
                                .multipliedBy(100)
                                .gt(new BigNumber(proposal.participationRequiredPct)
                                .dividedBy('1e16')))
                               ) ?
                               !(
                                moment()
                                .isBetween(
                                  moment.unix(proposal.startDate)
                                  .format('YYYY-MM-DD hh:mm:ss a'),
                                  moment.unix(proposal.endDate)
                                  .format('YYYY-MM-DD hh:mm:ss a')
                               )) ?
                                "Finished" 
                                :
                                <CountdownTimer startDate={moment.unix(proposal.endDate).format()} endDate={null} /> :
                              "Finished"
                          }
                          <br/> */}
                          {
                            proposal.executed ?
                              "Finished" :
                              (moment()
                                .isBetween(
                                  moment.unix(proposal.startDate),
                                  moment.unix(proposal.endDate))) &&
                                  !(new BigNumber(0)
                                      .plus(proposal.yea)
                                      .plus(proposal.nay)
                                      .plus(proposal.abstain)
                                      .dividedBy(proposal.votingPower)
                                      .multipliedBy(100)
                                      .eq(100)) ?
                                <CountdownTimer startDate={moment.unix(proposal.endDate).format()} endDate={null} /> :
                                "Finished"
                          }
                        </span>
                      </div>
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <div className={classes.progressText}>
                        <div>
                          <Typography component={'span'} className={classes.progressPercentage}>
                            Yes
                          </Typography>
                        </div>
                        <div>
                          <Typography component={'span'} style={{ fontFamily: 'Poppins', fontSize: '12px', color: '#8173AC', fontWeight: 700 }}>
                            {
                              proposal.yea === null || proposal.yea === '0' ?
                                '0%'
                                :
                                props?.supply != null ? (new BigNumber(proposal.yea).dividedBy(proposal.votingPower).multipliedBy(100).toFixed(5))+ '%' : '0%'
                            }
                          </Typography>
                        </div>
                      </div>
                      <Box>
                        <LinearProgress
                          value={proposal.yea === null ? 0 : (new BigNumber(proposal.yea).dividedBy(proposal.votingPower).multipliedBy(100).toNumber())}
                          className={classes.progressBarYes}
                          variant="determinate" />
                      </Box>
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <div className={classes.progressText}>
                        <div>
                          <Typography component={'span'} className={classes.progressPercentage}>
                            No
                          </Typography>
                        </div>
                        <div>
                          <Typography component={'span'} style={{ fontFamily: 'Poppins', fontSize: '12px', color: '#8173AC', fontWeight: 700 }}>
                            {
                              proposal.nay === null || proposal.nay === '0' ?
                                '0%'
                                :
                                props?.supply != null ?(new BigNumber(proposal.nay).dividedBy(proposal.votingPower).multipliedBy(100).toFixed(5)) + '%' : '0%'
                            }
                          </Typography>
                        </div>
                      </div>
                      <Box>
                        <LinearProgress
                          value={proposal.nay === null ? 0 : (new BigNumber(proposal.nay).dividedBy(proposal.votingPower).multipliedBy(100).toNumber())}
                          className={classes.progressBarNo}
                          variant="determinate" />
                      </Box>
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <div className={classes.progressText}>
                        <div>
                          <Typography component={'span'} className={classes.progressPercentage}>
                            Quorum
                          </Typography>
                        </div>
                        <div>
                          <Typography component={'span'} style={{ fontFamily: 'Poppins', fontSize: '12px', color: '#8173AC', fontWeight: 700 }}>
                            {
                              !proposal.voters.length ? '0%' :
                              props?.supply != null ? (new BigNumber(0).plus(proposal.yea).plus(proposal.nay).plus(proposal.abstain).dividedBy(proposal.votingPower).multipliedBy(100).toFixed(5)) + '%' : '0%'
                            }
                            {
                              !proposal.voters.length ?
                              <span>
                                {' '}(0 ${proposal.dao.token.symbol})
                              </span>
                              :
                              <span>
                                {' '}({new BigNumber(0).plus(proposal.yea).plus(proposal.nay).plus(proposal.abstain).dividedBy('1e18').toFixed(2)} ${proposal.dao.token.symbol})
                              </span>
                            }
                          </Typography>
                        </div>
                      </div>
                      <Box>
                        <LinearProgress
                          value={
                            !proposal.voters.length ? 
                            0 
                            :
                            (new BigNumber(0).plus(proposal.yea).plus(proposal.nay).plus(proposal.abstain).dividedBy(proposal.votingPower).multipliedBy(100).toNumber())
                          }
                          className={classes.progressBarQuorum}
                          variant="determinate" />
                      </Box>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          )
        })
      }
          {
            account === manager ? gridItem :
              lpTokenBalance && lpTokenBalance >= requiredTokenBalance ? gridItem :
            <Grid item  style={{ height: dao?.data?.subgraph?.proposals.length === 0 ? '450px' : '' }} className={classes.spacing} lg={3} md={4} sm={6} xs={12}>
              <CreateCard title='You do not have LP tokens to create proposal' daoId={daoId} />
            </Grid>
          }
    </Grid>
  )
}
