export const VotingTypes = [
  {
    name: 'updateManager',
    code: '0x58aba00f',
  },
  {
    name: 'addToTrustedProtocols',
    code: '0xac76a31c',
  },
  {
    name: 'removeFromTrustedProtocols',
    code: '0x89ca0027',
  },
  {
    name: 'setDepositLimits',
    code: '0x4eddea06',
  },
  {
    name: 'setSpendingLimit',
    code: '0x3c9607f6',
  },
  {
    name: 'setTokensForDepositing',
    code: '0x86304251',
  },
  {
    name: 'removeTokensForDepositing',
    code: '0xc7dc59cb',
  },
  {
    name: 'setTokensForTrading',
    code: '0x973d3ca9',
  },
  {
    name: 'removeTokensForTrading',
    code: '0xe8efe397',
  },
  {
    name: 'setLockLimit',
    code: '0xa32bdee9',
  },
  {
    name: 'setWithdrawlWindows',
    code: '0x7b7b2105',
  },
  {
    name: 'setRageQuitFee',
    code: '0xb272a7e9',
  },
  {
    name: 'setEntranceFee',
    code: '0xfe56f5a',
  },
  {
    name: 'addWhitelistedUsers',
    code: '0x7f649783',
  },
  {
    name: 'removeWhitelistedUsers',
    code: '0x548db174',
  },
  {
    name: 'setFundManagementFee',
    code: '0x183bb2c1',
  },
  {
    name: 'setPerformanceFee',
    code: '0x70897b23',
  },
  {
    name: 'setVotingParams',
    code: '0xbecbd871',
  },
  {
    name: 'setDirectTransferParams',
    code: '0x21feab07',
  },
  {
    name: 'setFundraisingStartTimeAndFinishTime',
    code: '0x692c20d4',
  },
]
