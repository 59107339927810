import { useEffect, useState } from 'react'

// Material-UI imports
import {
  Card,
  CardContent,
  Grid,
  TextField,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import 'react-datepicker/dist/react-datepicker.css'


const useStyle = makeStyles((theme) => ({
  divider: {
    borderBottom: '1px solid rgb(224 224 224)',
  },
  cardTitle: {
    fontSize: 13,
    fontWeight: 600,
    fontStyle: 'normal',
    lineHeight: 4,
    margin: 0,
    paddingLeft: '16px',
  },
  cardContent: {
    borderRadius: '18px',
    border: '1px solid rgba(214, 210, 242, 0.5)',
    boxShadow: '0px 51px 89px -49px rgba(196, 193, 232, 0.4)',
    '& .MuiCardContent-root': {
      padding: 0,
    },
  },
  contentHelper: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '-25px',
    padding: '0 14px'
  },
  labelHelper: {
    fontWeight: 500,
    fontSize: '9px',
    color: '#958BAE',
  },
  contentHelper2: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    marginBottom: '-25px',
    padding: '0 14px'
  },
  boldText: {
    letterSpacing: '0.4px',
    fontSize: '12px',
    color: '#4C4C66',
  },
  spanTextColor: {
    color: '#BC65F2',
    fontSize: '12px',
    textAlign: 'right',
  },
  questionMark: {
    width: '20px'
  },
  link: {
    color: '#BC65F2'
  },
  inputContent: {
    "& .MuiInputBase-input": {
      padding: '10.5px 14px',
      background: '#FBFBFF',
    },
    "& .MuiInputBase-root": {
      borderRadius: '9px'
    },
    display: 'grid',
    fontSize: '12px',
    fontWeight: 500,
    padding: '15px 10px',
  },
  input: {
    marginTop: '15px',
  },
  exampleContent: {
    fontSize: '8px',
    height: '100%',
  },
  exampleText: {
    lineHeight: '22px',
    marginTop: '0px',
    color: '#958BAE',
    margin: '8px'
  },
  exampleTitle: {
    color: '#BC65F2',
    margin: '5px 8px'
  },
  alignItems: {
    alignItems: 'flex-end'
  },
  title: {
    fontWeight: 500,
    margin: '14px 0 5px 0',
    fontSize: '1rem',
    color: 'rgba(0, 0, 0, 0.54)',
    lineHeight: '23.87px'
  }
}))

type State = {
  address: string | null,
  func: string
}

const InitialState: State = {
  address: null,
  func: 'upgradeTo'
}

export const UpgradeDaoContract = (props) => {
  const { setIsProposalValid } = props;
  const classes = useStyle()

  const [upgradeConfig, setUpgradeConfig] = useState({ ...InitialState })

  // CHECK VALIDATION IN CREATION DAO
const inputValidation = upgradeConfig?.address && upgradeConfig?.address!.length === 42

useEffect(()=>{
  setIsProposalValid(inputValidation)
    // eslint-disable-next-line react-hooks/exhaustive-deps
}, [inputValidation])

  useEffect(() => {
    props.func(upgradeConfig)
  })

  return (
    <Card className={classes.cardContent}>
      <div className={classes.divider}>
        <p className={classes.cardTitle}>Upgrade dao contract</p>
      </div>
      <CardContent style={{ padding: '16px' }}>
        <Grid container className={classes.alignItems}>
          <p className={classes.title}>New implementation address</p>
          <Grid container>
            <Grid item lg={12} xs={12}>
              <TextField
                className={classes.input}
                variant="outlined"
                type="text"
                onChange={(e: any) => setUpgradeConfig({ ...upgradeConfig, address: e.target.value })}
                placeholder='Enter address'
              />
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}
