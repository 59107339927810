import React, { useCallback, useLayoutEffect, useRef, useState } from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import { Container, Grid, LinearProgress, Typography } from '@material-ui/core'
import earthSrc from '../../assets/images/earth.png'
import tropoSrc from '../../assets/images/tropo.png'
import stratoSrc from '../../assets/images/strato.png'
import mesoSrc from '../../assets/images/meso.png'
import thermoSrc from '../../assets/images/thermo.png'
import moonProgressSrc from '../../assets/icons/moon.svg'
import { useWindowWidth } from '../../hooks'
import { ModalGrades } from '../ModalGrades/ModalGrades'

const useStyle = makeStyles((theme) => ({
  root: {
    margin: '74px 0 64px 0',
    fontFamily: theme.fontFamilies.secondary,
    '@media screen and (max-width: 645px)': {
      margin: '28px 0 57px 0',
    },
    '@media screen and (max-width: 380px)': {
      margin: '17px 0 37px 0',
    },
  },
  title: {
    fontWeight: 'bold',
    fontSize: 14,
    lineHeight: '22px',
    color: theme.palette.primary.main,
    margin: theme.spacing(2, 0),
    '@media screen and (max-width: 380px)': {
      margin: '0 0 13px 0',
    },
  },
  progressBarWrapper: {
    borderRadius: 26,
    border: `3px solid ${theme.palette.thirtieth.main}`,
    backgroundColor: theme.palette.sixth.main,
    padding: 3,
    margin: '0 0 20px 0',
    '@media screen and (max-width: 380px)': {
      margin: '0 0 9px 0',
      backgroundColor: 'transparent',
      height: 26,
      // border: 'none',
      // padding: 0,
    },
  },
  progressBar: {
    width: '100%',
  },
  scale: {
    justifyContent: 'space-evenly',
    fontSize: 14,
    lineHeight: '150%',
  },
  scaleItem: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 90,
    // width: 83,
    '& > img': {
      width: 30,
      height: 30,
      '@media screen and (max-width: 645px)': {
        width: 16,
        height: 16,
      },
      '@media screen and (max-width: 380px)': {
        display: 'none',
      },
    },
    '@media screen and (max-width: 645px)': {
      height: 70,
      // width: 53,
    },
    '@media screen and (max-width: 380px)': {
      margin: '0 0 9px 0',
      height: 'fit-content',
      // width: 'auto',
    },
  },
  scaleItemName: {
    fontWeight: 600,
    color: theme.palette.twentyFifth.main,
    '@media screen and (max-width: 380px)': {
      display: 'none',
    },
  },
  scaleItemValue: {
    fontWeight: 'bold',
    color: theme.palette.twentieth.main,
    '@media screen and (max-width: 380px)': {
      display: 'none',
    },
  },
  pointsWrapper: {
    justifyContent: 'space-evenly',
    position: 'relative',
    height: 36,
    margin: '-62px 0 0 0',
    alignItems: 'center',
    '@media screen and (max-width: 380px)': {
      height: 26,
      margin: '-35px 0 0 0',
    },
  },
  point: {
    height: 6,
    width: 4,
    backgroundColor: theme.palette.primary.main,
    borderRadius: 6,
    margin: '0 13px',
    '@media screen and (max-width: 645px)': {
      margin: '0 6px',
    },
  },
  backScales: {
    position: 'relative',
    margin: '-90px 0 0 0',
    fontSize: 14,
    lineHeight: '150%',
    justifyContent: 'space-between',
    '@media screen and (max-width: 645px)': {
      margin: '-70px 0 0 0',
    },
    '@media screen and (max-width: 380px)': {
      margin: '-30px 0 0 0',
    },
  },
  moon: {
    position: 'absolute',
    '@media screen and (max-width: 380px)': {
      height: 16,
      margin: '-1px 0 0 -1px',
    },
  },
  clicking: {
    cursor: 'pointer',
  },
  tableName: {
    padding: 0,
    margin: 0,
    color: theme.palette.primary.main,
    fontSize: 32,
    lineHeight: '38px',
    fontFamily: theme.fontFamilies.fourth,
    fontWeight: 400,
    display: 'flex',
    alignItems: 'center',
  },
  comingSoon: {
    display: 'inline',
    marginLeft: theme.spacing(2),
    color: theme.palette.twentyFourth.main,
    alignSelf: 'center',
  },
}))

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 36,
    borderRadius: 26,
    '@media screen and (max-width: 380px)': {
      height: 26,
      backgroundColor: 'transparent',
      position: 'relative',
      marginTop: '-35px',
    },
  },
  colorPrimary: {
    backgroundColor: theme.palette.sixth.main,
    // border: `3px solid ${theme.palette.thirtieth.main}`,
    '@media screen and (max-width: 380px)': {
      backgroundColor: 'transparent',
    },
  },
  bar: {
    borderRadius: 26,
    backgroundColor: theme.palette.primary.main,
  },
}))(LinearProgress)

const scales = [
  {
    src: earthSrc,
    name: 'Earth',
    value1: '1 ticket',
    value2: '1 ticket',
  },
  {
    src: tropoSrc,
    name: 'Tropo',
    value1: '2 tickets',
    value2: '2 tickets',
  },
  {
    src: stratoSrc,
    name: 'Strato',
    value1: '3 tickets',
    value2: '3 tickets',
  },
  {
    src: mesoSrc,
    name: 'Meso',
    value1: '4 tickets',
    value2: '4 tickets',
  },
  {
    src: thermoSrc,
    name: 'Thermo',
    value1: '5 tickets',
    value2: '5 tickets',
  },
]

export const StakingProgress = () => {
  const classes = useStyle()
  const width = useWindowWidth()
  const ref = useRef(null)
  const [progressScaleWidth, setProgressScaleWidth] = useState(width)
  const [isModalGradesOpen, setIsModalGradesOpen] = useState(false)
  useLayoutEffect(() => {
    setProgressScaleWidth(ref.current.offsetWidth)
  }, [width])
  const value = 0
  const tokens = 0

  const onProgressBarClick = useCallback(() => {
    setIsModalGradesOpen(true)
  }, [])

  const onModalClose = useCallback(() => {
    setIsModalGradesOpen(false)
  }, [])

  return (
    <Container disableGutters className={classes.root}>
      <Typography variant={'h3'} className={classes.tableName}>
        Investor Grade and Eligibility
        <Typography
          component={'span'}
          variant="body2"
          className={classes.comingSoon}
        >
          Coming soon
        </Typography>
      </Typography>
      <Typography variant={'h5'} className={classes.title}>
        You have {tokens} DTM Tokens
      </Typography>

      <Grid container justify="center">
        {width > 380 ? (
          <Grid
            container
            className={`${classes.progressBarWrapper} ${classes.clicking}`}
            onClick={onProgressBarClick}
          >
            <BorderLinearProgress
              ref={ref}
              className={classes.progressBar}
              variant="determinate"
              value={value}
            />
            {value === 0 && (
              <img
                src={moonProgressSrc}
                className={classes.moon}
                alt="progress"
              />
            )}
          </Grid>
        ) : (
          <>
            <Grid
              container
              className={`${classes.progressBarWrapper} ${classes.clicking}`}
              onClick={onProgressBarClick}
            >
              {value === 0 && (
                <img
                  src={moonProgressSrc}
                  className={classes.moon}
                  alt="progress"
                />
              )}
            </Grid>
            <BorderLinearProgress
              ref={ref}
              className={classes.progressBar}
              variant="determinate"
              value={value}
            />
          </>
        )}
        <Grid
          container
          className={`${classes.pointsWrapper} ${classes.clicking}`}
          onClick={onProgressBarClick}
        >
          <div
            className={classes.point}
            style={{ ...(value >= 25 && { backgroundColor: 'white' }) }}
          />
          <div
            className={classes.point}
            style={{ ...(value >= 51 && { backgroundColor: 'white' }) }}
          />
          <div
            className={classes.point}
            style={{ ...(value >= 76 && { backgroundColor: 'white' }) }}
          />
        </Grid>
        <Grid
          container
          style={{ justifyContent: 'center', width: progressScaleWidth }}
        >
          <Grid
            container
            className={classes.scale}
            style={{
              width:
                width > 645
                  ? progressScaleWidth - 43
                  : width > 380
                  ? progressScaleWidth - 25
                  : progressScaleWidth,
            }}
          >
            {scales.slice(1, 4).map((item) => {
              return (
                <div className={classes.scaleItem} key={item.name}>
                  <img src={item.src} alt={item.name} />
                  <span className={classes.scaleItemName}>{item.name}</span>
                  <span className={classes.scaleItemValue}>
                    {width > 645 ? item.value1 : item.value2}
                  </span>
                </div>
              )
            })}
          </Grid>
          <Grid container className={classes.backScales}>
            {[scales[0], scales[4]].map((item) => {
              return (
                <div className={classes.scaleItem} key={item.name}>
                  <img src={item.src} alt={item.name} />
                  <span className={classes.scaleItemName}>{item.name}</span>
                  <span className={classes.scaleItemValue}>
                    {width > 645 ? item.value1 : item.value2}
                  </span>
                </div>
              )
            })}
          </Grid>
        </Grid>
      </Grid>
      <ModalGrades onClose={onModalClose} open={isModalGradesOpen} />
    </Container>
  )
}
