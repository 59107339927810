import { makeStyles } from '@material-ui/core/styles'
import { Grid, Container } from '@material-ui/core'
import { SectionTitle } from '../SectionTitle'

const useStyles = makeStyles(
  (theme) => ({
    tableContainer: {
      padding: 0,
    },
    tableContent: {
      overflowX: 'auto',
      '@media screen and (max-width: 1200px)': {
        // padding: '0 20px 0 20px',
      },
    },
  }),
  { name: 'SectionTable' }
)

export const SectionTable = ({ className, title, subtitle, children }) => {
  const classes = useStyles()

  return (
    <Grid className={className}>
      <Container>
        <SectionTitle title={title} subtitle={subtitle} />
      </Container>

      <Container className={classes.tableContainer}>
        <div className={classes.tableContent}>{children}</div>
      </Container>
    </Grid>
  )
}
