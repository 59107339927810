import { useState } from 'react'

// Material Imports
import Card from '@material-ui/core/Card'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import {
  Step,
  StepConnector,
  StepContent,
  StepLabel,
  Stepper,
  CardContent,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

// WEB3 Imports
import { useWeb3React } from '@web3-react/core'

// Steps
import { SpecifyGeneralInfo } from './steps/specifyGeneralInfo'
import { LpAgreement } from './steps/lpAgreement'
import { Controlling } from './steps/Controlling'
import { InvestManagerAppointement } from './steps/InvestManagerAppointemnt'

// Assets
import CircleIcon from '../../assets/icons/circle.svg'
import TickIcon from '../../assets/icons/tick.svg'
import DaoCreationImg from '../../assets/images/createDaoModal.svg'

//Modals
import { ModalLoading } from '../ModalLoading'
import { ModalSuccess } from '../ModalSuccess'
import { DaoAPI } from '../../api/DaoAPI'
import { useNavigate } from 'react-router'
import { useEnvSmartContract } from '../../hooks/useSmartContract'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'inline-flex',
    flexWrap: 'nowrap',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      display: 'grid',
    },
  },

  progressSteps: {
    width: '40%',
    marginRight: '50px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginRight: '0px',
    },
  },
  progressContent: {
    width: '100%',
  },
  stepLabel: {
    flexDirection: 'row-reverse',
    height: '50px',
    border: '1px solid #F7F1FF',
    borderRadius: '9px',
    paddingLeft: '15px',
    '& .MuiStepLabel-iconContainer': {
      '& circle': { display: 'none' },
      '& text': { display: 'none' },
      paddingRight: 0,
      background: '#E3E2F0',
      borderRadius: '9px',
    },
    '& .MuiStepIcon-root': {
      padding: '13px',
    },
  },
  connectorLine: {
    display: 'none',
  },
  MuiStepContent: {
    border: '1px solid transparent',
    padding: 0,
    margin: 0,
  },
  stepperRoot: {
    padding: '0px',
  },
  completed: {
    '& path': { display: 'none' },
    backgroundImage: `url(${TickIcon})`,
    backgroundColor: '#8765F2',
    backgroundSize: '50%',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    color: '#8765F2',
    padding: '13px',
    border: '1px solid #F7F1FF',
    borderRadius: '9px',
  },
  active: {
    '& circle': { display: 'none' },
    '& text': { display: 'none' },
    backgroundImage: `url(${CircleIcon})`,
    backgroundColor: '#8765F2',
    backgroundSize: '50%',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    color: '#8765F2',
    padding: '13px',
    border: '1px solid #F7F1FF',
    borderRadius: '9px',
  },
  disabled: {
    '& circle': { display: 'none' },
    '& text': { display: 'none' },
    border: '1px solid #F7F1FF',
    borderRadius: '9px',
    padding: '13px',
    backgroundColor: '#F7F1FF',
  },
  progressTitle: {
    fontFamily: 'Poppins',
    fontSize: '13px',
    backgroundColor: '#FCFAFF',
    borderBottom: '1px solid rgba(214, 210, 242, 0.5)',
    fontWeight: 600,
    padding: 16,
  },
  progressCard: {
    border: '1px solid rgba(214, 210, 242, 0.5)',
    boxShadow: '0px 51px 89px -49px rgb(196 193 232 / 40%)',
    borderRadius: '18px',
  },
  labelContent: {
    display: 'flex',
    marginRight: '12px',
  },
  spacebetween: {
    marginRight: '30px',
    marginBottom: '0px',
    fontSize: '12px',
    fontFamily: 'Poppins',
    color: '#CAC0E8',
  },
  label: {
    fontFamily: 'Poppins',
    fontSize: '12px',
    marginBottom: '0px'
  },
  transparent: {
    background: 'transparent',
    padding: 0,
  },
  stepTitle: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '22px',
    lineHeight: '33px',
    color: '#2F2F4D',
  },
  modalLoadingBody: {
    textAlign: 'center',
  },
  labelHelper: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '11px',
    color: '#4C4C66',
    marginTop: '10px',
  },
}))

const steps = [
  {
    id: '01',
    label: 'Specify General info',
    title: 'Specify General info',
  },
  {
    id: '02',
    label: 'LP Agreement',
    title: 'LP Agreement',
  },
  {
    id: '03',
    label: 'Investment Manager(s) appointment',
    title: 'Investment Manager(s) appointment',
  },
  {
    id: '04',
    label: 'Controlling',
    title: 'Controlling',
  },
]

export const ProgressComponent = (props) => {
  const classes = useStyles()

  // WEB 3
  const { account, chainId } = useWeb3React()

  // Data from Dao creation form
  const [activeStep, setActiveStep] = useState(0)

  const navigate = useNavigate()

  const [stepperValues, setStepperValues] = useState(
    {
      ...props, 
      supportedDepositTokens: [],
      supportedTradingTokens: [],
      calculationToken: "USDC",
      hasWhitelist: 1,
      whiteListedAddresses: [],
      allowListedForInvestments: [],
      defiProtocols: [],
      isDepositLimitDisabled: false,
      isAllowlistedToDepositDisabled: false,
      isWithdrawalDisabled: true,
      isAllowedListForInvestmentsDisabled: false,
    })
    
  const [createdDao, setCreatedDao] = useState(false)
  const [isCreatingDao, setCreatingDao] = useState(false)

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  }

  const handleBack = ()=> {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }
  
  const closeDaoCreationModal = () => {
    navigate('/AllVaults')
    setCreatedDao(false)
  }
  
  const CreateDao = async () => {
    const daoData = { ...stepperValues }

    const { DaoRegistry } = useEnvSmartContract(chainId)

    if (!chainId){
      console.error('Wallet must be connected')
      return <Typography className={classes.progressTitle}>Connect Wallet</Typography>
    }

    
    setCreatingDao(true)
    try {
      const response = await DaoAPI.createDao(daoData, account, DaoRegistry, chainId)
      try {
        if (response) {
          setCreatingDao(false)
          setCreatedDao(true)
        }
      } catch (error) {
        setCreatingDao(false)
        setCreatedDao(false)
      }
    } catch (error) {
      setCreatingDao(false)
      setCreatedDao(false)
    }
  }

  return (
    <Grid className={classes.container}>
      <Grid item className={classes.progressSteps} md={6} lg={6}>
        <Card className={classes.progressCard}>
          <Typography className={classes.progressTitle}>Progress</Typography>
          <CardContent>
            <Stepper
              className={classes.stepperRoot}
              activeStep={activeStep}
              orientation="vertical"
              connector={ <StepConnector classes={{ line: classes.connectorLine,}} /> } >
              {
                steps.map((step) => (
                  <Step key={step.label}>
                    <StepLabel
                      className={classes.stepLabel}
                      StepIconProps={{
                        classes: {
                          completed: classes.completed,
                          active: classes.active,
                        },
                      }} >
                      <div className={classes.labelContent}>
                        <p className={classes.spacebetween}>{step.id}</p>
                        <p className={classes.label}>{step.label}</p>
                      </div>
                    </StepLabel>
                  </Step>
                ))
              }
            </Stepper>
          </CardContent>
        </Card>
      </Grid>

      <Grid className={classes.progressContent}>
        <Stepper
          activeStep={activeStep}
          orientation="vertical"
          className={classes.transparent}
          connector={ <StepConnector classes={{ line: classes.connectorLine }} /> } >
          {
            steps.map((step, index) => (
              <Step key={index}>
                <StepContent className={classes.MuiStepContent}>
                  <h1 className={classes.stepTitle}>{step.title}</h1>
                  <h2 className={classes.labelHelper}>*All fields are required</h2>
                  {(() => {
                    switch (index) {
                      case 0:
                        return <SpecifyGeneralInfo stepperValues={stepperValues} setStepperValues={setStepperValues} handleBack={handleBack} handleNext={handleNext}/>
                      case 1:
                        return <LpAgreement stepperValues={stepperValues} setStepperValues={setStepperValues} handleBack={handleBack} handleNext={handleNext}/>
                      case 2:
                        return <InvestManagerAppointement stepperValues={stepperValues} setStepperValues={setStepperValues} handleBack={handleBack} handleNext={handleNext}/>
                      case 3:
                        return <Controlling stepperValues={stepperValues} setStepperValues={setStepperValues} handleBack={handleBack} createDao={CreateDao}/>  
                      default:
                        return null
                    }
                  })()}
                </StepContent>
              </Step>
            ))
          }
        </Stepper>
        <ModalLoading open={isCreatingDao} onCancel={undefined} modalImg={DaoCreationImg} title={'DAO creation in progress'} subTitle={'Please be patient until DAO will be created'}></ModalLoading>
        <ModalSuccess open={createdDao} onCancel={closeDaoCreationModal} modalImg={DaoCreationImg} title={'DAO has been created'} subTitle={'You can start to explore new opportunities'}/>
      </Grid>
    </Grid>
  )
}
