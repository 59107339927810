import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { fetchPoolsRequest } from '../redux/actions/pools.actions'
import { useWeb3React } from '@web3-react/core'

export const usePoolsRequest = (limit, offset) => {
  const { account, connector } = useWeb3React()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(
      fetchPoolsRequest({
        userAddress: account,
        provider: connector,
        limit,
        offset,
      })
    )
  }, [dispatch, account, connector, limit, offset])
}
