import React, { useMemo } from 'react'
import { Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Modal } from '../Modal'
import { Button } from '../Button'
import { useWindowHeight } from '../../hooks'
import successIcon from '../../assets/icons/successIcon.svg'

const useStyles = makeStyles((theme) => ({
  root: {
    fontFamily: 'Poppins',
  },
  modalBodyClass: {
    maxWidth: 350,
    marginTop: 148,
    padding: 0,
    borderRadius: 18,
  },
  modalContent: {
    display: 'grid',
    width: '100%',
    justifyContent: 'center',
    padding: 16,
    '& span': {
      justifySelf: 'center',
      textAlign: 'center',
    },
  },
  title: {
    fontWeight: 500,
    fontSize: 21,
    lineHeight: '23.73px',
    letterSpacing: '0.01em',
    color: '#8765F2',
    width: '100%',
    margin: '9px 0 4px 0',
  },
  subTitle: {
    fontWeight: 500,
    fontSize: 14,
    color: '#4C4C66',
    width: '100%',
    margin: '9px 0 16px 0',
  },
  button: {
    padding: '9px 30px 9px 30px',
    fontSize: 16,
    lineHeight: '22px',
    width: '100%',
    margin: '0 16px 16px 16px',
    borderRadius: '7px',
    '@media screen and (max-width: 360px)': {
      width: '100%',
      '&:first-child': {
        margin: '0 0 12px 0',
      },
    },
  },
}))

export const ModalSuccess = ({
  onCancel,
  title,
  subTitle,
  modalImg,
  ...rest
}) => {
  const classes = useStyles()

  const windowHeight = useWindowHeight()

  const marginTop = useMemo(() => {
    return (windowHeight - 480) / 2
  }, [windowHeight])

  return (
    <Modal
      marginTop={marginTop}
      modalBodyClass={classes.modalBodyClass}
      {...rest}
    >
      <Grid container className={classes.root}>
        <img src={modalImg} alt="modalImg" />
        <div className={classes.modalContent}>
          <span className={classes.modalProggress}>
            <img src={successIcon} alt="success" />
          </span>
          <span className={classes.title}>{title}</span>
          <span className={classes.subTitle}>{subTitle}</span>
        </div>
      </Grid>
      <Grid container className={classes.buttons}>
        <Button type="primary" className={classes.button} onClick={onCancel}>
          Onwards!
        </Button>
      </Grid>
    </Modal>
  )
}
