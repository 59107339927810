import { poolsTypes } from '../types/'

const initialState = []

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case poolsTypes.FETCH_POOLS_SUCCESS:
    case poolsTypes.SET_POOLS:
      return action.payload.pools
    default:
      return state
  }
}
