import React from 'react'
import { Link } from 'react-router-dom'

import { routes } from '../../const/routes'

import logoSrc from '../../assets/icons/logo.svg'
import logoLightSrc from '../../assets/icons/logo-light.svg'

export const Logo = ({ className, type, ...rest }) => {
  return (
    <Link className="logo" to={routes.MAIN} {...rest}>
      {type === 'light' ? (
        <img className={className} src={logoLightSrc} alt="Logo" />
      ) : (
        <img className={className} src={logoSrc} alt="Logo" />
      )}
    </Link>
  )
}
