/* eslint-disable react-hooks/exhaustive-deps */
import { useNavigate } from 'react-router-dom';

// Helpers & Routes
import { moneyFormatter } from '../../../helpers/moneyFormatter';
import { routes, ID } from '../../../const/routes';

// Material Imports
import { Box } from '@material-ui/core';
import { Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { ChipLabel } from '../../../components/Chip';
import Linkify from 'react-linkify';
import { MAX_UINT } from '../../../const/ethConst';

// Chart
import { MiniChart } from '../../../components/Charts/MiniChart';
import { ChainIcon } from '../../../components/ChainIcon';

const useStyles = makeStyles((theme) => ({
  content: {
    margin: '100px 0 100px 0',
    display: 'flex',
    justifyContent: 'space-between',
    '@media screen and (max-width: 644px)': {
      margin: '50px 0 50px 0',
    },
    '@media screen and (max-width: 320px)': {
      margin: '36px 0 50px 0',
    },
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    display: 'inline-grid',
    fontSize: 22,
    fontWeight: 'bold',
    marginLeft: '25px',
    width: '100%',
    fontFamily: 'Poppins',
    [theme.breakpoints.down('xs')]:{
      fontSize:'19px',
      marginLeft:'15px'
    }
  },
  sectionFunds:{
    display: 'inline-flex',
    padding: 16,
    width: '100%',
  },
  '@media screen and (max-width: 1024px)': {
    sectionFunds: {
      display: 'flex',
      padding: '16px 0'
    },
  },
  primaryCard: {
    backgroundColor: '#FFFFFF',
    borderRadius: 12,
    width: '100%',
    height: '100%'
  }, 
  textSm: {
    fontSize: 12,
    fontWeight: 'bold',
    padding: '20px 30px',
    display: 'flex',
    justifyContent: 'flex-start',
    background:'#FCFAFF',
    borderBottom: '1px solid #EDEEFF',
  },
  text: {
    fontSize: 12,
    fontWeight: 'bold',
    padding: 30,
    display: 'flex',
    justifyContent: 'flex-start',
  },
  link: {
    display: 'flex',
    flexBasis: '140px',
    justifyContent: 'space-between',
    alignContent: 'center',
    alignItems:'center',
    justify:'center',
    '&:hover': {
      textDecoration: 'underline'
    }
  },
  '@media screen and (max-width: 425px)': {
    link: {
      margin: '34px 0 0 0'
    }
  },
  footerContent: {
    padding: '0 0 64px 0',
    '& > a': {
      fontWeight: 'bold',
      fontSize: 18,
      lineHeight: '22px',
      color: theme.palette.primary.main,
      textDecoration: 'none'
    },
    justifyContent: 'center'
  },
  '@media screen and (max-width: 320px)': {
    footerContent: {
      padding: '0 0 44px 0'
    }
  },
  '@media screen and (max-width: 644px)': {
    footerContent: {
      padding: '0 0 44px 0'
    }
  },
  textSmDisplay:{
    display: 'inline-grid',
    padding: 0,
    fontFamily: 'Poppins'
  },
  spanText:{
    fontSize: 24,
    fontWeight:'bold',
     [theme.breakpoints.down('sm')]: {
      fontSize: '19px'
    },
  },
  fontSize12:{
    fontSize: 10,
    fontFamily: 'Poppins',
    color: 'rgba(0, 0, 0, 0.54)'
  },
  descriptionTitle:{
    fontSize: 10,
    paddingBottom: 5,
    fontFamily: 'Poppins'
  },
  changeText:{
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0 30px'
  },
  progressPercentage:{
    color: '#8765F2',
    fontWeight:800,
    fontSize: 12,
    fontFamily:'Poppins'
  },
  progressTitle:{
    fontSize: 10,
    marginTop: 10,
    marginLeft: 15
  },
  link1:{
    textDecoration: "none",
    cursor:'pointer',
    width: '100%'
  },
  description:{
    padding:'20px 30px'
  },
  blockChainIconContent:{
    display: 'flex',
    justifyContent: 'flex-end',
    alignSelf: 'center',
    marginBottom: '6px'
  },
  blockChainImg:{
    width:'30px',
    height:'30px'
  },
  descriptionContent: {
    fontSize: 9,
    fontFamily: 'Poppins',
    fontWeight: 500,
    wordBreak: 'break-all',
    whiteSpace: 'pre-line'
  },
  separator: {
    border: '1px solid #EDEEFF',
    marginTop: 25,
    width: '86%'
  },
  logoImg:{
    objectFit: 'cover',
    width: '70px',
    height: '70px'
  },
}));


export const FundsGrid = ({ daos, fundsContainer, fundsCard }) => {
  const classes = useStyles();
  const navigate = useNavigate();

  return (
   <Container>
      <Grid container className={`${fundsContainer}`}>
        {daos?.pages.map((page) =>
            page.records?.map((item: any) => {
              return (
                <Grid item lg={4} md={6} sm={12} className={classes.sectionFunds} key={item.name}>
                  <div
                    className={`${classes.link1} ${fundsCard}`}>
                    <Card
                      className={classes.primaryCard}
                      variant="outlined">
                      <Box display="flex" className={classes.textSm} onClick={() => navigate(
                        {
                          pathname: routes.FUNDS.replace(ID, item.id),
                          search: `?chainId=${item.chainId}`,
                        })}>
                        <div>
                        <img src={item.logo} alt="cardLogo" className={classes.logoImg} />
                        </div>
                        <Typography variant="h4" component="h2" className={classes.title}>
                          {
                            item.name.length > 15 ? 
                            `${item.name.substring(0, 15)}...`
                              :
                            item.name
                          }
                          {
                            item.isPublic ? <ChipLabel type="public" label="Public"/>:<ChipLabel type="private" label="Private"/>
                          } 
                        </Typography>
                        <ChainIcon chainId={item.chainId} />
                      </Box>
                      <Box display="flex" className={classes.text} onClick={() => navigate(
                        {
                          pathname: routes.FUNDS.replace(ID, item.id),
                          search: `?chainId=${item.chainId}`,
                        })}>
                        <Container className={classes.textSmDisplay}>
                          <Typography className={classes.fontSize12} color="textSecondary">
                            Funding Target
                          </Typography>
                          <span className={classes.spanText}>
                            ${moneyFormatter(item?.maxCap)}
                          </span>
                        </Container>
                        <Container className={classes.textSmDisplay}>
                          <Typography
                            color="textSecondary"
                            className={classes.fontSize12}>
                            Deposit Range
                          </Typography>
                          {
                            item?.depositRange?.minimum === '0' && item?.depositRange?.maximum === MAX_UINT ?
                            <span className={classes.spanText}>Any</span>
                              :
                            <span className={classes.spanText}>
                            ${moneyFormatter(item?.depositRange?.minimum)} - ${moneyFormatter(item?.depositRange?.maximum)}
                          </span>
                          }
                        </Container>
                      </Box>

                      <MiniChart data={item.lpPrices} />                    

                      <div className={classes.changeText}>
                        <span className={classes.fontSize12}>
                          Change 24h:{' '}
                          {
                            item?.APies?.length > 0 && Number(item?.APies[item?.APies.length - 1].twentyFourHours) > 0 ?
                            <ChipLabel
                              size="small"
                              type="change24h"
                              label={`${item?.APies?.length > 0 ? Number(item?.APies[item?.APies.length - 1].twentyFourHours).toFixed(2) : '0'} %`}
                            ></ChipLabel> :
                            <ChipLabel
                              size="small"
                              type="totalChange"
                              label={`${item?.APies?.length > 0 ? Number(item?.APies[item?.APies.length - 1].twentyFourHours).toFixed(2) : '0'} %`}
                            ></ChipLabel>
                          }
                        </span>
                        <span className={classes.fontSize12}>
                          Total Change:{' '}
                          {
                            item?.APies?.length > 0 && Number(item?.APies[item?.APies.length - 1].total) > 0  ?
                            <ChipLabel
                              size="small"
                              type="change24h"
                              label={`${item?.APies?.length > 0 ? Number(item?.APies[item?.APies.length - 1].total).toFixed(2) : '0'} %`}
                            ></ChipLabel> :
                            <ChipLabel
                              size="small"
                              type="totalChange"
                              label={`${item?.APies?.length > 0 ? Number(item?.APies[item?.APies.length - 1].total).toFixed(2) : '0'} %`}
                            ></ChipLabel> 
                          }
                        </span>
                      </div>
                      <hr className={classes.separator}></hr>
                      <div className={classes.description}>
                        <Typography
                          className={classes.descriptionTitle}
                          color="textSecondary">
                          Description
                        </Typography>
                        <Typography
                          className={classes.descriptionContent}>
                          <Linkify>
                          {
                              item.description.length > 50 ?
                                `${item.description.substring(0, 160)}...`
                                :
                                item.description
                            }
                          </Linkify>
                        </Typography>
                      </div>
                    </Card>
                  </div>
                </Grid>
              )
            })
          )
        }
      </Grid>
      </Container>
  )
}


