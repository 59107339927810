import { makeStyles } from '@material-ui/core/styles';

import BannerImg from '../../src/assets/images/banner.jpg'


const useStyles = makeStyles((theme) => ({
  banner: {
    maxWidth: '1200px',
    height: '388px',
    borderRadius: '28px',
    margin: '60px auto 58px',
    paddingLeft: '80px',
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'Poppins',
    fontSize: '50px',
    fontWeight: 600,
    lineHeight: '75px',
    textAlign: 'left',
    color: '#FFFFFF',
    boxSizing: 'border-box',
    backgroundImage: `url(${BannerImg})`,
    backgroundSize: 'cover',
    [theme.breakpoints.down('xs')]: {
      backgroundImage: 'none',
      fontSize: '35px',
      color: '#000',
      paddingLeft: 10,
      paddingRight: 10,
      margin: '20px auto 20px',
      justifyContent: 'center'
    },
  }
}))

export const Banner = () => {
  const classes = useStyles();

  return (
    <div className={classes.banner}>
      Get smart tokens <br />
      that work for you
    </div>
  )
}