import React, { useCallback, useEffect, useState } from 'react'
import { Grid, Modal, Checkbox, Link } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { getCookie, setCookie } from '../../helpers/cookie'
import { DO_THE_MOON_COOKIE } from '../../const/cookie'
import { Button } from '../'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: theme.palette.thirtySeventh.main,
    '& > div': {
      '&:first-child': {
        backgroundColor: 'transparent !important',
      },
    },
  },
  content: {
    outline: 'none',
    flexDirection: 'column',
    flexWrap: 'wrap',
    alignItems: 'center',
    backgroundColor: 'white',
    maxWidth: 658,
    height: 'fit-content',
    borderRadius: 16,
    margin: 'auto 20px auto 20px',
    padding: 60,
    '@media screen and (max-width: 680px)': {
      padding: '60px 33px 60px 33px',
    },
    '@media screen and (max-width: 400px)': {
      padding: '30px 20px 30px 20px',
    },
  },
  title: {
    margin: '0 0 40px 0',
    fontSize: 32,
    lineHeight: '38px',
    fontFamily: theme.fontFamilies.fourth,
    fontWeight: 400,
    color: theme.palette.primary.main,
    '@media screen and (max-width: 400px)': {
      margin: '0 0 28px 0',
      fontSize: 30,
      lineHeight: '36px',
    },
  },
  list: {
    margin: 0,
    padding: 0,
    listStyle: 'none',
    '& > li': {
      display: 'flex',
      alignItems: 'flex-start',
      margin: '0 0 26px 0',
      '@media screen and (max-width: 400px)': {
        margin: '0 0 20px 0',
      },
    },
  },
  checkbox: {
    padding: 0,
    margin: '0 16px 0 0',
  },
  description: {
    margin: 0,
    fontWeight: 500,
    fontSize: 16,
    lineHeight: '150%',
    color: theme.palette.nineteenth.main,
  },
  button: {
    width: '100%',
    height: 48,
    fontWeight: 'bold',
    fontSize: 16,
    lineHeight: '22px',
    margin: '2px 0 0 0',
    '@media screen and (max-width: 400px)': {
      margin: 0,
    },
  },
}))

export const TermsAndConditions = () => {
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const [privacyPolicyChecked, setPrivacyPolicyChecked] = useState(false)
  const [defiChecked, setDefiChecked] = useState(false)

  useEffect(() => {
    if (!getCookie(DO_THE_MOON_COOKIE)) {
      setOpen(true)
    }
  }, [])

  const onAcceptConditionsClick = useCallback(() => {
    setCookie(DO_THE_MOON_COOKIE, DO_THE_MOON_COOKIE)
    setOpen(false)
  }, [])

  return (
    <Modal open={open} className={classes.root}>
      <Grid container className={classes.content}>
        <h3 className={classes.title}>I hereby declare the following:</h3>
        <ul className={classes.list}>
          <li>
            <Checkbox
              className={classes.checkbox}
              checked={privacyPolicyChecked}
              onChange={(e) => setPrivacyPolicyChecked(e.target.checked)}
            />
            <p className={classes.description}>
              I have read the{' '}
              <Link
                href="https://docs.google.com/document/d/1y3eJWgEgBpANXz6jwGhXPj12IAJVTyDEXA3Gw4Om1Jg/edit#heading=h.39mi8lzb6hz4"
                target="_blank"
                className={classes.link}
              >
                terms and conditions
              </Link>{' '}
              and{' '}
              <Link
                href="https://docs.google.com/document/d/1cVbsj-E0mMKqLFnwXFrEtUxR8Xjlr6xAzybbBzBAxgM/edit#"
                target="_blank"
              >
                privacy policy
              </Link>{' '}
              and accept the same thereof.
            </p>
          </li>
          <li>
            <Checkbox
              className={classes.checkbox}
              checked={defiChecked}
              onChange={(e) => setDefiChecked(e.target.checked)}
            />
            <p className={classes.description}>
              I understand DeFi is a new phenomenon, and understand and
              undertake any technological and market risks associated with it.
            </p>
          </li>
        </ul>
        <Button
          type="primary"
          className={classes.button}
          onClick={onAcceptConditionsClick}
          disabled={!(privacyPolicyChecked && defiChecked)}
        >
          Accept Conditions
        </Button>
      </Grid>
    </Modal>
  )
}
