import { useState, useEffect } from 'react'
import { useLocation } from 'react-router'

import web3 from 'web3'

// Material-UI imports
import {
  Card,
  CardContent,
  Grid,
  TextField,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import { DaoAPI } from '../../../api/DaoAPI'
import { decodeDaoConfigParams } from '../../../helpers/abiDecoder'
import { NumericFormat } from 'react-number-format'
import { useWeb3React } from '@web3-react/core'


const useStyle = makeStyles((theme) => ({
  divider: {
    borderBottom: '1px solid rgb(224 224 224)',
  },
  cardTitle: {
    fontSize: 13,
    fontWeight: 600,
    fontStyle: 'normal',
    lineHeight: 4,
    margin: 0,
    paddingLeft: '16px',
  },
  cardContent: {
    borderRadius: '18px 0px 0px 0px',
    border: '1px solid rgba(214, 210, 242, 0.5)',
    boxShadow: '0px 51px 89px -49px rgba(196, 193, 232, 0.4)',
    '& .MuiCardContent-root': {
      padding: 0
    }
  },
  contentHelper: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '-25px',
    padding: '0 14px'
  },
  labelHelper: {
    fontWeight: 500,
    fontSize: '9px',
    color: '#958BAE',
  },
  contentHelper2: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    marginBottom: '-25px',
    padding: '0 14px'
  },
  boldText: {
    letterSpacing: '0.4px',
    fontSize: '12px',
    color: '#4C4C66',
  },
  spanTextColor: {
    color: '#BC65F2',
    fontSize: '12px',
    textAlign: 'right',
  },
  questionMark: {
    width: '20px'
  },
  link: {
    color: '#BC65F2'
  },
  inputContent: {
    "& .MuiInputBase-input": {
      padding: '10.5px 14px',
      background: '#FBFBFF',
    },
    "& .MuiInputBase-root": {
      borderRadius: '9px'
    },
    display: 'grid',
    fontSize: '12px',
    fontWeight: 500,
    padding: '15px 10px',
  },
  input: {
    marginTop: '15px',
  },
  exampleContent: {
    fontSize: '8px',
    height: '100%',
    border: '1px solid rgba(214, 210, 242, 0.5)',
    borderTop: 'none',
    borderRadius: '2px 0px 18px 18px',
    background: 'white',
    width: '100%',
    paddingTop: '15px',
  },
  exampleText: {
    lineHeight: '22px',
    marginTop: '0px',
    color: '#958BAE',
    margin: '8px'
  },
  exampleTitle: {
    color: '#BC65F2',
    margin: '5px 8px'
  },
  alignItems: {
    alignItems: 'flex-end'
  },
  cardContentSecondary: {
    borderRadius: '0px 18px  0px 0px',
    border: '1px solid rgba(214, 210, 242, 0.5)',
    boxShadow: '0px 51px 89px -49px rgba(196, 193, 232, 0.4)',
    width: '100%',
  },
}))

export const VoteForChangeManagerFundFee = (props) => {
  const classes = useStyle()

  const { hexToNumber } = web3.utils
  const { connector } = useWeb3React()

  const location = useLocation();
  const daoAddress = location.state.state.data.subgraph.id

  const [daoFees, setDaoFees] = useState<any>({})
  const [proposedFundManagerFee, setProposedFundManagerFee] = useState<any>('')

  useEffect(() => {
    const decodeFundManagerFee = async () => {
      try {
        setDaoFees(await DaoAPI.getDaoFees(daoAddress, connector))
        const result = decodeDaoConfigParams(props?.action)
        setProposedFundManagerFee(hexToNumber(result.inputs[0]._hex) / 100)
      } catch (e) {
        console.log('Error message:', e)
      }
    }
    decodeFundManagerFee()
      .catch(console.error)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const limitedValue = (inputObj) => {
    const { value } = inputObj
    if (value <= 100) return true
    return false
  }

  return (
    <>
      <Grid container style={{ marginBottom: '16px' }}>
        <Grid item lg={6} md={6} sm={6} xs={12}>
          <Card className={classes.cardContent}>
            <div className={classes.divider}>
              <p className={classes.cardTitle}>Proposed fund management fee </p>
            </div>
            <CardContent style={{ padding: '16px' }}>
              <Grid container className={classes.alignItems}>
                <Grid item lg={12} md={8} sm={8} xs={12}>
                  <div className={classes.contentHelper2}>
                    <div className={classes.labelHelper}>
                      <b className={classes.boldText}>Fund management fee,</b> %
                      from AUM in LP tokens for 365 days
                    </div>
                  </div>
                </Grid>
                <Grid item lg={12} md={8} sm={8} xs={12}>
                  <div className={classes.inputContent}>
                    <NumericFormat
                      disabled
                      value={proposedFundManagerFee}
                      customInput={TextField}
                      variant="outlined"
                      className={classes.input}
                      placeholder="5%"
                      suffix={'%'}
                      valueIsNumericString={true}
                      allowNegative={false}
                      isAllowed={limitedValue}
                    />
                  </div>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        <Grid item lg={6} md={6} sm={6} xs={12}>
          <Card className={classes.cardContentSecondary}>
            <div className={classes.divider}>
              <p className={classes.cardTitle}>Current fund management fee </p>
            </div>
            <CardContent style={{ padding: '16px' }}>
              <Grid container className={classes.alignItems}>
                <Grid item lg={12} md={8} sm={8} xs={12}>
                  <div className={classes.contentHelper2}>
                    <div className={classes.labelHelper}>
                      <b className={classes.boldText}>Fund management fee,</b> %
                      from AUM in LP tokens for 365 days
                    </div>
                  </div>
                </Grid>
                <Grid item lg={12} md={8} sm={8} xs={12}>
                  <div className={classes.inputContent}>
                    <NumericFormat
                      disabled
                      value={daoFees?.fundManagementFee / 100}
                      customInput={TextField}
                      variant="outlined"
                      className={classes.input}
                      placeholder="5%"
                      suffix={'%'}
                      valueIsNumericString={true}
                      allowNegative={false}
                      isAllowed={limitedValue}
                    />
                  </div>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item lg={12} md={8} sm={8} xs={12}>
          <div className={classes.exampleContent}>
            <p className={classes.exampleTitle}>
              Fee is calculated as a share of average AUM over the 1 year period
              and taken in LP tokens on withdrawal
            </p>
            <p className={classes.exampleText}>
              Example: If average 1 year AUM is $1 000 000, user has 10% of LP
              tokens in average and stays in the fund for 100 days, user will
              pay $1 000 000 * 10% * 5% * (100 / 365) of avg. AUM
            </p>
          </div>
        </Grid>
      </Grid>
    </>
  )
}
