import { useEffect, useMemo, useState } from 'react'
import { useEnvNetowrks } from '../../hooks/useChain'
import { MetaMask } from '@web3-react/metamask'
import { WalletConnect } from '@web3-react/walletconnect-v2'

// Mui Imports
import {
  FormGroup,
  Checkbox,
  FormControlLabel,
  Grid,
  Modal,
  List,
  ListItem,
  ListItemIcon,
  Button,
  useMediaQuery,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import { useWindowHeight } from '../../hooks/useWindowHeight'

import MetaMaskIcon from '../../assets/icons/metaMask.svg'
import WalletConnectIcon from '../../assets/icons/walletconnect-icon.svg'

import './ModalWallet.css'
import { useWeb3React } from '@web3-react/core'

const useStyles = makeStyles((theme) => ({
  modalBody: {
    padding: '10px 10px 10px 10px',
    backgroundColor: theme.palette.common.white,
    display: 'grid',
    alignItems: 'center',
    maxWidth: 500,
    margin: '0 auto',
    borderRadius: 12,
    '&:focus': {
      outline: 'none',
    },
  },
  title: {
    fontWeight: 500,
    fontSize: 21,
    lineHeight: '23.73px',
    letterSpacing: '0.01em',
    color: '#8765F2',
    width: '100%',
    margin: '9px 0 4px 0',
    padding: '0 30px'
  },
  card: {
    flexDirection: 'column',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 12,
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: 'rgba(195, 195, 195, 0.14)',
    },
  },
  subTitle: {
    fontSize: 18,
    color: 'rgb(169, 169, 188)',
  },
  walletIcon: {
    height: 45,
    width: 45,
  },
  divider: {
    margin: '5px 0 5px 0',
    height: 1,
    width: '100%',
    backgroundColor: 'rgba(195, 195, 195, 0.14)',
  },
  networkDisplay: {
    display: 'flex',
    justifyContent: 'start',
    textAlign: 'center',
    marginLeft: '25px',
    '& .button': {
      width: '100px',
    },
  },
  walletDisplay: {
    display: 'flex',
    justifyContent: 'start',
    textAlign: 'center',
    marginLeft: '25px',
    '& .button': {
      width: '100px',
    },
  },
  list: {
    '& .MuiListItem-root': {
      display: 'block',
      padding: '13px 10px',
    },
    '& .MuiFormControlLabel-root': {
      marginLeft: '35px',
    },
  },
  listNumber: {
    color: '#8765F2',
    fontSize: ' 18px',
    background: '#EEECEC',
    borderRadius: '9px',
    padding: '4px 11px',
    marginRight: '20px',
  },
  listTitle: {
    color: '#000000',
    fontWeight: 400,
    fontSize: '18px',
    lineHeight: '27px',
    marginBottom: '20px',
    '& a': {
      color: '#BC65F2',
      textDecoration: 'underline',
    },
  },
  connectButton: {
    borderRadius: '7px',
    fontFamily: 'Poppins',
    fontSize: '14px',
    fontWeight: 700,
    background: '#8765F2',
    color: '#FFFFFF',
    textAlign: 'center',
  },
  btnDisabled: {
    background: '#E2DDEDB2',
    color: '#6F5E9A',
  },
  scrollModal: {
    overflow: 'scroll',
  },
}))

export const ModalWallet = ({ title, onClose, onConnectorChange, props }) => {
  const classes = useStyles()
  const networks = useEnvNetowrks()
  const windowHeight = useWindowHeight()

  const { chainId, connector } = useWeb3React()

  const [networkParams, setNetworkParams] = useState({})

  const [termsAndconditions, setTermAndConditions] = useState(false)
  const [walletName, setWalletName] = useState('')
  const [wallets, setWallets] = useState([
    { name: 'MetaMask', icon: MetaMaskIcon, isDisabled: false },
    { name: 'WalletConnect', icon: WalletConnectIcon, isDisabled: false },
  ])
  const isMobileSize = useMediaQuery((theme) => theme.breakpoints.down('xs'))

  useEffect(() => {
    if (isMobileSize) {
      setWallets([
        { name: 'WalletConnect', icon: WalletConnectIcon, isDisabled: false },
      ])
    } else {
      setWallets([
        { name: 'MetaMask', icon: MetaMaskIcon, isDisabled: false },
        { name: 'WalletConnect', icon: WalletConnectIcon, isDisabled: false },
      ])
    }
  }, [isMobileSize])

  useEffect(() => {
    if (chainId) {
      const network = networks.find(
        (n) => parseInt(n.params.chainId) === chainId
      )
      if (network) {
        setTermAndConditions(true)
        setNetworkParams(network.params)
      }
    } else {
      setTermAndConditions(false)
      setNetworkParams({})
    }
  }, [chainId, networks])

  useEffect(() => {
    if (connector) {
      setWalletName(getConnectorName(connector))
    } else {
      setWalletName('')
    }
  }, [connector])

  const getConnectorName = (connector) => {
    if (connector instanceof MetaMask) return 'MetaMask'
    if (connector instanceof WalletConnect) return 'WalletConnect'
    return 'Unknown'
  }

  const modalBodyStyle = useMemo(() => {
    return {
      marginTop: (windowHeight - 470) / 2,
    }
  }, [windowHeight])

  const handleNetworkChange = (event) => {
    setNetworkParams(JSON.parse(event.target.value))
  }

  const handleWalletChange = (event) => {
    setWalletName(event.target.value)
  }

  return (
    <Modal open={true} onClose={onClose} className={classes.scrollModal}>
      <Grid container className={classes.modalBody} style={modalBodyStyle}>
        {title ? <span className={classes.title}>{title}</span> : ''}
        <List className={classes.list}>
          <ListItem>
            <span className={classes.listNumber}>1</span>
            <ListItemIcon>
              <span className={classes.listTitle}>
                Accept {''}
                <a
                  href="https://docs.google.com/document/d/1y3eJWgEgBpANXz6jwGhXPj12IAJVTyDEXA3Gw4Om1Jg/edit#heading=h.39mi8lzb6hz4"
                  target="_blank"
                  rel="noreferrer"
                >
                  Terms of Service
                </a>{' '}
                and {''}
                <a
                  href="https://docs.google.com/document/d/1cVbsj-E0mMKqLFnwXFrEtUxR8Xjlr6xAzybbBzBAxgM/edit#"
                  target="_blank"
                  rel="noreferrer"
                >
                  Privacy Policy
                </a>
              </span>
            </ListItemIcon>
            <div>
              <Grid>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={(e) => setTermAndConditions(e.target.checked)}
                        checked={termsAndconditions}
                      />
                    }
                    label="I read and accept"
                  />
                </FormGroup>
              </Grid>
            </div>
          </ListItem>
          <ListItem>
            <span className={classes.listNumber}>2</span>
            <ListItemIcon>
              <span className={classes.listTitle}>Choose network</span>
            </ListItemIcon>
            <div>
              <Grid className={classes.networkDisplay}>
                {networks.map((network) => (
                  <div className="button" key={network.name}>
                    <input
                      type="radio"
                      name="networkName"
                      onChange={handleNetworkChange}
                      value={JSON.stringify(network.params)}
                      id={network.name}
                      disabled={network.isDisabled}
                      checked={
                        networkParams?.chainId === network.params?.chainId
                      }
                    />
                    <label className="label" htmlFor={network.name}>
                      <img className="img" src={network.icon} alt="network" />
                    </label>
                    <p>{network.name}</p>
                  </div>
                ))}
              </Grid>
            </div>
          </ListItem>

          <ListItem>
            <span className={classes.listNumber}>3</span>
            <ListItemIcon>
              <span className={classes.listTitle}>Choose wallet</span>
            </ListItemIcon>
            <div>
              <Grid className={classes.walletDisplay}>
                {wallets.map((wallet) => (
                  <div className="button" key={wallet.name}>
                    <input
                      type="radio"
                      name="walletName"
                      onChange={handleWalletChange}
                      value={wallet.name}
                      id={wallet.name}
                      disabled={wallet.isDisabled}
                      checked={wallet.name === walletName}
                    />
                    <label className="label" htmlFor={wallet.name}>
                      <img className="img" src={wallet.icon} alt="wallet" />
                    </label>
                    <p>{wallet.name}</p>
                  </div>
                ))}
              </Grid>
            </div>
          </ListItem>
        </List>
        <Button
          className={classes.connectButton}
          disabled={
            !(termsAndconditions && networkParams.chainId && walletName)
          }
          classes={{ disabled: classes.btnDisabled }}
          onClick={() => onConnectorChange(walletName, networkParams)}
        >
          {' '}
          Connect Wallet{' '}
        </Button>
      </Grid>
    </Modal>
  )
}
