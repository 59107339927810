export const formatSeconds = (seconds) => {
  seconds = parseInt(seconds)
  const d = Math.floor(seconds / (3600 * 24))
  const h = Math.floor((seconds % (3600 * 24)) / 3600)
  const m = Math.floor((seconds % 3600) / 60)
  const s = Math.floor(seconds % 60)

  return `${d}d ${h}h ${m}m ${s}s`
}

export const formatMilliseconds = (milliseconds) => {
  milliseconds = parseInt(milliseconds)
  const d = Math.floor(milliseconds / 1000 / (3600 * 24))
  const h = Math.floor(((milliseconds / 1000) % (3600 * 24)) / 3600)
  const m = Math.floor(((milliseconds / 1000) % 3600) / 60)
  const s = Math.floor((milliseconds / 1000) % 60)

  return `${d}d ${h}h ${m}m ${s}s`
}

export const formatSecondsToDays = (seconds) => {
  seconds = parseInt(seconds)
  let d = Math.floor(seconds / (3600 * 24))
  const h = Math.floor((seconds % (3600 * 24)) / 3600)
  return h >= 12 ? d + 1 : d
}

export const formatMillisecondsToDays = (milliseconds) => {
  return formatSecondsToDays(Math.floor(milliseconds / 1000))
}
