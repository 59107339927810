/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from 'react'

// Material-UI imports
import {
  Card,
  CardContent,
  Grid,
  Badge,
  TextField,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import questionMark from '../../../../assets/icons/questionMark.svg'
import { NumericFormat } from 'react-number-format'
import { useLocation } from 'react-router'
import { DaoAPI } from '../../../../api/DaoAPI'
import BigNumber from 'bignumber.js'
import { useWeb3React } from '@web3-react/core'

const useStyle = makeStyles((theme) => ({
    divider:{
        borderBottom: '1px solid rgb(224 224 224)'
      },
      cardTitle:{
        fontSize: 13,
        fontWeight: 600,
        fontStyle: 'normal',
        lineHeight: 4,
        margin: 0,
        paddingLeft: '16px'
      },
      cardContent:{
    borderRadius: '18px',
    border: '1px solid rgba(214, 210, 242, 0.5)',
    boxShadow: '0px 51px 89px -49px rgba(196, 193, 232, 0.4)',
    '& .MuiCardContent-root':{
      padding: 0
    }
  },
  contentHelper:{
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '-25px',
    padding: '0 14px'
  },
  labelHelper: {
    fontWeight: 500,
    fontSize: '9px',
    color: '#958BAE',
  },
  contentHelper2:{
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    marginBottom: '-25px',
    padding: '0 14px',
    justifyContent:'space-between',
    [theme.breakpoints.down('xs')]:{
      display:'block'
     }
  },
  boldText: {
    letterSpacing: '0.4px',
    fontSize: '12px',
    color: '#4C4C66',
  },
  spanTextColor:{
    color: '#BC65F2',
    fontSize: '12px',
    textAlign: 'right',
},
questionMark:{
    width: '20px'
  },
  link:{
    color:'#BC65F2'
},
inputContent: {
    "& .MuiInputBase-input":{
       padding:'10.5px 14px',
    background: '#FBFBFF',
    },
    "& .MuiInputBase-root":{
      borderRadius: '9px'
    },
    display: 'grid',
    fontSize: '12px',
    fontWeight: 500,
    padding: '15px 10px',
  },
  input: {
    marginTop: '15px',
  },
  exampleContent:{
    fontSize: '8px',
    height: '100%',
  },
  exampleText:{
    lineHeight: '22px',
    marginTop: '0px',
    color: '#958BAE',
    margin: '8px'
  },
  exampleTitle:{
      color: '#BC65F2',
      margin:'5px 8px'
  },
}))

export const ChangeRageQuitFine = (props:any) => {
  const {setIsProposalValid, setProposalError, setProposalMsg} = props;
  
  const classes = useStyle()
  const location = useLocation();
  const { connector } = useWeb3React();

  const [daoConfig, setDaoConfig] = useState<any>({})
  const [daoFees, setDaoFees] = useState<any>({})
  const [rageQuitFine, setRageQuitFine] = useState<any>({
    rageFine: undefined,
    func: 'changerageQuitFee'
  })

  let paths = location.pathname.split('/');
  let daoId = paths[paths.length - 1];

  const inputValidation =
    rageQuitFine?.rageFine !== undefined &&
    !isNaN(rageQuitFine?.rageFine);

  useEffect(() => {
    const decodeDaoFees = async () => {
      try {
        setDaoFees(await DaoAPI.getDaoFees(daoId, connector))
        setDaoConfig(await DaoAPI.getConfiguration(daoId, connector))
      } catch (e) {
        console.log('Error message:', e)
      }
    }
    decodeDaoFees()
      .catch(console.error)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  
  useEffect(()=>{
    setIsProposalValid(inputValidation)
    if(parseInt(daoFees?.rageQuitFee) === rageQuitFine?.rageFine){
      setProposalError(true);
      setProposalMsg('You cannot create a proposal with the same rage quit fee that is already present.')
    }else if(daoConfig?.withdrawlWindow === '0'){
      setProposalError(true);
      setProposalMsg("You can't create a proposal because the withdrawal window is disabled.")
    } else {
      setProposalError(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[inputValidation, rageQuitFine])

  const limitedValue = (inputObj) => {
    const { value } = inputObj
    if (value <= 100) return true
    return false
  }

  useEffect(() => {
    props.func(rageQuitFine)
  })
  
  return (
      <Card className={classes.cardContent}>
        <div className={classes.divider}>
          <p className={classes.cardTitle}>Change rage quit fine</p>
        </div>
        <CardContent style={{ padding: '16px' }}>
          <Grid container>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <div className={classes.contentHelper2}>
                <div className={classes.labelHelper}>
                  <b className={classes.boldText}>Rage quit fine,</b>  % of withdrawn assets
                </div>
                <span className={classes.spanTextColor}>
                  <Badge overlap="rectangular">
                    <img className={classes.questionMark} src={questionMark} alt="questionMark" />
                  </Badge>{' '}
                  <a href="" className={classes.link}> Learn more withdrawal windows</a>
                </span>
              </div>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <div className={classes.inputContent}>
                <NumericFormat
                  customInput={TextField}
                  variant="outlined"
                  className={classes.input}
                  placeholder={`${isNaN(daoFees?.rageQuitFee) ? 0 : new BigNumber(daoFees?.rageQuitFee).dividedBy(100)}%`}
                  onChange={(e) => setRageQuitFine({ ...rageQuitFine, rageFine:  (parseInt(e.target.value.replace(/[%\s]/g, '')) * 100)})}
                  suffix={'%'}
                  valueIsNumericString={true}
                  allowNegative = {false}
                  isAllowed={limitedValue}
                />
              </div>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <div className={classes.exampleContent}>
                <p className={classes.exampleTitle}>
                  Fine for removing capital outside of withdrawal window
                </p>
                <p className={classes.exampleText}>
                  Example: To align the interests of the manager and the investors, 
                  there are 2 periods following each other: 
                  “Withdrawal period” and “Freeze period”, 
                  which are constantly following each other. 
                  If the user wants to burn his LP tokens during the “Freeze period”, 
                  he needs to pay “Rage Quit Fine”. If the user burns his LP tokens in the “Withdrawal period”, 
                  a rage quit fine will not be applied.
                </p>
              </div>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
  )
}
