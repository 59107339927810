import { combineReducers } from 'redux'
import { reducer as pools } from './pools.reducer'
import { reducer as timers } from './timers.reducer'
import { reducer as loading } from './loading.reducer'
import { reducer as user } from './user.reducer'
import { walletReducer } from '../wallet/wallet.reducer'
import daos from './dao.reducer'

export const reducers = combineReducers({
  pools,
  timers,
  loading,
  user,
  daos,
  wallet: walletReducer,
})
