import { Container, Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import backgroundImg from '../../assets/images/cabinetBackgroun.svg'
import AllocationTable from '../../components/CabinetComponents/AllocationsTable'
import PortfolioTable from '../../components/CabinetComponents/PortfolioTable'
const useStyles = makeStyles((theme) => ({
  backgroundColor: {
    display: 'flex',
    backgroundImage: `url(${backgroundImg})`,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
  },
  container: {
    paddingTop: 40,
    paddingBottom: 50,
    [theme.breakpoints.up('xl')]: {
      maxWidth: 1440,
    },
  },
  title: {
    fontSize: '50px',
    fontWeight: 600,
    lineHeight: '64.5px',
    color: '#351F8E',
  },
  infoText: {
    fontWeight: 300,
    fontSize: '29px',
    lineHeight: '43.5px',
    color: '#351F8E',
  },
  labelText: {
    fontWeight: 600,
    fontSize: '29px',
    lineHeight: '43.5px',
    color: '#8765F2',
  },
}))

export const CabinetPage = (props) => {
  const classes = useStyles(props)

  return (
    <div className={classes.backgroundColor}>
      <Container className={classes.container}>
        <h1 className={classes.title}>Investor Grade and Eligibility</h1>
        {/* <span className={classes.infoText}>You have <label className={classes.labelText}>0 ASPIS Tokens</label></span> */}
        <Grid container spacing={4}>
          {/* <Grid item lg={12} md={12} sm={12} xs={12}>
                  <ProgressBar />
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Disclaimer/>
                </Grid> */}
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <PortfolioTable />
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <AllocationTable />
          </Grid>
        </Grid>
      </Container>
    </div>
  )
}
