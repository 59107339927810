import { useEffect, useRef } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { createChart } from 'lightweight-charts'
import _ from 'lodash'
import { getPriceConf } from '../../helpers/getPriceConf'

const useStyles = makeStyles({
  chartWrapper: {
    width: 318,
    height: 120,
    margin: '0 auto 20px',
  },
})

export const MiniChart = (props) => {
  const classes = useStyles(props)
  const chartRef = useRef<any>()

  useEffect(() => {
    if (!chartRef.current || !props.data) return

    const chartOptions = {
      overlay: false,
      layout: {
        textColor: '#4C4C66',
        background: {
          type: 'solid',
          color: 'transparent',
        },
      },
      rightPriceScale: {
        visible: false,
      },
      grid: {
        vertLines: {
          visible: false,
        },
        horzLines: {
          visible: false,
        },
      },
      timeScale: {
        visible: false,
      },
      crosshair: {
        mode: 2,
      },
    }
    // @ts-ignore
    const chart = createChart(chartRef.current, chartOptions)

    const areaSeries = chart.addAreaSeries({
      lineColor: '#BC65F2',
      priceLineVisible: false,
      topColor: 'transparent',
      bottomColor: 'transparent',
    })

    let lpPrices = props.data
    lpPrices = _.sortBy(lpPrices, ['time'])

    // Set Data
    const data = lpPrices.map((el) => ({
      time: new Date(el.time).valueOf() / 1000,
      value: Number(el.price),
    }))

    areaSeries.setData(_.uniqBy(data, 'time'))

    // Set visible range
    const from = Date.now() / 1000 - 60 * 60 * 24 * 7
    const to = Date.now() / 1000

    if (!lpPrices[lpPrices.length - 1]) return

    const sorted = _.sortBy(lpPrices, ['price'])
    const priceConf = getPriceConf(
      sorted[0].price,
      sorted[sorted.length - 1].price,
    )

    if (priceConf) {
      areaSeries.applyOptions({
        priceFormat: {
          type: 'price',
          ...priceConf,
        },
      })
    }

    if (!chart) return
    // @ts-ignore
    chart.timeScale().setVisibleRange({ from, to })

    return () => {
      chart.remove()
    }
  }, [chartRef, props.data])

  return <div className={classes.chartWrapper} ref={chartRef} />
}
