// Material UI imports
import {
  Container,
  Card,
  CardContent,
  Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import voteIcon from '../../assets/icons/voteIcon.svg'
import { convert } from '../../helpers/currencyHelper'
import { Button } from '../Button'

const useStyles = makeStyles({
  card: {
    background: '#FFFFFF',
    border: '1px solid rgba(214, 210, 242, 0.5)',
    boxShadow: '0px 51px 89px -49px rgba(196, 193, 232, 0.4)',
    borderRadius: '18px',
  },
  container: {
    borderBottom: '1px solid rgb(224 224 224)',
  },
  cardTitle: {
    fontSize: 13,
    fontWeight: 600,
    fontStyle: 'normal',
    fontFamily: 'Poppins',
    lineHeight: 4,
  },
  votesContent: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '15px 5px',
    borderBottom: '1px solid rgb(224 224 224)',
  },
  text: {
    display: 'flex',
    color: '#2F2F4D',
    fontSize: '15px',
    fontWeight: 600,
  },
  icon: {
    marginRight: '13px',
  },
  buttonContent: {
    display: 'flex',
    justifyContent: 'center',
    margin: '10px auto',
    width:'30%'
  },
})

export const Votes = (props: any) => {
  const classes = useStyles()
  
  const { voters } = props

  return (
      <Card className={classes.card}>
        <Container className={classes.container}>
          <Typography className={classes.cardTitle}>Votes</Typography>
        </Container>
        <CardContent>
          {
            voters?.length !== 0 ?
              voters?.map((item: any) => (
                <div className={classes.votesContent} key={item.voter.id}>
                  <div className={classes.text}>
                    <img className={classes.icon} src={voteIcon} alt='icon' />
                    {item.voter.id.slice(0, 5) + '...' + item.voter.id.slice(37, 42)}
                  </div>
                  <div>
                    {
                      item.vote === 'Yea' ?
                        'for'
                        :
                        item.vote === 'Nay' ?
                          'against'
                          :
                          'abstain'
                    }
                  </div>
                  <div className={classes.text}>
                    {props?.token} {convert(item.weight, props?.dao?.token?.decimals).toFixed(2)}
                  </div>
                </div>
              ))
              :
              <span>None of the members has voted</span>
          }
        </CardContent>
        <div className={classes.buttonContent}>
          <Button type="secondaryOutlined">Show more</Button>
        </div>
      </Card>
  )
}
