import { useEffect, useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'

// Redux
import { useSelector } from 'react-redux'
import { userInfoSelector } from '../../redux/selectors'

// Mui Imports
import { makeStyles } from '@material-ui/core/styles'
import { Grid } from '@material-ui/core'

// Assets
import AboutAspis from '../../assets/icons/AboutASPIS.svg'
// import Cabinet from '../../assets/icons/Cabinet.svg'
import CreateDao from '../../assets/icons/CreateDAO.svg'
import DaoPad from '../../assets/icons/DAOPad.svg'
// import Exchange from '../../assets/icons/Exchange.svg'
import menuIcon from '../../assets/icons/menuIcon.svg'

// Constants
import { routes } from '../../const/routes'
import { UserRoles } from '../../const/userRoles'

// Web 3 and wallet imports
import { useWeb3React } from '@web3-react/core'
import { WalletConnectorNames } from '../../const/walletConnectorNames'
import {
  Content,
  Logo,
  MobileMenu,
  UnlockWalletButton,
  Account,
  ModalWallet,
} from '../index'
import {
  getInjectedConnector,
  getWalletConnectConnector,
  setCurrentConnectionName,
} from '../../helpers/wallet'
import useModals from '../../hooks/useModals'

const useStyles = makeStyles((theme) => ({
  content: {
    height: 58,
    background: 'white',
    justifyContent: 'space-between',
    alignItems: 'center',
    maxWidth: '100%',
    padding: '0 8px',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'row-reverse',
    },
  },
  nav: {
    '& > *': {
      color: '#6F6C99',
      fontSize: 14,
      fontWeight: 400,
      letterSpacing: '0.5px',
      margin: '0 30px 0 0',
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
        cursor: 'pointer',
      },
    },
  },
  '@media screen and (max-width: 910px)': {
    nav: {
      '& > *': {
        margin: '0 5px 0 0',
      },
    },
  },
  menuDesktop: {
    display: 'flex',
    justifyContent: 'center',
    alignSelf: 'baseline',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  menuMobile: {
    display: 'none !important',
    [theme.breakpoints.down('sm')]: {
      display: 'flex !important',
    },
  },
  menuIcon: {
    color: theme.palette.primary.main,
    top: '10px',
    position: 'relative',
  },
  button: {
    width: 160,
    borderRadius: '7px !important',
    fontFamily: 'Poppins',
    '@media screen and (max-width: 979px)': {
      width: '40px',
    },
  },
  account: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    fontSize: 14,
    lineHeight: '22px',
    padding: '9px 16px 9px 16px',
    borderRadius: 100,
  },
  logo: {
    width: '87px',
    height: '34px',
  },
  walletBtnDisplay: {
    display: 'flex',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'flex-start',
    },
  },
  active: {
    color: 'green',
  },
  inactive: {
    color: 'red',
  },
}))

export const Header = (props) => {
  const classes = useStyles(props)

  const { account } = useWeb3React()
  const { role } = useSelector(userInfoSelector)

  const [accountWasClicked, setAccountWasClicked] = useState(false)
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false)

  const { openModal, closeModal } = useModals()

  const navigate = useNavigate()

  const onMenuClick = () => {
    setIsMobileMenuOpen((prevState) => !prevState)
  }

  const onConnectorChange = async (connectorName, networkParams) => {
    let connector
    if (connectorName === WalletConnectorNames.Metamask) {
      connector = getInjectedConnector()
    } else if (connectorName === WalletConnectorNames.WalletConnect) {
      connector = getWalletConnectConnector()
    }

    connector.activate(parseInt(networkParams.chainId))
    setCurrentConnectionName(connectorName)

    setAccountWasClicked(true)

    closeModal()
  }

  useEffect(() => {
    if (accountWasClicked && role !== UserRoles.Unlogged) {
      setAccountWasClicked(false)
    }
  }, [accountWasClicked, role, navigate])

  const openWalletModal = () => {
    openModal(
      <ModalWallet onClose={closeModal} onConnectorChange={onConnectorChange} />
    )
  }

  return (
    <>
      <div className={classes.root}>
        <Content className={classes.content}>
          <Grid className={classes.menuMobile}>
            <img src={menuIcon} onClick={onMenuClick} alt="menuIcon" />
          </Grid>
          <Grid item lg={1} md={1} sm={6}>
            <Logo className={classes.logo} />
          </Grid>
          <Grid item lg={9} className={classes.menuDesktop}>
            <nav className={classes.nav}>
              <NavLink
                style={({ isActive }) => {
                  return isActive
                    ? { color: '#8765f2', textDecoration: 'underline' }
                    : {}
                }}
                to={routes.MAIN}
              >
                <img className={classes.menuIcon} src={DaoPad} alt="daoPad" />
                All Vaults
              </NavLink>
              {/* <Tooltip title="Cooming Soon">
                <NavLink to="#">
                  <img
                    className={classes.menuIcon}
                    src={Cabinet}
                    alt="cabinet"
                  />
                  Cabinet
                </NavLink>
              </Tooltip> */}
              <NavLink
                style={({ isActive }) => {
                  return isActive
                    ? { color: '#8765f2', textDecoration: 'underline' }
                    : {}
                }}
                to={routes.CREATE_DAO}
              >
                <img
                  className={classes.menuIcon}
                  src={CreateDao}
                  alt="createDao"
                />
                Create Vault
              </NavLink>
              {/* <Tooltip title="Cooming Soon">
                <NavLink to={'#'}>
                  <img
                    className={classes.menuIcon}
                    src={Exchange}
                    alt="exchange"
                  />
                  Exchange
                </NavLink>
              </Tooltip> */}
              <a
                href={'https://info.aspis.finance/introduction/what-is-aspis'}
                target="_blank"
                rel="noreferrer"
              >
                <img
                  className={classes.menuIcon}
                  src={AboutAspis}
                  alt="aboutAspis"
                />
                Docs
              </a>
            </nav>
          </Grid>
          <MobileMenu
            isOpen={isMobileMenuOpen}
            onClose={onMenuClick}
            role={role}
            openWalletModal={openWalletModal}
          />
          <Grid
            item
            lg={1}
            md={1}
            sm={5}
            xs={1}
            className={classes.walletBtnDisplay}
          >
            {!account ? (
              <UnlockWalletButton
                type="success"
                className={classes.button}
                onConnectorChange={onConnectorChange}
                text={'Unlock Wallet'}
                openWalletModal={openWalletModal}
                closeWalletModal={closeModal}
              />
            ) : (
              <Account
                className={classes.account}
                openWalletModal={openWalletModal}
                closeWalletModal={closeModal}
              />
            )}
          </Grid>
        </Content>
      </div>
    </>
  )
}
