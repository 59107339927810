import { useEffect } from 'react'
import { WalletConnectorNames } from '../const/walletConnectorNames'
import {
  getCurrentConnectionName,
  getInjectedConnector,
  getWalletConnectConnector,
} from '../helpers/wallet'

export const useWalletConnectAttempt = () => {
  useEffect(() => {
    const cachedConnectionName = getCurrentConnectionName()

    let walletConnector
    if (cachedConnectionName === WalletConnectorNames.Metamask) {
      walletConnector = getInjectedConnector()
    } else if (cachedConnectionName === WalletConnectorNames.WalletConnect) {
      walletConnector = getWalletConnectConnector()
    }
    if (walletConnector) {
      walletConnector.connectEagerly().catch((error) => {
        console.debug('Failed to connect eagerly to walletconnect', error)
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}
