import React, { useEffect, useState } from 'react'
import { Card, CardContent, Grid, makeStyles } from '@material-ui/core'
import { CopyToClipboardBtn } from '../../CopyToClipboard'
import { DaoAPI } from '../../../api/DaoAPI'
// import { decodeDaoConfigParams } from '../../../helpers/abiDecoder'
import { useLocation } from 'react-router'
import { decodeDaoConfigParams } from '../../../helpers/abiDecoder'
import { useWeb3React } from '@web3-react/core'

const useStyle = makeStyles((theme) => ({
  divider: {
    borderBottom: '1px solid rgb(224 224 224)',
  },
  cardTitle: {
    fontSize: 13,
    fontWeight: 600,
    fontStyle: 'normal',
    lineHeight: 4,
    margin: 0,
    paddingLeft: '16px',
    background: 'white'
  },
  cardContent: {
    width: '100%',
    borderRadius: '18px 0 0 18px',
    boxShadow: '0px 51px 89px -49px rgba(196, 193, 232, 0.4)',
    '& .MuiCardContent-root': {
      borderRight: '1px solid #f4e0fa'
    }
  },
  cardContentSecondary: {
    width: '100%',
    borderRadius: '0 18px 18px 0',
    background: '#F7F6FC',
    boxShadow: '0px 51px 89px -49px rgba(196, 193, 232, 0.4)',
  },
  cardInsideContent: {
    padding: '16px',
    height: '161px',
    overflow: 'auto'
  },
  fundManagerChip: {
    color: '#8765F2',
    background: 'rgba(137, 99, 244, 0.07)',
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: 15,
    borderRadius: '86px',
    alignItems: 'center',
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    padding: '0px 16px',
    '& .MuiTypography-body1': {
      fontFamily: 'Poppins',
      fontSize: '15px',
      fontWeight: 500,
      letterSpacing: 0
    }
  },
  loadBtnContent: {
    display: 'flex',
    justifyContent: 'center',
    padding: '16px',
    borderTop: '1px solid rgba(214, 210, 242, 0.5);',
  },
  loadBtn: {
    background: 'rgba(232, 99, 244, 0.07)',
    border: '1px solid #BC65F2',
    borderRadius: '5px',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '21px',
    textAlign: 'center',
    color: '#BC65F2',
  },
  content: {
    '& .MuiFormControlLabel-root': {
      marginRight: 0
    },
    display: 'inline-flex',
    marginBottom: '15px',
  },
  cardDisplay: {
    display: 'grid',
  }
}))

export const VoteForRemoveAllowlistedWalletsToDeposit = (props) => {
  const classes = useStyle()

  const location = useLocation();
  const { connector } = useWeb3React()
  const daoAddress = location.state.state.data.subgraph.id

  const [currentAddresses, setCurrentAddresses] = useState<any>([])
  const [proposedAddress, setProposedAddress] = useState<any>([])

  useEffect(() => {
    const decodeAddresses = async () => {
      try {
        setCurrentAddresses(await DaoAPI.getWhitelistedUsers(daoAddress, connector))
        const result = decodeDaoConfigParams(props?.action)
        setProposedAddress(result?.inputs[0])
      } catch (e) {
        console.log('Error message:', e)
      }
    }
    decodeAddresses()
      .catch(console.error)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Grid container>
        <Grid item lg={6} md={6} sm={6} xs={12}>
          <Card className={classes.cardContent}>
            <div className={classes.divider}>
              <p className={classes.cardTitle}>Remove allowlisted addresses for deposit</p>
            </div>
            <CardContent className={classes.cardInsideContent}>
              <Grid container>
                <Grid item lg={3} className={classes.cardDisplay}>
                  {
                    proposedAddress.map((item) => (
                      <div className={classes.content}>
                        <div className={classes.fundManagerChip}>{item.slice(0, 11).toLowerCase() + '...' + item.slice(37, 42).toLowerCase()}
                          {/* <img src={item.icon} alt="icon" />
                            <p>{item.name}</p> */}
                        </div>
                        <CopyToClipboardBtn props={item} />
                      </div>
                    ))
                  }
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item lg={6} md={6} sm={6} xs={12}>
          <Card className={classes.cardContentSecondary}>
            <div className={classes.divider}>
              <p className={classes.cardTitle}>Allowlisted addresses for deposit</p>
            </div>
            <CardContent className={classes.cardInsideContent}>
              <Grid container>
                <Grid item lg={3} className={classes.cardDisplay}>
                  {
                    currentAddresses.map((item) => (
                      <div className={classes.content}>
                        <div className={classes.fundManagerChip}>{item.slice(0, 11).toLowerCase() + '...' + item.slice(37, 42).toLowerCase()}
                          {/* <img src={item.icon} alt="icon" />
                            <p>{item.name}</p> */}
                        </div>
                        <CopyToClipboardBtn props={item} />
                      </div>
                    ))
                  }
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  )
}
