import { userTypes } from '../types'
import { UserRoles } from '../../const/userRoles'

const initialState = {
  role: UserRoles.Initial,
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case userTypes.FETCH_USER_INFO_SUCCESS:
      return action.payload.userInfo
    case userTypes.CLEAR_USER_INFO:
      return { ...initialState, role: UserRoles.Unlogged }
    default:
      return state
  }
}
