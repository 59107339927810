/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router'

import { routes, ID } from '../../../const/routes'
import { VotingTypes } from '../../../const/votingTypes'
import moment from 'moment'

// Material UI imports
import {
  Grid,
  Chip,
  Container,
  CircularProgress,
  Tooltip,
  withStyles,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

// Components
import { CopyToClipboardBtn } from '../../CopyToClipboard'
import { CurrentResults } from '../CurrentResult'
import { NavigationBar } from '../../NavigationBar'
import { VoteForAllowlistedAddresses } from '../VotingComponents/VoteForAllowListedAddresses'
import { VoteForAppointCurrentFundManagers } from '../VotingComponents/VoteForChangeCurrentFundManager'
import { VoteForChangeDepositLimit } from '../VotingComponents/VoteForChangeDepositLimit'
import { VoteForChangeEntranceFee } from '../VotingComponents/VoteForChangeEntranceFee'
import { VoteForChangeManagerFundFee } from '../VotingComponents/VoteForChangeManagementFee'
import { VoteForChangePerformanceFee } from '../VotingComponents/VoteForChangePerformanceFee'
import { VoteForChangeVotingParameters } from '../VotingComponents/VoteForChangeVotingParameters'
import { VoteForChangeRageQuitFine } from '../VotingComponents/VoteForRageQuitFine'
import { VoteForChangeSpendingLimits } from '../VotingComponents/VoteForChangeSpendingLimits'
import { VoteForChangeTokenForDeposit } from '../VotingComponents/VoteForChangeTokenForDeposit'
import { VoteForAddAllowlistedWalletsToDeposit } from '../VotingComponents/VoteForAddAllowlistedWalletsToDeposit'
import { VoteForRemoveAllowlistedWalletsToDeposit } from '../VotingComponents/VoteForRemoveAllowlistedWalletsToDeposit'
import { VoteForChangeTokenLockUpPeriod } from '../VotingComponents/VoteForChangeTokenLockupPeriod'
import { VoteForChangeWithdrawalWindows } from '../VotingComponents/VoteForChangeWithdrawalWindows'
import { VoteForDeleteAllowlistedAddresses } from '../VotingComponents/VoteForDeleteAllowlistedAddresses'
import { Votes } from '../Votes'
import { VotingButtons } from '../VotingButtons'

// Image imports
import backgroundImg from '../../../assets/images/proposalBackground.svg'
import { BackgroundImage } from '../../BackgroundImage'
// import { Button } from '../../Button'
// import { useWeb3React } from '@web3-react/core'
import { useSingleDao } from '../../../hooks/useDaos'
import { VoteForCreateAdHocTransaction } from '../VotingComponents/VoteForCreateAdHocTransaction'
import { VoteForRemoveTokenForDeposit } from '../VotingComponents/VoteForRemoveTokenForDeposit'
import { VoteForChangeTokenForTrading } from '../VotingComponents/VoteForChangeTokenForTrading'
import { VoteForRemoveTokenForTrading } from '../VotingComponents/VoteForRemoveTokenForTrading'
import { VoteForChangeFundraisingPhase } from '../VotingComponents/VoteForChangeFundraisingPhase'
import { useWeb3React } from '@web3-react/core'

const useStyles = makeStyles((theme) => ({
  //Input needs to be hided for the moment
  // searchInputContent:{
  //   display: 'flex',
  //   alignItems: 'center',
  //   justifyContent: 'flex-end'
  // },
  // searchInput:{
  //   width: '65%',
  //   '& .MuiOutlinedInput-input':{
  //     padding: '14.5px 14px'
  //   },
  //   [theme.breakpoints.down('xs')]:{
  //     width: '100%',
  //     marginBottom: '25px'
  //   },
  //   '& .MuiOutlinedInput-root':{
  //     background: 'white',
  //     borderRadius: '100px',
  //   }
  // },
  titleContent: {
    display: 'flex'
  },
  title: {
    fontSize: '50px',
    color: '#351F8E',
    marginLeft: '25px',
    [theme.breakpoints.down('sm')]:{
      fontSize: '25px',
      marginLeft: '15px',
      wordBreak: 'break-all'
    }
  },
  badge: {
    marginBottom: '30px',
    marginLeft: '10px'
  },
  infoTitle: {
    fontSize: '10px',
    color: '#4C4C66'
  },
  infoValue: {
    fontSize: '13px',
    color: '#44435E'
  },
  fundManagerChip: {
    color: '#8765F2',
    background: 'rgba(137, 99, 244, 0.07)',
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: 13,
    padding: 15,
    '& .MuiChip-label': {
      paddingLeft: 0,
      paddingRight: 0
    }
  },
  chipContent: {
    padding: '4px',
    background: '#FDFBFF',
    border: '1px solid #EEE7F8',
    borderRadius: '9px'
  },
  content: {
    margin: '16px 16px 16px 0px'
  },
  descriptionTitle: {
    color: '#44435E',
    fontSize: '20px',
    fontWeight: 600,
    lineHeight: '43.4px'
  },
  descriptionContent: {
    fontSize: 13,
    fontWeight: 400,
    color: '#2F2F4D',
    lineHeight: '23.99px'
  },
  buttonPrimary: {
    background: 'rgba(232, 99, 244, 0.07)',
    border: '1px solid #BC65F2',
    borderRadius: '86px',
    fontWeight: 'bold',
    fontFamily: 'Poppins',
    margin: '5px 5px 0px 0px',
    color: '#BC65F2',
    padding: '20px'
  },
  buttonContent: {
    display: 'flex',
    justifyContent: 'center'
  },
  logo: {
    [theme.breakpoints.down('xs')]:{
      width: '27px'
    } 
  },
  addressContent:{
    display: 'flex',
    alignItems: 'center'
  },
  daoLogo:{
    width: '75px',
    objectFit: 'contain'
  },
    tokenBadge:{
      color: 'white',
      height: '100%',
      padding: '1px 10px',
      fontSize: '13px',
      background: '#8765F2',
      fontWeight: 500,
      borderRadius: '57px',
    },
})
)

const TooltipMessage = withStyles({
  tooltip: {
    color: "red",
    backgroundColor: "white",
    padding:'13px',
    fontFamily:'Poppins',
    fontSize:'11px'
  }
})(Tooltip);

export const VoteForProposal = (props) => {

  const { chainId } = useWeb3React()
  
  const classes = useStyles();
  const location = useLocation();
  const pathname = window.location.pathname;

  const [daoData, setDaoData] = useState<any>(location.state);
  const [votingType, setVotingType] = useState<any>()
 
  let paths = location.pathname.split('/');
  let proposalId = paths[paths.length - 1];
  let daoId = paths[paths.length - 2];
  
  const daoFromState = useSingleDao(daoId, chainId);

  const proposal = daoFromState.data?.subgraph?.proposals.find((x: any) => x.voteId === proposalId);
  
  useEffect(() => {
    displayVotingType()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [daoData, location.state, proposal?.dao?.id])

  const displayVotingType = () => {
    // eslint-disable-next-line array-callback-return
    VotingTypes.some(type => {
      if(proposal?.actions[0]?.data.includes(type.code)) {
        setVotingType(type.name)
      }
    })
  }

  return (
    <Container>
      <BackgroundImage imageUrl={backgroundImg} props={undefined} />
      <Grid container spacing={2}>
        <Grid item lg={6} md={12}>
          <NavigationBar link={routes.FUNDS.replace(ID, proposal?.dao?.id)} pathName={pathname} />
        </Grid>
        <Grid item lg={12} md={12} sm={12}>
          <div className={classes.titleContent}>
              <img className={classes.daoLogo} src={daoData?.state?.img} alt='daoLogo' />
            <h1 className={classes.title}>{proposal?.dao?.name}</h1>
            <span className={classes.tokenBadge}>
              {proposal?.dao?.token?.symbol}
            </span>

          </div>
        </Grid>
        <Grid item lg={6} xs={12}>
          <div>
            <Grid container>
              <Grid item lg={4} xs={5} className={classes.content}>
                <p className={classes.infoTitle}>Author</p>
                <div className={classes.addressContent}>
                  <Chip
                    className={classes.fundManagerChip}
                    label={proposal?.creator?.slice(0, 5) + '...' + proposal?.creator?.slice(36, 41)} />
                  <CopyToClipboardBtn props={proposal?.creator} />
                </div>
              </Grid>
              <Grid item lg={3} xs={3} className={classes.content}>
                <p className={classes.infoTitle}>Proposal type</p>
                <b className={classes.infoValue}>Simple Voting</b>
              </Grid>
              <Grid item lg={3} xs={2} className={classes.content}>
                <p className={classes.infoTitle}>Proposal ID</p>
                <b className={classes.infoValue}>{proposal?.voteId}</b>
              </Grid>
              <Grid item lg={5} md={5} xs={5} className={classes.content}>
                <p className={classes.infoTitle}>Start Date</p>
                <b className={classes.infoValue}>
                  {moment.unix(proposal?.startDate).utc().format('MMMM D, YYYY, h:mm A z')}
                </b>
              </Grid>
              <Grid item lg={5} md={5} xs={5} className={classes.content}>
                <p className={classes.infoTitle}>End Date</p>
                <b className={classes.infoValue}>
                  {moment.unix(proposal?.endDate).utc().format('MMMM D, YYYY, h:mm A z')}
                </b>
              </Grid>
            </Grid>
          </div>
        </Grid>
        <Grid item lg={6} xs={12}>
          <CurrentResults proposal={proposal} dao={daoFromState.data?.subgraph} />
        </Grid>
        <Grid item lg={6} xs={12}>
          <Grid item lg={12} xs={12}>
            <p className={classes.infoTitle}>Proposal Details</p>
            {
              !proposal?.details ?
                <CircularProgress />
                :
                <>
                  <h1 className={classes.descriptionTitle}>
                    {proposal?.details?.title}
                  </h1>
                  <p className={classes.descriptionContent}>
                    {proposal?.details?.description}
                  </p>
                  <div className={classes.buttonContent}>
                    {/* <Button className={classes.buttonPrimary}>Show more</Button> */}
                  </div>
                </>
            }
          </Grid>
          <Grid item lg={12} xs={12}>
            {
              location?.state?.lpBalance > 0 ?
                <Grid item lg={12} xs={12}>
                  <VotingButtons
                    votingId={proposalId}
                    daoId={daoFromState.data?.subgraph?.id}
                    proposal={proposal}
                  />
                </Grid>
                :
                null
            }
          </Grid>
        </Grid>
        <Grid item lg={6} style={{ display: "grid", gap: "16px", marginBottom:'40px' }}>
          <Grid item lg={12} xs={12}>
            {
              (() => {
                switch (votingType) {
                  case 'addToTrustedProtocols':
                    return <VoteForAllowlistedAddresses action={proposal?.actions[0]?.data} />
                  case 'updateManager':
                    return <VoteForAppointCurrentFundManagers action={proposal?.actions[0]?.data} />
                  case 'setDepositLimits':
                    return <VoteForChangeDepositLimit action={proposal?.actions[0]?.data} />
                  case 'setEntranceFee':
                    return <VoteForChangeEntranceFee action={proposal?.actions[0]?.data} />
                  case 'setFundManagementFee':
                    return <VoteForChangeManagerFundFee action={proposal?.actions[0]?.data} />
                  case 'setPerformanceFee':
                    return <VoteForChangePerformanceFee action={proposal?.actions[0]?.data} />
                  case 'setRageQuitFee':
                    return <VoteForChangeRageQuitFine action={proposal?.actions[0]?.data} />
                  case 'setSpendingLimit':
                    return <VoteForChangeSpendingLimits action={proposal?.actions[0]?.data} />
                  case 'setTokensForDepositing':
                    return <VoteForChangeTokenForDeposit action={proposal?.actions[0]?.data} chainId={chainId} />
                  case 'removeTokensForDepositing':
                    return <VoteForRemoveTokenForDeposit action={proposal?.actions[0]?.data} chainId={chainId} />
                  case 'setTokensForTrading':
                    return <VoteForChangeTokenForTrading action={proposal?.actions[0]?.data} chainId={chainId} />
                  case 'removeTokensForTrading':
                    return <VoteForRemoveTokenForTrading action={proposal?.actions[0]?.data} chainId={chainId} />
                  case 'addWhitelistedUsers':
                    return <VoteForAddAllowlistedWalletsToDeposit action={proposal?.actions[0]?.data} />
                  case 'removeWhitelistedUsers':
                    return <VoteForRemoveAllowlistedWalletsToDeposit action={proposal?.actions[0]?.data} chainId={chainId} />
                  case 'setLockLimit':
                    return <VoteForChangeTokenLockUpPeriod action={proposal?.actions[0]?.data} />
                  case 'setWithdrawlWindows':
                    return <VoteForChangeWithdrawalWindows action={proposal?.actions[0]?.data} />
                  case 'removeFromTrustedProtocols':
                    return <VoteForDeleteAllowlistedAddresses action={proposal?.actions[0]?.data} />
                  case 'setVotingParams':
                    return <VoteForChangeVotingParameters action={proposal?.actions[0]?.data} />
                  case 'setDirectTransferParams':
                    return <VoteForCreateAdHocTransaction action={proposal?.actions[0]?.data} />
                  case 'setFundraisingStartTimeAndFinishTime':
                    return <VoteForChangeFundraisingPhase action={proposal?.actions[0]?.data} />
                  default:
                    return null
                }
              })()
            }
          </Grid>
          <Grid item lg={12} xs={12}>
            <Votes
              dao={daoData?.state?.data?.subgraph}
              voters={proposal?.voters}
              token={proposal?.dao?.token?.symbol} />
          </Grid>
        </Grid>
      </Grid>
    </Container>
  )
}
