// Components
// import { SectionStats } from '../../components'
import { RecentFunds } from '../../components/RecentFunds'
import { Banner } from '../../components/Banner'
import backgroundImage from '../../assets/images/backgroundImage.svg'
import { BackgroundImage } from '../../components/BackgroundImage/BackgroundImage'

export const PageMain = () => {
  // const pools = usePools()

  // const upcomingAndClosedPools = useMemo(() => {
  //   return pools.filter(
  //     (pool) =>
  //       getPoolStatus(pool) === PoolStatuses.Upcoming ||
  //       getPoolStatus(pool) === PoolStatuses.Closed
  //   )
  // }, [pools])

  return (
    <>
      <BackgroundImage imageUrl={backgroundImage} />
      <Banner />
      {/* <SectionStats /> */}
      {/* <SectionFunds type={'incomiong'} /> */}
      <RecentFunds />
      {/* <SectionPools type={PoolStatuses.Closed} /> */}
      {/* <SectionForm
        className={
          upcomingAndClosedPools.length === 0 ? classes.withoutPools : ''
        }
      /> */}
    </>
  )
}
