export const moneyFormatter = (num) => {
  if (num >= 1000000000) {
    return (num / 1000000000).toFixed(1).replace(/\.0$/, '') + 'B'
  }
  // if (num >= 1000000) {
  //     return (num / 1000000).toFixed(1).replace(/\.0$/, '') + ' mln';
  // }
  // if (num >= 1000) {
  //     return (num / 1000).toFixed(1).replace(/\.0$/, '') + 'K';
  // }
  return num
}
