import { useCallback, useMemo } from 'react'
import { Button } from '../Button'
import UnlockWalletWhite from '../../assets/icons/UnlockWalletWhite.svg'

export const UnlockWalletButton = ({
  type,
  className,
  onConnectorChange,
  openWalletModal,
  closeWalletModal,
  text,
}) => {
  const isMetaMaskAvailable = useMemo(
    () => typeof window.ethereum !== 'undefined',
    []
  )

  const width = window.innerWidth < 979

  const onUnlockWalletClick = useCallback(() => {
    if (!isMetaMaskAvailable) {
      openWalletModal()
    } else {
      openWalletModal()
    }
  }, [isMetaMaskAvailable, openWalletModal])

  return (
    <>
      {!width ? (
        <Button
          id={'wallet-connect-button'}
          className={className}
          type={type === 'secondary' ? 'primary' : 'primary'}
          onClick={onUnlockWalletClick}
        >
          {text}
        </Button>
      ) : (
        <Button
          id={'wallet-connect-button'}
          className={className}
          type={type === 'secondary' ? 'primary' : 'primary'}
          onClick={onUnlockWalletClick}
        >
          <img src={UnlockWalletWhite} alt="unlockWallet" />
        </Button>
      )}
    </>
  )
}
