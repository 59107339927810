import Web3 from "web3"
import { AbiItem } from "web3-utils"
import { DAO, AspisConfiguration } from "../abi"
import { mainnetNetworks } from "../const/networks"



export const aspisConfiguration = async (daoAddress, library) => {

  let web3
  if (library.provider) {
    web3 = new Web3(library.provider)
  } else {
    web3 = new Web3(mainnetNetworks[0].params.rpcUrls[0])
  }
  const daoInstance = new web3.eth.Contract(
    DAO as AbiItem[],
    daoAddress
  )
  const configurationAddress = await daoInstance.methods.configuration().call()
  return new web3.eth.Contract(
    AspisConfiguration as AbiItem[],
    configurationAddress
  )
}