import {
  INITIALIZE_TIMERS,
  REMOVE_TIMERS,
  REMOVE_ALL_TIMERS,
  START_TO_UPDATE_TIMERS,
  UPDATE_TIMERS,
  SET_TIMERS,
  STOP_TO_UPDATE_TIMERS,
} from '../types/timers.types'

export const initializeTimers = ({ timers }) => {
  return {
    type: INITIALIZE_TIMERS,
    payload: {
      timers,
    },
  }
}

export const startToUpdateTimers = () => {
  return {
    type: START_TO_UPDATE_TIMERS,
  }
}

export const stopToUpdateTimers = () => {
  return {
    type: STOP_TO_UPDATE_TIMERS,
  }
}

export const updateTimers = ({ timers }) => {
  return {
    type: UPDATE_TIMERS,
    payload: {
      timers,
    },
  }
}

export const removeTimers = ({ poolsIds }) => {
  return {
    type: REMOVE_TIMERS,
    payload: {
      poolsIds,
    },
  }
}

export const removeAllTimers = () => {
  return {
    type: REMOVE_ALL_TIMERS,
  }
}

export const setTimers = ({ timers }) => {
  return {
    type: SET_TIMERS,
    payload: {
      timers,
    },
  }
}
