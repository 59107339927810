/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router'

// Custom Hooks
import { useSingleDao } from '../../hooks/useDaos'

// Material UI
import { Container, Card, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

// WEB 3
import { DaoAPI } from '../../api/DaoAPI'
import { useWeb3React } from '@web3-react/core'

// Components
import { BackgroundImage } from '../../components/BackgroundImage'
import { DescriptionCard } from '../../components/descriptionCard'
import { FundManager } from '../../components/FundManagers'
import { ProposalCard } from '../../components/votings/proposals/ProposalCard'
import { SingleSectionFunds } from '../../components/SingeSectionFunds'
import { Button } from '../../components/Button'
import SingleFundTable from '../../components/SingeFundTable'
import TransactionHistoryTable from '../../components/transactionHistoryTable'
import { ModalLoading } from '../../components/ModalLoading'
import { ModalSuccess } from '../../components/ModalSuccess'
import { TransactionHash } from '../../components/ExplorerLinks/ExplorerLinks'
import BigNumber from 'bignumber.js'

//Assets
import singlePageBackground from '../../assets/images/singePageBackground.svg'
import burnLPModalImg from '../../assets/images/burnLPmodal.svg'
import arrow from '../../assets/icons/arrow.png'

import { routes, ID } from '../../const/routes'
import {
  ButtonsLoader,
  FundManagerLoader,
  PortfolioLoader,
} from '../../components/Loaders'
import { FundCardLoader } from '../../components/Loaders'
import { useSelector } from 'react-redux'
import Swal from 'sweetalert2'
import {
  defaultChainId,
  getNetworkConfig,
  isChainSupported,
} from '../../const/networks'
import { supportedFundsList } from '../../const/aspisSupportedFundsList'
import { useSearchParams } from 'react-router-dom'

const useStyles = makeStyles({
  container: {
    paddingTop: 100,
    paddingBottom: 50,
    display: 'flex',
    '@media screen and (max-width: 700px)': {
      paddingTop: 35,
    },
  },
  inlineMargin: {
    marginLeft: 0,
  },
  daoFundSingePage: {
    justifyContent: 'center',
    height: '100%',
  },
  spacing: {
    padding: '16px 0',
  },
  '@media screen and (max-width: 1000px)': {
    container: {
      display: 'block',
    },
  },
  '@media screen and (max-width: 1295px)': {
    daoFundSingePage: {
      marginRight: 15,
    },
  },
  votingsContent: {
    padding: 0,
    borderTop: '1px solid #E8E4F4',
    marginBottom: '30px',
    '& .MuiGrid-root': {
      padding: '6px',
    },
  },
  proposalsContent: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  proposalsButton: {
    color: '#BC65F2',
    width: '160px',
    border: '1px solid #BC65F2',
    margin: '0 0 20px 0',
    display: 'flex',
    padding: '8px 20px 8px 20px',
    background: 'rgba(232, 99, 244, 0.07)',
    alignItems: 'center',
    fontFamily: 'Poppins, sans-serif',
    borderRadius: '25px',
    justifyContent: 'space-between',
    textDecoration: 'none',
    cursor: 'pointer',
  },
  cardContent: {
    padding: '25px',
  },
  centerContent: {
    justifyContent: 'center',
  },
  loaderContent: {
    textAlign: 'center',
    padding: '0 25px',
  },
  fundManagerContainer: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    paddingLeft: 24,
    paddingRight: 24,
  },
  fundManagerTitle: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: 13,
    lineHeight: 4,
  },
})

export const DaoFundSingePage = (props) => {
  const location = useLocation()
  const [searchParams] = useSearchParams();
  const navigate = useNavigate()
  const { account, connector, isActive } = useWeb3React()

  const [assets, setAssets] = useState([])
  const [claimDetails, setClaimDetails] = useState()

  const [manager, setManager] = useState('')
  const [supply, setTotalSupply] = useState(null)
  const [supportedTokens, setSupportedTokens] = useState([])
  const [lpTokenBalance, setLpTokenBalance] = useState(0)
  const [requiredTokenBalance, setRequiredTokenBalance] = useState(0)

  const [claiming, setClaiming] = useState(false)
  const [canClaimModal, setCanClaimModal] = useState(false)
  const [successClaim, setSuccessClaim] = useState(false)
  const [open, setOpen] = useState(false)

  let paths = location.pathname.split('/')
  let chainId = searchParams.get('chainId')
  let daoId = paths[paths.length - 1]


  useSingleDao(daoId, chainId)

  const daoInfo = useSelector((state) => {
    return state.daos.dao
  })

  const handleSuccessClose = () => setSuccessClaim(false)

  useEffect(() => {
    if (!daoInfo) return

    const network = getNetworkConfig(daoInfo.data.chainId)
    const library = { provider: network.params.rpcUrls[0] }

    const fetchTokenData = async () => {
      // Tokens
      setSupportedTokens(await DaoAPI.getDepositTokens(daoId, library))

      // Manager
      setManager(await DaoAPI.getManager(daoId, library, true))

      // Total Supply
      const supply = await DaoAPI.getTotalSupply(
        daoInfo?.data?.subgraph?.token?.id,
        library,
        true
      )
      setTotalSupply(supply)

      // RequiredTokenBalance
      const totalSupply = new BigNumber(`${supply}`).div('1e18')
      const minRequiredTokenPct = new BigNumber(
        daoInfo?.data?.subgraph?.packages[0].pkg.minRequiredTokenPct
      ).dividedBy('1e16')
      const requiredTokenBalance = minRequiredTokenPct
        .dividedBy(100)
        .multipliedBy(totalSupply)
        .toFixed()

      setRequiredTokenBalance(requiredTokenBalance)
    }

    // Assets
    filterAndSetAssets(daoInfo?.data?.assets)

    fetchTokenData().catch(console.error)
  }, [daoId, daoInfo])

  useEffect(() => {
    if (!account) return

    const fetchData = async () => {
      setLpTokenBalance(
        await DaoAPI.getTokenBalance(
          daoInfo?.data?.subgraph?.token?.id,
          account,
          daoInfo?.data?.subgraph?.token?.decimals,
          connector
        )
      )
    }
    fetchData()
  }, [account])

  const filterAndSetAssets = (unfilteredAssets) => {
    if (!unfilteredAssets || unfilteredAssets?.length === 0) {
      setAssets([])
      return
    }
    let _chainId = daoInfo.data.chainId
    const filteredAssets = unfilteredAssets.filter((asset) =>
      supportedFundsList[_chainId].tokens.some(
        (token) =>
          token.tokenAddress.toLowerCase() === asset.address.toLowerCase()
      )
    )
    setAssets(filteredAssets)
  }

  const classes = useStyles(props)

  const column = [
    { heading: 'Asset' },
    { heading: 'Balance' },
    { heading: '$ Value' },
    { heading: '% in pool' },
  ]

  const column2 = [
    { heading: 'Txn Hash' },
    { heading: 'Age' },
    { heading: 'From' },
    { heading: 'To' },
    { heading: 'Amount' },
  ]

  const claimManagerFees = async () => {
    try {
      if (assets.length > 0) {
        setClaiming(true)
        const claim = await DaoAPI.claimManagerFees(daoId, account, connector)
        if (claim) {
          setClaimDetails(claim)
          setClaiming(false)
          setSuccessClaim(true)
          setOpen(false)
        }
      } else {
        Swal.fire({
          icon: 'error',
          text: 'Currently, you cannot claim fees since there are no assets in the Vault.',
        })
      }
    } catch (error) {
      setClaiming(false)
      setOpen(false)
    }
  }

  if (
    daoInfo === null ||
    daoInfo === undefined ||
    daoId !== daoInfo?.data?.subgraph?.id
  ) {
    return (
      <Container className={classes.container}>
        <Grid container className={classes.daoFundSingePage}>
          <Grid item className={classes.spacing} xs={12} sm={12} md={12} lg={9}>
            <FundCardLoader />
          </Grid>
        </Grid>
        <Grid container className={classes.inlineMargin}>
          <Grid item className={classes.spacing} xs={12} md={12} lg={12}>
            <Card variant="outlined" style={{ borderRadius: 12 }}>
              <Container className={classes.fundManagerContainer}>
                <Typography
                  component={'div'}
                  className={classes.fundManagerTitle}
                >
                  Portfolio
                </Typography>
              </Container>
              <FundManagerLoader />
            </Card>
          </Grid>
          <Grid item className={classes.spacing} xs={12} md={12} lg={12}>
            <Card variant="outlined" style={{ borderRadius: 12 }}>
              <Container className={classes.fundManagerContainer}>
                <Typography
                  component={'div'}
                  className={classes.fundManagerTitle}
                >
                  Fund Managers
                </Typography>
              </Container>
              <PortfolioLoader />
            </Card>
          </Grid>
        </Grid>
      </Container>
    )
  }

  return (
    <>
      <BackgroundImage imageUrl={singlePageBackground} />
      <Container className={classes.container}>
        <Grid container className={classes.daoFundSingePage}>
          <Grid item className={classes.spacing} xs={12} sm={12} md={12} lg={9}>
            <SingleSectionFunds
              assets={assets}
              daoId={daoId}
              dao={{
                ...daoInfo?.data?.subgraph,
                fund: daoInfo?.data?.fund,
                logo: daoInfo?.img,
                rates: daoInfo?.data?.rates,
                chainId: daoInfo?.data?.chainId,
              }}
              manager={manager}
              supply={supply}
              supportedTokens={supportedTokens}
              lpTokenBalance={lpTokenBalance}
            />
          </Grid>
          <Grid container spacing={3} className={classes.centerContent}>
            <Grid item lg={9} sm={12} md={12} xs={12}>
              {manager === '' ? (
                <Card className={classes.loaderContent}>
                  <ButtonsLoader />
                </Card>
              ) : null}
              {manager === account ? (
                <Card className={classes.cardContent}>
                  <Button type="secondary" onClick={claimManagerFees}>
                    Claim fees
                  </Button>
                </Card>
              ) : null}
            </Grid>
            <Grid item lg={9} sm={12} md={12} xs={12}>
              {manager === '' ? (
                <Card className={classes.loaderContent}>
                  <ButtonsLoader />
                </Card>
              ) : null}
              {manager === account ? (
                <Card className={classes.cardContent}>
                  <Button
                    type="secondary"
                    onClick={() =>
                      navigate(routes.WALLETCONNECT.replace(ID, daoId), {
                        state: { dao: daoInfo, assets },
                      })
                    }
                  >
                    Create Transaction
                  </Button>
                </Card>
              ) : null}
            </Grid>
          </Grid>
        </Grid>
        <Grid container className={classes.inlineMargin}>
          <Grid item className={classes.spacing} xs={12} md={12} lg={12}>
            <FundManager chainId={daoInfo?.data?.chainId} dao={daoInfo?.data?.subgraph} daoId={daoId} />
          </Grid>
          <Grid item className={classes.spacing} xs={12} md={12} lg={12}>
            <SingleFundTable
              assets={assets}
              column={column}
              lpTokenBalance={lpTokenBalance}
              rates={daoInfo?.data?.rates}
            />
          </Grid>
          <Grid item className={classes.spacing} xs={12} md={12} lg={12}>
            <DescriptionCard dao={daoInfo?.data?.fund} />
          </Grid>
          <Grid item className={classes.spacing} xs={12} md={12} lg={12}>
            <TransactionHistoryTable
              column={column2}
              dao={daoInfo?.data?.subgraph}
            />
          </Grid>
        </Grid>
      </Container>
      <Container>
        <div className={classes.proposalsContent}>
          <h1>My Votings</h1>
          {daoInfo?.data?.subgraph?.proposals.length > 0 ? (
            <div
              className={classes.proposalsButton}
              onClick={() =>
                navigate(routes.PROPOSALS.replace(ID, daoId), {
                  state: lpTokenBalance,
                })
              }
            >
              All proposals <img src={arrow} alt="arrow" />
            </div>
          ) : null}
        </div>
        <ProposalCard
          dao={daoInfo}
          supply={supply}
          supportedTokens={supportedTokens}
          proposalsToShow={3}
          requiredTokenBalance={requiredTokenBalance}
          lpTokenBalance={lpTokenBalance}
          lpTotalSupply={supply}
          manager={manager}
        />
      </Container>
      <ModalLoading
        open={claiming}
        onCancel={undefined}
        modalImg={burnLPModalImg}
        title={'Claiming fees...'}
        subTitle={'Please wait for the fees to be claimed'}
      />
      <ModalSuccess
        open={successClaim}
        onCancel={handleSuccessClose}
        title={'Claim successful'}
        subTitle={
          <TransactionHash
            chainId={chainId}
            txnHash={claimDetails?.transactionHash}
          />
        }
        modalImg={burnLPModalImg}
      />
    </>
  )
}
