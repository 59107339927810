import React, { useMemo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Box } from '@material-ui/core'

import { Disclaimer, SectionTable } from '../../components'
import { usePools } from '../../hooks'
import { StakingProgress } from '../../components'
import { AllocationsTable } from '../../components/AllocationsTable'
import { Container } from '@material-ui/core'

const useStyles = makeStyles(
  (theme) => ({
    header: {
      color: theme.palette.primary.main,
      flexDirection: 'column',
      alignItems: 'center',
      margin: '64px 0 52px 0',
      '& > *': {
        margin: 0,
        '&:first-child': {
          fontFamily: theme.fontFamilies.secondary,
          fontWeight: 'bold',
          fontSize: 14,
          lineHeight: '22px',
          margin: '0 0 20px 0',
          textTransform: 'uppercase',
          '@media screen and (max-width: 320px)': {
            margin: '0 0 12px 0',
          },
        },
        '&:last-child': {
          fontFamily: theme.fontFamilies.primary,
          fontWeight: 900,
          fontSize: 48,
          lineHeight: '100%',
          '@media screen and (max-width: 320px)': {
            fontSize: 36,
          },
        },
      },
      '@media screen and (max-width: 644px)': {
        margin: '54px 0 50px 0',
      },
      '@media screen and (max-width: 320px)': {
        margin: '40px 0 40px 0',
      },
    },
    cards: {
      flexDirection: 'row',
      justifyContent: 'space-around',
      flexWrap: 'nowrap',
      margin: '0 0 32px 0',
      '& > *': {
        '&:first-child': {
          margin: '0 20px 0 0',
          '@media screen and (max-width: 680px)': {
            margin: '0 0 20px 0',
          },
        },
        '@media screen and (max-width: 680px)': {
          maxWidth: '100%',
        },
      },
      '@media screen and (max-width: 680px)': {
        flexWrap: 'wrap',
        margin: '0 0 36px 0',
      },
      '@media screen and (max-width: 320px)': {
        flexWrap: 'wrap',
        margin: '0 0 40px 0',
      },
    },
    tokenCards: {
      '& > *': {
        '&:first-child': {
          '@media screen and (max-width: 680px)': {
            margin: '0 0 36px 0',
          },
          '@media screen and (max-width: 320px)': {
            flexWrap: 'wrap',
            margin: '0 0 20px 0',
          },
        },
      },
    },
    disclaimer: {
      width: '100%',
    },
    sectionTable: {
      margin: '19px auto 109px',
      '@media screen and (max-width: 680px)': {
        margin: '19px auto 50px',
      },
      '@media screen and (max-width: 360px)': {
        margin: '9px auto 60px',
      },
    },
    sectionInvestor: {
      padding: '0 20px',
    },
  }),
  { name: 'PageAccount' }
)

export const PageAccount = () => {
  const classes = useStyles()

  const pools = usePools()

  const poolsWithInvests = useMemo(() => {
    // изменил pool.investLimit на Number(pool.startTime)
    return pools.filter((pool) => pool.investLimit > 0)
  }, [pools])

  return (
    <>
      <Container>
        <StakingProgress />
        <Box className={classes.disclaimer} mb={4}>
          <Disclaimer />
        </Box>
        {/*<Grid container className={classes.header}>*/}
        {/*  <h6>Staking</h6>*/}
        {/*  <h2>DoTheMoon</h2>*/}
        {/*</Grid>*/}

        {/*<Grid container className={classes.cards}>*/}
        {/*  <PageAccountCardInformation*/}
        {/*    title="Rewards Program"*/}
        {/*    firstLabel="Total Value Locked"*/}
        {/*    firstValue={'0 DTM'}*/}
        {/*    secondLabel="Your share"*/}
        {/*    secondValue={'0 %'}*/}
        {/*  />*/}
        {/*  <PageAccountCardInformation*/}
        {/*    title="Token Information"*/}
        {/*    firstLabel="Total Token Circulation"*/}
        {/*    firstValue={'0 DTM'}*/}
        {/*    secondLabel="Token Price"*/}
        {/*    secondValue={'0 $'}*/}
        {/*  />*/}
        {/*</Grid>*/}

        {/*<Grid container className={clsx([classes.cards, classes.tokenCards])}>*/}
        {/*  <PageAccountCardToken*/}
        {/*    type="locked"*/}
        {/*    tokenValue={tokenLocked}*/}
        {/*    available={tokenLockedAvailable}*/}
        {/*  />*/}
        {/*  <PageAccountCardToken*/}
        {/*    type="earned"*/}
        {/*    tokenValue={tokenEarned}*/}
        {/*    available={tokenEarnedAvailable}*/}
        {/*  />*/}
        {/*</Grid>*/}
      </Container>

      {poolsWithInvests.length > 0 && (
        <SectionTable className={classes.sectionTable} title="Your Allocations">
          <AllocationsTable />
          {/*<PoolsTable type={TableTypes.Allocations} />*/}
        </SectionTable>
      )}
    </>
  )
}
