import { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Container } from '@material-ui/core'
import { Grid } from '@material-ui/core'
import { DaoAPI } from '../../api/DaoAPI'
import { BigNumber } from 'bignumber.js'

const useStyles = makeStyles((theme) => ({
  content: {
    position: 'relative',
    marginTop: '90px',
    marginBottom: '50px',
    '@media screen and (max-width: 644px)': {
      margin: '50px 0 40px 0',
    },
    // '@media screen and (max-width: 480px)': {
    //   margin: 0,
    // },
    '@media screen and (max-width: 999px)': {
      marginTop: '50px',
    },
  },
  title: {
    marginBottom: '90px',
    fontSize: 60,
    fontWeight: 400,
    lineHeight: '90px',
    textAlign: 'center',
    '@media screen and (max-width: 644px)': {
      marginBottom: '50px',
    },
    '@media screen and (max-width: 448px)': {
      fontSize: 36,
      lineHeight: '44px',
    },
    '@media screen and (max-width: 999px)': {
      marginBottom: '50px',
      fontSize: 40,
      lineHeight: '60px',
    },
    letterSpacing: '0.02em',
  },
  img: {
    width: '100%',
    '@media screen and (max-width: 890px)': {
      height: '80vh',
    },
    '@media screen and (max-width: 480px)': {
      height: '100%',
    },
  },
  tile: {
    backgroundColor: '#F8DBF8',
    color: '#D236BF',
    fontSize: 40,
    borderRadius: '50px',
    padding: '20px 30px;',
  },
  value: {
    float: 'right',
  },
  audit: {
    marginTop: '50px',
    textAlign: 'center',
    fontSize: '14px',
    '& a': {
      color: 'black',
      textDecoration: 'none',
    },
  },
}))

export const SectionStats = () => {
  const classes = useStyles()
  const [stats, setStats] = useState({})

  useEffect(() => {
    async function fetchData() {
      setStats(await DaoAPI.getStats())
    }
    fetchData()
  }, [])

  return (
    <Container className={classes.content}>
      <h1 className={classes.title}>
        <b>
          Discover on-chain Vaults managing your assets without intermediaries
        </b>
      </h1>
      <Grid container spacing={3}>
        <Grid item lg={4} md={6} sm={12} xs={12}>
          <div className={classes.tile}>
            Vaults: <span className={classes.value}>{stats.totalDaos}</span>
          </div>
        </Grid>
        <Grid item lg={4} md={6} sm={12} xs={12}>
          <div className={classes.tile}>
            TVL:{' '}
            <span className={classes.value}>
              $
              {stats.totalTVL &&
                new BigNumber(stats.totalTVL).dividedBy('1e18').toFixed(0)}
            </span>
          </div>
        </Grid>
        <Grid item lg={4} md={6} sm={12} xs={12}>
          <div className={classes.tile}>
            Deposits:{' '}
            <span className={classes.value}>{stats.totalDeposits}</span>
          </div>
        </Grid>
      </Grid>
      <div className={classes.audit}>
        <a
          href="https://drive.google.com/file/d/14cyVTtryfKkYC8CRBr9PNibv0V98brZm/view"
          rel="noreferrer"
          target="_blank"
        >
          Security Audit by DefiMoon
        </a>
      </div>
    </Container>
  )
}
