import { useEffect, useState } from 'react';

// Material UI
import { makeStyles } from '@material-ui/core/styles';
import { Card, Container, Typography, Chip } from '@material-ui/core';
import { ChipLabel } from './Chip'

import { DaoAPI } from '../api/DaoAPI';
import { CopyToClipboardBtn } from './CopyToClipboard/CopyToClipboardBtn';
import { useWeb3React } from '@web3-react/core';
import { MembershipStructure } from './ExplorerLinks/ExplorerLinks';
import { FundManagerLoader } from './Loaders';

// Chart
import { SingleChart } from './Charts/SingleChart';
import { getNetworkConfig } from '../const/networks';

const useStyles = makeStyles({
  fundPerformanceCard: {
    boxSizing: 'border-box',
    boxShadow: '0px 51px 89px -49px rgba(196, 193, 232, 0.4)',
    borderRadius: 18,
    marginBottom: '30px'
  },
  fundPerformanceContainer: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    padding: '30px 24px 10px 6px'
  },
  fundManagerCard: {
    boxSizing: 'border-box',
    boxShadow: '0px 51px 89px -49px rgba(196, 193, 232, 0.4)',
    borderRadius: 18,
  },
  container: {
    paddingTop: 100,
    paddingBottom: 50,
  },
  fundManagerTitle: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: 13,
    lineHeight: 4,
  },
  fundManagerContent: {
    fontFamily: 'Poppins',
    fontWeight: 500,
    fontSize: 11,
    lineHeight: 5,
    color: '#4C4C66',
  },
  fundManagerButtons: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: 20,
  },
  justifyContent: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  fundManagerContainer: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    paddingLeft: 24,
    paddingRight: 24,
  },
  padding0: {
    paddingLeft: 0,
    paddingRight: 0,
  },
})

export const FundManager= (props:any) => {
    const { chainId } = useWeb3React()

    const classes = useStyles(props)
    const [manager, setManager] = useState<any>();
    const [daoConfig, setDaoConfig] = useState<any>();

    useEffect(() => {
      const fetchSubgraphData = async () => {
        const network = getNetworkConfig(props.chainId)

        const library = { provider: network.params.rpcUrls[0] }

        try {
          setManager(await DaoAPI.getManager(props.daoId, library, true))
          setDaoConfig(await DaoAPI.getConfiguration(props.daoId, library))
        } catch (e) {
          console.log('Error message:', e)
        }
      }
      fetchSubgraphData();
    }, [props?.daoId, props?.chainId]);


  return (
    <Container className={classes.padding0}>
      <Card variant="outlined" className={classes.fundPerformanceCard}>
        <Container className={classes.fundManagerContainer}>
          <Typography component={'div'} className={classes.fundManagerTitle}>
          Fund performance
          </Typography>
        </Container>
        <Container className={classes.fundPerformanceContainer}>
            <SingleChart daoId={props?.daoId} />
        </Container>
      </Card>
      <Card variant="outlined" className={classes.fundManagerCard}>
        <Container className={classes.fundManagerContainer}>
          <Typography component={'div'} className={classes.fundManagerTitle}>
            Fund Managers
          </Typography>
        </Container>
        <Container className={classes.fundManagerContainer}>
          <Typography className={classes.fundManagerContent}>
            {
              daoConfig?.canChangeManager === undefined ? 'Fund manager can be replaced' :
                !!daoConfig?.canChangeManager ?
                'Fund manager can be replaced':
                'Fund manager can not be replaced'
            }
          </Typography>
          <div className={classes.fundManagerButtons}>
            {manager === undefined ? 
            <Chip label={<FundManagerLoader/>} ></Chip>
           :   <ChipLabel type="fundManagerChip" label={(manager?.slice(0, 7) + '...' + manager?.slice(28, 42).toLowerCase())} ></ChipLabel>}
            <CopyToClipboardBtn props={manager} />
          </div>
        </Container>
        <MembershipStructure 
          chainId={chainId} 
          tokenId={props?.dao?.token?.id} />
      </Card>
    </Container>
  )
  }
