import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { fetchUserInfoRequest } from '../redux/actions/user.actions'
import { useWeb3React } from '@web3-react/core'

export const useUserInfo = () => {
  const { account } = useWeb3React()
  const dispatch = useDispatch()

  useEffect(() => {
    if (account) {
      dispatch(fetchUserInfoRequest({ userAddress: account }))
    }
  }, [dispatch, account])
}
