import {
  FETCH_POOLS_REQUEST,
  FETCH_POOLS_SUCCESS,
  FETCH_POOLS_ERROR,
  UPDATE_POOLS_STATUSES,
  SET_POOLS,
  UPDATE_POOL,
} from '../types/pools.types'

export const fetchPoolsRequest = ({ userAddress, provider, limit, offset }) => {
  return {
    type: FETCH_POOLS_REQUEST,
    limit,
    offset,
    payload: {
      userAddress,
      provider,
    },
  }
}

export const fetchPoolsSuccess = ({ pools }) => {
  return {
    type: FETCH_POOLS_SUCCESS,
    payload: {
      pools,
    },
  }
}

export const fetchPoolsError = () => {
  return {
    type: FETCH_POOLS_ERROR,
  }
}

export const setPools = ({ pools }) => {
  return {
    type: SET_POOLS,
    payload: {
      pools,
    },
  }
}

export const updatePool = ({ userAddress, poolAddress, provider }) => {
  return {
    type: UPDATE_POOL,
    payload: {
      userAddress,
      poolAddress,
      provider,
    },
  }
}

export const updatePoolsStatuses = ({ poolsIds }) => {
  return {
    type: UPDATE_POOLS_STATUSES,
    payload: {
      poolsIds,
    },
  }
}
