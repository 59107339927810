// WEB3 Imports
import { AbiItem } from 'web3-utils';
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';
import BigNumber from 'bignumber.js';
import Web3 from 'web3';

import { arrayToLowerCase } from '../helpers/arrayToLowerCase';
import { aspisConfiguration } from '../helpers/daoApiConfig'

import { IMajorityVoting, DAO } from '../abi';
import moment from 'moment';

const Web3EthAbi = require('web3-eth-abi');

export enum VoterState {
    None,
    Abstain,
    Yea,
    Nay
}

export class VotingAPI {

    static async appointNewFundManager({ daoAddress, fundManager }, library) {
        
        let web3
        if (Web3.givenProvider) {
          web3 = new Web3(Web3.givenProvider)
        } else {
          web3 = new Web3(library.provider)
        }

        if(fundManager === undefined) {
            return Promise.reject('Fundmanager address cannot be undefined!')
        }

        try {
            const DaoInstance = new web3.eth.Contract(
                DAO as AbiItem[],
                daoAddress
            )
            const action = {
                to: daoAddress, data: DaoInstance.methods.updateManager(fundManager.toLowerCase()).encodeABI()
            }
            return action
        } catch (err) {
            return Promise.reject(err)
        }
    }

    static async addNewAllowlistedAddress({ daoAddress, address }, library) {
        if(address.length === 0) {
            return Promise.reject('Allowlisted address cannot be undefined!')
        }

        try {
            const ConfigurationInstance = await aspisConfiguration(daoAddress, library)
            const action = {
                to: ConfigurationInstance.options.address,
                data: ConfigurationInstance.methods.addToTrustedProtocols(arrayToLowerCase(address)).encodeABI()
            }
            return action
        } catch (err) {
            return Promise.reject(err)
        }
    }

    static async deleteAllowlistedAddress({ daoAddress, address }, library) {
        if(address.length === 0) {
            return Promise.reject('Allowlisted address cannot be undefined!')
        }
        try {
            const ConfigurationInstance = await aspisConfiguration(daoAddress, library)
            const action = {
                to: ConfigurationInstance.options.address,
                data: ConfigurationInstance.methods.removeFromTrustedProtocols(arrayToLowerCase(address)).encodeABI()
            }
            return action
        } catch (err) {
            return Promise.reject(err)
        }
    }

    static async addWhitelistedUsers({ daoAddress, addresses }, library) {
        if(addresses.length === 0) {
            return Promise.reject('Allowlisted address cannot be undefined!')
        }
        try {
            const ConfigurationInstance = await aspisConfiguration(daoAddress, library)
            const action = {
                to: ConfigurationInstance.options.address,
                data: ConfigurationInstance.methods.addToWhitelist(arrayToLowerCase(addresses)).encodeABI()
            }
            return action
        } catch (err) {
            return Promise.reject(err)
        }
    }

    static async removeWhitelistedUsers({ daoAddress, addresses }, library) {
        if(addresses.length === 0) {
            return Promise.reject('Allowlisted address cannot be undefined!')
        }
        try {
            const ConfigurationInstance = await aspisConfiguration(daoAddress, library)
            const action = {
                to: ConfigurationInstance.options.address,
                data: ConfigurationInstance.methods.removeFromWhitelist(arrayToLowerCase(addresses)).encodeABI()
            }
            return action
        } catch (err) {
            return Promise.reject(err)
        }
    }

    static async changeDepositLimits({ daoAddress, minimumDeposit, maximumDeposit }, library) {
        if(minimumDeposit === undefined || maximumDeposit === undefined) {
            return Promise.reject('Deposit limits cannot be undefined!')
        }
        try {
            const ConfigurationInstance = await aspisConfiguration(daoAddress, library)
            const action = {
                to: ConfigurationInstance.options.address,
                data: ConfigurationInstance.methods.setDepositLimits(minimumDeposit, maximumDeposit).encodeABI()
            }
            return action
        } catch (err) {
            return Promise.reject(err)
        }
    }

    static async changeWithdrawalWindows({ daoAddress, freezePeriod, window }, library) {
        if(freezePeriod === undefined || window === undefined) {
            return Promise.reject('Withdrawal windows cannot be undefined!')
        }
        try {
            const ConfigurationInstance = await aspisConfiguration(daoAddress, library)
            const action = {
                to: ConfigurationInstance.options.address,
                data: ConfigurationInstance.methods.setWithdrawlWindows(freezePeriod, window).encodeABI()
            }
            return action
        } catch (err) {
            return Promise.reject(err)
        }
    }

    static async changeSpendingLimits({ daoAddress, monthlyAssetsPct }, library) {
        if(monthlyAssetsPct === undefined) {
            return Promise.reject('Spending limit cannot be undefined!')
        }

        try {
            const ConfigurationInstance = await aspisConfiguration(daoAddress, library)
            const action = {
                to: ConfigurationInstance.options.address,
                data: ConfigurationInstance.methods.setSpendingLimit(monthlyAssetsPct).encodeABI()
            }
            return action
        } catch (err) {
            return Promise.reject(err)
        }
    }

    static async changeLockupPeriod({ daoAddress, hours }, library) {
        if(hours === undefined) {
            return Promise.reject('Lockup period cannot be undefined!')
        }
        
        try {
            const ConfigurationInstance = await aspisConfiguration(daoAddress, library)
            const action = {
                to: ConfigurationInstance.options.address,
                data: ConfigurationInstance.methods.setLockLimit(hours).encodeABI()
            }
            return action
        } catch (err) {
            console.log('eror')
            
        }
    }

    static async changerageQuitFee({ daoAddress, rageFine }, library) {
        if(rageFine === undefined) {
            return Promise.reject('Rage quit fine cannot be undefined!')
        }

        try {
            const ConfigurationInstance = await aspisConfiguration(daoAddress, library)
            const action = {
                to: ConfigurationInstance.options.address,
                data: ConfigurationInstance.methods.setRageQuitFee(rageFine).encodeABI(),
            }
            return action
        } catch (err) {
            return Promise.reject(err)
        }
    }

    static async changeVotingConfig({
        minDuration,
        participationRequiredPct,
        supportRequiredPct,
        minRequiredTokenPct,
        votingAddress
    }, library) {
        try {
            let web3
            if (Web3.givenProvider) {
                web3 = new Web3(Web3.givenProvider)
            } else {
                web3 = new Web3(library.provider)
            }
            const VotingInstance = new web3.eth.Contract(
                IMajorityVoting as AbiItem[],
                votingAddress
            )
    
            const action = {
                to: votingAddress,
                data: VotingInstance.methods.changeVoteConfig(
                    `${new BigNumber(participationRequiredPct).multipliedBy('1e16')}`,
                    `${new BigNumber(supportRequiredPct).multipliedBy('1e16')}`,
                    `${new BigNumber(minRequiredTokenPct).multipliedBy('1e16')}`,
                    `${new BigNumber(minDuration).multipliedBy(60).multipliedBy(60)}`
                ).encodeABI()
            }
            return action
        } catch (err) {
            return Promise.reject(err)
        }
    }

    static async changeEntranceFee({ daoAddress, fee }, library) {
        if(fee === undefined) {
            return Promise.reject('Entrance fee cannot be undefined!')
        }

        try {
            const ConfigurationInstance = await aspisConfiguration(daoAddress, library)
            const action = {
                to: ConfigurationInstance.options.address,
                data: ConfigurationInstance.methods.setEntranceFee(fee).encodeABI()
            }
            return action
        } catch (err) {
            return Promise.reject(err)
        }
    }

    static async changeFundManagementFee({ daoAddress, fee }, library) {
        if(fee === undefined) {
            return Promise.reject('Fund manager fee cannot be undefined!')
        }

        try {
            const ConfigurationInstance = await aspisConfiguration(daoAddress, library)
            const action = {
                to: ConfigurationInstance.options.address,
                data: ConfigurationInstance.methods.setFundManagementFee(fee).encodeABI()
            }
            return action
        } catch (err) {
            return Promise.reject(err)
        }
    }

    static async changePerformanceFee({ daoAddress, fee }, library) {
        if(fee === undefined) {
            return Promise.reject('Performance fee cannot be undefined!')
        }
        try {
            const ConfigurationInstance = await aspisConfiguration(daoAddress, library)
            const action = {
                to: ConfigurationInstance.options.address,
                data: ConfigurationInstance.methods.setPerformanceFee(fee).encodeABI()
            }
            return action
        } catch (err) {
            return Promise.reject(err)
        }
    }

    static async changeTokensForDepositing({ daoAddress, token }, library) {
        try {
            const ConfigurationInstance = await aspisConfiguration(daoAddress, library)
            const action = {
                to: ConfigurationInstance.options.address,
                data: ConfigurationInstance.methods.addDepositTokens(token).encodeABI()
            }
            return action
        } catch (err) {
            return Promise.reject(err)
        }
    }

    static async changeTokensForTrading({ daoAddress, token }, library) {
        try {
            const ConfigurationInstance = await aspisConfiguration(daoAddress, library)
            const action = {
                to: ConfigurationInstance.options.address,
                data: ConfigurationInstance.methods.addTradingTokens(token).encodeABI()
            }
            return action
        } catch (err) {
            return Promise.reject(err)
        }
    }

    static directAssetTransfer(actor: string, pool: string, data: any) {
        try {
          const jsonInterface = {
            "inputs": [
              {
                "internalType": "address",
                "name": "_target",
                "type": "address"
              },
              {
                "internalType": "uint256",
                "name": "_ethValue",
                "type": "uint256"
              },
              {
                "internalType": "bytes",
                "name": "_data",
                "type": "bytes"
              }
            ],
            "name": "directAssetTransfer",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
          };
    
          const encodedData = Web3EthAbi.encodeFunctionCall(jsonInterface, [data[0], data[1], data[2]]);
          const action = {
            to: pool,
            data: encodedData
          }
          return action
        } catch (error) {
          return (error)
        }
    }

    static async removeTokensForDepositing({ daoAddress, token }, library) {
        try {
            const ConfigurationInstance = await aspisConfiguration(daoAddress, library)
            const action = {
                to: ConfigurationInstance.options.address,
                data: ConfigurationInstance.methods.removeDepositTokens(token).encodeABI()
            }
            return action
        } catch (err) {
            return Promise.reject(err)
        }
    }

    static async removeTokensForTrading({ daoAddress, token }, library) {
        try {
            const ConfigurationInstance = await aspisConfiguration(daoAddress, library)
            const action = {
                to: ConfigurationInstance.options.address,
                data: ConfigurationInstance.methods.removeFromTradingTokens(token).encodeABI()
            }
            return action
        } catch (err) {
            return Promise.reject(err)
        }
    }

    static async addToDefiProtocols({ daoAddress, _defProtocols }, library) {
        try {
            const ConfigurationInstance = await aspisConfiguration(daoAddress, library)
            const action = {
                to: ConfigurationInstance.options.address,
                data: ConfigurationInstance.methods.addToDefiProtocols(_defProtocols).encodeABI()
            }
            return action
        } catch (err) {
            return Promise.reject(err)
        }
    }

    static async removeFromDefiProtocols({ daoAddress, _defProtocols }, library) {
        try {
            const ConfigurationInstance = await aspisConfiguration(daoAddress, library)
            const action = {
                to: ConfigurationInstance.options.address,
                data: ConfigurationInstance.methods.removeFromDefiProtocols(_defProtocols).encodeABI()
            }
            return action
        } catch (err) {
            return Promise.reject(err)
        }
    }

    static async setFundraisingStartTimeAndFinishTime({ daoAddress, newStartDate, duration }, library) {
        const newEndDate = new Date(newStartDate);
        newEndDate.setDate(newStartDate.getDate() + duration);
        
        try {
            const ConfigurationInstance = await aspisConfiguration(daoAddress, library)
            const action = {
                to: ConfigurationInstance.options.address,
                data: ConfigurationInstance.methods
                    .setFundraisingStartTimeAndFinishTime(moment(newStartDate).unix(), moment(newEndDate).unix())
                    .encodeABI()
            }
            return action
        } catch (err) {
            console.log('eror')
            
        }
    }

    static async upgradeTo({ daoAddress, address }, library) {
        let web3
        if (Web3.givenProvider) {
          web3 = new Web3(Web3.givenProvider)
        } else {
          web3 = new Web3(library.provider)
        }

        const DaoInstance = new web3.eth.Contract(
            DAO as AbiItem[],
            daoAddress
        )

        return {
            to: daoAddress,
            data: DaoInstance.methods.upgradeTo(address).encodeABI()
        }
    }

    static async newVote({ address, script, metadata, account }, library) {
        try {
            let web3
            const uuid = uuidv4()

            if (Web3.givenProvider) {
              web3 = new Web3(Web3.givenProvider)
            } else {
              web3 = new Web3(library.provider)
            }


            const voting = new web3.eth.Contract(
                IMajorityVoting as AbiItem[],
                address
            )
            const tx = await voting.methods.newVote(web3.utils.asciiToHex(uuid), [[script.to, 0, script.data]], 0, 0, false, VoterState.None)
            try {
                await tx.send({ 
                    from: account, 
                    maxPriorityFeePerGas: null,
                    maxFeePerGas: null
                })
                if (uuid) {
                    const formData = new FormData()
                    const proposalDetails = JSON.stringify({
                        title: metadata.proposalTitle,
                        description: metadata.proposalDescription
                    })

                    formData.append('details', proposalDetails)
                    const response = await axios({
                        method: 'post',
                        url: `/proposal/upload/${uuid}`,
                        data: formData,
                        headers: { 'Content-Type': 'multipart/form-data' },
                    })
                    return response
                }
            } catch (error) {
                return Promise.reject(error)
            }
        } catch (err) {
            return Promise.reject(err)
        }
    }

    static async getCanVote({address, votingId, account}, library): Promise<any> {
        try {
            let web3;
            if (Web3.givenProvider) {
                web3 = new Web3(Web3.givenProvider)
            } else {
                web3 = new Web3(library.provider)
            }

            const voting = new web3.eth.Contract(
                IMajorityVoting as AbiItem[],
                address
            )
            return await voting.methods.canVote(votingId, account).call()
        } catch (err) {
            return Promise.reject(err)
        }
    }

    static async vote({ address, votingId, decision, account }, library) {
        try {
            let web3;
            if (Web3.givenProvider) {
                web3 = new Web3(Web3.givenProvider)
            } else {
                web3 = new Web3(library.provider)
            }

            const voting = new web3.eth.Contract(
                IMajorityVoting as AbiItem[],
                address
            )
            const tx = await voting.methods.vote(votingId, decision, false)
            await tx.send({ 
                from: account, 
                maxPriorityFeePerGas: null,
                maxFeePerGas: null
            })

            return true
        } catch (error) {
            return Promise.reject(error)
        }
    }
    
    static async executeVote({ address, votingId, account }, library) {
        try {

            let web3;
            if (Web3.givenProvider) {
                web3 = new Web3(Web3.givenProvider)
            } else {
                web3 = new Web3(library.provider)
            }

            const voting = new web3.eth.Contract(
                IMajorityVoting as AbiItem[],
                address
            )
            const tx = await voting.methods.execute(votingId)
            await tx.send({ 
                from: account, 
                maxPriorityFeePerGas: null,
                maxFeePerGas: null
            })

            return true
        } catch (error) {
            return Promise.reject(error)
        }
    }
}
