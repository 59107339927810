import { useCallback, useMemo, useState, useEffect } from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import { Grid, Menu, MenuItem } from '@material-ui/core'

import { clearWalletCache } from '../../helpers/wallet'
import { useWeb3React } from '@web3-react/core'
import UnlockWallet from '../../assets/icons/UnlockWalletDark.svg'
import { isChainSupported } from '../../const/networks'
import { UnlockWalletButton } from '../index'

const useStyles = makeStyles((theme) => ({
  account: {
    color: '#6839FD',
    backgroundColor: 'rgba(135, 101, 242, 0.1);',
    fontWeight: 600,
    fontSize: 14,
    lineHeight: '21px',
    padding: '9px 16px 9px 16px',
    border: '1px solid #8765F2',
    borderRadius: '7px',
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: theme.palette.thirtySixth.main,
    },
  },
  img: {
    marginRight: '5px',
  },
  button: {
    width: 220,
    borderRadius: '7px !important',
    fontFamily: 'Poppins',
    '@media screen and (max-width: 979px)': {
      width: '40px',
    },
  },
}))

const StyledMenu = withStyles(() => ({
  paper: {
    margin: '10px 0 0 0',
    border: '1px solid #d3d4d5',
  },
}))((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
))

export const Account = ({ className, openWalletModal, closeWalletModal }) => {
  const classes = useStyles()
  const { account, connector, chainId } = useWeb3React()

  const [anchorEl, setAnchorEl] = useState(null)
  const [chainSupported, setChainSupported] = useState(true)

  const formattedAccount = useMemo(() => {
    try {
      if (account) {
        const start = account.substring(0, 3)
        const end = account.substring(account.length - 3, account.length)
        return `${start}...${end}`
      }
      return 'Not connected'
    } catch (e) {
      console.log(e)
    }
  }, [account])

  useEffect(() => {
    setChainSupported(isChainSupported(chainId))
  }, [chainId])

  const handleClose = useCallback(() => {
    setAnchorEl(null)
  }, [])

  const onDisconnectClick = () => {
    if (connector?.deactivate) {
      connector.deactivate()
    } else {
      connector.resetState()
    }

    clearWalletCache()
    handleClose()
  }

  return (
    <>
      {chainSupported ? (
        <span
          onClick={(e) => setAnchorEl(e.currentTarget)}
          className={
            className ? `${classes.account} ${className}` : classes.account
          }
        >
          <img src={UnlockWallet} className={classes.img} alt="wallet" />{' '}
          {formattedAccount}
        </span>
      ) : (
        <UnlockWalletButton
          type="success"
          className={classes.button}
          openWalletModal={openWalletModal}
          closeWalletModal={closeWalletModal}
          text={'Unsupported chain'}
        />
      )}
      <Grid>
        <StyledMenu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          onBlur={handleClose}
        >
          <MenuItem onClick={openWalletModal}>Choose Network</MenuItem>
          <MenuItem onClick={onDisconnectClick}>Disconnect</MenuItem>
        </StyledMenu>
      </Grid>
    </>
  )
}
