import { loadingTypes } from '../types'

const initialState = {
  state: false,
  requests: new Set(),
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case loadingTypes.SET_LOADING_TRUE:
    case loadingTypes.SET_LOADING_FALSE:
      return {
        ...state,
        state: action.payload.loading,
      }
    case loadingTypes.SET_REQUEST_ACTION_TYPES:
      return {
        ...state,
        requests: action.payload.types,
      }
    default:
      return state
  }
}
