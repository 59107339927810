import { useEffect, useState } from 'react'
import { useLocation } from 'react-router'

// Material UI imports
import { makeStyles } from '@material-ui/core/styles'
import { Container, Grid } from '@material-ui/core'

// Web3
import { useWeb3React } from '@web3-react/core'
import { DaoAPI } from '../../api/DaoAPI'

// Custom Hooks
import { useSingleDao } from '../../hooks/useDaos'

// Components
import { BackgroundImage } from '../../components/BackgroundImage'
import { NavigationBar } from '../../components/NavigationBar'
import { ProposalCard } from '../../components/votings/proposals/ProposalCard'

import { routes, ID } from '../../const/routes'
import backgroundImg from '../../assets/images/singePageBackground.svg'
import BigNumber from 'bignumber.js'

const useStyles = makeStyles({
  card: {
    height: '400px',
    cursor: 'pointer',
    borderRadius: '10px',
    boxShadow: '0px 17px 26px rgba(215, 190, 255, 0.05);',
    padding: '16px 0',
  },
  cardContent: {
    background: '#FFFFFF',
    borderRadius: '10px',
    '& .MuiCardContent-root': {
      padding: 0,
    },
  },
  titleContent: {
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    fontSize: '9px',
    marginLeft: '12px',
    color: '#8173AC',
  },
  boldText: {
    fontSize: '9px',
    fontWeight: 'bold',
    marginTop: '7px',
  },
  logo: {
    width: '45px',
  },
  proposalTitle: {
    fontSize: '12px',
    fontWeight: 700,
    lineHeight: '20.52px',
    color: '#4C4C66',
  },
  proposalDescription: {
    fontSize: '9px',
    fontWeight: 400,
    lineHeight: '14.22px',
  },
  votingTimer: {
    fontSize: '10px',
    fontWeight: 500,
    color: '#4C4C66',
    opacity: 0.67,
  },
  timer: {
    color: '#2F2F4D',
    fontWeight: 600,
    fontSize: '20px',
  },
  progressBarYes: {
    height: '6px',
    left: '15px',
    borderRadius: '53px',
    '& .MuiLinearProgress-barColorPrimary': {
      background: '#4DCAC2',
    },
  },
  progressBarNo: {
    height: '6px',
    left: '15px',
    borderRadius: '53px',
    '& .MuiLinearProgress-barColorPrimary': {
      background: '#DA418F',
    },
  },
  progressBarQuorum: {
    height: '6px',
    left: '15px',
    borderRadius: '53px',
  },
  progressText: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '17px',
  },
  progressPercentage: {
    color: '#988DBC',
    fontWeight: 800,
    fontSize: 12,
    fontFamily: 'Poppins',
    opacity: 0.9,
  },
  progressTitle: {
    fontSize: 10,
    marginTop: 10,
  },
  content: {
    marginBottom: '45px',
  },
  spacing: {
    padding: '8px',
  },
})

export const ProposalsPage = (props) => {
  const classes = useStyles()
  const location = useLocation()

  const { account, connector, chainId } = useWeb3React()

  // eslint-disable-next-line no-unused-vars
  const [supply, setTotalSupply] = useState(null)
  const [manager, setManager] = useState('')
  const [supportedTokens, setSupportedTokens] = useState([])
  const [lpTokenBalance, setLpTokenBalance] = useState(0)
  const [requiredTokenBalance, setRequiredTokenBalance] = useState(0)

  let paths = location.pathname.split('/')
  let daoId = paths[paths.length - 1]
  let dao = useSingleDao(daoId, chainId)
  const pathname = window.location.pathname

  useEffect(() => {
    const fetchTokenData = async () => {
      if (account !== undefined && dao != null) {
        setManager(await DaoAPI.getManager(daoId, connector))
        setLpTokenBalance(
          await DaoAPI.getTokenBalance(
            dao?.data?.subgraph?.token?.id,
            account,
            dao?.data?.subgraph?.token?.decimals,
            connector
          )
        )
        setSupportedTokens(await DaoAPI.getDepositTokens(daoId, connector))
        let totalSupply = await DaoAPI.getTotalSupply(
          dao?.data?.subgraph?.token?.id,
          connector
        )
        setTotalSupply(totalSupply)
      }
    }
    const totalSupply = new BigNumber(`${supply}`).div('1e18')
    const minRequiredTokenPct = new BigNumber(
      dao?.data?.subgraph?.packages[0].pkg.minRequiredTokenPct
    ).dividedBy('1e16')
    const requiredTokenBalance = minRequiredTokenPct
      .dividedBy(100)
      .multipliedBy(totalSupply)
      .toFixed()
    setRequiredTokenBalance(requiredTokenBalance)

    fetchTokenData().catch(console.error)
  }, [
    account,
    dao,
    dao?.data?.subgraph?.packages,
    dao?.data?.subgraph?.token?.decimals,
    dao?.data?.subgraph?.token?.id,
    daoId,
    supply,
    connector,
  ])

  return (
    <Container>
      <BackgroundImage imageUrl={backgroundImg} />
      <NavigationBar
        link={routes.FUNDS.replace(ID, daoId) + `?chainId=${dao.data.chainId}`}
        pathName={pathname}
      />
      <Grid container className={classes.content}>
        <ProposalCard
          account={account}
          dao={dao}
          supply={supply}
          supportedTokens={supportedTokens}
          proposalsToShow={undefined}
          lpTokenBalance={lpTokenBalance}
          requiredTokenBalance={requiredTokenBalance}
          manager={manager}
        />
      </Grid>
    </Container>
  )
}
