import { useEffect, useRef, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { createChart } from 'lightweight-charts'
import _ from 'lodash'

import { DaoAPI } from '../../api/DaoAPI'
import { getPriceConf } from '../../helpers/getPriceConf'

const useStyles = makeStyles({
  chartWrapper: {
    width: '100%',
    height: 260,
  },
})

export const SingleChart = (props) => {
  const classes = useStyles(props)
  const { daoId } = props

  const chartRef = useRef<any>()
  const [lpPrices, setLpPrices] = useState<any>()

  useEffect(() => {
    if (!daoId) return

    const fetchLpTokenPrice = async () => {
      try {
        const response = await DaoAPI.getLpPrices(daoId)
        setLpPrices(response.data)
      } catch (e) {
        console.log('Error message:', e)
      }
    }

    fetchLpTokenPrice()
  }, [daoId])

  useEffect(() => {
    if (!chartRef.current || !lpPrices) return

    const chartOptions = {
      layout: {
        textColor: '#4C4C66',
        background: { type: 'solid', color: 'transparent' },
      },
      leftPriceScale: {
        visible: true,
        borderVisible: false,
      },
      rightPriceScale: {
        borderVisible: false,
        visible: false,
      },
      grid: {
        vertLines: {
          visible: false,
        },
        horzLines: {
          color: '#eee',
        },
      },
      crosshair: {
        vertLine: {
          labelBackgroundColor: '#BC65F2',
        },
        horzLine: {
          labelBackgroundColor: '#BC65F2',
        },
      },
    }
    // @ts-ignore
    const chart = createChart(chartRef.current, chartOptions)

    const areaSeries = chart.addAreaSeries({
      lineColor: '#BC65F2',
      topColor: '#BC65F2',
      bottomColor: 'rgba(188, 101, 242, 0.18)',
    })

    let data = _.sortBy(lpPrices, ['time'])

    // Set Data
    data = data.map((el) => ({
      time: new Date(el.time).valueOf() / 1000,
      value: Number(el.price),
    }))

    areaSeries.setData(_.uniqBy(data, 'time'))

    // Set visible range
    const from = Date.now() / 1000 - 60 * 60 * 24 * 7
    const to = Date.now() / 1000

    if (!lpPrices[lpPrices.length - 1]) return

    const sorted = _.sortBy(lpPrices, ['price'])
    const priceConf = getPriceConf(
      sorted[0].price,
      sorted[sorted.length - 1].price,
    )

    if (priceConf) {
      areaSeries.applyOptions({
        priceFormat: {
          type: 'price',
          ...priceConf,
        },
      })
    }

    if (!chart) return
    // @ts-ignore
    chart.timeScale().setVisibleRange({ from, to })

    return () => {
      chart.remove()
    }
  }, [chartRef, lpPrices])

  return <div className={classes.chartWrapper} ref={chartRef} />
}
