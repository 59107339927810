import { walletTypes } from '../types'
import { AnyAction } from 'redux'

type WalletState = {
  status: 'approved' | 'swapped' | 'claimed' | 'lockedIn' | null,
  error: string | null
}

const initialState: WalletState = {
  status: null,
  error: null
}

export const walletReducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case walletTypes.SWAP_SUCCESS:
      return {...state, status: 'swapped'}
    case walletTypes.APPROVE_SUCCESS:
      return {...state, status: 'approved'}
    case walletTypes.CLAIM_SUCCESS:
      return {...state, status: 'claimed'}
    case walletTypes.LOCK_IN_SUCCESS:
      return {...state, status: 'lockedIn'}

    case walletTypes.SWAP_ERROR:
    case walletTypes.APPROVE_ERROR:
    case walletTypes.CLAIM_ERROR:
    case walletTypes.LOCK_IN_ERROR:
    case walletTypes.SET_ERROR:
      return {...state, error: action.payload.message}

    case walletTypes.RESET_ERROR:
      return {...state, error: null}

    default:
      return state
  }
}


// const walletSlice = createSlice({
//   name: 'wallet',
//   initialState,
//   reducers: {
//     SWAP_SUCCESS: (state) => {
//       state.status = 'swapped'
//     },
//     APPROVE_SUCCESS: (state) => {
//       state.status = 'approved'
//     },
//     CLAIM_SUCCESS: (state) => {
//       state.status = 'claimed'
//     },
//     LOCK_IN_SUCCESS: (state) => {
//       state.status = 'lockedIn'
//     },
//   }
// })
// export const walletReducer = walletSlice.reducer
