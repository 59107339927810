import { useEffect, useState } from 'react'
import web3 from 'web3'

// Material-UI imports
import {
  Badge,
  Card,
  CardContent,
  Grid,
  Switch,
  TextField,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import questionMark from '../../../assets/icons/questionMark.svg'

import { decodeDaoConfigParams } from '../../../helpers/abiDecoder'
import { NumericFormat } from 'react-number-format'

const useStyle = makeStyles((theme) => ({
  divider: {
    borderBottom: '1px solid rgb(224 224 224)',
  },
  cardTitle: {
    fontSize: 13,
    fontWeight: 600,
    fontStyle: 'normal',
    lineHeight: 4,
    margin: 0,
    paddingLeft: '16px',
  },
  cardContent: {
    borderRadius: '18px',
    border: '1px solid rgba(214, 210, 242, 0.5)',
    boxShadow: '0px 51px 89px -49px rgba(196, 193, 232, 0.4)',
    '& .MuiCardContent-root': {
      padding: 0,
    },
  },
  contentHelper2: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    padding: '5px'
  },
  labelHelper: {
    fontWeight: 500,
    fontSize: '9px',
    color: '#958BAE',
  },
  boldText: {
    letterSpacing: '0.4px',
    fontSize: '12px',
    color: '#4C4C66',
  },
  spanTextColor: {
    color: '#BC65F2',
    fontSize: '12px',
    textAlign: 'right',
  },
  questionMark: {
    width: '20px'
  },
  link: {
    color: '#BC65F2'
  },
  inputOffBoarding: {
    width: '100%',
    '& .MuiOutlinedInput-input': {
      padding: '12.5px 14px'
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderRadius: '10px'
    }
  },

}))

export const VoteForChangeWithdrawalWindows = (props) => {
  const classes = useStyle()
  const { hexToNumber } = web3.utils

  // const location = useLocation();
  // const daoAddress = location.state.state.data.subgraph.id

  // const [daoConfig, setDaoConfig] = useState<any>({})
  const [proposedDelay, setProposedDelay] = useState<any>({})

  useEffect(() => {
    const decodeWindows = async () => {
      try {
        // setDaoConfig(await DaoAPI.getConfiguration(daoAddress))
        const result = decodeDaoConfigParams(props?.action)
        setProposedDelay({
          ...proposedDelay,
          window: hexToNumber(result.inputs[1]._hex) / 24,
          freezePeriod: hexToNumber(result.inputs[0]._hex) / 24
        })
      } catch (e) {
        console.log('Error message:', e)
      }
    }
    decodeWindows()
      .catch(console.error)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Grid container>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Card className={classes.cardContent}>
            <div className={classes.divider}>
              <p className={classes.cardTitle}>Proposed withdrawal window</p>
            </div>
            <CardContent style={{ padding: '16px' }}>
              <Grid item lg={12} md={8} sm={8} xs={12}>
                <div className={classes.contentHelper2}>
                  <div className={classes.labelHelper}>
                    <b className={classes.boldText}>Withdrawal windows,</b>days
                    <Switch />
                  </div>
                  <span className={classes.spanTextColor}>
                    <Badge overlap="rectangular">
                      <img className={classes.questionMark} src={questionMark} alt='questionMark' />
                    </Badge>
                    <a href="!#" target="_blank" className={classes.link}> Learn more withdrawal windows </a>
                  </span>
                </div>
              </Grid>

              <Grid item lg={12} md={8} sm={8} xs={12}>
                <div className={classes.contentHelper2}>
                  <b className={classes.boldText}>Window,</b>&nbsp;
                  <div className={classes.labelHelper}>days</div>
                </div>
                <NumericFormat
                  disabled
                  value={proposedDelay?.window}
                  customInput={TextField}
                  variant="outlined"
                  className={classes.inputOffBoarding}
                  allowNegative={false}
                  thousandSeparator=","
                />
              </Grid>
              <Grid item lg={12} md={8} sm={8} xs={12}>
                <div className={classes.contentHelper2}>
                  <b className={classes.boldText}>Freeze period,</b>&nbsp;
                  <div className={classes.labelHelper}>days</div>
                </div>
                <NumericFormat
                  disabled
                  value={proposedDelay?.freezePeriod}
                  customInput={TextField}
                  variant="outlined"
                  className={classes.inputOffBoarding}
                  allowNegative={false}
                  thousandSeparator=","
                />
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  )
}
