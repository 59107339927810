import {
  SET_LOADING_TRUE,
  SET_LOADING_FALSE,
  ADD_REQUEST_ACTION_TYPE,
  REMOVE_REQUEST_ACTION_TYPE,
  SET_REQUEST_ACTION_TYPES,
} from '../types/loading.types'

export const setLoadingTrue = () => {
  return {
    type: SET_LOADING_TRUE,
    payload: {
      loading: true,
    },
  }
}

export const setLoadingFalse = () => {
  return {
    type: SET_LOADING_FALSE,
    payload: {
      loading: false,
    },
  }
}

export const addRequestActionType = ({ type }) => {
  return {
    type: ADD_REQUEST_ACTION_TYPE,
    payload: {
      type,
    },
  }
}

export const removeRequestActionType = ({ type }) => {
  return {
    type: REMOVE_REQUEST_ACTION_TYPE,
    payload: {
      type,
    },
  }
}

export const setRequestActionTypes = ({ types }) => {
  return {
    type: SET_REQUEST_ACTION_TYPES,
    payload: {
      types,
    },
  }
}
