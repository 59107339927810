import { BigNumber } from 'ethers'
import { DoTheMoonFactory } from '../const/types/DoTheMoonFactory.ts'
import { Erc20Factory } from '../const/types/Erc20Factory'
import { NoMintRewardPoolFactory } from '../const/types/NoMintRewardPoolFactory'
import { ETH_TOKEN_ADDRESS } from '../const/ethConst'
import { numberToTransactionalNumber, toLowBN } from '../helpers/toBN'
import { truncated } from '../helpers/formatValue'

export const getErc20FactoryInstance = (address, providerOrSigner) => {
  return Erc20Factory.connect(address, providerOrSigner)
}

export const getDoTheMoonFactoryInstance = (address, providerOrSigner) => {
  return DoTheMoonFactory.connect(address, providerOrSigner)
}

export const getNoMintRewardPoolFactoryInstance = (
  address,
  providerOrSigner
) => {
  return NoMintRewardPoolFactory.connect(address, providerOrSigner)
}

export const getPoolFromContract = async (
  poolAddress,
  // depositTokenAddress,
  provider
) => {
  try {
    const poolInstance = getDoTheMoonFactoryInstance(poolAddress, provider)
    const promises = [
      // poolInstance.userMinInvest(),
      // poolInstance.userMaxInvest(),
      poolInstance.totalStaked(),
      poolInstance.balances(provider.provider.selectedAddress),
      poolInstance.depositToken(),
      // poolInstance.investLimit(),
      // poolInstance.investStartTime(),
      // poolInstance.investFinishTime(),
    ]

    const [
      // { value: userMinInvest },
      // { value: userMaxInvest },
      { value: totalStaked },
      { value: userStaked },
      { value: depositToken },
      // { value: investLimit },
      // { value: investStartTime },
      // { value: investFinishTime },
    ] = await Promise.allSettled(promises)

    const decimals = await getDecimals(depositToken, provider)

    return {
      // userMinInvest: +userMinInvest
      //   ?.div(BigNumber.from(10).pow(decimals))
      //   .toString(),
      // userMaxInvest: +userMaxInvest
      //   ?.div(BigNumber.from(10).pow(decimals))
      //   .toString(),
      totalStaked: toLowBN(totalStaked, decimals).toNumber(),
      userStaked: toLowBN(userStaked, decimals).toNumber(),
      // investLimit: +investLimit
      //   ?.div(BigNumber.from(10).pow(decimals))
      //   .toString(),
      // investStartTime: +investStartTime.toString(),
      // investFinishTime: +investFinishTime.toString(),
    }
  } catch (error) {
    console.log({ error })
    return {
      // userMinInvest: 0,
      // userMaxInvest: 0,
      totalStaked: 0,
      userStaked: 0,
      // investLimit: 0,
      // investStartTime: 0,
      // investFinishTime: 0,
    }
  }
}

export const getDepositToken = async (poolAddress, provider) => {
  const instance = getDoTheMoonFactoryInstance(poolAddress, provider)
  try {
    return await instance.depositToken()
  } catch (e) {
    console.error('getDepositToken', e)
  }
  // return '0x05DB8263D0E4BD16E74d77c0a0018fD3803E4660' //instance.depositToken()
}

export const getUserPoolBalance = async (
  userAddress,
  poolAddress,
  provider
) => {
  const poolDeposit = await getDepositToken(poolAddress, provider)
  const userBalance = await getBalance(userAddress, poolDeposit, provider)
  const decimals = await getDecimals(poolDeposit, provider)
  return truncated(toLowBN(userBalance, decimals).toString(), 5)
}

export const getDecimals = async (tokenAddress, provider) => {
  if (tokenAddress === ETH_TOKEN_ADDRESS) {
    return 18
  }
  const instance = getErc20FactoryInstance(tokenAddress, provider)
  return instance.decimals()
}

export const getBalance = async (userAddress, tokenAddress, provider) => {
  if (tokenAddress === ETH_TOKEN_ADDRESS) {
    return provider.getBalance(userAddress)
  }
  const instance = getErc20FactoryInstance(tokenAddress, provider)
  return instance.balanceOf(userAddress)
}
export const getAllowance = async (
  userAddress,
  tokenAddress,
  poolAddress,
  provider
) => {
  if (tokenAddress === ETH_TOKEN_ADDRESS) {
    return BigNumber.from(
      '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff'
    )
  }
  const instance = getErc20FactoryInstance(tokenAddress, provider)
  return instance.allowance(userAddress, poolAddress)
}

export const swap = async (
  userAddress,
  poolAddress,
  amount,
  provider,
  poolId,
  password
) => {
  const depositTokenAddress = await getDepositToken(poolAddress, provider)
  const decimals = await getDecimals(depositTokenAddress, provider)
  const amountBigNumber = numberToTransactionalNumber(amount, decimals)
  const poolInstance = await getDoTheMoonFactoryInstance(
    poolAddress,
    provider.getSigner()
  )
  if (depositTokenAddress === ETH_TOKEN_ADDRESS) {
    const tx = await poolInstance.stake(amountBigNumber, password, {
      value: amountBigNumber,
    })
    await tx.wait()
  } else {
    const tx = await poolInstance.stake(amountBigNumber, password)
    await tx.wait()
  }
}

export const claim = async (poolAddress, provider) => {
  const poolInstance = getDoTheMoonFactoryInstance(
    poolAddress,
    provider.getSigner()
  )
  await poolInstance.claimReward()
}
// true = swap
export const checkApprove = async (
  userAddress,
  poolAddress,
  depositTokenAddress,
  amount,
  provider
) => {
  const approved = await getAllowance(
    userAddress,
    depositTokenAddress,
    poolAddress,
    provider
  )
  const decimals = await getDecimals(depositTokenAddress, provider)
  return toLowBN(approved, decimals).gte(amount)
}

export const approve = async (
  userAddress,
  poolAddress,
  depositTokenAddress,
  amount,
  provider
) => {
  const tokenInstance = getErc20FactoryInstance(
    depositTokenAddress,
    provider.getSigner()
  )
  // const decimals = await tokenInstance.decimals()

  const tx = await tokenInstance.approve(
    poolAddress,
    numberToTransactionalNumber(amount)
  )

  await tx.wait()
}

export const getFeePercent = async (poolAddress, provider) => {
  const poolInstance = getDoTheMoonFactoryInstance(poolAddress, provider)
  const freePercent = await poolInstance.feePercent()

  return freePercent.toString() / 10
}
//
// export const lockIn = async (
//   userAddress,
//   farmingPoolAddress,
//   depositTokenAddress,
//   amount,
//   provider
// ) => {
//   const farmingPoolInstance = getNoMintRewardPoolFactoryInstance(
//     farmingPoolAddress,
//     provider.getSigner()
//   )
//   const decimals = await farmingPoolInstance.decimals()
//
//   const tx = await farmingPoolInstance.approve(
//     farmingPoolAddress,
//     BigNumber.from(Math.floor(amount)).mul(BigNumber.from(10).pow(decimals))
//   )
//
//   await tx.wait()
// }
