import { useState, useEffect } from 'react'
import { useLocation } from 'react-router'

// Material-UI imports
import {
  Card,
  CardContent,
  Grid,
  Chip
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import { DaoAPI } from '../../../api/DaoAPI'
import { decodeDAOParams } from '../../../helpers/abiDecoder'
import { CopyToClipboardBtn } from '../../CopyToClipboard'
import { useWeb3React } from '@web3-react/core'

const useStyle = makeStyles((theme) => ({
  divider: {
    borderBottom: '1px solid rgb(224 224 224)',
  },
  cardTitle: {
    fontSize: 13,
    fontWeight: 600,
    fontStyle: 'normal',
    lineHeight: 4,
    margin: 0,
    paddingLeft: '16px',
  },
  cardContent: {
    borderRadius: '18px 0px 0px 18px',
    border: '1px solid rgba(214, 210, 242, 0.5)',
    boxShadow: '0px 51px 89px -49px rgba(196, 193, 232, 0.4)',
    width: '105%',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      borderRadius: '18px 18px 18px 18px',
    }
  },
  cardContentSecondary: {
    borderRadius: '18px',
    border: '1px solid rgba(214, 210, 242, 0.5)',
    boxShadow: '0px 51px 89px -49px rgba(196, 193, 232, 0.4)',
    width: '100%',
    background: '#F7F6FC',
  },
  cardInsideContent: {
    padding: '16px',
    height: '161px',
    overflow: 'auto'
  },
  fundManagerChip: {
    color: '#8765F2',
    background: 'rgba(137, 99, 244, 0.07)',
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: 15,
    borderRadius: '86px',
    '& .MuiTypography-body1': {
      fontFamily: 'Poppins',
      fontSize: '15px',
      fontWeight: 500,
      letterSpacing: 0
    }
  },
  loadBtnContent: {
    display: 'flex',
    justifyContent: 'center',
    padding: '16px',
    borderTop: '1px solid rgba(214, 210, 242, 0.5);',
  },
  loadBtn: {
    background: 'rgba(232, 99, 244, 0.07)',
    border: '1px solid #BC65F2',
    borderRadius: '5px',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '21px',
    textAlign: 'center',
    color: '#BC65F2',
  },
  content: {
    '& .MuiFormControlLabel-root': {
      marginRight: 0
    },
    display: 'flex',
    alignItems: 'center'
  },
}))

export const VoteForAppointCurrentFundManagers = (props) => {
  const classes = useStyle()
  const location = useLocation();
  const { connector } = useWeb3React()

  const daoAddress = location.state.state.data.subgraph.id

  const [currentManager, setCurrentManager] = useState<any>([])
  const [proposedManager, setProposedManager] = useState<any>('')

  useEffect(() => {
    const decodeAddresses = async () => {
      try {
        setCurrentManager(await DaoAPI.getManager(daoAddress, connector))
        const result = decodeDAOParams(props?.action)
        setProposedManager(result?.inputs[0])
      } catch (e) {
        console.log('Error message:', e)
      }
    }
    decodeAddresses()
      .catch(console.error)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Grid container>
      <Grid item lg={6} md={6} sm={6} xs={12}>
        <Card className={classes.cardContent}>
          <div className={classes.divider}>
            <p className={classes.cardTitle}>Fund managers to appoint</p>
          </div>
          <CardContent className={classes.cardInsideContent}>
            <Grid container>
              <Grid item lg={3}>
                <div className={classes.content}>
                  <Chip 
                    className={classes.fundManagerChip} 
                    label={('0x' + proposedManager.slice(0, 7) + '...' + proposedManager.slice(28, 41))} />
                  <CopyToClipboardBtn props={'0x' + proposedManager} />
                </div>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <Grid item lg={6} md={6} sm={6} xs={12}>
        <Card className={classes.cardContentSecondary}>
          <div className={classes.divider}>
            <p className={classes.cardTitle}>Current fund managers</p>
          </div>
          <CardContent className={classes.cardInsideContent}>
            <Grid container>
              <Grid item lg={3}>
                  <div className={classes.content}>
                    <Chip 
                      className={classes.fundManagerChip} 
                       label={(currentManager.slice(0, 7) + '...' + currentManager.slice(28, 41))} />
                      <CopyToClipboardBtn props={currentManager} />
                   </div>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  )
}
