export const SWAP_REQUEST = 'wallet.SWAP_REQUEST'
export const SWAP_SUCCESS = 'wallet.SWAP_SUCCESS'
export const SWAP_ERROR = 'wallet.SWAP_ERROR'

export const APPROVE_REQUEST = 'wallet.APPROVE_REQUEST'
export const APPROVE_SUCCESS = 'wallet.APPROVE_SUCCESS'
export const APPROVE_ERROR = 'wallet.APPROVE_ERROR'

export const CLAIM_REQUEST = 'wallet.CLAIM_REQUEST'
export const CLAIM_SUCCESS = 'wallet.CLAIM_SUCCESS'
export const CLAIM_ERROR = 'wallet.CLAIM_ERROR'

export const LOCK_IN_REQUEST = 'wallet.LOCK_IN_REQUEST'
export const LOCK_IN_SUCCESS = 'wallet.LOCK_IN_SUCCESS'
export const LOCK_IN_ERROR = 'wallet.LOCK_IN_ERROR'

export const RESET_ERROR = 'wallet.RESET_ERROR'
export const SET_ERROR = 'wallet.SET_ERROR'
