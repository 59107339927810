/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useMemo, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router'
import BigNumber from 'bignumber.js'

import { useSingleDao } from '../../hooks/useDaos'

// Components
import { BackgroundImage } from '../../components/BackgroundImage'
import { Button, ModalWallet, SwapCardInput } from '../../components'
import { CopyToClipboardBtn } from '../../components/CopyToClipboard'
import { ErrorMessage } from '../../components/ErrorMessage/ErrorMessage'

import useModals from '../../hooks/useModals'
import { ModalLoading } from '../../components/ModalLoading'
import { ModalSuccess } from '../../components/ModalSuccess'

import { NavigationBar } from '../../components/NavigationBar'
import { TransactionHash } from '../../components/ExplorerLinks/ExplorerLinks'
import { LightTooltip } from '../../components/CopyToClipboard/CopyToClipboardBtn'

// // Wallet
// import { WalletConnectorNames } from '../../const/walletConnectorNames'
// import {
//   getInjectedConnector,
//   getWalletConnectConnector,
//   setCurrentConnectionName,
// } from '../../helpers/wallet'

// WEB3 Imports
import { useWeb3React } from '@web3-react/core'
import { DaoAPI } from '../../api/DaoAPI'

// Material UI
import { makeStyles } from '@material-ui/core/styles'
import InfoIcon from '@material-ui/icons/Info'
import Grid from '@material-ui/core/Grid'
import { CardContent, Card, Box, Chip, Container } from '@material-ui/core'

// Assets
import approvingModalImg from '../../assets/images/approvingModal.svg'
import depositModalImg from '../../assets/images/depositModal.svg'
import RulesPageBackground from '../../assets/images/singePageBackground.svg'
import copySvg from '../../assets/icons/copyicon-light.svg'
import error404 from '../../assets/icons/icon-not-found.jpg'

import { convert, toUSD } from '../../helpers/currencyHelper'
import { moneyFormatter } from '../../helpers/moneyFormatter'

import { ID, routes } from '../../const/routes'
import moment from 'moment'

import { useAspisSupportedTokens, useDefiProtocols } from '../../hooks/useChain'
import { MAX_UINT } from '../../const/ethConst'
import { getNetworkConfig } from '../../const/networks'
import { useSearchParams } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: 100,
    paddingBottom: 50,
    display: 'inline-flex',
    '@media screen and (max-width: 700px)': {
      paddingTop: 35,
    },
  },
  contentDisplay: {
    display: 'flex',
    [theme.breakpoints.down('md')]: {
      display: 'grid',
    },
    [theme.breakpoints.down('xs')]: {
      display: 'block',
      paddingBottom: '16px',
    },
  },
  innerContent: {
    display: 'contents',
    [theme.breakpoints.down('md')]: {
      display: 'flex',
    },
    [theme.breakpoints.down('xs')]: {
      display: 'block',
    },
  },
  cardTitle: {
    background: '#8765F2',
    border: '1px solid rgba(214, 210, 242, 0.5)',
    boxShadow: '0px 51px 89px -49px rgba(196, 193, 232, 0.4)',
    borderRadius: '18px 18px 0px 0px',
    fontWeight: 600,
    fontSize: '13px',
    padding: 15,
    color: 'white',
  },
  cardTitleWhite: {
    border: '1px solid rgba(214, 210, 242, 0.5)',
    boxShadow: '0px 51px 89px -49px rgba(196, 193, 232, 0.4)',
    borderRadius: '18px 18px 0px 0px',
    fontWeight: 600,
    fontSize: '13px',
    padding: 15,
    background: '#FCFAFF;',
  },
  card: {
    borderRadius: 18,
    border: '1px solid #D6D2F280',
    boxShadow: '0px 51px 104px -80px rgba(196, 193, 232, 0.4)',
    margin: 16,
    [theme.breakpoints.down('sm')]: {
      margin: '25px 0 0 0',
    },
  },
  label: {
    fontWeight: 500,
    fontSize: '10px',
    color: '#4C4C66',
  },
  display: {
    display: 'grid',
  },
  infoText: {
    fontSize: '11px',
    color: '#4C4C66',
  },
  btnContent: {
    display: 'flex',
    justifyContent: 'center',
  },
  divider: {
    border: '1px solid #D6D2F280',
  },
  displayFlex: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'start',
  },
  displayInlineFlex: {
    display: 'inline-flex',
  },
  marginBtm1rem: {
    marginBottom: '1rem',
  },
  divWidth: {
    width: '100%',
  },
  title: {
    fontSize: 10,
    color: '#4C4C66',
    opacity: 0.67,
    '@media screen and (min-width: 600px)': {
      fontSize: 13,
    },
  },
  spanText: {
    fontSize: 20,
    fontWeight: 600,
    color: '#2F2F4D',
  },
  fundManagerChip: {
    color: '#8765F2',
    background: 'rgba(137, 99, 244, 0.07)',
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: 15,
    padding: 15,
    width: '80%',
  },
  infoText2: {
    display: 'flex',
    margin: '-15px 0px 16px 20px',
    fontSize: '11px',
    color: '#4C4C66',
    opacity: '0.67;',
  },
  contentHelper: {
    display: 'inline-flex',
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'center',
    background: '#FFFFFF',
    border: '1px solid #F7F1FF',
    borderRadius: '9px',
    margin: '10px 0',
  },
  insideContentHelper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    margin: '5px 10px',
    alignItems: 'baseline',
  },
  spanNumbers: {
    fontWeight: 600,
    fontSize: '18px',
    color: '#BC65F2',
  },
  votingTitle: {
    fontWeight: 600,
    fontSize: 12,
    color: '#8765F2',
  },
  tickIcon: {
    marginTop: 10,
    width: 18,
  },
  fullWidth: {
    width: '33.3333333%',
  },
  mainTitle: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
    [theme.breakpoints.up('md')]: {
      padding: '0 19px',
    },
  },
  logo: {
    width: '65px',
    height: '65px',
    [theme.breakpoints.down('xs')]: {
      width: '55px',
      height: '55px',
    },
  },
  pageTitle: {
    fontSize: '50px',
    fontWeight: 600,
    color: '#351F8E',
    marginLeft: '20px',
    marginTop: 0,
    [theme.breakpoints.down('xs')]: {
      marginLeft: '5px',
      fontSize: '30px',
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: '15px',
      marginBottom: '0px',
    },
  },
  brandTitle: {
    fontSize: '50px',
    fontWeight: 600,
    color: ' #C7C0E4',
    marginLeft: '12px',
    marginTop: 0,
    display: 'flex',
    alignItems: 'self-start',
    [theme.breakpoints.down('xs')]: {
      marginLeft: '5px',
      fontSize: '30px',
    },
  },
  chipTitle: {
    color: '#7D71A5',
    fontSize: '13px',
    background: 'rgba(133, 152, 201, 0.07)',
    fontFamily: 'Poppins',
    fontWeight: 600,
    padding: '20px',
    marginRight: '-20px',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  titleContent: {
    display: 'flex',
  },
  managerChip: {
    background: '#8963F4',
    color: 'white',
    cursor: 'pointer',
    fontSize: '14px',
    fontWeight: 500,
    padding: 20,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
    '&:after': {
      content: 'url(' + copySvg + ')',
    },
    '&:hover, &:focus': {
      background: '#8963F4',
    },
  },
  modalBody: {
    textAlign: 'center',
  },
  alertApproveSuccess: {
    zIndex: 1500,
  },
  infoContent: {
    alignItems: 'baseline',
    display: 'flex',
    '& svg': {
      width: '20px',
      position: 'relative',
      top: '7px',
      left: '3px',
    },
    '& a': {
      color: 'black',
    },
  },
  btnContentHelper: {
    width: '50%',
    margin: '0 auto 20px auto',
  },
  delegatedManagerContent: {
    display: 'flex',
    padding: '0 16px',
    [theme.breakpoints.down('sm')]: {
      padding: 0,
    },
  },
  currenciesDisplay: {
    display: 'flex',
  },
  icon: {
    width: '25px',
    marginRight: '5px',
    height: '25px',
  },
  defiContent: {
    display: 'flex',
    alignItems: 'center',
  },
  protocolName: {
    margin: 0,
    marginLeft: '10px',
    color: '#8A8AB5',
    fontWeight: 600,
    fontSize: '14px',
    letterSpacing: '0.1px',
  },
  tokenBadge: {
    color: 'white',
    height: '100%',
    padding: '1px 10px',
    fontSize: '13px',
    background: '#8765F2',
    fontWeight: 500,
    borderRadius: '57px',
    [theme.breakpoints.down('xs')]: {
      position: 'relative',
      top: '-14px',
    },
  },
  daoName: {
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      display: 'block',
    },
  },
  tradingAsset: {
    marginLeft: '5px',
    fontSize: '10px',
    fontWeight: 'bold',
  },
  tradingAssetSymbol: {
    fontSize: '12px',
  },
  tradingAssetAddress: {
    color: '#8765F2',
  },
  countdown: {
    fontSize: 14,
    color: '#4C4C66',
  },
}))

export const DaoRulesPage = (props) => {
  const [searchParams] = useSearchParams();

  const { isActive, account, connector } = useWeb3React()

  const { openModal, closeModal } = useModals()

  const classes = useStyles(props)
  const pathname = window.location.pathname

  const location = useLocation()
  const paths = location.pathname.split('/')
  const navigate = useNavigate()

  const daoAddress = paths[paths.length - 1]
  const chainId = searchParams.get('chainId')

  const dao = useSingleDao(daoAddress, chainId)

  const [daoConfig, setDaoConfig] = useState({})
  const [fees, setFees] = useState({})

  const [whitelistedUsers, setWhitelistedUsers] = useState([])

  const [decimals, setDecimals] = useState(0)
  const [depositAmount, setDepositAmount] = useState(0)

  const [insufficientMessage, setInsufficientMessage] = useState('')
  const [manager, setManager] = useState('')
  const [token, setDepositToken] = useState('')
  const [tokenSymbol, setTokenSymbol] = useState('')
  const [txnHash, setTxnHash] = useState('')
  const [priceInstabilityAlert, setPriceInstabilityAlert] = useState('')
  // const [fundraisingPhaseFinished, setFundraisingPhaseFinished] = useState(false)

  const [approved, setApproved] = useState(false)
  const [deposited, setDeposited] = useState(false)
  const [isApproving, setApproving] = useState(false)
  const [isDepositing, setDepositing] = useState(false)
  const [showConvertedAmount, setConvertedAmount] = useState(false)
  const [sufficientBalance, setSufficienceBalance] = useState(false)
  const [showAllWallets, setShowAllWallets] = useState(false)

  const supportedDefiProtocols = useDefiProtocols()
  const [selectedDefiProtocols, setSelectedDefiProtocols] = useState()
  const [selectedProtocolsObj, setSelectedProtocolsObj] = useState()

  const supportedTradingTokens = useAspisSupportedTokens([])
  const [selectedTradingTokens, setSelectedTradingTokens] = useState()

  const [currentPeriod, setCurrentPeriod] = useState('')
  const [remainingTime, setRemainingTime] = useState(0)
  const [endsInCountdown, setEndsInCountdown] = useState('')

  const rates = dao?.data?.rates
  const subgraph = dao?.data?.subgraph

  const pkg = subgraph?.packages[0]?.pkg

  const tokenDetails = location?.state?.token
  const supply = convert(location?.state?.supply, tokenDetails?.decimals)

  useEffect(() => {
    if (isActive && dao) {
      connector.activate(parseInt(dao.data.chainId))
    }
  }, [])

  useEffect(() => {
    if(isActive && Number(chainId) !== Number(dao.data.chainId)){
      connector.activate(parseInt(dao.data.chainId))
    }
  }, [chainId, dao])

  useEffect(() => {
    if(isActive && Number(chainId) !== Number(dao.data.chainId)){
      return
   }

    fetchSubgraphData()
  }, [isActive, supportedDefiProtocols, supportedTradingTokens, daoConfig?.configAddress])

  useEffect(() => {
    if(isActive && Number(chainId) !== Number(dao.data.chainId)){
       return
    }

    const newSelectedProtocols = []
    supportedDefiProtocols.forEach((protocol) => {
      if (
        selectedDefiProtocols.includes(protocol.protocolAddress.toLowerCase())
      ) {
        const { icon, protocolName, protocolAddress } = protocol
        const selected = { icon, protocolName, protocolAddress }
        newSelectedProtocols.push(selected)
      }
    })
    setSelectedProtocolsObj(newSelectedProtocols)
  }, [selectedDefiProtocols])

  useEffect(() => {
    if(isActive && Number(chainId) !== Number(dao.data.chainId)){
      return
    }

    if (Object.keys(daoConfig).length === 0) return
    const now = Date.now() / 1000
    const finishTime = parseInt(daoConfig.finishTime)
    if (now < finishTime) {
      setCurrentPeriod('Live')
      setRemainingTime(finishTime - now)
      return
    }
    const freezePeriod = parseInt(daoConfig.freezePeriod) * 3600
    if (!freezePeriod) return
    const withdrawalPeriod = parseInt(daoConfig.withdrawlWindow) * 3600
    const timeSinceFinish = now - finishTime
    const oneCycle = freezePeriod + withdrawalPeriod
    const currentCycle = timeSinceFinish % oneCycle
    if (currentCycle < freezePeriod) {
      setCurrentPeriod('Freeze Period')
      setRemainingTime(freezePeriod - currentCycle)
    } else {
      setCurrentPeriod('Withdrawal Period')
      setRemainingTime(oneCycle - currentCycle)
    }
  }, [daoConfig])

  useEffect(() => {
    if(isActive && Number(chainId) !== Number(dao.data.chainId)){
      return
    }

    const targetTime = Date.now() / 1000 + remainingTime
    const interval = setInterval(() => {
      const difference = targetTime - Date.now() / 1000
      if (difference <= 0) {
        clearInterval(interval)
      } else {
        const days = Math.floor(difference / 86400)
        let remainingSeconds = difference % 86400
        const hours = Math.floor(remainingSeconds / 3600)
        remainingSeconds = remainingSeconds % 3600
        const minutes = Math.floor(remainingSeconds / 60)
        const seconds = Math.floor(remainingSeconds % 60)
        setEndsInCountdown(
          `${days}d ${hours.toString().padStart(2, '0')}:${minutes
            .toString()
            .padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`
        )
      }
    }, 1000)

    return () => clearInterval(interval)
  }, [remainingTime])

  const closeDepositedModal = () => {
    setDeposited(false)
    navigate(`/funds/${subgraph?.id}?chainId=${dao.data.chainId}`)
  }
  const closeApprovedModal = () => setApproved(false)

  const accountErrorMessage = useMemo(() => {
    if (!account) return 'Wallet is locked, please unlock first'
  }, [account])

  const handleDepositToken = (token, decimals, symbol) => {
    setDepositAmount(0)
    setDepositToken(token)
    setDecimals(decimals)
    setTokenSymbol(symbol)
    setConvertedAmount(true)
    setSufficienceBalance(false)
  }

  const calculateTotalDeposits = (deposits, rates) => {
    let totalDeposits = new BigNumber(0)
    deposits.forEach((deposit) => {
      totalDeposits = totalDeposits.plus(
        toUSD(
          new BigNumber(deposit.amount)
            .dividedBy(`1e${deposit?.token?.decimals}`)
            .toNumber(),
          rates[deposit?.token?.symbol?.toUpperCase()]
        )
      )
    })
    return totalDeposits.toNumber()
  }

  const handleDepositAmount = (amount, balance) => {
    if (daoConfig?.maxDeposit === '-1' && daoConfig?.minDeposit === '-1') {
      setDeposit(amount, balance, tokenSymbol)
      return
    }

    const depositAmountUSD = toUSD(amount, rates[tokenSymbol])
    const existingDeposit = subgraph?.deposits?.filter(
      (d) => d.sender === account.toLowerCase()
    )
    const totalDeposits = calculateTotalDeposits(existingDeposit, rates)

    if (totalDeposits + amount > daoConfig.maxDeposit) {
      setInsufficientMessage('The total per wallet must be < max deposit')
      setDeposit(
        amount,
        false,
        null,
        'The total per wallet must be < max deposit'
      )
      return
    }

    if (
      depositAmountUSD > daoConfig?.maxDeposit ||
      depositAmountUSD < daoConfig?.minDeposit
    ) {
      setInsufficientMessage(
        `Deposit range is between ${daoConfig.minDeposit}$ - ${daoConfig.maxDeposit}$`
      )
      setDeposit(
        amount,
        false,
        null,
        `Deposit range is between ${daoConfig.minDeposit}$ - ${daoConfig.maxDeposit}$`
      )
      return
    }

    if (moment().format() < moment.unix(daoConfig?.startTime).format()) {
      setInsufficientMessage(`Fundraising period hasn’t started. Try later.`)
      setDeposit(
        amount,
        false,
        null,
        'Fundraising period hasn’t started. Try later.'
      )
      return
    }

    if (
      0.00097 * depositAmount < depositAmount &&
      depositAmount < 0.0103 * depositAmount
    ) {
      setPriceInstabilityAlert(
        'Attention: your deposit is close to the minimum allowed, the transaction may not take place due to price instability'
      )
    } else {
      setPriceInstabilityAlert('')
    }

    setDeposit(amount, balance, tokenSymbol)

    function setDeposit(amount, balance, tokenSymbol, message) {
      setDepositAmount(amount)
      setSufficienceBalance(amount === 0 || amount <= balance)
      setInsufficientMessage(
        message ||
          (amount > balance ? `Insufficient ${tokenSymbol} balance` : '')
      )
    }
  }

  const toggleMaxAmountToDeposit = async () => {
    try {
      const amount = await DaoAPI.getTokenBalance(
        token,
        account,
        decimals,
        connector
      )
      setDepositAmount(amount)
      setSufficienceBalance(true)
      if (amount === '0' || amount === 0) {
        setSufficienceBalance(false)
      }
      if (daoConfig.maxDeposit === '-1') {
        setDepositAmount(amount)
        setSufficienceBalance(true)
        setInsufficientMessage('')
      } else {
        if (toUSD(amount, rates[tokenSymbol]) >= daoConfig.maxDeposit) {
          setSufficienceBalance(false)
          setInsufficientMessage(
            `Deposit range is between ${daoConfig.minDeposit}$ - ${daoConfig.maxDeposit}$`
          )
        }
      }
    } catch (err) {
      console.log(err)
    }
  }

  const deposit = async () => {
    try {
      if (
        whitelistedUsers?.length === 0 ||
        whitelistedUsers?.includes(account)
      ) {
        if (
          !moment(moment.unix().format()).isBefore(
            moment.unix(daoConfig?.startTime).format()
          )
        ) {
          if (
            !moment(moment.unix().format()).isAfter(
              moment.unix(daoConfig?.finishTime).format()
            )
          ) {
            if (
              toUSD(depositAmount, rates[tokenSymbol]) +
                location?.state?.totalBalance <=
              daoConfig?.maxCap
            ) {
              const allowance = await DaoAPI.getAllowance(
                token,
                account,
                daoAddress,
                connector
              )
              if (
                depositAmount >
                new BigNumber(allowance).div(`1e${decimals}`).toFixed()
              ) {
                setApproving(true)
                const approve = await DaoAPI.approveDeposit(
                  daoAddress,
                  token,
                  account,
                  new BigNumber(depositAmount)
                    .multipliedBy(`1e${decimals}`)
                    .decimalPlaces(0, BigNumber.ROUND_DOWN)
                    .toFixed()
                    .toString(),
                  connector
                )
                if (approve) {
                  setApproved(true)
                  setApproving(false)
                  setDepositing(false)
                }
              }

              setDepositing(true)
              setApproved(false)

              const deposited = await DaoAPI.deposit(
                daoAddress,
                token,
                decimals,
                depositAmount,
                account,
                connector
              )
              if (deposited) {
                setTxnHash(deposited.transactionHash)
                setDepositing(false)
                setDeposited(true)
                setDepositAmount(0)
                setSufficienceBalance(false)
              }
            } else {
              setInsufficientMessage(
                `Your deposit amount exceeds fundraising target`
              )
              setSufficienceBalance(false)
            }
          } else {
            setInsufficientMessage(`Fundraising phase has ended!`)
            setSufficienceBalance(false)
          }
        } else {
          setInsufficientMessage(`Fundraising phase has not started yet!`)
          setSufficienceBalance(false)
        }
      } else {
        setInsufficientMessage(
          `You're not authorized to deposit. Your wallet is not allowlisted`
        )
        setSufficienceBalance(false)
      }
    } catch (err) {
      setDepositing(false)
      setApproving(false)
    }
  }

  const fetchSubgraphData = async () => {
    console.log('fetching subgraph')
    try {
      if (
        isActive &&
        account !== null &&
        account !== undefined &&
        dao !== null &&
        dao !== undefined
      ) {
        const network = getNetworkConfig(dao.data.chainId)
        const library = { provider: network.params.rpcUrls[0] }

        const daoConfig = await DaoAPI.getConfiguration(daoAddress, library)
        if (daoConfig){
          setDaoConfig(daoConfig)
        }

        const users = await DaoAPI.getWhitelistedUsers(daoAddress, library)
        if(users){
          setWhitelistedUsers(users)
        }

        const fee = await DaoAPI.getDaoFees(daoAddress, library)
        if (fee){
          setFees(fee)
        }

        const manager = await DaoAPI.getManager(daoAddress, library, true)
        if (manager){
          setManager(manager)
        }
      }
      if (
        isActive &&
        account !== null &&
        account !== undefined &&
        dao !== null &&
        dao !== undefined &&
        daoConfig?.configAddress
      ) {
        setSelectedDefiProtocols(
          await DaoAPI.getDefiProtocols(
            supportedDefiProtocols,
            daoConfig?.configAddress,
            connector
          )
        )

        const daoTradingTokens = await DaoAPI.getTradingTokens(
          daoConfig.configAddress,
          connector
        )
        const newSelectedTradingTokens = []
        supportedTradingTokens.forEach((token) => {
          if (daoTradingTokens.includes(token.address)) {
            newSelectedTradingTokens.push(token)
          }
        })
        setSelectedTradingTokens(newSelectedTradingTokens)
      }
    } catch (err) {
      console.log('Error message:', err)
    }
  }

  const disableJoining = () => {
    return moment().unix() <= daoConfig?.finishTime ? true : false
  }

  const onConnectorChange = async (connectorName, networkParams) => {
    connector.activate(parseInt(dao.data.chainId))
    closeModal()
  }

  const [copySuccess, setCopySuccess] = useState('Copy Address')

  const copyToClipBoard = async (copyMe) => {
    try {
      await navigator.clipboard.writeText(copyMe)
      setCopySuccess('Copied!')
      setTimeout(() => {
        setCopySuccess('Copy Address')
      }, 2000)
    } catch (err) {
      setCopySuccess('Failed to copy!')
    }
  }

  useEffect(() => {

    console.log('isActive', isActive)

    if (!isActive) {
      openModal(
        <ModalWallet
          title="Unlock Wallet"
          subTitle="Switch network to see full information"
          onClose={() => {
            navigate(`/funds/${daoAddress}?chainId=${dao.data.chainId}`)
            closeModal()
          }}
          onConnectorChange={onConnectorChange}
        />
      )
    }
  }, [])

  return (
    <Container>
      <BackgroundImage imageUrl={RulesPageBackground} />
      <NavigationBar
        link={routes.FUNDS.replace(ID, daoAddress) + `?chainId=${dao.data.chainId}`}
        pathName={pathname}
      />
      <Grid container>
        <Grid item lg={12} sm={12} md={12} xs={12}>
          <div className={classes.mainTitle}>
            <div className={classes.titleContent}>
              <img src={dao?.img} className={classes.logo} alt="daologo" />
              <h1 className={classes.pageTitle}>Rules</h1>
            </div>
            <div className={classes.daoName}>
              <h1 className={classes.brandTitle}>{subgraph?.name}</h1>
              <span className={classes.tokenBadge}>
                {subgraph?.token?.symbol}
              </span>
            </div>
          </div>
          <div className={classes.delegatedManagerContent}>
            <Chip className={classes.chipTitle} label="Manager" />
            <LightTooltip title={copySuccess}>
              <Chip
                className={classes.managerChip}
                label={manager.slice(0, 6) + '...' + manager.slice(36, 42)}
                onClick={() => copyToClipBoard(manager)}
              />
            </LightTooltip>
          </div>
        </Grid>
      </Grid>

      <div className={classes.contentDisplay}>
        {/* Depositer */}
        <Grid container>
          <Grid item lg={12} sm={12} md={12} xs={12}>
            <Card className={classes.card}>
              <div className={classes.cardTitle}>Amount</div>
              <CardContent className={classes.display}>
                {
                  <SwapCardInput
                    tokens={location?.state?.acceptedCurrencies}
                    className={classes.input}
                    value={depositAmount}
                    account={account}
                    fundraisingFinished={!disableJoining()}
                    handleDepositAmount={handleDepositAmount}
                    handleDepositToken={handleDepositToken}
                    toggleMaxAmountToDeposit={toggleMaxAmountToDeposit}
                  />
                }
                {insufficientMessage !== '' ? (
                  <ErrorMessage message={insufficientMessage} />
                ) : null}
                {showConvertedAmount ? (
                  <label className={classes.label}>
                    {depositAmount} {tokenSymbol} | $
                    {toUSD(depositAmount, rates[tokenSymbol])
                      .toString()
                      .match(/^-?\d+(?:\.\d{0,2})?/)}
                  </label>
                ) : null}
              </CardContent>
              <hr className={classes.divider} />
              <CardContent>
                {token !== '' ? (
                  <p className={classes.infoText}>
                    <b>You will get: </b>
                    {(
                      toUSD(depositAmount, rates[tokenSymbol]) /
                      (daoConfig?.initialPrice / Math.pow(10, 4))
                    ).toFixed(2)}{' '}
                    {tokenDetails?.symbol} tokens
                    {supply !== '0'
                      ? depositAmount !== 0
                        ? ` (${(
                            (toUSD(depositAmount, rates[tokenSymbol]) /
                              (supply +
                                toUSD(depositAmount, rates[tokenSymbol]))) *
                            100
                          ).toFixed(2)}% of all assets)`
                        : ' (0% of all assets)'
                      : ' (100% of all assets)'}
                  </p>
                ) : null}
                <div className={classes.btnContent}>
                  <Button
                    type="primary"
                    disabled={
                      !account ||
                      (sufficientBalance ? false : true) ||
                      !disableJoining() ||
                      depositAmount <= 0
                    }
                    onClick={deposit}
                  >
                    Deposit {tokenSymbol !== '' ? tokenSymbol : null}
                  </Button>
                </div>
                {priceInstabilityAlert ? (
                  <ErrorMessage message={priceInstabilityAlert} />
                ) : null}
                {disableJoining() ? null : (
                  <ErrorMessage message="Fundraising phase has finished, you cannot deposit anymore" />
                )}
                {accountErrorMessage && (
                  <Box className={classes.errorBox}>
                    <ErrorMessage message={accountErrorMessage} />
                  </Box>
                )}
              </CardContent>
            </Card>
            <ModalLoading
              open={isApproving}
              modalImg={approvingModalImg}
              title={'Approving in progress'}
              subTitle={'Go to metamask to confrim approving'}
            />
            <ModalSuccess
              open={approved}
              onCancel={closeApprovedModal}
              modalImg={approvingModalImg}
              title={'Approving is finished'}
              subTitle={'Now you are able to deposit funds'}
            />
            <ModalLoading
              open={isDepositing}
              modalImg={depositModalImg}
              title={'Depositing in progress'}
              subTitle={'Go to metamask to confirm transaction'}
            />
            <ModalSuccess
              open={deposited}
              onCancel={closeDepositedModal}
              modalImg={depositModalImg}
              title={'Funds have been deposited'}
              subTitle={
                <TransactionHash
                  chainId={location?.state?.chainId}
                  txnHash={txnHash}
                />
              }
            />
          </Grid>
        </Grid>

        <div className={classes.innerContent}>
          {/* Capital On Boarding */}
          <Grid container>
            <Grid item lg={12} sm={11} md={12} xs={12}>
              <Card className={classes.card}>
                <div className={classes.cardTitleWhite}>Capital onboarding</div>
                <CardContent className={classes.displayFlex}>
                  <div className={classes.divWidth}>
                    <p className={classes.title}>Deposit Range:</p>
                    {daoConfig?.minDeposit === '0' &&
                    daoConfig?.maxDeposit === MAX_UINT ? (
                      <span className={classes.spanText}>Any</span>
                    ) : (
                      <span className={classes.spanText}>
                        ${moneyFormatter(daoConfig?.minDeposit)} - $
                        {moneyFormatter(daoConfig?.maxDeposit)}
                      </span>
                    )}
                  </div>
                  <div className={classes.divWidth}>
                    <p className={classes.title}>Lock-up period</p>
                    <span className={classes.spanText}>
                      {daoConfig?.lockLimit} hours
                    </span>
                  </div>
                </CardContent>
                <CardContent className={classes.displayFlex}>
                  <div className={classes.divWidth}>
                    <p className={classes.title}>Accepted Currencies</p>
                    <div className={classes.currenciesDisplay}>
                      {location?.state?.acceptedCurrencies.map((item) => (
                        <a
                          href={item.link}
                          target="_blank"
                          rel="noreferrer"
                          key={item.symbol}
                        >
                          <img
                            className={classes.icon}
                            src={`https://cdn.jsdelivr.net/gh/atomiclabs/cryptocurrency-icons@1a63530be6e374711a8554f31b17e4cb92c25fa5/svg/color/${item.symbol}.svg`.toLowerCase()}
                            alt=""
                            title={item.symbol}
                            onError={({ currentTarget }) => {
                              currentTarget.onerror = null
                              currentTarget.src = error404
                            }}
                          />
                        </a>
                      ))}
                    </div>
                  </div>
                </CardContent>
                <CardContent>
                  <div>
                    <p className={classes.title}>
                      Allowlisted wallets to deposit
                    </p>
                    {whitelistedUsers?.length !== 0 ? (
                      ''
                    ) : (
                      <span className={classes.spanText}>
                        Anyone can deposit
                      </span>
                    )}
                    {showAllWallets
                      ? whitelistedUsers?.map((item) => {
                          return (
                            <div className={classes.displayFlex} key={item}>
                              <Chip
                                className={classes.fundManagerChip}
                                label={
                                  item.slice(0, 9) + '...' + item.slice(30)
                                }
                              />
                              <CopyToClipboardBtn props={item} />
                            </div>
                          )
                        })
                      : whitelistedUsers?.slice(0, 3).map((item) => {
                          return (
                            <div className={classes.displayFlex} key={item}>
                              <Chip
                                className={classes.fundManagerChip}
                                label={
                                  item.slice(0, 9) + '...' + item.slice(30)
                                }
                              />
                              <CopyToClipboardBtn props={item} />
                            </div>
                          )
                        })}
                  </div>
                </CardContent>
                {whitelistedUsers?.length !== 0 ? (
                  <div className={classes.btnContentHelper}>
                    <Button
                      type="secondaryOutlined"
                      disabled={whitelistedUsers?.length <= 3}
                      onClick={() => setShowAllWallets(!showAllWallets)}
                    >
                      {showAllWallets ? 'Show less' : 'View All wallets'}
                    </Button>
                  </div>
                ) : null}
              </Card>
              {/* End of Capital onboarding */}

              {/* Fees */}
              <Card className={classes.card}>
                <div className={classes.cardTitleWhite}>Fees</div>
                <CardContent>
                  <div className={classes.contentHelper}>
                    <div className={classes.insideContentHelper}>
                      <p className={classes.title}>
                        Entrance fee, % of deposit:
                      </p>
                      <span className={classes.spanNumbers}>
                        {fees.entranceFee
                          ? fees?.entranceFee / 100 + '%'
                          : null}
                      </span>
                    </div>
                  </div>
                  <div className={classes.contentHelper}>
                    <div className={classes.insideContentHelper}>
                      <p className={classes.title}>
                        Fund management fee, % of withdrawal
                      </p>
                      <span className={classes.spanNumbers}>
                        {fees.fundManagementFee
                          ? fees?.fundManagementFee / 100 + '%'
                          : null}
                      </span>
                    </div>
                  </div>
                  <div className={classes.contentHelper}>
                    <div className={classes.insideContentHelper}>
                      <p className={classes.title}>
                        Performance fee, % of total profit
                      </p>
                      <span className={classes.spanNumbers}>
                        {fees.performanceFee
                          ? fees?.performanceFee / 100 + '%'
                          : null}
                      </span>
                    </div>
                  </div>
                  <div className={classes.contentHelper}>
                    <div className={classes.insideContentHelper}>
                      <p className={classes.title}>
                        Rage quit fee, % of withdrawal
                      </p>
                      <span className={classes.spanNumbers}>
                        {fees.rageQuitFee
                          ? fees?.rageQuitFee / 100 + '%'
                          : null}
                      </span>
                    </div>
                  </div>
                </CardContent>
              </Card>
              {/* End of Fees */}
              {/* Voting rules<*/}
              <Card className={classes.card}>
                <div className={classes.cardTitleWhite}>Voting rules</div>
                <CardContent className={classes.displayFlex}>
                  <div className={classes.divWidth}>
                    <p className={classes.title}>Quorum</p>
                    <span className={classes.spanText}>
                      {pkg?.participationRequiredPct / 10 ** 16}%
                    </span>
                  </div>
                  <div className={classes.divWidth}>
                    <p className={classes.title}>Minimum approval</p>
                    <span className={classes.spanText}>
                      {pkg?.supportRequiredPct / 10 ** 16}%
                    </span>
                  </div>
                  <div className={classes.divWidth}>
                    <p className={classes.title}>Minimum Share</p>
                    <span className={classes.spanText}>
                      {pkg?.minRequiredTokenPct / 10 ** 16}%
                    </span>
                  </div>
                  <div className={classes.divWidth}>
                    <p className={classes.title}>Voting duration</p>
                    <span className={classes.spanText}>
                      {`${new BigNumber(pkg?.minDuration).dividedBy(3600)} ${
                        new BigNumber(pkg?.minDuration).dividedBy(3600) < 2
                          ? 'hour'
                          : 'hours'
                      }`}
                    </span>
                  </div>
                </CardContent>
                <CardContent className={classes.displayFlex}>
                  <div className={classes.divWidth}>
                    <p className={classes.title}>
                      Direct transfers from vault balance based on voting:
                    </p>
                    <span className={classes.spanText}>
                      {daoConfig?.isDirectTransferAllowed ? (
                        <span className={classes.spanTex}>
                          Manager can create direct transactions from the
                          vault's balance using voting
                        </span>
                      ) : (
                        <span className={classes.spanTex}>
                          Direct transactions from the vault are not allowed
                        </span>
                      )}
                    </span>
                  </div>
                </CardContent>
                <CardContent className={classes.displayFlex}>
                  <div className={classes.divWidth}>
                    <p className={classes.title}>Manager replacement:</p>
                    <span className={classes.spanText}>
                      {daoConfig?.canChangeManager ? (
                        <span className={classes.spanTex}>
                          Manager of this fund can be replaced through the
                          voting
                        </span>
                      ) : (
                        <span className={classes.spanTex}>
                          Manager of this fund can not be replaced through the
                          voting
                        </span>
                      )}
                    </span>
                  </div>
                </CardContent>
              </Card>
              {/* End of Voting rules */}
            </Grid>
          </Grid>

          {/* Capital off Boarding */}
          <Grid container>
            <Grid item lg={12} sm={12} md={12} xs={12}>
              <Card className={classes.card}>
                <div className={classes.cardTitleWhite}>
                  Capital Offboarding
                </div>
                <CardContent className={classes.displayFlex}>
                  <div className={classes.divWidth}>
                    <p className={classes.title}>
                      Time to redeem after request:
                    </p>
                    <span className={classes.spanText}>Instantly</span>
                  </div>
                  <div className={classes.divWidth}>
                    <p className={classes.title}>Withdrawal tokens:</p>
                    <span className={classes.spanText}>All tokens</span>
                  </div>
                </CardContent>
                <CardContent className={classes.displayFlex}>
                  <div className={classes.divWidth}>
                    <div className={classes.infoContent}>
                      <p className={classes.title}>Withdrawal windows</p>
                      <a
                        href="https://info.aspis.finance/features-description/dao-creation/withdrawal-windows-and-rage-quit"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <InfoIcon />
                      </a>
                    </div>
                    {daoConfig?.withdrawlWindow &&
                    daoConfig?.freezePeriod === '0' ? (
                      <span className={classes.spanText}>Turned off</span>
                    ) : (
                      <span className={classes.spanText}>Turned on</span>
                    )}
                  </div>
                </CardContent>
                <CardContent className={classes.displayFlex}>
                  <div className={classes.divWidth}>
                    <p className={classes.title}>
                      Withdraw without rage quit fine
                    </p>
                    {fees?.rageQuitFee > '0' ? (
                      <span className={classes.spanText}>
                        {daoConfig?.withdrawlWindow / 24} day every{' '}
                        {daoConfig?.freezePeriod / 24} days
                      </span>
                    ) : (
                      <span className={classes.spanText}>Anytime</span>
                    )}
                  </div>
                </CardContent>
                {currentPeriod && (
                  <CardContent>
                    <div className={classes.divWidth}>
                      <p className={classes.title}>Current Period</p>
                      <span className={classes.spanText}>{currentPeriod}</span>
                      <div
                        className={classes.countdown}
                      >{`Ends in: ${endsInCountdown}`}</div>
                    </div>
                  </CardContent>
                )}
                {/* <label className={classes.infoText2}>
                    Next window opening: 02.31.2022
                  </label> */}
              </Card>
              {/* End of Capital off boarding */}

              {/* Controlling */}
              <Card className={classes.card}>
                <div className={classes.cardTitleWhite}>
                  Allowed protocols and DEXes
                </div>
                {/* Defi protocols */}
                <CardContent>
                  <p className={classes.title}>Allowlisted protocols</p>
                  {selectedProtocolsObj?.map((protocol) => {
                    return (
                      <div
                        className={classes.marginBtm1rem}
                        key={protocol.protocolName}
                      >
                        <div className={classes.defiContent}>
                          <img width={30} src={protocol.icon} alt="icon" />
                          <p className={classes.protocolName}>
                            {' '}
                            {protocol.protocolName}
                          </p>
                        </div>
                        <div className={classes.displayFlex}>
                          <Chip
                            className={classes.fundManagerChip}
                            label={
                              protocol.protocolAddress.slice(0, 9) +
                              '...' +
                              protocol.protocolAddress.slice(30)
                            }
                          />
                          <CopyToClipboardBtn props={protocol} />
                        </div>
                      </div>
                    )
                  })}
                </CardContent>
                {/* end of defi protocols */}
              </Card>
              {/* End of Controlling */}
              <Card className={classes.card}>
                <div className={classes.cardTitleWhite}>
                  Allowed assets for trading
                </div>
                <CardContent>
                  {selectedTradingTokens?.map((token) => {
                    return (
                      <div className={classes.marginBtm1rem} key={token.symbol}>
                        <div className={classes.displayInlineFlex}>
                          <img
                            alt=""
                            width={34}
                            height={34}
                            onError={({ currentTarget }) => {
                              currentTarget.onerror = null
                              currentTarget.src = error404
                            }}
                            src={`https://cdn.jsdelivr.net/gh/atomiclabs/cryptocurrency-icons@1a63530be6e374711a8554f31b17e4cb92c25fa5/svg/color/${token.symbol}.svg`.toLowerCase()}
                          />
                          <div className={classes.tradingAsset}>
                            <div className={classes.tradingAssetSymbol}>
                              {token.symbol}
                            </div>
                            <div className={classes.tradingAssetAddress}>
                              {token.address}{' '}
                              <CopyToClipboardBtn
                                props={token.address}
                                size={{ img: 10, circle: 20 }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  })}
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </div>
      </div>
    </Container>
  )
}
