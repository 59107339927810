import {
  FETCH_DAO_REQUEST,
  FETCH_DAO_SUCCESS,
  FETCH_DAO_ERROR,
  FETCH_SINGLE_DAO_REQUEST,
  FETCH_SINGLE_DAO_SUCCESS,
  FETCH_SINGLE_DAO_ERROR,
} from '../types/dao.types'

const initialState = {
  daos: [],
  loading: false,
  error: null,
  dao: null,
}

const daos = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_DAO_REQUEST:
      return { ...state, loading: true }
    case FETCH_DAO_SUCCESS:
      return { ...state, loading: false, daos: action.daos }
    case FETCH_DAO_ERROR:
      return { ...state, loading: false, error: action.message }
    case FETCH_SINGLE_DAO_REQUEST:
      return { ...state, loading: true }
    case FETCH_SINGLE_DAO_SUCCESS:
      return { ...state, loading: false, dao: action.dao }
    case FETCH_SINGLE_DAO_ERROR:
      return { ...state, loading: false, error: action.message }
    default:
      return state
  }
}

export default daos
