import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  pageMainBackground: {
    position: 'absolute',
    width: '100%',
    left: '0',
    zIndex: '-1',
    '@media screen and (max-width: 644px)': {
      width: '100%',
      height: '500px',
    },
  },
}))

export const BackgroundImage = ({ props, imageUrl }) => {
  const classes = useStyles(props)

  return (
    <>
      <img
        src={imageUrl}
        className={classes.pageMainBackground}
        alt="pagemainImg"
      />
    </>
  )
}
