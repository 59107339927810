import { makeStyles } from '@material-ui/core/styles';
import { Button, Card, Container, Grid, Typography } from '@material-ui/core';
import { socialMedias } from '../const/socialMedias';
import Linkify from 'react-linkify';

const useStyles = makeStyles ((theme)=>({
    descriptionCard:{
        boxSizing: 'border-box',
        boxShadow: '0px 51px 89px -49px rgba(196, 193, 232, 0.4)',
        borderRadius: 18,
      },
      container:{
        paddingTop: 100,
        paddingBottom: 50
      },
      descriptionTitle:{
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: 13,
        lineHeight: 4
      },
      descriptionContent:{
        fontFamily: 'Poppins',
        fontWeight: 500,
        fontSize: 11,
        lineHeight: 2,
        color:'#4C4C66',
        paddingTop: 15,
        paddingBottom: 15,
        whiteSpace: 'pre-line'
      },
      websiteButton:{
        background: '#BC65F2',
        fontFamily: 'Poppins',
        fontWeight: 600,
        fontSize: 11,
        color: 'white',
        padding: 15,
      },
      docsButton:{
        background: '#929AB6',
        fontFamily: 'Poppins',
        fontWeight: 600,
        fontSize: 11,
        color: 'white',
        padding: '15px 25px',
        marginLeft: '5px'
      },
      buttonContainer:{
        display: 'flex',
        margin: '25px 24px'
      },
      descriptionContainer:{
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
        paddingLeft:24,
        paddingRight:24
      },
      padding0:{
        paddingLeft: 0,
        paddingRight: 0
      },
      iconMargin:{
        marginRight: 5
      },
      tags: {
        padding: 0,
        margin: 0,
        display: 'flex',
        justifyContent: 'space-between',
        '& li': {
          listStyle: 'none',
          '& a': {
            textDecoration: 'none',
            display: 'inline-block',
          },
        },
      },
      tagsRight: {
        height: 26,
        width: 110,
        '& li': {
          '& a': {
            '& img': {
              width: 26,
              height: 26,
            },
          },
        },
        '@media screen and (max-width: 475px)': {
          margin: '16px 0 0 0',
        },
      },
      list: {
        padding: 0,
        margin: 0,
        display: 'flex',
        flex: 2,
        justifyContent: 'space-between',
        gap: 20
      },
      socialIconList: {
        display: 'flex',
        justifyContent: 'flex-end',
        gap: 4,
      },
      [theme.breakpoints.down('xs')]:{
       socialIconList:{
        gap: '24px',
        marginTop: '5px'
       },
      },
      listSocialIconItem: {
        listStyle: 'none',
        '&:last-child': {
          marginBottom: 0,
        },
      },
      socialMediaIcon:{
        width: '100%'
      },
      blogIcon:{
        width: '38px',
        height: '38px',
        // backgroundColor: "#929AB6",
        // padding:'6px',
        // borderRadius: '50%',
        // margin:0
      },
      noLinks:{
        display: 'none'
      }

}));

export const DescriptionCard = (props: any) => {
  const classes = useStyles();

  const website = props?.dao?.links?.website !== undefined
  const docs = props?.dao?.links?.docs !== undefined
  const twitter = props?.dao?.links?.twitter !== undefined
  const telegram = props?.dao?.links?.telegram !== undefined
  const medium = props?.dao?.links?.medium !== undefined

  return (
    <Container className={classes.padding0}>
      <Card variant="outlined" className={classes.descriptionCard}>
        <Container className={classes.descriptionContainer}>
          <Typography component={'div'} className={classes.descriptionTitle}>
            Description
          </Typography>
        </Container>
        <Container className={classes.descriptionContainer}>
          <Typography component={'div'} className={classes.descriptionContent}>
            <Linkify>{props?.dao?.description}</Linkify>
          </Typography>
        </Container>
        <div className={classes.buttonContainer}>
          <Grid container>
          <Grid item lg={3} md={3} sm={3} xs={6}>
          {website ? (
          <Button
            className={classes.websiteButton}
            href={props?.dao?.links?.website}
            target="_blank"
          >
            <img
              src={socialMedias[0].src}
              className={classes.iconMargin}
              alt={socialMedias[0].alt}
            />{' '}
            Website
          </Button>
          ):(
            <Button className={classes.noLinks}></Button>
          )}
          </Grid>
          <Grid item lg={3} md={3} sm={3} xs={6}>
          {docs ? (
          <Button
            className={classes.docsButton}
            href={props?.dao?.links?.docs}
            target="_blank"
          >
            <img src={socialMedias[1].src} 
                 className={classes.iconMargin} 
                 alt={socialMedias[1].alt}/>
            Docs
          </Button>
          ):(
            <Button className={classes.noLinks}></Button>
          )}
          </Grid>
          <ul className={`${classes.list} ${classes.socialIconList}`}>
            <li className={classes.listSocialIconItem}>
              <Grid item lg={12} xs={12}>
              {twitter ? (
              <a
                href={props?.dao?.links?.twitter}
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={socialMedias[2].src}
                  className={classes.socialMediaIcon}
                  alt={socialMedias[2].alt}
                />
              </a>
              ):(
                <Button className={classes.noLinks}></Button>
              )}
              </Grid>
            </li>
            <li className={classes.listSocialIconItem}>
              <Grid item lg={12}>
              {telegram ? (
              <a
                href={props?.dao?.links?.telegram}
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={socialMedias[3].src}
                  className={classes.socialMediaIcon}
                  alt={socialMedias[3].alt}
                />
              </a>
              ):(
                <Button className={classes.noLinks}></Button>
              )}
              </Grid>
            </li>
            <li className={classes.listSocialIconItem}>
              <Grid item lg={12}>
              {medium ? (
              <a
                href={props?.dao?.links?.medium}
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={socialMedias[4].src}
                  className={classes.blogIcon}
                  alt={socialMedias[4].alt}
                />
              </a>
              ):(
                <Button className={classes.noLinks}></Button>
              )}
              </Grid>
            </li>
          </ul>
          </Grid>
        </div>
      </Card>
    </Container>
  )
}
