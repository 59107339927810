import { LinearProgress, makeStyles, withStyles } from '@material-ui/core'


export const useStyles = makeStyles((theme) => ({
  root: {
    flexDirection: 'column',
  },
  valueSection: {
    '& *': {
      lineHeight: '150%',
    },
  },
  valueSectionHeader: {
    display: 'inline-block',
    margin: '0 0 4px 0',
    fontWeight: 500,
    background: 'rgba(255, 140, 237, 0.3)',
    color: '#E51BC5',
    fontSize: '16px',
    borderRadius: 15,
    textAlign: 'center',
    padding: '3px 25px'
  },
  valueSectionValues: {
    justifyContent: 'space-between',
    alignItems: 'baseline',
    margin: '0 0 16px 0',
    '& > span': {
      margin: '0 0 8px 0',
      '&:first-child': {
        color: theme.palette.twentieth.main,
        fontWeight: 'bold',
        fontSize: 28,
        '@media screen and (max-width: 400px)': {
          fontSize: 22,
          lineHeight: '150%',
        },
      },
    },
    '@media screen and (max-width: 400px)': {
      margin: 0,
    },
  },
  swapProgressSection: {
    // margin: '0 0 28px 0',
    '@media screen and (max-width: 400px)': {
      margin: '5px 0 0 0',
    },
  },
  swapProgressTitle: {
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '17px',
    margin: '0 0 12px 0',
  },
  progressBar: {
    margin: '0 0 8px 0',
    width: '100%',
  },
  swapProgressValues: {
    color: theme.palette.twentyFifth.main,
    fontWeight: 'bold',
    fontSize: 12,
    lineHeight: '15px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  tokenValues: {
    justifyContent: 'space-between',
    margin: '-5px -10px 19px -10px',
    width: 'auto',
    '& > span': {
      fontWeight: 'bold',
      fontSize: 12,
      lineHeight: '15px',
      padding: '5px 10px 5px 10px',
      color: theme.palette.twentyFifth.main,
      '&:first-child': {
        color: theme.palette.twentyFifth.main,
      },
      '&:last-child': {
        color: theme.palette.twentySeventh.main,
      },
    },
    '@media screen and (max-width: 400px)': {
      margin: '0 0 20px 0',
    },
  },
  buttons: {
    justifyContent: 'flex-end',
  },
  button: {
    width: 92,
    fontSize: 16,
    '&:last-child': {
      margin: '0 0 0 14px',
    },
    '@media screen and (max-width: 400px)': {
      width: '100%',
      '&:last-child': {
        margin: '12px 0 0 0 !important',
      },
    },
  },
  disclaimer: {
    alignSelf: 'center',
    textAlign: 'right',
    fontSize: 14,
    color: theme.palette.nineteenth.main,
  },
  approveButton: {
    width: 'auto',
    padding: '0 20px 0 20px',
  },
  errorBox: {
    // flex: 1,
    textAlign: 'right'
  },
  nameCard: {
    width: '100%',
    justifySelf: 'center',
    maxWidth: 584,
    display: 'flex',
    [theme.breakpoints.up('lg')]: {
      gridArea: 'nameCard',
      maxWidth: '100%',
    },
  },
  poolIcon: {
    display: 'block',
    maxWidth: 50,
    maxHeight: 50,
    [theme.breakpoints.up('sm')]: {
      marginBottom: 37,
    },
  },
  poolName: {
    fontFamily: theme.fontFamilies.primary,
    fontWeight: 600,
    fontSize: 22,
    letterSpacing: 2,
    margin: '2px 0 24px 5px',
    '@media screen and (max-width: 400px)': {
      margin: '0 0 33px 0',
    },
  },
  cardHeader:{
    display: 'inline-grid',
    marginLeft: 15
  },
  cardInfo:{
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: 24,
    lineHeight: '217%'
  },
  cardTextInfo:{
    fontSize: 12,
    lineHeight: '217%',
    color: '#4C4C66',
    opacity: 0.67
  },
  card: {
    width: '100%',
    padding: '20px',
    fontFamily: theme.fontFamilies.primary,
    justifySelf: 'center',
    maxWidth: 584,
    background: '#FFFFFF',
    border: '1px solid rgba(214, 210, 242, 0.5)',
    boxShadow: '0px 51px 89px -49px rgb(196 193 232 / 40%)',
    borderRadius: '18px',
    [theme.breakpoints.up('sm')]: {
      padding: '24px 32px',
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: '100%',
    },
  },
  securityCard:{
    width: '100%',
    marginLeft: 10,
    marginTop: 50
  }
}))

export const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 8,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: theme.palette.twentySixth.main,
  },
  bar: {
    borderRadius: 5,
    backgroundColor: theme.palette.primary.main,
  },
}))(LinearProgress)
