export const supportedFundsList = {
  1: {
    network: 'Ethereum',
    tokens: [
      {
        baseToken: 'ETH',
        tokenAddress: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE',
        priceFeed: '0x5f4ec3df9cbd43714fe2740f5e3616155c5b8419',
        decimals: 18,
      },
      {
        baseToken: 'WETH',
        tokenAddress: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
        priceFeed: '0x5f4ec3df9cbd43714fe2740f5e3616155c5b8419',
        decimals: 18,
      },
      {
        baseToken: 'WBTC',
        tokenAddress: '0x2260fac5e5542a773aa44fbcfedf7c193bc2c599',
        priceFeed: '0xf4030086522a5beea4988f8ca5b36dbc97bee88c',
        decimals: 8,
      },
      {
        baseToken: 'LINK',
        tokenAddress: '0x514910771af9ca656af840dff83e8264ecf986ca',
        priceFeed: '0x2c1d072e956affc0d435cb7ac38ef18d24d9127c',
        decimals: 18,
      },
      {
        baseToken: 'SXP',
        tokenAddress: '0x8ce9137d39326ad0cd6491fb5cc0cba0e089b6a9',
        priceFeed: '0xfb0cfd6c19e25db4a08d8a204a387cea48cc138f',
        decimals: 18,
      },
      {
        baseToken: 'SNX',
        tokenAddress: '0xc011a73ee8576fb46f5e1c5751ca3b9fe0af2a6f',
        priceFeed: '0xdc3ea94cd0ac27d9a86c180091e7f78c683d3699',
        decimals: 18,
      },
      {
        baseToken: 'COMP',
        tokenAddress: '0xc00e94cb662c3520282e6f5717214004a7f26888',
        priceFeed: '0xdbd020caef83efd542f4de03e3cf0c28a4428bd5',
        decimals: 18,
      },
      {
        baseToken: 'KNC',
        tokenAddress: '0xdeFA4e8a7bcBA345F687a2f1456F5Edd9CE97202',
        priceFeed: '0xf8ff43e991a81e6ec886a3d281a2c6cc19ae70fc',
        decimals: 18,
      },
      {
        baseToken: 'AAVE',
        tokenAddress: '0x7Fc66500c84A76Ad7e9c93437bFc5Ac33E2DDaE9',
        priceFeed: '0x547a514d5e3769680ce22b2361c10ea13619e8a9',
        decimals: 18,
      },
      {
        baseToken: 'UNI',
        tokenAddress: '0x1f9840a85d5af5bf1d1762f925bdaddc4201f984',
        priceFeed: '0x553303d460ee0afb37edff9be42922d8ff63220e',
        decimals: 18,
      },
      {
        baseToken: 'YFI',
        tokenAddress: '0x0bc529c00C6401aEF6D220BE8C6Ea1667F6Ad93e',
        priceFeed: '0xa027702dbb89fbd58938e4324ac03b58d812b0e1',
        decimals: 18,
      },
      {
        baseToken: 'MATIC',
        tokenAddress: '0x7d1afa7b718fb893db30a3abc0cfc608aacfebb0',
        priceFeed: '0x7bac85a8a13a4bcd8abb3eb7d6b4d632c5a57676',
        decimals: 18,
      },
      {
        baseToken: 'FXS',
        tokenAddress: '0x3432b6a60d23ca0dfca7761b7ab56459d9c964d0',
        priceFeed: '0x6ebc52c8c1089be9eb3945c4350b68b8e4c2233f',
        decimals: 18,
      },
      {
        baseToken: '1INCH',
        tokenAddress: '0x111111111117dc0aa78b770fa6a738034120c302',
        priceFeed: '0xc929ad75b72593967de83e7f7cda0493458261d9',
        decimals: 18,
      },
      {
        baseToken: 'CRV',
        tokenAddress: '0xD533a949740bb3306d119CC777fa900bA034cd52',
        priceFeed: '0xcd627aa160a6fa45eb793d19ef54f5062f20f33f',
        decimals: 18,
      },
      {
        baseToken: 'ANKR',
        tokenAddress: '0x8290333cef9e6d528dd5618fb97a76f268f3edd4',
        priceFeed: '0x7eed379bf00005cfed29fed4009669de9bcc21ce',
        decimals: 18,
      },
      {
        baseToken: 'SAND',
        tokenAddress: '0x3845badAde8e6dFF049820680d1F14bD3903a5d0',
        priceFeed: '0x35e3f7e558c04ce7eee1629258ecbba03b36ec56',
        decimals: 18,
      },
      {
        baseToken: 'renDOGE',
        tokenAddress: '0x3832d2F059E55934220881F831bE501D180671A7',
        priceFeed: '0x2465cefd3b488be410b941b1d4b2767088e2a028',
        decimals: 18,
      },
      {
        baseToken: 'MKR',
        tokenAddress: '0x9f8f72aa9304c8b593d555f12ef6589cc3a579a2',
        priceFeed: '0xec1d1b3b0443256cc3860e24a46f108e699484aa',
        decimals: 18,
      },
      {
        baseToken: 'SUSHI',
        tokenAddress: '0x6b3595068778dd592e39a122f4f5a5cf09c90fe2',
        priceFeed: '0xcc70f09a6cc17553b2e31954cd36e4a2d89501f7',
        decimals: 18,
      },
      {
        baseToken: 'wAVAX',
        tokenAddress: '0x85f138bfee4ef8e540890cfb48f620571d67eda3',
        priceFeed: '0xff3eeb22b5e3de6e705b44749c2559d704923fd7',
        decimals: 18,
      },
      {
        baseToken: 'stETH',
        tokenAddress: '0xae7ab96520de3a18e5e111b5eaab095312d7fe84',
        priceFeed: '0xcfe54b5cd566ab89272946f602d76ea879cab4a8',
        decimals: 18,
      },
      {
        baseToken: 'EURt',
        tokenAddress: '0xC581b735A1688071A1746c968e0798D642EDE491',
        priceFeed: '0x01d391a48f4f7339ac64ca2c83a07c22f95f587a',
        decimals: 6,
      },
      {
        baseToken: 'RSR',
        tokenAddress: '0x320623b8e4ff03373931769a31fc52a4e78b5d70',
        priceFeed: '0x759bbc1be8f90ee6457c44abc7d443842a976d02',
        decimals: 18,
      },
      {
        baseToken: 'wSOL',
        tokenAddress: '0xd31a59c85ae9d8edefec411d448f90841571b89c',
        priceFeed: '0x4ffc43a60e009b551865a93d232e33fce9f01507',
        decimals: 18,
      },
      {
        baseToken: 'SPELL',
        tokenAddress: '0x090185f2135308bad17527004364ebcc2d37e5f6',
        priceFeed: '0x8c110b94c5f1d347facf5e1e938ab2db60e3c9a8',
        decimals: 18,
      },
      {
        baseToken: 'RPL',
        tokenAddress: '0xd33526068d116ce69f19a9ee46f0bd304f21a51f',
        priceFeed: '0x4e155ed98afe9034b7a5962f6c84c86d869daa9d',
        decimals: 18,
      },
      {
        baseToken: 'STG',
        tokenAddress: '0xaf5191b0de278c7286d6c7cc6ab6bb8a73ba2cd6',
        priceFeed: '0x7a9f34a0aa917d438e9b6e630067062b7f8f6f3d',
        decimals: 18,
      },
      {
        baseToken: 'CAKE',
        tokenAddress: '0x152649eA73beAb28c5b49B26eb48f7EAD6d4c898',
        priceFeed: '0xeb0adf5c06861d6c07174288ce4d0a8128164003',
        decimals: 18,
      },
      {
        baseToken: 'BAL',
        tokenAddress: '0xba100000625a3754423978a60c9317c58a424e3d',
        priceFeed: '0xdf2917806e30300537aeb49a7663062f4d1f2b5f',
        decimals: 18,
      },
      {
        baseToken: 'BADGER',
        tokenAddress: '0x3472A5A71965499acd81997a54BBA8D852C6E53d',
        priceFeed: '0x66a47b7206130e6ff64854ef0e1edfa237e65339',
        decimals: 18,
      },
      {
        baseToken: 'IMX',
        tokenAddress: '0xf57e7e7c23978c3caec3c3548e3d615c346e79ff',
        priceFeed: '0xbaebefc1d023c0feccc047bff42e75f15ff213e6',
        decimals: 18,
      },
      {
        baseToken: 'CVX',
        tokenAddress: '0x4e3fbd56cd56c3e72c1403e103b45db9da5b9d2b',
        priceFeed: '0xd962fc30a72a84ce50161031391756bf2876af5d',
        decimals: 18,
      },
    ],
  },
  5: {
    network: 'Goerli',
    tokens: [
      {
        baseToken: 'ETH',
        tokenAddress: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE',
        priceFeed: '0xD4a33860578De61DBAbDc8BFdb98FD742fA7028e',
        decimals: 18,
      },
      {
        baseToken: 'WETH',
        tokenAddress: '0xB4FBF271143F4FBf7B91A5ded31805e42b2208d6',
        priceFeed: '0xD4a33860578De61DBAbDc8BFdb98FD742fA7028e',
        decimals: 18,
      },
      {
        baseToken: 'WBTC',
        tokenAddress: '0xC04B0d3107736C32e19F1c62b2aF67BE61d63a05',
        priceFeed: '0xA39434A63A52E749F02807ae27335515BA4b07F7',
        decimals: 18,
      },
      {
        baseToken: 'LINK',
        tokenAddress: '0x326C977E6efc84E512bB9C30f76E30c160eD06FB',
        priceFeed: '0x48731cF7e84dc94C5f84577882c14Be11a5B7456',
        decimals: 18,
      },
      {
        baseToken: 'USDC',
        tokenAddress: '0x7A04986ea1103712C3e066D0608dbEF7490DeFfe',
        priceFeed: '0xAb5c49580294Aff77670F839ea425f5b78ab3Ae7',
        decimals: 6,
      },
      {
        baseToken: 'USDT',
        tokenAddress: '0xAd2A5257b0846e952ee2B58328a01a67E722CdA6',
        priceFeed: '0xAb5c49580294Aff77670F839ea425f5b78ab3Ae7',
        decimals: 6,
      },
    ],
  },
  56: {
    network: 'Bsc',
    tokens: [
      {
        baseToken: 'BNB',
        tokenAddress: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE',
        priceFeed: '0x0567f2323251f0aab15c8dfb1967e4e8a7d42aee',
        decimals: 18,
      },
      {
        baseToken: 'WBNB',
        tokenAddress: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
        priceFeed: '0x0567f2323251f0aab15c8dfb1967e4e8a7d42aee',
        decimals: 18,
      },
      {
        baseToken: 'ETH',
        tokenAddress: '0x2170Ed0880ac9A755fd29B2688956BD959F933F8',
        priceFeed: '0x9ef1b8c0e4f7dc8bf5719ea496883dc6401d5b2e',
        decimals: 18,
      },
      {
        baseToken: 'BTC',
        tokenAddress: '0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c',
        priceFeed: '0x264990fbd0a4796a3e3d8e37c4d5f87a3aca5ebf',
        decimals: 18,
      },
      {
        baseToken: 'BCH',
        tokenAddress: '0x8fF795a6F4D97E7887C79beA79aba5cc76444aDf',
        priceFeed: '0x43d80f616daf0b0b42a928eed32147dc59027d41',
        decimals: 18,
      },
      {
        baseToken: 'XVS',
        tokenAddress: '0xcF6BB5389c92Bdda8a3747Ddb454cB7a64626C63',
        priceFeed: '0xbf63f430a79d4036a5900c19818aff1fa710f206',
        decimals: 18,
      },
      {
        baseToken: 'USDC',
        tokenAddress: '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d',
        priceFeed: '0x51597f405303c4377e36123cbc172b13269ea163',
        decimals: 18,
      },
      {
        baseToken: 'BUSD',
        tokenAddress: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
        priceFeed: '0xcbb98864ef56e9042e7d2efef76141f15731b82f',
        decimals: 18,
      },
      {
        baseToken: 'SXP',
        tokenAddress: '0x47BEAd2563dCBf3bF2c9407fEa4dC236fAbA485A',
        priceFeed: '0xe188a9875af525d25334d75f3327863b2b8cd0f1',
        decimals: 18,
      },
      {
        baseToken: 'LTC',
        tokenAddress: '0x4338665CBB7B2485A8855A139b75D5e34AB0DB94',
        priceFeed: '0x74e72f37a8c415c8f1a98ed42e78ff997435791d',
        decimals: 18,
      },
      {
        baseToken: 'LINK',
        tokenAddress: '0xF8A0BF9cF54Bb92F17374d9e9A321E6a111a51bD',
        priceFeed: '0xca236e327f629f9fc2c30a4e95775ebf0b89fac8',
        decimals: 18,
      },
      {
        baseToken: 'USDT',
        tokenAddress: '0x55d398326f99059fF775485246999027B3197955',
        priceFeed: '0xb97ad0e74fa7d920791e90258a6e2085088b4320',
        decimals: 18,
      },
      {
        baseToken: 'FIL',
        tokenAddress: '0x0D8Ce2A99Bb6e3B7Db580eD848240e4a0F9aE153',
        priceFeed: '0xe5dbfd9003bff9df5feb2f4f445ca00fb121fb83',
        decimals: 18,
      },
      {
        baseToken: 'XRP',
        tokenAddress: '0x1D2F0da169ceB9fC7B3144628dB156f3F6c60dBE',
        priceFeed: '0x93a67d414896a280bf8ffb3b389fe3686e014fda',
        decimals: 18,
      },
      {
        baseToken: 'DOT',
        tokenAddress: '0x7083609fCE4d1d8Dc0C979AAb8c869Ea2C873402',
        priceFeed: '0xc333eb0086309a16aa7c8308dfd32c8bba0a2592',
        decimals: 18,
      },
      {
        baseToken: 'YFI',
        tokenAddress: '0x88f1A5ae2A3BF98AEAF342D26B30a79438c9142e',
        priceFeed: '0xd7eaa5bf3013a96e3d515c055dbd98dbdc8c620d',
        decimals: 18,
      },
      {
        baseToken: 'TRX',
        tokenAddress: '0xCE7de646e7208a4Ef112cb6ed5038FA6cC6b12e3',
        priceFeed: '0xf4c5e535756d11994fcbb12ba8add0192d9b88be',
        decimals: 6,
      },
      {
        baseToken: 'LINA',
        tokenAddress: '0x762539b45A1dCcE3D36d080F74d1AED37844b878',
        priceFeed: '0x38393201952f2764e04b290af9df427217d56b41',
        decimals: 18,
      },
      {
        baseToken: 'UNI',
        tokenAddress: '0xBf5140A22578168FD562DCcF235E5D43A02ce9B1',
        priceFeed: '0xb57f259e7c24e56a1da00f66b55a5640d9f9e7e4',
        decimals: 18,
      },
      {
        baseToken: 'WOO',
        tokenAddress: '0x4691937a7508860F876c9c0a2a617E7d9E945D4B',
        priceFeed: '0x02bfe714e78e2ad1bb1c2bee93ec8dc5423b66d4',
        decimals: 18,
      },
      {
        baseToken: 'ATOM',
        tokenAddress: '0x0Eb3a705fc54725037CC9e008bDede697f62F335',
        priceFeed: '0xb056b7c804297279a9a673289264c17e6dc6055d',
        decimals: 18,
      },
      {
        baseToken: 'PAXG',
        tokenAddress: '0x7950865a9140cB519342433146Ed5b40c6F210f7',
        priceFeed: '0x7f8cad4690a38ac28bda3d132ef83db1c17557df',
        decimals: 18,
      },
      {
        baseToken: 'YFII',
        tokenAddress: '0x7F70642d88cf1C4a3a7abb072B53B929b653edA5',
        priceFeed: '0xc94580faaf145b2fd0ab5215031833c98d3b77e4',
        decimals: 18,
      },
      {
        baseToken: 'DOGE',
        tokenAddress: '0xbA2aE424d960c26247Dd6c32edC70B295c744C43',
        priceFeed: '0x3ab0a0d137d4f946fbb19eecc6e92e64660231c8',
        decimals: 8,
      },
      {
        baseToken: 'EOS',
        tokenAddress: '0x56b6fB708fC5732DEC1Afc8D8556423A2EDcCbD6',
        priceFeed: '0xd5508c8ffdb8f15ce336e629fd4ca9adb48f50f0',
        decimals: 18,
      },
      {
        baseToken: 'WING',
        tokenAddress: '0x3CB7378565718c64Ab86970802140Cc48eF1f969',
        priceFeed: '0xf7e7c0ffcb11dac6eca1434c67fab9ae000e10a7',
        decimals: 9,
      },
      {
        baseToken: 'ONT',
        tokenAddress: '0xFd7B3A77848f1C2D67E05E54d78d174a0C850335',
        priceFeed: '0x887f177cbed2cf555a64e7bf125e1825eb69db82',
        decimals: 18,
      },
      {
        baseToken: 'CFX',
        tokenAddress: '0x045c4324039dA91c52C55DF5D785385Aab073DcF',
        priceFeed: '0xe3ca2f3bad1d8327820f648c759f17162b5383ae',
        decimals: 18,
      },
      {
        baseToken: 'COMP',
        tokenAddress: '0x52CE071Bd9b1C4B00A0b92D298c512478CaD67e8',
        priceFeed: '0x0db8945f9aef5651fa5bd52314c5aae78dfde540',
        decimals: 18,
      },
      {
        baseToken: 'SOL',
        tokenAddress: '0x570A5D26f7765Ecb712C0924E4De545B89fD43dF',
        priceFeed: '0x0e8a53dd9c13589df6382f13da6b3ec8f919b323',
        decimals: 18,
      },
      {
        baseToken: 'XTZ',
        tokenAddress: '0x16939ef78684453bfDFb47825F8a5F714f12623a',
        priceFeed: '0x9a18137adcf7b05f033ad26968ed5a9cf0bf8e6b',
        decimals: 18,
      },
      {
        baseToken: 'LIT',
        tokenAddress: '0xb59490aB09A0f526Cc7305822aC65f2Ab12f9723',
        priceFeed: '0x83766ba8d964feaed3819b145a69c947df9cb035',
        decimals: 18,
      },
      {
        baseToken: 'REEF',
        tokenAddress: '0xF21768cCBC73Ea5B6fd3C687208a7c2def2d966e',
        priceFeed: '0x46f13472a4d4fec9e07e8a00ee52f4fa77810736',
        decimals: 18,
      },
      {
        baseToken: 'AAVE',
        tokenAddress: '0xfb6115445Bff7b52FeB98650C87f44907E58f802',
        priceFeed: '0xa8357bf572460fc40f4b0acacbb2a6a61c89f475',
        decimals: 18,
      },
      {
        baseToken: 'MATIC',
        tokenAddress: '0xCC42724C6683B7E57334c4E856f4c9965ED682bD',
        priceFeed: '0x7ca57b0ca6367191c94c8914d7df09a57655905f',
        decimals: 18,
      },
      {
        baseToken: 'MASK',
        tokenAddress: '0x2eD9a5C8C13b93955103B9a7C167B67Ef4d568a3',
        priceFeed: '0x4978c0abe6899178c1a74838ee0062280888e2cf',
        decimals: 18,
      },
      {
        baseToken: 'ALPACA',
        tokenAddress: '0x8F0528cE5eF7B51152A59745bEfDD91D97091d2F',
        priceFeed: '0xe0073b60833249ffd1bb2af809112c2fbf221df6',
        decimals: 18,
      },
      {
        baseToken: 'FET',
        tokenAddress: '0x031b41e504677879370e9DBcF937283A8691Fa7f',
        priceFeed: '0x657e700c66c48c135c4a29c4292908dbda7aa280',
        decimals: 18,
      },
      {
        baseToken: 'INJ',
        tokenAddress: '0xa2B726B1145A4773F68593CF171187d8EBe4d495',
        priceFeed: '0x63a9133cd7c611d6049761038c16f238fdda71d7',
        decimals: 18,
      },
      {
        baseToken: 'CAKE',
        tokenAddress: '0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82',
        priceFeed: '0xb6064ed41d4f67e353768aa239ca86f4f73665a1',
        decimals: 18,
      },
      {
        baseToken: 'DODO',
        tokenAddress: '0x67ee3Cb086F8a16f34beE3ca72FAD36F7Db929e2',
        priceFeed: '0x87701b15c08687341c2a847ca44ecfbc8d7873e1',
        decimals: 18,
      },
      {
        baseToken: 'AVAX',
        tokenAddress: '0x1CE0c2827e2eF14D5C4f29a091d735A204794041',
        priceFeed: '0x3b37c6f1e3207de5a4664e837072bd9a25269b39',
        decimals: 18,
      },
      {
        baseToken: 'KNC',
        tokenAddress: '0xfe56d5892BDffC7BF58f2E84BE1b2C32D21C308b',
        priceFeed: '0xf2f8273f6b9fc22c90891dc802caf60eef805cdf',
        decimals: 18,
      },
      {
        baseToken: 'VET',
        tokenAddress: '0x6FDcdfef7c496407cCb0cEC90f9C5Aaa1Cc8D888',
        priceFeed: '0x9f1fd2cef7b226d555a747da0411f93c5fe74e13',
        decimals: 18,
      },
      {
        baseToken: 'RDNT',
        tokenAddress: '0xf7DE7E8A6bd59ED41a4b5fe50278b3B7f31384dF',
        priceFeed: '0x20123c6ebd45c6496102beea86e1a6616ca547c6',
        decimals: 18,
      },
      {
        baseToken: 'KAVA',
        tokenAddress: '0x5F88AB06e8dfe89DF127B2430Bba4Af600866035',
        priceFeed: '0x12bf0c3f7d5aca9e711930d704da2774358d9210',
        decimals: 6,
      },
      {
        baseToken: 'BSW',
        tokenAddress: '0x965F527D9159dCe6288a2219DB51fc6Eef120dD1',
        priceFeed: '0x08e70777b982a58d23d05e3d7714f44837c06a21',
        decimals: 18,
      },
      {
        baseToken: '1INCH',
        tokenAddress: '0x111111111117dC0aa78b770fA6A738034120C302',
        priceFeed: '0x9a177bb9f5b6083e962f9e62bd21d4b5660aeb03',
        decimals: 18,
      },
      {
        baseToken: 'FXS',
        tokenAddress: '0xe48A3d7d0Bc88d552f730B62c006bC925eadB9eE',
        priceFeed: '0x0e9d55932893fb1308882c7857285b2b0bcc4f4a',
        decimals: 18,
      },
      {
        baseToken: 'NEAR',
        tokenAddress: '0x1Fa4a73a3F0133f0025378af00236f3aBDEE5D63',
        priceFeed: '0x0fe4d87883005fcafaf56b81d09473d9a29dcdc3',
        decimals: 18,
      },
      {
        baseToken: 'C98',
        tokenAddress: '0xaEC945e04baF28b135Fa7c640f624f8D90F1C3a6',
        priceFeed: '0x889158e39628c0397dc54b84f6b1cbe0aaeb7ffc',
        decimals: 18,
      },
      {
        baseToken: 'HIGH',
        tokenAddress: '0x5f4Bde007Dc06b867f86EBFE4802e34A1fFEEd63',
        priceFeed: '0xdf4dd957a84f798acfadd448badd2d8b9bc99047',
        decimals: 18,
      },
      {
        baseToken: 'XLM',
        tokenAddress: '0x43C934A845205F0b514417d757d7235B8f53f1B9',
        priceFeed: '0x27cc356a5891a3fe6f84d0457de4d108c6078888',
        decimals: 18,
      },
      {
        baseToken: 'SUSHI',
        tokenAddress: '0x947950BcC74888a40Ffa2593C5798F11Fc9124C4',
        priceFeed: '0xa679c72a97b654cfff58ab704de3ba15cde89b07',
        decimals: 18,
      },
      {
        baseToken: 'SHIB',
        tokenAddress: '0x2859e4544C4bB03966803b044A93563Bd2D0DD4D',
        priceFeed: '0xa615be6cb0f3f36a641858db6f30b9242d0abed8',
        decimals: 18,
      },
      {
        baseToken: 'agEUR',
        tokenAddress: '0x12f31B73D812C6Bb0d735a218c086d44D5fe5f89',
        priceFeed: '0x0bf79f617988c472dca68ff41efe1338955b9a80',
        decimals: 18,
      },
      {
        baseToken: 'rBTCB',
        tokenAddress: '0x34d4F4459c1b529BEbE1c426F1e584151BE2C1e5',
        priceFeed: '0x264990fbd0a4796a3e3d8e37c4d5f87a3aca5ebf',
        decimals: 18,
      },
      {
        baseToken: 'rUSDT',
        tokenAddress: '0x4Ff2DD7c6435789E0BB56B0553142Ad00878a004',
        priceFeed: '0xb97ad0e74fa7d920791e90258a6e2085088b4320',
        decimals: 18,
      },
      {
        baseToken: 'rBUSD',
        tokenAddress: '0x89d763e8532D256a3e3e60c1C218Ac71E71cF664',
        priceFeed: '0xcbb98864ef56e9042e7d2efef76141f15731b82f',
        decimals: 18,
      },
      {
        baseToken: 'rUSDC',
        tokenAddress: '0x3bDCEf9e656fD9D03eA98605946b4fbF362C342b',
        priceFeed: '0x51597f405303c4377e36123cbc172b13269ea163',
        decimals: 18,
      },
      {
        baseToken: 'rWETH',
        tokenAddress: '0x455a281D508B4e34d55b31AC2e4579BD9b77cA8E',
        priceFeed: '0x9ef1b8c0e4f7dc8bf5719ea496883dc6401d5b2e',
        decimals: 18,
      },
      {
        baseToken: 'rWBNB',
        tokenAddress: '0x58b0BB56CFDfc5192989461dD43568bcfB2797Db',
        priceFeed: '0x0567f2323251f0aab15c8dfb1967e4e8a7d42aee',
        decimals: 18,
      },
    ],
  },
  137: {
    network: 'Polygon',
    tokens: [
      {
        baseToken: 'MATIC',
        tokenAddress: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE',
        priceFeed: '0xab594600376ec9fd91f8e885dadf0ce036862de0',
        decimals: 18,
      },
      {
        baseToken: 'WMATIC',
        tokenAddress: '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
        priceFeed: '0xab594600376ec9fd91f8e885dadf0ce036862de0',
        decimals: 18,
      },
      {
        baseToken: 'USDT',
        tokenAddress: '0xc2132D05D31c914a87C6611C10748AEb04B58e8F',
        priceFeed: '0x0a6513e40db6eb1b165753ad52e80663aea50545',
        decimals: 6,
      },
      {
        baseToken: 'USDC.e',
        tokenAddress: '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174',
        priceFeed: '0xfe4a8cc5b5b2366c1b58bea3858e81843581b2f7',
        decimals: 6,
      },
      {
        baseToken: 'USDC',
        tokenAddress: '0x3c499c542cEF5E3811e1192ce70d8cC03d5c3359',
        priceFeed: '0xfe4a8cc5b5b2366c1b58bea3858e81843581b2f7',
        decimals: 6,
      },
      {
        baseToken: 'ETH',
        tokenAddress: '0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
        priceFeed: '0xf9680d99d6c9589e2a93a78a04a279e509205945',
        decimals: 18,
      },
      {
        baseToken: 'DAI',
        tokenAddress: '0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063',
        priceFeed: '0x4746dec9e833a82ec7c2c1356372ccf2cfcd2f3d',
        decimals: 18,
      },
      {
        baseToken: 'WBTC',
        tokenAddress: '0x1BFD67037B42Cf73acF2047067bd4F2C47D9BfD6',
        priceFeed: '0xde31f8bfbd8c84b5360cfacca3539b938dd78ae6',
        decimals: 8,
      },
      {
        baseToken: 'LINK',
        tokenAddress: '0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39',
        priceFeed: '0xd9ffdb71ebe7496cc440152d43986aae0ab76665',
        decimals: 18,
      },
      {
        baseToken: 'SNX',
        tokenAddress: '0x50B728D8D964fd00C2d0AAD81718b71311feF68a',
        priceFeed: '0xbf90a5d9b6ee9019028dbfc2a9e50056d5252894',
        decimals: 18,
      },
      {
        baseToken: 'SUSHI',
        tokenAddress: '0x0b3F868E0BE5597D5DB7fEB59E1CADBb0fdDa50a',
        priceFeed: '0x49b0c695039243bbfeb8ecd054eb70061fd54aa0',
        decimals: 18,
      },
      {
        baseToken: 'SAND',
        tokenAddress: '0xBbba073C31bF03b8ACf7c28EF0738DeCF3695683',
        priceFeed: '0x3d49406edd4d52fb7ffd25485f32e073b529c924',
        decimals: 18,
      },
      {
        baseToken: 'FXS',
        tokenAddress: '0x1a3acf6D19267E2d3e7f898f42803e90C9219062',
        priceFeed: '0x6c0fe985d3cacbcde428b84fc9431792694d0f51',
        decimals: 18,
      },
      {
        baseToken: 'UNI',
        tokenAddress: '0xb33EaAd8d922B1083446DC23f610c2567fB5180f',
        priceFeed: '0xdf0fb4e4f928d2dcb76f438575fdd8682386e13c',
        decimals: 18,
      },
      {
        baseToken: 'TUSD',
        tokenAddress: '0x2e1AD108fF1D8C782fcBbB89AAd783aC49586756',
        priceFeed: '0x7c5d415b64312d38c56b54358449d0a4058339d2',
        decimals: 18,
      },
      {
        baseToken: 'PAXG',
        tokenAddress: '0x553d3D295e0f695B9228246232eDF400ed3560B5',
        priceFeed: '0x0f6914d8e7e1214cdb3a4c6fbf729b75c69df608',
        decimals: 18,
      },
      {
        baseToken: 'MANA',
        tokenAddress: '0xA1c57f48F0Deb89f569dFbE6E2B7f46D33606fD4',
        priceFeed: '0xa1cbf3fe43bc3501e3fc4b573e822c70e76a7512',
        decimals: 18,
      },
      {
        baseToken: 'KNC',
        tokenAddress: '0x1C954E8fe737F99f68Fa1CCda3e51ebDB291948C',
        priceFeed: '0x10e5f3dfc81b3e5ef4e648c4454d04e79e1e41e2',
        decimals: 18,
      },
      {
        baseToken: 'COMP',
        tokenAddress: '0x8505b9d2254A7Ae468c0E9dd10Ccea3A837aef5c',
        priceFeed: '0x2a8758b7257102461bc958279054e372c2b1bde6',
        decimals: 18,
      },
      {
        baseToken: 'BAT',
        tokenAddress: '0x3Cef98bb43d732E2F285eE605a8158cDE967D219',
        priceFeed: '0x2346ce62bd732c62618944e51cbfa09d985d86d2',
        decimals: 18,
      },
      {
        baseToken: 'AAVE',
        tokenAddress: '0xD6DF932A45C0f255f85145f286eA0b292B21C90B',
        priceFeed: '0x72484b12719e23115761d5da1646945632979bb6',
        decimals: 18,
      },
      {
        baseToken: 'agEUR',
        tokenAddress: '0xE0B52e49357Fd4DAf2c15e02058DCE6BC0057db4',
        priceFeed: '0x73366fe0aa0ded304479862808e02506fe556a98',
        decimals: 18,
      },
      {
        baseToken: 'BAL',
        tokenAddress: '0x9a71012B13CA4d3D0Cdc72A177DF3ef03b0E76A3',
        priceFeed: '0xd106b538f2a868c28ca1ec7e298c3325e0251d66',
        decimals: 18,
      },
      {
        baseToken: '1INCH',
        tokenAddress: '0x9c2C5fd7b07E95EE044DDeba0E97a665F142394f',
        priceFeed: '0x443c5116cdf663eb387e72c688d276e702135c87',
        decimals: 18,
      },
      {
        baseToken: 'AVAX',
        tokenAddress: '0x2C89bbc92BD86F8075d1DEcc58C7F4E0107f286b',
        priceFeed: '0xe01ea2fbd8d76ee323fbed03eb9a8625ec981a10',
        decimals: 18,
      },
      {
        baseToken: 'YFI',
        tokenAddress: '0xDA537104D6A5edd53c6fBba9A898708E465260b6',
        priceFeed: '0x9d3a43c111e7b2c6601705d9fcf7a70c95b1dc55',
        decimals: 18,
      },
      {
        baseToken: 'WOO',
        tokenAddress: '0x1B815d120B3eF02039Ee11dC2d33DE7aA4a8C603',
        priceFeed: '0x6a99ec84819fb7007dd5d032068742604e755c56',
        decimals: 18,
      },
      {
        baseToken: 'GNS',
        tokenAddress: '0xE5417Af564e4bFDA1c483642db72007871397896',
        priceFeed: '0x9cb43aa3d036cb035a694ba0aaa91f8875b16ce1',
        decimals: 18,
      },
      {
        baseToken: 'aPolDAI',
        tokenAddress: '0x82E64f49Ed5EC1bC6e43DAD4FC8Af9bb3A2312EE',
        priceFeed: '0x4746dec9e833a82ec7c2c1356372ccf2cfcd2f3d',
        decimals: 18,
      },
      {
        baseToken: 'aPolLINK',
        tokenAddress: '0x191c10Aa4AF7C30e871E70C95dB0E4eb77237530',
        priceFeed: '0xd9ffdb71ebe7496cc440152d43986aae0ab76665',
        decimals: 18,
      },
      {
        baseToken: 'aPolUSDC',
        tokenAddress: '0x625E7708f30cA75bfd92586e17077590C60eb4cD',
        priceFeed: '0xfe4a8cc5b5b2366c1b58bea3858e81843581b2f7',
        decimals: 6,
      },
      {
        baseToken: 'aPolWBTC',
        tokenAddress: '0x078f358208685046a11C85e8ad32895DED33A249',
        priceFeed: '0xde31f8bfbd8c84b5360cfacca3539b938dd78ae6',
        decimals: 8,
      },
      {
        baseToken: 'aPolWETH',
        tokenAddress: '0xe50fA9b3c56FfB159cB0FCA61F5c9D750e8128c8',
        priceFeed: '0xf9680d99d6c9589e2a93a78a04a279e509205945',
        decimals: 18,
      },
      {
        baseToken: 'aPolUSDT',
        tokenAddress: '0x6ab707Aca953eDAeFBc4fD23bA73294241490620',
        priceFeed: '0x0a6513e40db6eb1b165753ad52e80663aea50545',
        decimals: 6,
      },
      {
        baseToken: 'aPolAAVE',
        tokenAddress: '0xf329e36C7bF6E5E86ce2150875a84Ce77f477375',
        priceFeed: '0x72484b12719e23115761d5da1646945632979bb6',
        decimals: 18,
      },
      {
        baseToken: 'aPolWMATIC',
        tokenAddress: '0x6d80113e533a2C0fe82EaBD35f1875DcEA89Ea97',
        priceFeed: '0xab594600376ec9fd91f8e885dadf0ce036862de0',
        decimals: 18,
      },
      {
        baseToken: 'aPolSUSHI',
        tokenAddress: '0xc45A479877e1e9Dfe9FcD4056c699575a1045dAA',
        priceFeed: '0x49b0c695039243bbfeb8ecd054eb70061fd54aa0',
        decimals: 18,
      },
      {
        baseToken: 'aPolBAL',
        tokenAddress: '0x8ffDf2DE812095b1D19CB146E4c004587C0A0692',
        priceFeed: '0xd106b538f2a868c28ca1ec7e298c3325e0251d66',
        decimals: 18,
      },
      {
        baseToken: 'aPolAGEUR',
        tokenAddress: '0x8437d7C167dFB82ED4Cb79CD44B7a32A1dd95c77',
        priceFeed: '0x73366fe0aa0ded304479862808e02506fe556a98',
        decimals: 18,
      },
    ],
  },
  31337: {
    network: 'local',
    tokens: [
      {
        baseToken: 'ETH',
        tokenAddress: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE',
        priceFeed: '0x5f4eC3Df9cbd43714FE2740f5E3616155c5b8419',
        decimals: 18,
      },
      {
        baseToken: 'USDT',
        tokenAddress: '0xdAC17F958D2ee523a2206206994597C13D831ec7',
        priceFeed: '0x3E7d1eAB13ad0104d2750B8863b489D65364e32D',
        decimals: 6,
      },
      {
        baseToken: 'USDC',
        tokenAddress: '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
        priceFeed: '0x8fFfFfd4AfB6115b954Bd326cbe7B4BA576818f6',
        decimals: 6,
      },
    ],
  },
  42161: {
    network: 'Arbitrum',
    tokens: [
      {
        baseToken: 'ETH',
        tokenAddress: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE',
        priceFeed: '0x639Fe6ab55C921f74e7fac1ee960C0B6293ba612',
        decimals: 18,
      },
      {
        baseToken: 'WETH',
        tokenAddress: '0x82aF49447D8a07e3bd95BD0d56f35241523fBab1',
        priceFeed: '0x639Fe6ab55C921f74e7fac1ee960C0B6293ba612',
        decimals: 18,
      },
      {
        baseToken: 'AAVE',
        tokenAddress: '0xba5DdD1f9d7F570dc94a51479a000E3BCE967196',
        priceFeed: '0xaD1d5344AaDE45F43E596773Bcc4c423EAbdD034',
        decimals: 18,
      },
      {
        baseToken: 'ARB',
        tokenAddress: '0x912CE59144191C1204E64559FE8253a0e49E6548',
        priceFeed: '0xb2A824043730FE05F3DA2efaFa1CBbe83fa548D6',
        decimals: 18,
      },
      {
        baseToken: 'BAL',
        tokenAddress: '0x040d1EdC9569d4Bab2D15287Dc5A4F10F56a56B8',
        priceFeed: '0xBE5eA816870D11239c543F84b71439511D70B94f',
        decimals: 18,
      },
      {
        baseToken: 'COMP',
        tokenAddress: '0x354A6dA3fcde098F8389cad84b0182725c6C91dE',
        priceFeed: '0xe7C53FFd03Eb6ceF7d208bC4C13446c76d1E5884',
        decimals: 18,
      },
      {
        baseToken: 'CRV',
        tokenAddress: '0x11cDb42B0EB46D95f990BeDD4695A6e3fA034978',
        priceFeed: '0xaebDA2c976cfd1eE1977Eac079B4382acb849325',
        decimals: 18,
      },
      {
        baseToken: 'DAI',
        tokenAddress: '0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1',
        priceFeed: '0xc5C8E77B397E531B8EC06BFb0048328B30E9eCfB',
        decimals: 18,
      },
      {
        baseToken: 'DODO',
        tokenAddress: '0x69Eb4FA4a2fbd498C257C57Ea8b7655a2559A581',
        priceFeed: '0xA33a06c119EC08F92735F9ccA37e07Af08C4f281',
        decimals: 18,
      },
      {
        baseToken: 'agEUR',
        tokenAddress: '0xFA5Ed56A203466CbBC2430a43c66b9D8723528E7',
        priceFeed: '0xA14d53bC1F1c0F31B4aA3BD109344E5009051a84',
        decimals: 18,
      },
      {
        baseToken: 'FXS',
        tokenAddress: '0x9d2F299715D94d8A7E6F5eaa8E654E8c74a988A7',
        priceFeed: '0x36a121448D74Fa81450c992A1a44B9b7377CD3a5',
        decimals: 18,
      },
      {
        baseToken: 'GMX',
        tokenAddress: '0xfc5A1A6EB076a2C7aD06eD22C90d7E710E35ad0a',
        priceFeed: '0xDB98056FecFff59D032aB628337A4887110df3dB',
        decimals: 18,
      },
      {
        baseToken: 'GNS',
        tokenAddress: '0x18c11FD286C5EC11c3b683Caa813B77f5163A122',
        priceFeed: '0xE89E98CE4E19071E59Ed4780E0598b541CE76486',
        decimals: 18,
      },
      {
        baseToken: 'JOE',
        tokenAddress: '0x371c7ec6D8039ff7933a2AA28EB827Ffe1F52f07',
        priceFeed: '0x04180965a782E487d0632013ABa488A472243542',
        decimals: 18,
      },
      {
        baseToken: 'KNC',
        tokenAddress: '0xe4DDDfe67E7164b0FE14E218d80dC4C08eDC01cB',
        priceFeed: '0xbF539d4c2106dd4D9AB6D56aed3d9023529Db145',
        decimals: 18,
      },
      {
        baseToken: 'LDO',
        tokenAddress: '0x13Ad51ed4F1B7e9Dc168d8a00cB3f4dDD85EfA60',
        priceFeed: '0xA43A34030088E6510FecCFb77E88ee5e7ed0fE64',
        decimals: 18,
      },
      {
        baseToken: 'LINK',
        tokenAddress: '0xf97f4df75117a78c1A5a0DBb814Af92458539FB4',
        priceFeed: '0x86E53CF1B870786351Da77A57575e79CB55812CB',
        decimals: 18,
      },
      {
        baseToken: 'PENDLE',
        tokenAddress: '0x0c880f6761F1af8d9Aa9C466984b80DAb9a8c9e8',
        priceFeed: '0x66853E19d73c0F9301fe099c324A1E9726953433',
        decimals: 18,
      },
      {
        baseToken: 'RDNT',
        tokenAddress: '0x3082CC23568eA640225c2467653dB90e9250AaA0',
        priceFeed: '0x20d0Fcab0ECFD078B036b6CAf1FaC69A6453b352',
        decimals: 18,
      },
      {
        baseToken: 'RPL',
        tokenAddress: '0xB766039cc6DB368759C1E56B79AFfE831d0Cc507',
        priceFeed: '0xF0b7159BbFc341Cc41E7Cb182216F62c6d40533D',
        decimals: 18,
      },
      {
        baseToken: 'SPELL',
        tokenAddress: '0x3E6648C5a70A150A88bCE65F4aD4d506Fe15d2AF',
        priceFeed: '0x383b3624478124697BEF675F07cA37570b73992f',
        decimals: 18,
      },
      {
        baseToken: 'SUSHI',
        tokenAddress: '0xd4d42F0b6DEF4CE0383636770eF773390d85c61A',
        priceFeed: '0xb2A8BA74cbca38508BA1632761b56C897060147C',
        decimals: 18,
      },
      {
        baseToken: 'TUSD',
        tokenAddress: '0x4D15a3A2286D883AF0AA1B3f21367843FAc63E07',
        priceFeed: '0xb2A8BA74cbca38508BA1632761b56C897060147C',
        decimals: 18,
      },
      {
        baseToken: 'UNI',
        tokenAddress: '0xFa7F8980b0f1E64A2062791cc3b0871572f1F7f0',
        priceFeed: '0x9C917083fDb403ab5ADbEC26Ee294f6EcAda2720',
        decimals: 18,
      },
      {
        baseToken: 'USDC',
        tokenAddress: '0xaf88d065e77c8cC2239327C5EDb3A432268e5831',
        priceFeed: '0x50834F3163758fcC1Df9973b6e91f0F0F0434aD3',
        decimals: 6,
      },
      {
        baseToken: 'USDD',
        tokenAddress: '0x680447595e8b7b3Aa1B43beB9f6098C79ac2Ab3f',
        priceFeed: '0x4Ee1f9ec1048979930aC832a3C1d18a0b4955a02',
        decimals: 18,
      },
      {
        baseToken: 'USDT',
        tokenAddress: '0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9',
        priceFeed: '0x3f3f5dF88dC9F13eac63DF89EC16ef6e7E25DdE7',
        decimals: 6,
      },
      {
        baseToken: 'WBTC',
        tokenAddress: '0x2f2a2543B76A4166549F7aaB2e75Bef0aefC5B0f',
        priceFeed: '0xd0C7101eACbB49F3deCcCc166d238410D6D46d57',
        decimals: 8,
      },
      {
        baseToken: 'WOO',
        tokenAddress: '0xcAFcD85D8ca7Ad1e1C6F82F651fA15E33AEfD07b',
        priceFeed: '0x5e2b5C5C07cCA3437c4D724225Bb42c7E55d1597',
        decimals: 18,
      },
      {
        baseToken: 'YFI',
        tokenAddress: '0x82e3A8F066a6989666b031d916c43672085b1582',
        priceFeed: '0x745Ab5b69E01E2BE1104Ca84937Bb71f96f5fB21',
        decimals: 18,
      },
      {
        baseToken: 'aArbUSDT',
        tokenAddress: '0x6ab707Aca953eDAeFBc4fD23bA73294241490620',
        priceFeed: '0x3f3f5dF88dC9F13eac63DF89EC16ef6e7E25DdE7',
        decimals: 6,
      },
      {
        baseToken: 'aArbDAI',
        tokenAddress: '0x82E64f49Ed5EC1bC6e43DAD4FC8Af9bb3A2312EE',
        priceFeed: '0xc5C8E77B397E531B8EC06BFb0048328B30E9eCfB',
        decimals: 18,
      },
      {
        baseToken: 'aArbUSDC',
        tokenAddress: '0x625E7708f30cA75bfd92586e17077590C60eb4cD',
        priceFeed: '0x50834F3163758fcC1Df9973b6e91f0F0F0434aD3',
        decimals: 6,
      },
      {
        baseToken: 'aArbLINK',
        tokenAddress: '0x191c10Aa4AF7C30e871E70C95dB0E4eb77237530',
        priceFeed: '0x86E53CF1B870786351Da77A57575e79CB55812CB',
        decimals: 18,
      },
      {
        baseToken: 'aArbWBTC',
        tokenAddress: '0x078f358208685046a11C85e8ad32895DED33A249',
        priceFeed: '0xd0C7101eACbB49F3deCcCc166d238410D6D46d57',
        decimals: 8,
      },
      {
        baseToken: 'aArbWETH',
        tokenAddress: '0xe50fA9b3c56FfB159cB0FCA61F5c9D750e8128c8',
        priceFeed: '0x639Fe6ab55C921f74e7fac1ee960C0B6293ba612',
        decimals: 18,
      },
      {
        baseToken: 'aArbAAVE',
        tokenAddress: '0xf329e36C7bF6E5E86ce2150875a84Ce77f477375',
        priceFeed: '0xaD1d5344AaDE45F43E596773Bcc4c423EAbdD034',
        decimals: 18,
      },
      {
        baseToken: 'aArbARB',
        tokenAddress: '0x6533afac2E7BCCB20dca161449A13A32D391fb00',
        priceFeed: '0xb2A824043730FE05F3DA2efaFa1CBbe83fa548D6',
        decimals: 18,
      },
      {
        baseToken: 'rWBTC',
        tokenAddress: '0x727354712BDFcd8596a3852Fd2065b3C34F4F770',
        priceFeed: '0xd0C7101eACbB49F3deCcCc166d238410D6D46d57',
        decimals: 8,
      },
      {
        baseToken: 'rUSDT',
        tokenAddress: '0xd69D402D1bDB9A2b8c3d88D98b9CEaf9e4Cd72d9',
        priceFeed: '0x3f3f5dF88dC9F13eac63DF89EC16ef6e7E25DdE7',
        decimals: 6,
      },
      {
        baseToken: 'rUSDC',
        tokenAddress: '0x48a29E756CC1C097388f3B2f3b570ED270423b3d',
        priceFeed: '0x50834F3163758fcC1Df9973b6e91f0F0F0434aD3',
        decimals: 6,
      },
      {
        baseToken: 'rDAI',
        tokenAddress: '0x0D914606f3424804FA1BbBE56CCC3416733acEC6',
        priceFeed: '0xc5C8E77B397E531B8EC06BFb0048328B30E9eCfB',
        decimals: 18,
      },
      {
        baseToken: 'rWETH',
        tokenAddress: '0x0dF5dfd95966753f01cb80E76dc20EA958238C46',
        priceFeed: '0x639Fe6ab55C921f74e7fac1ee960C0B6293ba612',
        decimals: 18,
      },
      {
        baseToken: 'rARB',
        tokenAddress: '0x2dADe5b7df9DA3a7e1c9748d169Cd6dFf77e3d01',
        priceFeed: '0xb2A824043730FE05F3DA2efaFa1CBbe83fa548D6',
        decimals: 18,
      },
    ],
  },
}
