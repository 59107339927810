export const getPriceConf = (a, b) => {
  const aStr = String(a)
  const bStr = String(b)

  const index = Array.from(aStr).findIndex((letter, index) => {            
      if(letter !== bStr[index] && bStr[index] !== '0'){
          return true
      }
      
      return false
  })

  let nullesCount = index + 1
  let minMove = '0.'
  while(nullesCount--){
    minMove += '0'
  }
  minMove += '1'

  if (index > 2){
    return {
      precision: index + 2,
      minMove: Number(minMove)
    }
  }
  
  return null
}