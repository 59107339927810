import telegramIconScr from '../assets/icons/telegram.png'
import blogIcon from '../assets/icons/blogIcon.png'
import twitterIconScr from '../assets/icons/twitter.png'
import docsIcon from '../assets/icons/docsIcon.png'
import websiteIcon from '../assets/icons/websiteIcon.png'

export const socialMedias = [
  {
    src: websiteIcon,
    alt: 'Website Link',
  },
  {
    src: docsIcon,
    alt: 'Docs Link',
  },
  {
    src: twitterIconScr,
    alt: 'Twitter link',
  },
  {
    src: telegramIconScr,
    alt: 'Telegram link',
  },
  {
    src: blogIcon,
    alt: 'M link',
  },
]
