import React from 'react'
import { Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { usePool, usePoolSlugByUrl } from '../../hooks'
import clsx from 'clsx'

const useStyles = makeStyles((theme) => ({
  card: {
    flexDirection: 'column',
  },
  title: {
    fontWeight: 500,
    fontSize: 12,
    lineHeight: '217%',
    color: '#4C4C66',
    opacity: '0.67',
    margin: '0 0 9px 0',
    '@media screen and (max-width: 400px)': {
      margin: '0 0 5px 0',
    },
  },
  checkList: {
    padding: 0,
    margin: '0 -10px 14px -10px',
    display: 'flex',
    flexDirection: 'row',
    // justifyContent: 'space-between',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    '& > li': {
      listStyle: 'none',
      fontWeight: 600,
      fontSize: 16,
      lineHeight: '26px',
      margin: '15px 0 0 0',
      padding: '0 10px 0 10px',
      '&:last-child': {
        margin: '15px 0 0 0',
      },
      '& > img': {
        margin: '0 9px 0 0',
      },
      '@media screen and (max-width: 400px)': {
        padding: 0,
      },
    },
    '@media screen and (max-width: 400px)': {
      margin: 0,
      flexDirection: 'column',
    },
  },
  securityTitle: {
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: 12,
    border: '1px solid',
    color: '#6E87E1',
    textAlign: 'center',
    padding: 8,
    marginRight: 10,
    borderRadius: 20,
  },
}))

export const SecurityChecksCard = ({ className }) => {
  const classes = useStyles()

  const slug = usePoolSlugByUrl()
  const { securityChecks } = usePool(slug)

  const isExist =
    securityChecks && securityChecks.filter((check) => !!check).length

  if (!isExist) return null

  return (
    <Grid container className={clsx([className, classes.card])}>
      <h3 className={classes.title}>Security Checks</h3>
      <ul className={classes.checkList}>
        {/* {securityChecks &&
        securityChecks.map((check, index) => (
          <li key={index}>
            <img src={checkSrc} alt='checked' />
            <span>{check}</span>
          </li>
        ))} */}
        <span className={classes.securityTitle}>Contract audit</span>
        <span className={classes.securityTitle}>Team KYC</span>
        <span className={classes.securityTitle}>Research</span>
      </ul>
    </Grid>
  )
}
