import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Chip, Grid } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.sixth.main,
    border: '1px solid rgba(214, 210, 242, 0.5)',
    boxShadow: '0px 51px 89px -49px rgba(196, 193, 232, 0.4)',
    borderRadius: '18px',
    '& p': {
      margin: '26px',
      fontWeight: 500,
      fontSize: '21px',
      lineHeight: '46px',
      color: '#4C4C66',
    },
    '@media screen and (max-width: 700px)': {
      padding: '48px 32px 42px 32px',
    },
    '@media screen and (max-width: 400px)': {
      padding: '48px 20px 42px 20px',
    },
  },
  title: {
    fontWeight: 600,
    fontSize: '13px',
    color: '#4C4C66',
    padding: '0px 16px',
  },
  content: {
    color: theme.palette.secondary.main,
    fontFamily: theme.fontFamilies.secondary,
    fontWeight: 'bold',
    fontSize: 16,
    lineHeight: '26px',
    justifyContent: 'space-between',
    '@media screen and (max-width: 700px)': {
      fontSize: 14,
    },
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    width: '49%',
    '& > p': {
      margin: '0 0 12px 0',
      '&:last-child': {
        margin: 0,
      },
    },
    '@media screen and (max-width: 700px)': {
      width: '100%',
      margin: '0 0 30px 0',
      '&:last-child': {
        margin: 0,
      },
      '& > p': {
        margin: '0 0 15px 0',
        '&:last-child': {
          margin: 0,
        },
      },
    },
  },
  list: {
    margin: 0,
    padding: 0,
    '& > li': {
      listStyle: 'none',
      margin: '0 0 15px 0',
      display: 'flex',
      alignItems: 'end',
      '& > span': {
        display: 'inline-block',
        margin: '0 0 0 21px',
      },
      // listStyleImage: 'url("../../assets/li-sign.png")',
    },
  },
  liSign: {
    height: 8,
    width: 8,
    minWidth: 8,
    borderRadius: 100,
    backgroundColor: theme.palette.secondary.main,
    display: 'inline-block',
    margin: '9px 0 0 0',
  },
  titleContent: {
    width: '100%',
    borderBottom: '1px solid rgba(214, 210, 242, 0.5)',
  },
  label: {
    fontWeight: 700,
    fontSize: '21px',
    color: '#4C4C66',
  },
  chip: {
    color: '#68689D',
    fontFamily: 'Poppins',
    fontSize: '15px',
    fontWeight: 600,
    background: 'rgba(209, 209, 232, 0.6)',
  },
}))

export const Disclaimer = () => {
  const classes = useStyles()

  return (
    <Grid container className={classes.root}>
      <div className={classes.titleContent}>
        <h3 className={classes.title}>Disclaimer</h3>
      </div>
      <Grid container className={classes.content}>
        <p>
          <label className={classes.label}>ASPIS</label> &nbsp;Whitelisting
          mechanics will be released together with DTM token’s TGE.{' '}
          <Chip className={classes.chip} label="Stay tuned!"></Chip>{' '}
        </p>
        {/*<Grid className={classes.column}>*/}
        {/*  <p>*/}
        {/*    Funds must be staked for a minimum of 1 month and cannot be*/}
        {/*    withdrawn before the 30 days period.*/}
        {/*  </p>*/}
        {/*  <p>*/}
        {/*    If you stake DTM for 3 and more months, you will get a level upgrade*/}
        {/*    and a reward pool share.*/}
        {/*  </p>*/}
        {/*</Grid>*/}
        {/*<Grid className={classes.column}>*/}
        {/*  <ul className={classes.list}>*/}
        {/*    <li>*/}
        {/*      <div className={classes.liSign} />*/}
        {/*      <span>Staking rewards allocation - 20mln tokens in total</span>*/}
        {/*    </li>*/}
        {/*    <li>*/}
        {/*      <div className={classes.liSign} />*/}
        {/*      <span>*/}
        {/*        Unlock schedule 5% (1mln tokens) each 90 days from TGE*/}
        {/*      </span>*/}
        {/*    </li>*/}
        {/*  </ul>*/}
        {/*</Grid>*/}
      </Grid>
    </Grid>
  )
}
