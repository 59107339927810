// import { useCallback, useEffect, useState } from 'react'
// import { formatMilliseconds, formatMillisecondsToDays } from '../utils/formatSeconds'
//
import { useEffect, useRef, useState } from 'react'
import { formatMilliseconds} from '../utils/formatSeconds'
import { DebugLog } from '../helpers/DebugLog'

type Props = {
  date: Date,
  timeOffset?: number, // ms
  onTimeout?: () => void
}

const debugLog = new DebugLog('InTime', false);


export const InTime = ({date, timeOffset = 0, onTimeout}: Props) => {
  const [timeLeft, setTimeLeft] = useState<string>()
  const [isTimeoutCalled, setIsTimeoutCalled] = useState(false)
  const interval = useRef<any>(null);


  useEffect(() => {
    function handleTime() {
      debugLog.log('handleTime')
      if (onTimeout && !isTimeoutCalled && hasTimePast()) {
        debugLog.log('handleTime.onTimeout')
        onTimeout()
        setIsTimeoutCalled(true)
      }
      if (hasOffsetTimePast()) {
        debugLog.log('handleTime.stopInterval')
        stopInterval()
        return[]
      }
      debugLog.log('handleTime.setTimeLeft')
      setTimeLeft(getTimeLeft())
    }
    function stopInterval() {
      debugLog.log('stopInterval')
      if (interval.current) {
        debugLog.log('clearInterval')
        clearInterval(interval.current)
      }
    }
  
    function hasTimePast() {
      const nowTime = new Date().getTime()
      return nowTime >= date.getTime();
    }
    function hasOffsetTimePast() {
      const nowTime = new Date().getTime()
      return nowTime >= (date.getTime() + timeOffset);
    }
  
  
  
    function getTimeLeft() {
      const nowTime = new Date().getTime()
      return hasOffsetTimePast()
        ? formatMilliseconds(0)
        : formatMilliseconds(date.getTime() + timeOffset - nowTime)
    }
  
    handleTime()
    interval.current = setInterval(() => handleTime(), 1000)
    return () => stopInterval()
  }, [date, isTimeoutCalled, onTimeout, timeOffset])


  return (
    <>{timeLeft}</>
  )
}
