import { timersTypes } from '../types'

const initialState = []

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case timersTypes.INITIALIZE_TIMERS:
    case timersTypes.UPDATE_TIMERS:
      return action.payload.timers
    case timersTypes.REMOVE_ALL_TIMERS:
      return initialState
    default:
      return state
  }
}
