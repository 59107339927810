import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(
  (theme) => ({
    root: {
      color: theme.palette.error.main,
      fontSize: '.85rem',
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(2),
    },
  }),
  { name: 'ErrorMessage' }
)

const formatErrorMessage = (message: string) => {
  if (message.includes('Wallet is locked')) {
    return message
  }
  if (message.includes('Please switch network')) {
    return message
  }
  if (message.includes('is not whitelisted')) {
    return message
  }
  if (message.includes('User denied transaction')) {
    return 'Transaction declined'
  }
  if (message.includes('not a staking time')) {
    return 'Not a staking time'
  }
  if (message) {
    return message
  }
  return 'Something went wrong. Please try again'
}

export const ErrorMessage = ({ message }: { message: string }) => {
  const classes = useStyles()
  return <div className={classes.root}>{formatErrorMessage(message)}</div>
}
