import { useEffect, useState } from 'react'

// Material-UI imports
import {
  Card,
  CardContent,
  Grid,
  TextField
} from '@material-ui/core'

import Papa from 'papaparse'
import web3 from 'web3';

import { Button } from '../../../Button'
import { makeStyles } from '@material-ui/core/styles'

import { DaoAPI } from '../../../../api/DaoAPI'
import { useLocation } from 'react-router'
import { useWeb3React } from '@web3-react/core';

const useStyle = makeStyles((theme) => ({
  divider: {
    borderBottom: '1px solid rgb(224 224 224)',
  },
  cardTitle: {
    fontSize: 13,
    fontWeight: 600,
    fontStyle: 'normal',
    lineHeight: 4,
    margin: 0,
    paddingLeft: '16px',
  },
  cardContent: {
    borderRadius: '18px',
    border: '1px solid rgba(214, 210, 242, 0.5)',
    boxShadow: '0px 51px 89px -49px rgba(196, 193, 232, 0.4)',
    '& .MuiCardContent-root': {
      padding: 0,
    },
  },
  helperText: {
    fontSize: '9px',
    color: '#878799',
  },
  title:{
    margin: 0,
    fontSize: '11px',
    fontWeight: 500,
    lineHeight: '23.87px'
  },
  loadBtnContent: {
    display: 'flex',
    justifyContent: 'center',
    padding: '16px',
    borderTop: '1px solid rgba(214, 210, 242, 0.5);',
  },
  loadBtn: {
    background: 'rgba(232, 99, 244, 0.07)',
    border: '1px solid #BC65F2',
    borderRadius: '5px',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '21px',
    textAlign: 'center',
    color: '#BC65F2',
  },
  inputCSV:{
    width:'100%',
    '& .MuiOutlinedInput-notchedOutline':{
      borderColor: 'transparent',
      background: 'rgb(236 157 243 / 7%)',
      borderRadius: '10px',
    }
  },
  walletAddressesDisplay:{
    background: '#fcf7fe',
    paddingTop: '10px',
    borderRadius: '18px',
    paddingBottom: '10px',
    fontSize:'14px',
      '&limaker':{
      background:'red',
      color:'yellow'
  }
  },
}))

export const AllowlisedWalletsToDeposit = (props: any) => {
  const {func, setIsProposalValid, setProposalError, setProposalMsg} = props;
 
  const classes = useStyle()
  const location = useLocation();
  const { connector } = useWeb3React()

  const [currentwhitelistedUsers, setCurrentWhitelistedUsers] = useState([])
  const [walletAddresses, setWalletAddresses] = useState({
    addresses: [],
    func:'addWhitelistedUsers'
  })
  const [allowListedWalletsErr, setAllowListedWalletsErr] = useState(false)

  const paths = location.pathname.split('/');
  const daoId = paths[paths.length - 1];

  const setAllowlistedWallets = (value:any) => {
    setWalletAddresses({ ...walletAddresses,
      addresses: value
      .toLowerCase()
      .replace(/\s+/g, '')
      .split(',')
      .filter((elem, index, self) => index === self.indexOf(elem))
   })
  }

  const allowlistedWalletsError = () => {
    if(walletAddresses.addresses.length <= 0){
      setAllowListedWalletsErr(true)
    }
    else{setAllowListedWalletsErr(false)}
  }

  const inputValidation = walletAddresses?.addresses.length > 0 &&
    walletAddresses?.addresses.every((walletAddress)=>web3.utils.isAddress(walletAddress));

  const hasCommonAllowListedAddresses = currentwhitelistedUsers.some((currentwhitelistedTokens: string) => {
    return walletAddresses?.addresses.some((WhitelistedTokens:string) => {
      return currentwhitelistedTokens.toLowerCase() === WhitelistedTokens.toLowerCase()
    }) 
  });
  
  // const isWhitelisted = currentwhitelistedUsers
  //   .map((currentwhitelistedUser: string) => currentwhitelistedUser.toLowerCase())
  //   .filter((currentwhitelistedUser: string) => walletAddresses?.whiteListedAddresses
  //     .map((proposedWhiteListedAddresses: string) => proposedWhiteListedAddresses.toLowerCase())
  //       .includes(currentwhitelistedUser))

  const loadCSV = (event) => {
    Papa.parse(event[0], {
      header: false,
      skipEmptyLines: true,
      complete: function (results:any) {
        let addresses = results?.data
        setWalletAddresses({ ...walletAddresses,
          addresses: addresses
          .toString()
          .replace(/\n/g, "")
          .toLowerCase()
          .split(',')
          .filter((elem, index, self)  =>  index === self.indexOf(elem))
       })
        setAllowListedWalletsErr(false)
      }
    })
  }
  
  useEffect(() => {
    const decodeAddresses = async () => {
      try {
        setCurrentWhitelistedUsers(await DaoAPI.getWhitelistedUsers(daoId, connector))
      } catch (e) {
        console.log('Error message:', e)
      }
    }
    decodeAddresses()
      .catch(console.error)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setIsProposalValid(inputValidation)
    if (hasCommonAllowListedAddresses){
      setProposalError(true)
      setProposalMsg(`Some allowlisted wallets are already on allow list for deposit!`)
    } else {
      setProposalError(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [walletAddresses, inputValidation])

  useEffect(() => {
    func(walletAddresses)
  })

  return (
      <Card className={classes.cardContent}>
        <div className={classes.divider}>
          <p className={classes.cardTitle}>Add allowlisted wallets to deposit</p>
        </div>
        <CardContent style={{ padding: '16px' }}>
          <Grid container>
            <Grid item lg={12}>
              <p className={classes.title}>Allowlisted wallets to deposit </p>
              <TextField
                    variant="outlined"
                    multiline
                    minRows={3}
                    maxRows={5}
                    className={classes.inputCSV}
                    value={walletAddresses.addresses}
                    // disabled={stepperValues.isAllowlistedToDepositDisabled}
                    onChange={(e)=>setAllowlistedWallets(e.target.value)}
                    onBlur={allowlistedWalletsError}
                    error={allowListedWalletsErr && walletAddresses?.addresses.length === 0}
                    helperText={
                      allowListedWalletsErr
                        ? walletAddresses.addresses.length === 0
                          ? 'Please add list of wallets allowed to deposit into your DAO or choose “Anyone can deposit"'
                          : '' 
                        : null
                    }
                  />
            </Grid>
            {
              walletAddresses.addresses.length !== 0 && 
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <ol className={classes.walletAddressesDisplay}>
                  {
                    walletAddresses.addresses.map((address: string, idx:number) => 
                      <li key={idx}>
                        {
                          web3.utils.isAddress(address) ? 
                            ([address])
                            :
                            (address) !== '' ?
                              <p>Invalid Address</p>
                              :
                              <p>Address cannot be empty</p>
                        }
                      </li>
                    )
                  }
                </ol>
              </Grid>
            }
          </Grid>
        </CardContent>
        <div className={classes.loadBtnContent}>
          <Button
            type="CSVBtn"
            // disabled={stepperValues.isAllowlistedToDepositDisabled}
            component="label"
          >
            Load CSV
            <input type="file" accept=".csv" hidden onChange={(e)=>loadCSV(e.target.files)}/>
          </Button>
        </div>
      </Card>
  )
}
