import { makeStyles } from '@material-ui/core/styles'
import { Grid} from '@material-ui/core'
import backBtn from '../assets/icons/backBtn.svg'

const useStyles = makeStyles(
  (theme) => ({
    root: {
     padding: '40px 0px',
     [theme.breakpoints.down('xs')]: { 
      padding: '25px 0'
    },
    [theme.breakpoints.up('md')]: { 
      padding: '40px 24px'
    }
    },
    name: {
      padding: 0,
      marginLeft: '28px',
      color: '#2F2F4D',
      fontSize: 13,
      lineHeight: '28.1px',
      fontFamily: theme.fontFamilies.fourth,
      fontWeight: 500,
      cursor: 'pointer',
      [theme.breakpoints.down('xs')]: { 
        marginLeft:'9px',
        fontSize: 10,
      }
    },
    backBtn:{
      [theme.breakpoints.down('xs')]:{
        width: '20px',
      }
    },
    comingSoon: {
      marginLeft: theme.spacing(2),
      color: theme.palette.twentyFourth.main,
      alignSelf: 'center',
    },
  }),
  {name: 'NavigationBar'},
)

export const NavigationBar = ({ pathName, link }: { pathName: string, link: string }) => {
  const classes = useStyles()

  return (
    <Grid container className={classes.root}>
      <a href={link}>
        <img className={classes.backBtn} src={backBtn} alt="back" />
      </a>
      <span className={classes.name}>
        {pathName}
      </span>
    </Grid>
  )
}
