import 'typeface-nunito'
import 'typeface-inter'
import 'typeface-manrope'
import { createTheme } from '@material-ui/core'

export const theme = createTheme({
  overrides: {
    MuiButton: {
      root: {
        fontFamily:'Poppins',
        border: 0,
        padding: 0,
        width:'100%',
        margin: 0,
        height: 40,
        fontWeight: 'bold',
        textTransform: 'none',
        color: '#fff',
        minWidth: 'unset',
        borderRadius: '7px',
        fontSize: 14,
        lineHeight: '22px',
        boxShadow: 'none',
      },
      contained: {
        boxShadow: 'none',
        '&:hover': {
          boxShadow: 'none !important',
        },
      },
    },
  },
  palette: {
     button: {
       primary: {
      color: '#8765F2',
      hover: '#8438FF',
      click: '#351F8E',
      },
      secondary: {
        color: '#BC65F2',
        hover: '#B131FF',
        click: '#351F8E',
      },
      disabled: {
        color:'#E2DDED',
        textColor: '#6F5E9A',
      },
      nonActive: {
        color: '#8765F2',
        opacity: '10%'
      },
    },
    additionalBtn:{
      simple:{
        color: '#E863F436',
        borderColor:'#BC65F2',
        fontColor: '#BC65F2'
      },
      hover:{
        color: '#E863F436',
        borderColor:'#BC65F2',
        fontColor: '#BC65F2'
      },
       click:{
        color: '#E863F436',
        borderColor:'#BC65F2',
        fontColor: '#BC65F2'
      }

    },
    text: {
      // primary: '#fff',
    },
    darkGray: { main:'#68689D' },
    lightGray: { main:'#E3E2F0'},
    darkMagenta: {main: '#E51BC5'},
    lightMagenta: {main: '#FF8CED'},
    darkPurple: {main: '#8438FF'},
    lightPurple: {main: '#BE9FFF4D'},
    steelBlue: {main: '#489CFF'},
    lightSteelBlue: {main: '#A4DEFF'},
    pink: {main: '#FF4869'},
    lightPink: {main: '#FFA4A4'},
    darkRed: {main: '#B6002C'},
    lightRed: {main :'#FCE4F0'},
    green: {main: '#00B654'},
    lightGreen: {main: '#E4FCEF'},
    cyan: {main: '#1AD598'},
    backgroundColor: {main: '#F9F6FB'},
    white: {main: '#fff'},
    primary: { main: '#8765F2' },
    secondary: { main: '#BC65F2' },
    combo: { main: '#351F8E' },
    error: { main: '#B6002C' },
    third: { main: '#EBEFFF' },
    fourth: { main: '#A6B0D4' },
    fifth: { main: '#707CB9' },
    sixth: { main: '#F5F7FF' },
    poolCardBackground: { main: '#9396C0' },
    eighth: { main: '#384267' },
    // ninth: { main: '#4048DD' },
    poolCardBackgroundHover: { main: '#6168F1' },
    // eleventh: { main: '#787BA5' },
    twelfth: { main: '#9DFFE5' },
    thirteenth: { main: 'rgba(98, 108, 147, 0.7)' },
    fourteenth: { main: '#D2D7E9' },
    fifteenth: { main: '#969696' },
    sixteenth: { main: '#649745' },
    seventeenth: { main: 'rgba(96, 103, 241, 0.7)' },
    eighteenth: { main: 'rgba(0,0,0,0.1)' },
    nineteenth: { main: '#3C435D' },
    twentieth: { main: '#282D3E' },
    twentyFirst: { main: '#E98FFE' },
    twentySecond: { main: '#DDDFEB' },
    twentyThird: { main: 'rgba(40, 45, 62, 0.5)' },
    twentyFourth: { main: '#8C94B4' },
    twentyFifth: { main: '#6E7697' },
    twentySixth: { main: 'rgba(4, 6, 49, 0.1)' },
    twentySeventh: { main: '#35394A' },
    twentyEighth: { main: '#616CE9' },
    twentyNinth: { main: 'rgba(255,255,255,0.1)' },
    thirtieth: { main: '#DDDFFC' },
    thirtyFirst: { main: 'rgb(96,103,241,0.21)' },
    thirtySecond: { main: 'rgb(96,103,241,0.31)' },
    thirtyThird: { main: '#131D3A' },
    thirtyFourth: { main: '#F5F6FA' },
    thirtyFifth: { main: '#E1E3E6' },
    thirtySixth: { main: 'rgba(0,0,0,0.15)' },
    thirtySeventh: { main: 'rgba(3, 5, 22, 0.5)' },
    // thirtyEighth: { main: '#ff6500' },
    thirtyNinth: { main: '#E4E5FD' },
    fortieth: { main: '#351F8E'},
    disabled: { main: '#E2DDED'}
  },
  fontFamilies: {
    primary: ['Poppins', 'sans-serif'].join(', '),
    secondary: ['Poppins', 'sans-serif'].join(', '),
    third: ['Poppins', 'sans-serif'].join(', '),
    fourth: ['Poppins', 'sans-serif'].join(', '),
  },
})


declare module '@material-ui/core/styles/createTheme' {
  interface Theme {
    fontFamilies: {
      primary: React.CSSProperties['fontFamily'],
      secondary: React.CSSProperties['fontFamily'],
      third: React.CSSProperties['fontFamily'],
      fourth: React.CSSProperties['fontFamily'],
    }
  }
  interface ThemeOptions {
    fontFamilies: {
      primary: React.CSSProperties['fontFamily'],
      secondary: React.CSSProperties['fontFamily'],
      third: React.CSSProperties['fontFamily'],
      fourth: React.CSSProperties['fontFamily'],
    }
  }
}


declare module "@material-ui/core/styles/createPalette" {
  interface Palette {
    combo: Palette['primary'];
    backgroundColor: Palette['primary'];
    button: Palette['primary'];
    error: Palette['primary'];
    white: Palette['primary'];
    third: Palette['primary'];
    fourth: Palette['primary'];
    fifth: Palette['primary'];
    sixth: Palette['primary'];
    poolCardBackground: Palette['primary'];
    eighth: Palette['primary'];
    poolCardBackgroundHover: Palette['primary'];
    twelfth: Palette['primary'];
    thirteenth: Palette['primary'];
    fourteenth: Palette['primary'];
    fifteenth: Palette['primary'];
    sixteenth: Palette['primary'];
    seventeenth: Palette['primary'];
    eighteenth: Palette['primary'];
    nineteenth: Palette['primary'];
    twentieth: Palette['primary'];
    twentyFirst: Palette['primary'];
    twentySecond: Palette['primary'];
    twentyThird: Palette['primary'];
    twentyFourth: Palette['primary'];
    twentyFifth: Palette['primary'];
    twentySixth: Palette['primary'];
    twentySeventh: Palette['primary'];
    twentyEighth: Palette['primary'];
    twentyNinth: Palette['primary'];
    thirtieth: Palette['primary'];
    thirtyFirst: Palette['primary'];
    thirtySecond: Palette['primary'];
    thirtyThird: Palette['primary'];
    thirtyFourth: Palette['primary'];
    thirtyFifth: Palette['primary'];
    thirtySixth: Palette['primary'];
    thirtySeventh: Palette['primary'];
    // thirtyEighth: Palette['primary'];
    thirtyNinth: Palette['primary'];
    fortieth: Palette['primary'];
    disabled: Palette['primary'];
  }
  interface PaletteOptions {
    combo: PaletteOptions['primary'];
    backgroundColor: PaletteOptions['primary'];
    white: PaletteOptions['primary'];
    third: PaletteOptions['primary'];
    fourth: PaletteOptions['primary']
    fifth: PaletteOptions['primary']
    sixth: PaletteOptions['primary']
    poolCardBackground: PaletteOptions['primary']
    eighth: PaletteOptions['primary']
    // ninth: PaletteOptions['primary']
    poolCardBackgroundHover: PaletteOptions['primary']
    // eleventh: PaletteOptions['primary']
    twelfth: PaletteOptions['primary']
    thirteenth: PaletteOptions['primary']
    fourteenth: PaletteOptions['primary']
    fifteenth: PaletteOptions['primary']
    sixteenth: PaletteOptions['primary']
    seventeenth: PaletteOptions['primary']
    eighteenth: PaletteOptions['primary']
    nineteenth: PaletteOptions['primary']
    twentieth: PaletteOptions['primary']
    twentyFirst: PaletteOptions['primary']
    twentySecond: PaletteOptions['primary']
    twentyThird: PaletteOptions['primary']
    twentyFourth: PaletteOptions['primary']
    twentyFifth: PaletteOptions['primary']
    twentySixth: PaletteOptions['primary']
    twentySeventh: PaletteOptions['primary']
    twentyEighth: PaletteOptions['primary']
    twentyNinth: PaletteOptions['primary']
    thirtieth: PaletteOptions['primary']
    thirtyFirst: PaletteOptions['primary']
    thirtySecond: PaletteOptions['primary']
    thirtyThird: PaletteOptions['primary']
    thirtyFourth: PaletteOptions['primary']
    thirtyFifth: PaletteOptions['primary']
    thirtySixth: PaletteOptions['primary']
    thirtySeventh: PaletteOptions['primary']
    // thirtyEighth: PaletteOptions['primary']
    thirtyNinth: PaletteOptions['primary'],
    fortieth: PaletteOptions['primary'],
    darkGray: PaletteOptions['primary'],
    lightGray: PaletteOptions['primary'],
    darkMagenta: PaletteOptions['primary'],
    lightMagenta: PaletteOptions['primary'],
    darkPurple: PaletteOptions['primary'],
    lightPurple: PaletteOptions['primary'],
    steelBlue: PaletteOptions['primary'],
    lightSteelBlue: PaletteOptions['primary'],
    pink: PaletteOptions['primary'],
    lightPink: PaletteOptions['primary'],
    darkRed: PaletteOptions['primary'],
    lightRed: PaletteOptions['primary'],
    green: PaletteOptions['primary'],
    lightGreen: PaletteOptions['primary'],
    cyan: PaletteOptions['primary'],
    disabled: PaletteOptions['primary']
    button: {
      primary:{
        color:string, hover:string, click: string
      },
      secondary: {
        color:string, hover:string, click: string
      },
      disabled:{
        color:string, textColor:string
      },
      nonActive:{
        color:string, opacity:string
      }
    };
    additionalBtn:{ 
      simple:{ color:string, borderColor:string, fontColor:string }
      hover:{ color:string, borderColor:string, fontColor:string }
      click:{ color:string, borderColor:string, fontColor:string }
     }
  }
}
