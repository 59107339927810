import { useEffect, useState } from 'react'

// Material-UI imports
import {
  Card,
  CardContent,
  Grid,
  TextField,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { NumericFormat } from 'react-number-format'
import { DaoAPI } from '../../../../api/DaoAPI'
import { useLocation } from 'react-router'
import BigNumber from 'bignumber.js'
import { useWeb3React } from '@web3-react/core'

const useStyle = makeStyles((theme) => ({
  divider: {
    borderBottom: '1px solid rgb(224 224 224)',
  },
  cardTitle: {
    fontSize: 13,
    fontWeight: 600,
    fontStyle: 'normal',
    lineHeight: 4,
    margin: 0,
    paddingLeft: '16px',
  },
  cardContent: {
    borderRadius: '18px',
    border: '1px solid rgba(214, 210, 242, 0.5)',
    boxShadow: '0px 51px 89px -49px rgba(196, 193, 232, 0.4)',
    '& .MuiCardContent-root': {
      padding: 0,
    },
  },
  contentHelper:{
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '-25px',
    padding: '0 14px'
  },
  labelHelper: {
    fontWeight: 500,
    fontSize: '9px',
    color: '#958BAE',
  },
  contentHelper2:{
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    marginBottom: '-25px',
    padding: '0 14px'
  },
  boldText: {
    letterSpacing: '0.4px',
    fontSize: '12px',
    color: '#4C4C66',
  },
  spanTextColor:{
    color: '#BC65F2',
    fontSize: '12px',
    textAlign: 'right',
},
questionMark:{
    width: '20px'
  },
  link:{
    color:'#BC65F2'
},
inputContent: {
    "& .MuiInputBase-input":{
       padding:'10.5px 14px',
    background: '#FBFBFF',
    },
    "& .MuiInputBase-root":{
      borderRadius: '9px'
    },
    display: 'grid',
    fontSize: '12px',
    fontWeight: 500,
    padding: '15px 10px',
  },
  input: {
    marginTop: '15px',
  },
  exampleContent:{
    fontSize: '8px',
    height: '100%',
  },
  exampleText:{
    lineHeight: '22px',
    marginTop: '0px',
    color: '#958BAE',
    margin: '8px'
  },
  exampleTitle:{
      color: '#BC65F2',
      margin:'5px 8px'
  },
  alignItems:{
    alignItems: 'flex-end'
  },
}))

export const ChangePerformanceFee = (props) => {
  const {setIsProposalValid, setProposalError, setProposalMsg} = props;

  const classes = useStyle()
  const location = useLocation();
  const { connector } = useWeb3React();
  
  const [daoFees, setDaoFees] = useState<any>({})
 
  const [performanceFee, setPerformanceFee] = useState<any>({
    fee: undefined,
    func: 'changePerformanceFee'
  })

  let paths = location.pathname.split('/');
  let daoId = paths[paths.length - 1];

  const inputValidation =
    performanceFee?.fee !== undefined &&
    !isNaN(performanceFee?.fee);

  useEffect(() => {
    const decodeDaoFees = async () => {
      try {
        setDaoFees(await DaoAPI.getDaoFees(daoId, connector))
      } catch (e) {
        console.log('Error message:', e)
      }
    }
    decodeDaoFees()
      .catch(console.error)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  
  useEffect(()=>{
    setIsProposalValid(inputValidation)
    if(parseInt(daoFees?.performanceFee) === performanceFee?.fee){
      setProposalError(true);
      setProposalMsg('You cannot create a proposal with the same performance fee that is already present.')
    }else{
      setProposalError(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[inputValidation, performanceFee])

  const limitedValue = (inputObj) => {
    const { value } = inputObj
    if (value <= 100) return true
    return false
  }

  useEffect(() => {
    props.func(performanceFee)
   })

  return (
      <Card className={classes.cardContent}>
        <div className={classes.divider}>
          <p className={classes.cardTitle}>Change performance fee</p>
        </div>
        <CardContent style={{ padding: '16px' }}>
          <Grid container className={classes.alignItems}>
              <div className={classes.contentHelper2}>
                <div className={classes.labelHelper}>
                  <b className={classes.boldText}>Perfomance fee,</b> % of total profit in LP tokens
                </div>
              </div>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <div className={classes.inputContent}>
                <NumericFormat
                  customInput={TextField}
                  variant="outlined"
                  className={classes.input}
                  placeholder={`${isNaN(daoFees?.performanceFee) ? 0 : new BigNumber(daoFees?.performanceFee).dividedBy(100)}%`}
                  onChange={(e: any) => setPerformanceFee({ ...performanceFee, fee: (parseInt(e.target.value.replace(/[%\s]/g, '')) * 100) })}
                  valueIsNumericString={true}
                  allowNegative = {false}
                  isAllowed={limitedValue}
                  suffix={'%'}
                />
              </div>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <div className={classes.exampleContent}>
                <p className={classes.exampleTitle}>Fee which is calculated from the fund’s profit and taken in LP tokens on withdrawal </p>
                <p className={classes.exampleText}>
                  Example: User deposited 100 USDT. During fund performance value of his LP tokens increased to 160 USDT. User will pay 20% from (160 - 100) USDT on withdrawal in LP tokens
                </p>
              </div>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
  )
}
