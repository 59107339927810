import { useState, useEffect } from 'react'

// Material-UI imports
import {
  Card,
  CardContent,
  Grid,
  FormControl,
  Select,
  OutlinedInput,
  MenuItem
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import { useAspisSupportedTokens } from '../../../../hooks/useChain'

const useStyle = makeStyles((theme) => ({
  divider: {
    borderBottom: '1px solid rgb(224 224 224)',
  },
  cardTitle: {
    fontSize: 13,
    fontWeight: 600,
    fontStyle: 'normal',
    lineHeight: 4,
    margin: 0,
    paddingLeft: '16px',
  },
  cardContent: {
    borderRadius: '18px',
    border: '1px solid rgba(214, 210, 242, 0.5)',
    boxShadow: '0px 51px 89px -49px rgba(196, 193, 232, 0.4)',
    '& .MuiCardContent-root': {
      padding: 0,
    },
  },
  helperText: {
    fontSize: '9px',
    color: '#878799',
  },
  radioContent: {
    display: 'block',
  },
  formContent: {
    width: '100%',
    '& .MuiOutlinedInput-input':{
      padding: '11.5px 14px'
    },
    '& .MuiOutlinedInput-root': {
      borderRadius: '8px'
    }
  },
  title: {
    margin: 0,
    fontSize: '11px',
    fontWeight: 500,
    lineHeight: '23.87px'
  },
}))

export const ChangeTokenAvailableForDeposit = (props) => {
  const {title, cardContentClass, func, supportedTokens, setIsProposalValid, setProposalError, setProposalMsg} = props
  const classes = useStyle()

  const aspisSupportedTokens: any = useAspisSupportedTokens([])

  const [tokenName, setTokenName] = useState<any>()
  const [tokenDetails, setTokenDetails] = useState<any>({
    token: [],
    supportedTokens: supportedTokens,
    func: 'changeTokensForDepositing'
  })

  const inputValidation = tokenDetails?.token.length > 0
  const hasCommonTokens = supportedTokens.some((supportedToken: string) => {
    return tokenDetails?.token.some((token:string) => {
      return supportedToken.toLowerCase() === token.toLowerCase()
    }) 
  });

  const commonElements = supportedTokens.map((token: string) =>
    token.toLowerCase()
  ).filter((token: string) =>
    tokenDetails?.token.map((t: string) =>
      t.toLowerCase()).includes(token)
  );
  const proposedTokens = useAspisSupportedTokens(commonElements)
  
  useEffect(() => {
    setIsProposalValid(inputValidation)
    if (tokenDetails?.token.length === 0) {
      setProposalError(true)
      setProposalMsg('Please select a token!')
    } else if(hasCommonTokens){
      setProposalError(true)
      setProposalMsg(`${proposedTokens.map((tokenSymbol)=>{return tokenSymbol.symbol})}, already on supported tokens!`)
    } else {
      setProposalError(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokenDetails, inputValidation])

  useEffect(() => {
    func(tokenDetails)
  })

  return (
      <Card className={`${classes.cardContent} ${cardContentClass}`}>
        <div className={classes.divider}>
          <p className={classes.cardTitle}>{title}</p>
        </div>
        <CardContent style={{ padding: '16px' }}>
          <Grid container>
            <Grid item lg={12} md={12} sm ={12} xs={12}>
              <p className={classes.title}>Tokens available for depositing</p>
              <FormControl className={classes.formContent}>
                <Select
                  multiple
                  name={tokenName}
                  value={tokenDetails.token}
                  onChange={(e: any) => {
                    setTokenDetails({ ...tokenDetails, token: e.target.value })
                    setTokenName(e.target.name)
                  }}
                  input={<OutlinedInput />} >
                  {
                    aspisSupportedTokens?.map((token) => (
                      <MenuItem value={token.address}
                        key={token.address}
                        data-token={token.address}>
                        {token.symbol}
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
  )
}
