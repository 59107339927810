import { useEffect, useState } from 'react'

// Material-UI imports
import {
  Card,
  CardContent,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Checkbox
} from '@material-ui/core'

import { makeStyles } from '@material-ui/core/styles'

import { DaoAPI } from '../../../../api/DaoAPI'
import { CopyToClipboardBtn } from '../../../CopyToClipboard';
import { useWeb3React } from '@web3-react/core';

const useStyle = makeStyles((theme) => ({
    divider: {
      borderBottom: '1px solid rgb(224 224 224)',
    },
    cardTitle: {
      fontSize: 13,
      fontWeight: 600,
      fontStyle: 'normal',
      lineHeight: 4,
      margin: 0,
      paddingLeft: '16px',
    },
    cardContent: {
      borderRadius: '18px',
      border: '1px solid rgba(214, 210, 242, 0.5)',
      boxShadow: '0px 51px 89px -49px rgba(196, 193, 232, 0.4)',
    },
    cardContentInside:{
      marginLeft: '10px',
      height: '198px',
      overflow: 'overlay',
    },
    fundManagerChip: {
      color: '#8765F2',
      background: 'rgba(137, 99, 244, 0.07)',
      fontFamily: 'Poppins',
      fontWeight: 600,
      fontSize: 15,
      borderRadius: '86px',
      '& .MuiTypography-body1': {
        fontFamily: 'Poppins',
        fontSize: '15px',
        fontWeight: 500,
        letterSpacing: 0
      }
    },
    content: {
      marginBottom: '15px',
    },
  }))

export const RemoveAllowlistedWalletsToDeposit = (props: any) => {
  const {func, daoId, setIsProposalValid, title} = props;
 
  const classes = useStyle()
  const { connector } = useWeb3React()
  
  const [addresses, setAddresses] = useState([])

  const [deleteAllowListedAddress, setAllowListedAddress] = useState<any>({
    addresses: [],
    func: 'removeWhitelistedUsers'
  })

  const inputValidation = deleteAllowListedAddress?.addresses.length > 0 
  
  useEffect(()=>{
    setIsProposalValid(inputValidation)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[inputValidation])

  useEffect(() => {
    const fetchAddresses = async () => {
      setAddresses(await DaoAPI.getWhitelistedUsers(daoId, connector))
    }

    func(deleteAllowListedAddress)

    fetchAddresses().catch(console.error)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [daoId, deleteAllowListedAddress])

  return (
      <Card className={classes.cardContent}>
        <div className={classes.divider}>
          <p className={classes.cardTitle}>{title}</p>
        </div>
        <CardContent className={classes.cardContentInside}>
          {
            addresses.map((item) => (
              <div className={classes.content} key={item} style={{display:'flex', justifyContent:'space-between'}}>
                <FormControl style={{width:'100%'}}>
                  <RadioGroup>
                    <FormControlLabel
                      className={classes.fundManagerChip}
                      value={item}
                      control={
                        <Checkbox
                          onChange={(e) => {
                            if(e.target.checked) {
                              setAllowListedAddress({ ...deleteAllowListedAddress, addresses: [...deleteAllowListedAddress.addresses, e.target.value.toLowerCase()] }) 
                            } else {
                              setAllowListedAddress({ ...deleteAllowListedAddress, addresses: deleteAllowListedAddress.addresses.filter(el => el !== item) }) 
                             }
                        }} />
                      }
                      label={item}
                    />
                  </RadioGroup>
                </FormControl>
                <CopyToClipboardBtn props={item} />
              </div>
            ))
          }
        </CardContent>
      </Card>
  )
}
export default RemoveAllowlistedWalletsToDeposit;

