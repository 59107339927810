import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Box, Grid, Container } from '@material-ui/core'

import { socialMedias } from '../../const/socialMedias'
import { Logo } from '../'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.third.main,
  },
  logo: {
    color: theme.palette.primary.main,
    fontFamily: theme.fontFamilies.primary,
    fontSize: 32,
    width: 150,
    lineHeight: '38px',
    fontWeight: 900,
    display: 'inline-block',
    // margin: '0 105px 33px 0',
    // marginRight: 105,
    marginBottom: theme.spacing(1),
    alignItems: 'end',
  },
  copyRight: {
    display: 'inline-block',
    // marginTop: 60,
    // marginBottom: 30,
    color: theme.palette.fifth.main,
    fontFamily: theme.fontFamilies.third,
    fontSize: 13,
    fontWeight: 'normal',
    // '@media screen and (max-width: 1153px)': {
    // marginTop: 30,
    // },
  },
  sectionsWrapperWithLogo: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    // marginTop: 96,
    // marginTop: 96 / 2,
    padding: '50px 0',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    // '@media screen and (max-width: 1153px)': {
    // marginTop: 50,
    // },
    '@media screen and (max-width: 800px)': {
      flexDirection: 'column',
      textAlign: 'center',
      gap: 20,
      // marginTop: 43,
    },
  },
  // sectionsWrapper: {
  //   display: 'flex',
  //   flexWrap: 'wrap',
  //   width: 825,
  //   justifyContent: 'space-between',
  //   '@media screen and (max-width: 850px)': {
  //     width: 'auto',
  //   },
  // },
  section: {
    // width: 255,
    // margin: '0 0 32px 0',
  },
  sectionTitle: {
    color: theme.palette.fourth.main,
    fontWeight: 600,
    fontSize: 20,
    lineHeight: '30px',
    marginBottom: 27,
    display: 'inline-block',
  },
  list: {
    padding: 0,
    margin: 0,
    display: 'flex',
    flex: 2,
    justifyContent: 'space-between',
    gap: 20,
  },
  listItem: {
    listStyle: 'none',
    // marginBottom: 12,
    fontSize: 14,
    lineHeight: '30px',
    '& a': {
      color: theme.palette.fifth.main,
      textDecoration: 'none',
    },
    '&:last-child': {
      marginBottom: 0,
    },
  },
  socialIconList: {
    display: 'flex',
    // width: 140,
    // flex: 0,
    justifyContent: 'flex-end',
    gap: 20,
  },
  listSocialIconItem: {
    listStyle: 'none',
    '&:last-child': {
      marginBottom: 0,
    },
  },
}))

export const Footer = (props) => {
  const classes = useStyles(props)

  return (
    <Container className={classes.root}>
      <Grid className={classes.sectionsWrapperWithLogo}>
        <Grid>
          <Box>
            <Logo className={classes.logo} />
          </Box>
          <Box className={classes.copyRight}>
            &copy; 2022 Aspis. All rights reserved
          </Box>
        </Grid>
        <Grid className={classes.section}>
          {/*<span className={classes.sectionTitle}>Company</span>*/}
        </Grid>

        <Grid className={classes.section}>
          {/*<span className={classes.sectionTitle}>Reach us</span>*/}
          <ul className={`${classes.list} ${classes.socialIconList}`}>
            {socialMedias.map((socialMedia, index) => (
              <li key={index} className={classes.listSocialIconItem}>
                <a href={socialMedia.link}>
                  <img src={socialMedia.src} alt={socialMedia.alt} />
                </a>
              </li>
            ))}
          </ul>
        </Grid>
      </Grid>
    </Container>
  )
}
