import { PoolStatuses } from '../const/poolStatuses'
import { Pool } from '../models/Pool'

export const getPoolStatus = ({investStartTime, investFinishTime, address}: Pool) => {
  const currentDate = new Date()
  const startDate = new Date(investStartTime)
  const finishDate = new Date(investFinishTime)
  if (currentDate < startDate || !address) {
    return PoolStatuses.Upcoming
  }
  if (currentDate > finishDate) {
    return PoolStatuses.Closed
  }
  return PoolStatuses.Live
}
