import web3 from 'web3'

import { NetworkId } from '../models/Network'

// Network Assets
import BinanceIcon from '../assets/icons/binance.svg'
import EthereumIcon from '../assets/icons/ethereumIcon.svg'
import ArbitrumIcon from '../assets/icons/arbitrum.svg'
import PolygonIcon from '../assets/icons/polygon.svg'

type Network = {
  name: string;
  icon: string;
  isDisabled: Boolean
  params: NetworkParams
}

type NetworkParams = {
  chainName: string,
  chainId: string,
  nativeCurrency: Token,
  rpcUrls: string[]
}

type Token = {
  name: string,
  decimals: Number,
  symbol: string
}

const Ethereum: Network = {
  name: 'Ethereum',
  icon: EthereumIcon,
  isDisabled: true,
  params: {
    chainName: 'Ethereum',
    chainId: web3.utils.toHex(NetworkId.EthereumMainnet),
    nativeCurrency: { name: 'ETH', decimals: 18, symbol: 'ETH' },
    rpcUrls: ['https://mainnet.infura.io/v3/844934b4f474410bbfc729add585f1ec']
  }
}

const Goerli: Network = {
  name: 'Goerli Testnet',
  icon: EthereumIcon,
  isDisabled: false,
  params: {
    chainName: 'Goerli Testnet',
    chainId: web3.utils.toHex(NetworkId.GoerliTestNetwork),
    nativeCurrency: { name: 'ETH', decimals: 18, symbol: 'ETH' },
    rpcUrls: ['https://goerli.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161']
  }
}

const BSC: Network = {
  name: 'BSC Mainnet',
  icon: BinanceIcon,
  isDisabled: false,
  params: {
    chainName: 'Smart Chain',
    chainId: web3.utils.toHex(NetworkId.BSCMainnet),
    nativeCurrency: { name: 'BNB', decimals: 18, symbol: 'BNB' },
    rpcUrls: ['https://bsc-dataseed.binance.org/', 'https://bsc-pokt.nodies.app', 'https://bsc.meowrpc.com', 'https://1rpc.io/bnb']
  }
}

const Arbitrum: Network = {
  name: 'Arbitrum One',
  icon: ArbitrumIcon,
  isDisabled: false,
  params: {
    chainName: 'Arbitrum One',
    chainId: web3.utils.toHex(NetworkId.Arbitrum),
    nativeCurrency: { name: 'ETH', decimals: 18, symbol: 'ETH' },
    rpcUrls: ['https://arb1.arbitrum.io/rpc', 'https://arbitrum.llamarpc.com', 'https://arbitrum.llamarpc.com', 'https://arb-mainnet.g.alchemy.com/v2/eveE2yHPDz200SgF7Nhl9fp7WacpIr97']
  }
}

const Polygon: Network = {
  name: 'Polygon',
  icon: PolygonIcon,
  isDisabled: false,
  params: {
    chainName: 'Polygon PoS',
    chainId: web3.utils.toHex(NetworkId.Polygon),
    nativeCurrency: { name: 'MATIC', decimals: 18, symbol: 'MATIC' },
    rpcUrls: ['https://polygon-rpc.com', 'https://polygon.llamarpc.com', 'https://polygon-bor-rpc.publicnode.com', 'https://polygon.drpc.org', 'https://polygon-mainnet.g.alchemy.com/v2/eveE2yHPDz200SgF7Nhl9fp7WacpIr97']
  }
}

const Localhost: Network = {
  name: 'Localhost',
  isDisabled: false,
  icon: EthereumIcon,
  params: {
    chainName: 'Localhost',
    chainId: web3.utils.toHex(NetworkId.LocalhostTestNetwork),
    nativeCurrency: { name: 'ETH', decimals: 18, symbol: 'ETH' },
    rpcUrls: ['https://localhost:8545']
  }
}

const networks = {
  [NetworkId.EthereumMainnet]: Ethereum,
  [NetworkId.GoerliTestNetwork]: Goerli,
  [NetworkId.BSCMainnet]: BSC,
  [NetworkId.LocalhostTestNetwork]: Localhost,
  [NetworkId.Arbitrum]: Arbitrum,
  [NetworkId.Polygon]: Polygon
}

export function getNetworkConfig(chainId: number): Network {
  return networks[chainId];
}

export function isChainSupported(chainId: number): boolean {
  return networks[chainId] !== undefined;
}

export function getSupportedChainsIds() {
  return Object.keys(networks)
}

export const mainnetNetworks = [BSC, Arbitrum, Polygon]

export const testnetNetworks = [BSC, Arbitrum, Polygon]

export const defaultChainId = 56
