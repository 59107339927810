import { useEffect, useRef, useState } from 'react'

// Material-UI imports
import {
  Badge,
  Card,
  CardContent,
  Grid,
  Switch,
  TextField,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import questionMark from '../../../../assets/icons/questionMark.svg'
import { NumericFormat } from 'react-number-format'
import { DaoAPI } from '../../../../api/DaoAPI'
import { useLocation } from 'react-router'
import BigNumber from 'bignumber.js'
import { useWeb3React } from '@web3-react/core'

const useStyle = makeStyles((theme) => ({
  divider: {
    borderBottom: '1px solid rgb(224 224 224)',
  },
  cardTitle: {
    fontSize: 13,
    fontWeight: 600,
    fontStyle: 'normal',
    lineHeight: 4,
    margin: 0,
    paddingLeft: '16px',
  },
  cardContent: {
    borderRadius: '18px',
    border: '1px solid rgba(214, 210, 242, 0.5)',
    boxShadow: '0px 51px 89px -49px rgba(196, 193, 232, 0.4)',
    '& .MuiCardContent-root': {
      padding: 0,
    },
  },
  contentHelper2: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    padding: '5px',
  },
  contentHelper: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    padding: '5px',
    justifyContent:'space-between',
    [theme.breakpoints.down('xs')]:{
      display:'block'
     }
  },
  labelHelper: {
    fontWeight: 500,
    fontSize: '9px',
    color: '#958BAE',
  },
  boldText: {
    letterSpacing: '0.4px',
    fontSize: '12px',
    color: '#4C4C66',
  },
  spanTextColor: {
    color: '#BC65F2',
    fontSize: '12px',
    textAlign: 'right',
  },
  questionMark: {
    width: '20px'
  },
  link: {
    color: '#BC65F2'
  },
  inputOffBoarding: {
    width: '100%',
   '& .MuiOutlinedInput-input' :{
    padding:'12.5px 14px'
   },
   '& .MuiOutlinedInput-notchedOutline':{
    borderRadius: '10px'
   }
  },

}))

export const ChangeWithdrawalWindows = (props) => {
  const {func, setIsProposalValid, setProposalError, setProposalMsg} = props;
  const classes = useStyle()
  const location = useLocation();
  const { connector } = useWeb3React();

  const [daoConfig, setDaoConfig] = useState<any>({})
  const [isWithdrawalDisabled, setWithdrawalDisabled] = useState(false)
  const [inputProposalError, setInputProposalError] = useState(false)

  const [withdrawalWindow, setWithdrawalWindow] = useState<any>({
    window: undefined,
    freezePeriod: undefined,
    func: 'changeWithdrawalWindows'
  })
  let inputRef = useRef({ window: withdrawalWindow?.window, freezePeriod: withdrawalWindow?.freezePeriod });
  console.log(withdrawalWindow)
  let paths = location.pathname.split('/');
  let daoId = paths[paths.length - 1];

  const inputValidation =
    withdrawalWindow?.window !== undefined &&
    withdrawalWindow?.freezePeriod !== undefined &&
    !isNaN(withdrawalWindow?.window) &&
    !isNaN(withdrawalWindow?.freezePeriod) && isWithdrawalDisabled ?
    (withdrawalWindow?.window / 24) >= 0 && (withdrawalWindow?.window / 24) <= 365 &&
    (withdrawalWindow?.freezePeriod / 24) >= 0 && (withdrawalWindow?.freezePeriod / 24) <= 365 :
    (withdrawalWindow?.window / 24) > 0 && (withdrawalWindow?.window / 24) <= 365 &&
    (withdrawalWindow?.freezePeriod / 24) > 0 && (withdrawalWindow?.freezePeriod / 24) <= 365

  const proposalValidation =
    parseInt(daoConfig?.withdrawlWindow) === withdrawalWindow?.window &&
    parseInt(daoConfig?.freezePeriod) === withdrawalWindow?.freezePeriod;
    
    const disableWithdrawal = () => {
      setWithdrawalDisabled(!isWithdrawalDisabled)
      if (!isWithdrawalDisabled) {
        setWithdrawalWindow({ ...withdrawalWindow, freezePeriod: 0, window: 0 })
      } else {
        setWithdrawalWindow({
          ...withdrawalWindow,
          freezePeriod: inputRef.current.freezePeriod,
          window: inputRef.current.window
        }) 
      }
    }

  console.log(withdrawalWindow);
  console.log(daoConfig);
  

  useEffect(() => {
    const decodeConfiguration = async () => {
      try {
        setDaoConfig(await DaoAPI.getConfiguration(daoId, connector))
      } catch (e) {
        console.log('Error message:', e)
      }
    }
    decodeConfiguration()
      .catch(console.error)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(()=>{
    setIsProposalValid(inputValidation)
    if(proposalValidation){
      setProposalError(true);
      setProposalMsg('You cannot create a proposal with the same withdrawal window that is already present.')
    }else{
      setProposalError(false);
    }
    if(isWithdrawalDisabled === true) {
      setProposalError(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[inputValidation, withdrawalWindow, isWithdrawalDisabled, proposalValidation])

  useEffect(() => {
    func(withdrawalWindow)
  })

  return (
      <Card className={classes.cardContent}>
        <div className={classes.divider}>
          <p className={classes.cardTitle}>Change withdrawal window</p>
        </div>
        <CardContent style={{ padding: '16px' }}>
          <Grid container>
              <div className={classes.contentHelper}>
                <div className={classes.labelHelper}>
                  <b className={classes.boldText}>Withdrawal windows,</b>days
                  <Switch defaultChecked onClick={disableWithdrawal} />
                </div>
                <span className={classes.spanTextColor}>
                  <Badge overlap="rectangular">
                    <img className={classes.questionMark} src={questionMark} alt='questionMark' />
                  </Badge>
                  <a href="!#" target="_blank" className={classes.link}> Learn more withdrawal windows </a>
                </span>
              </div>
            <Grid item lg={12} md={12} sm={12} xs={12}>
            <div className={classes.contentHelper2}>
                    <b className={classes.boldText}>Window,</b>&nbsp;
                    <div className={classes.labelHelper}>days</div>
                  </div>
                <NumericFormat
                  customInput={TextField}
                  variant="outlined"
                  className={classes.inputOffBoarding}
                  disabled={isWithdrawalDisabled}
                  placeholder={`${isNaN(daoConfig?.withdrawlWindow) ? 0 : new BigNumber(daoConfig?.withdrawlWindow).dividedBy(24)}`}
                  onChange={(e: any) => setWithdrawalWindow({ ...withdrawalWindow, window: (parseInt(e.target.value.replace(/[%\s]/g, '')) * 24)})}
                  inputRef = {(input: any) => inputRef.current.window = (parseInt(input?.value.replace(/[%\s]/g, '')) * 24)}
                  allowNegative={false}
                  thousandSeparator=","
                  onBlur={()=>setInputProposalError(true)}
                  error={inputProposalError && (withdrawalWindow?.window / 24)  > 0 && (withdrawalWindow?.window / 24) <= 365 ? !inputProposalError : inputProposalError}
                  helperText={
                    inputProposalError && !isWithdrawalDisabled ? 
                    (withdrawalWindow?.window === undefined ? 'This field is required' : '') || 
                    ((withdrawalWindow?.window / 24) <= 365 ? "":"Please add value between 1 and 365" ) || 
                    ((withdrawalWindow?.window / 24) > 0 ? "":"Please add value between 1 and 365" ): null 
                  }
                />
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}> 
             <div className={classes.contentHelper2}>
                    <b className={classes.boldText}>Freeze period,</b>&nbsp;
                    <div className={classes.labelHelper}>days</div>
                  </div>
                <NumericFormat
                  customInput={TextField}
                  variant="outlined"
                  disabled={isWithdrawalDisabled}
                  className={classes.inputOffBoarding}
                  placeholder={`${isNaN(daoConfig?.freezePeriod) ? 0 : new BigNumber(daoConfig?.freezePeriod).dividedBy(24)}`}
                  onChange={(e: any) => setWithdrawalWindow({ ...withdrawalWindow, freezePeriod: (parseInt(e.target.value.replace(/[%\s]/g, '')) * 24)})}
                  inputRef = {(input: any) => inputRef.current.freezePeriod = (parseInt(input?.value.replace(/[%\s]/g, '')) * 24)}
                  allowNegative={false}
                  thousandSeparator=","
                  onBlur={()=>setInputProposalError(true)}
                  error={inputProposalError && (withdrawalWindow?.freezePeriod / 24) > 0 && (withdrawalWindow?.freezePeriod / 24) <= 365 ? !inputProposalError : inputProposalError}
                  helperText={
                    inputProposalError && !isWithdrawalDisabled  ? 
                    (withdrawalWindow?.freezePeriod === undefined ? 'This field is required' : '') || 
                    ((withdrawalWindow?.freezePeriod / 24) <= 365 ? "":"Please add value between 1 and 365" ) || 
                    ((withdrawalWindow?.freezePeriod / 24) > 0 ? "":"Please add value between 1 and 365" ): null 
                  }
                />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
  )
}
