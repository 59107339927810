import { useEffect, useState } from 'react'
import { useLocation } from 'react-router'
import web3 from 'web3'
import moment from 'moment'

// Material-UI imports
import {
  Card,
  CardContent,
  Grid,
  TextField
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import { decodeDaoConfigParams } from '../../../helpers/abiDecoder'
import { DaoAPI } from '../../../api/DaoAPI'
import { useWeb3React } from '@web3-react/core'

const useStyle = makeStyles((theme) => ({
  divider: {
    borderBottom: '1px solid rgb(224 224 224)',
  },
  cardTitle: {
    fontSize: 13,
    fontWeight: 600,
    fontStyle: 'normal',
    lineHeight: 4,
    margin: 0,
    paddingLeft: '16px',
  },
  cardContent: {
    borderRadius: '18px 0px 0px 18px',
    border: '1px solid rgba(214, 210, 242, 0.5)',
    boxShadow: '0px 51px 89px -49px rgba(196, 193, 232, 0.4)',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      borderRadius: '18px 18px 18px 18px',
    }
  },
  cardContentSecondary: {
    borderRadius: '0px 18px  18px 0px',
    border: '1px solid rgba(214, 210, 242, 0.5)',
    boxShadow: '0px 51px 89px -49px rgba(196, 193, 232, 0.4)',
    width: '100%',
    background: '#F7F6FC',
  },
  alignItems: {
    alignItems: 'flex-end'
  },
  contentHelper: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 14px'
  },
  labelHelper: {
    fontWeight: 500,
    fontSize: '9px',
    color: '#958BAE',
  },
  boldText: {
    letterSpacing: '0.4px',
    fontSize: '12px',
    color: '#4C4C66',
  },
  spanTextColor: {
    color: '#BC65F2',
    fontSize: '8px',
    textAlign: 'right',
  },
  inputContent: {
    "& .MuiInputBase-input": {
      padding: '10.5px 14px',
      background: '#FBFBFF',
    },
    "& .MuiInputBase-root": {
      borderRadius: '9px'
    },
    display: 'grid',
    fontSize: '12px',
    fontWeight: 500,
    padding: '15px 10px',
  },
}))

export const VoteForChangeFundraisingPhase = (props: any) => {
  const classes = useStyle()

  const { hexToNumber } = web3.utils
  const { connector } = useWeb3React()
 
  const location = useLocation();
  const daoAddress = location.state.state.data.subgraph.id

  const [daoConfig, setDaoConfig] = useState<any>({})
  const [proposedFundraisingPeriod, setProposedFundraisingPeriod] = useState<any>({})

  useEffect(() => {
    const decodeLockUp = async () => {
      try {
        setDaoConfig(await DaoAPI.getConfiguration(daoAddress, connector))
        const result = decodeDaoConfigParams(props?.action)

        setProposedFundraisingPeriod({
          startDate: moment.unix(hexToNumber(result.inputs[0]._hex)),
          endDate: moment.unix(hexToNumber(result.inputs[1]._hex))
        })
      } catch (e) {
        console.log('Error message:', e)
      }
    }
    decodeLockUp()
      .catch(console.error)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Grid container>
        <Grid item lg={6}>
          <Card className={classes.cardContent}>
            <div className={classes.divider}>
              <p className={classes.cardTitle}>New fundraising phase</p>
            </div>
            <CardContent style={{ padding: '16px' }}>
              <Grid container className={classes.alignItems}>
                <div className={classes.labelHelper}>
                  <b className={classes.boldText}>Start date</b>
                </div>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <div className={classes.inputContent}>
                    <TextField
                      disabled
                      value={proposedFundraisingPeriod.startDate ? proposedFundraisingPeriod.startDate.format('MMMM Do YYYY') : ''}
                      variant="outlined"
                    />
                  </div>
                </Grid>
              </Grid>
              <Grid container className={classes.alignItems}>
                <div className={classes.labelHelper}>
                  <b className={classes.boldText}>End date</b>
                </div>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <div className={classes.inputContent}>
                    <TextField
                      disabled
                      value={proposedFundraisingPeriod.endDate ? proposedFundraisingPeriod.endDate.format('MMMM Do YYYY'): ''}
                      variant="outlined"
                    />
                  </div>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        <Grid item lg={6}>
          <Card className={classes.cardContentSecondary}>
            <div className={classes.divider}>
              <p className={classes.cardTitle}>Last fundraising phase</p>
            </div>
            <CardContent style={{ padding: '16px' }}>
              <Grid container className={classes.alignItems}>
                <div className={classes.labelHelper}>
                  <b className={classes.boldText}>Start date</b>
                </div>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <div className={classes.inputContent}>
                    <TextField
                      disabled
                      value={moment.unix(daoConfig?.startTime).format('MMMM Do YYYY')}
                      variant="outlined"
                    />
                  </div>
                </Grid>
              </Grid>
              <Grid container className={classes.alignItems}>
                <div className={classes.labelHelper}>
                  <b className={classes.boldText}>End date</b>
                </div>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <div className={classes.inputContent}>
                    <TextField
                      disabled
                      value={moment.unix(daoConfig?.finishTime).format('MMMM Do YYYY')}
                      variant="outlined"
                    />
                  </div>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  )
}
