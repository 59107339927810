export enum ChainCode {
  BSC = 'bsc',
  ETH = 'eth',
  ETH_TEST_GOERLI = 'eth_test_goerli',
  LOCALHOST_TEST = 'localhost_test',
  ARBITRUM = 'arbitrum',
  POLYGON = 'polygon'
}

export enum NetworkId {
  EthereumMainnet = 1,
  GoerliTestNetwork = 5,
  BSCMainnet = 56,
  Polygon = 137,
  LocalhostTestNetwork = 31337,
  Arbitrum = 42161
}

export const NetworkMap = {
  [ChainCode.BSC]: NetworkId.BSCMainnet,
  [ChainCode.ETH]: NetworkId.EthereumMainnet,
  [ChainCode.ETH_TEST_GOERLI]: NetworkId.GoerliTestNetwork,
  [ChainCode.LOCALHOST_TEST]: NetworkId.LocalhostTestNetwork,
  [ChainCode.ARBITRUM]: NetworkId.Arbitrum,
  [ChainCode.POLYGON]: NetworkId.Polygon
}

export const NetworkNames = {
  [NetworkId.BSCMainnet]: 'Binance Smart Chain',
  [NetworkId.EthereumMainnet]: 'Ethereum Main Net',
  [NetworkId.GoerliTestNetwork]: 'Goerli Test Network',
  [NetworkId.LocalhostTestNetwork]: 'Localhost Test Network',
  [NetworkId.Arbitrum]: 'Arbitrum One',
  [NetworkId.Polygon]: 'Polygon'
}
