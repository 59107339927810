import { Avatar, Box, Container, Grid, Typography } from '@material-ui/core'
import { Button } from '../Button'
import { ChipLabel } from '../Chip'
import { makeStyles } from '@material-ui/core/styles'

// Assets
import BSCExplorer from '../../assets/icons/bnbIcon.png'
import EtherExplorer from '../../assets/icons/etherscan.png'
import Arbiscan from '../../assets/icons/arbitrum.svg'
import PolygonExplorer from '../../assets/icons/polygonIcon.png'
import { Link } from '@mui/material'
import { NetworkId } from '../../models/Network'

const useStyles = makeStyles(() => ({
  buttonContainer: {
    margin: '0 auto',
    paddingBottom: '16px',
    paddingTop: '16px',
  },
  chipBox: {
    paddingTop: 15,
    paddingBottom: 15,
  },
  padding0: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  explorerLink: {
    textDecoration: 'none',
  },
  moreInfoButton: {
    paddingTop: 15,
    paddingBottom: 15,
    margin: '0 auto',
  },
  txnLink: {
    color: '#8765F2 !important',
  },
  txnFont: {
    fontSize: '14px',
  },
}))

export const MembershipStructure = (props) => {
  const classes = useStyles()

  return (
    <Grid item className={classes.buttonContainer} lg={5} sm={5} md={8} xs={11}>
      {props?.chainId === NetworkId.GoerliTestNetwork && (
        <Button
          type="secondaryOutlined"
          href={`https://goerli.etherscan.io/token/${props?.tokenId}`}
          target="_blank"
        >
          See full membership structure
        </Button>
      )}
      {props?.chainId === NetworkId.BSCMainnet && (
        <Button
          type="primaryOutlined"
          href={`https://bscscan.com/address/${props?.daoId}`}
          target="_blank"
          size="large"
        >
          See more details
        </Button>
      )}
      {props?.chainId === NetworkId.Arbitrum && (
        <Button
          type="primaryOutlined"
          href={`https://arbiscan.io/address/${props?.daoId}`}
          target="_blank"
          size="large"
        >
          See more details
        </Button>
      )}
      {props?.chainId === NetworkId.Polygon && (
        <Button
          type="primaryOutlined"
          href={`https://polygonscan.com/address/${props?.daoId}`}
          target="_blank"
          size="large"
        >
          See more details
        </Button>
      )}
    </Grid>
  )
}

export const ViewOnExplorer = ({ chainId, daoId }) => {
  const classes = useStyles()

  return (
    <Box className={classes.chipBox}>
      <Container className={classes.padding0}>
        {Number(chainId) === NetworkId.GoerliTestNetwork && (
          <a
            className={classes.explorerLink}
            target="_blank"
            href={`https://goerli.etherscan.io/address/${daoId}`}
            rel="noreferrer"
          >
            <ChipLabel
              size="medium"
              label="View on Goerli Etherscan"
              type="chipInfo"
              avatar={<Avatar src={EtherExplorer} />}
            />
          </a>
        )}
        {Number(chainId) === NetworkId.BSCMainnet && (
          <a
            className={classes.explorerLink}
            target="_blank"
            href={`https://bscscan.com/address/${daoId}`}
            rel="noreferrer"
          >
            <ChipLabel
              size="medium"
              label="View on BSC Explorer"
              type="chipInfo"
              avatar={<Avatar src={BSCExplorer} />}
            />
          </a>
        )}
        {Number(chainId) === NetworkId.Arbitrum && (
          <a
            className={classes.explorerLink}
            target="_blank"
            href={`https://arbiscan.io/address/${daoId}`}
            rel="noreferrer"
          >
            <ChipLabel
              size="medium"
              label="View on Arbiscan"
              type="chipInfo"
              avatar={<Avatar src={Arbiscan} />}
            />
          </a>
        )}
        {Number(chainId) === NetworkId.Polygon && (
          <a
            className={classes.explorerLink}
            target="_blank"
            href={`https://polygonscan.com/address/${daoId}`}
            rel="noreferrer"
          >
            <ChipLabel
              size="medium"
              label="View on PolygonScan"
              type="chipInfo"
              avatar={<Avatar src={PolygonExplorer} />}
            />
          </a>
        )}
      </Container>
    </Box>
  )
}

export const TransactionHistoryButton = (props) => {
  const classes = useStyles()

  return (
    <Grid item xs={11} lg={5} md={5} sm={5} className={classes.moreInfoButton}>
      {props?.chainId === NetworkId.GoerliTestNetwork && (
        <Button
          type="primaryOutlined"
          href={`https://goerli.etherscan.io/address/${props?.daoId}`}
          target="_blank"
          size="large"
        >
          See more details
        </Button>
      )}
      {props?.chainId === NetworkId.BSCMainnet && (
        <Button
          type="primaryOutlined"
          href={`https://bscscan.com/address/${props?.daoId}`}
          target="_blank"
          size="large"
        >
          See more details
        </Button>
      )}
      {props?.chainId === NetworkId.Arbitrum && (
        <Button
          type="primaryOutlined"
          href={`https://arbiscan.io/address/${props?.daoId}`}
          target="_blank"
          size="large"
        >
          See more details
        </Button>
      )}
      {props?.chainId === NetworkId.Polygon && (
        <Button
          type="primaryOutlined"
          href={`https://polygonscan.com/address/${props?.daoId}`}
          target="_blank"
          size="large"
        >
          See more details
        </Button>
      )}
    </Grid>
  )
}

export const TransactionHash = (props) => {
  const classes = useStyles()

  const chainId = parseInt(props?.chainId)

  return (
    <Typography>
      {chainId === NetworkId.GoerliTestNetwork && (
        <p className={classes.txnFont}>
          Txn hash:{' '}
          <Link
            className={classes.txnLink}
            href={`https://goerli.etherscan.io/tx/${props?.txnHash}`}
            underline="hover"
            target="_blank"
          >
            {props?.txnHash?.slice(0, 12) +
              '...' +
              props?.txnHash?.slice(55, 67)}
          </Link>
        </p>
      )}
      {chainId === NetworkId.BSCMainnet && (
        <p className={classes.txnFont}>
          Txn hash:{' '}
          <Link
            className={classes.txnLink}
            href={`https://bscscan.com/tx/${props?.txnHash}`}
            underline="hover"
            target="_blank"
          >
            {props?.txnHash?.slice(0, 12) +
              '...' +
              props?.txnHash?.slice(55, 65)}
          </Link>
        </p>
      )}
      {chainId === NetworkId.Arbitrum && (
        <p className={classes.txnFont}>
          Txn hash:{' '}
          <Link
            className={classes.txnLink}
            href={`https://arbiscan.io/tx/${props?.txnHash}`}
            underline="hover"
            target="_blank"
          >
            {props?.txnHash?.slice(0, 12) +
              '...' +
              props?.txnHash?.slice(55, 67)}
          </Link>
        </p>
      )}
      {chainId === NetworkId.Polygon && (
        <p className={classes.txnFont}>
          Txn hash:{' '}
          <Link
            className={classes.txnLink}
            href={`https://polygonscan.com/tx/${props?.txnHash}`}
            underline="hover"
            target="_blank"
          >
            {props?.txnHash?.slice(0, 12) +
              '...' +
              props?.txnHash?.slice(55, 67)}
          </Link>
        </p>
      )}
    </Typography>
  )
}
