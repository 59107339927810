import { useState } from 'react'

// Material UI
import { Container } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

// Components
import { NavigationBar } from '../../components/NavigationBar'
import { Tabs } from '../../components'
import { BackgroundImage } from '../../components/BackgroundImage'

// Assets
import singlePageBackground from '../../assets/images/singePageBackground.svg'

import { routes } from '../../const/routes'
import { FundsContent } from './FundsContent/FundsContent'

const useStyles = makeStyles((theme) => ({
  tabs: {
    '@media screen and (max-width: 360px)': {
      margin: '0 0 40px 0',
    },
  },
  fundsContainer: {
    flexWrap: 'nowrap',
    overflow: 'overlay',
    display: 'block',
  },
}))

const filterValues = [
  { value: 'Popular Funds' },
  { value: '24h growth' },
  { value: 'Total growth' },
  { value: 'Largest Funds' },
  { value: 'Max deposit' },
  { value: 'ETH' },
  { value: 'USDT' },
  { value: 'USDC' },
]

export const Funds = (props) => {
  const classes = useStyles(props)

  const [filterNameInput, setFilterValue] = useState()

  const pathname = window.location.pathname

  const filterDaosByName = (value) => {
    setFilterValue(value)
  }

  return (
    <Container>
      <BackgroundImage imageUrl={singlePageBackground} />
      <div className={classes.container}>
        <NavigationBar link={routes.MAIN} pathName={pathname} />
        <Tabs
          className={classes.tabs}
          title="Choose where to invest"
          filterValue={filterValues}
          filterDaos={filterDaosByName}
        />
        <FundsContent
          fundsCard={classes.fundsContent}
          fundsContainer={classes.fundsContainer}
          filterNameInput={filterNameInput}
        />
      </div>
    </Container>
  )
}
