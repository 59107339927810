import * as React from 'react';
import {Button, Table,TableBody,TableCell,TableContainer,TableHead,TableRow} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import polkaLokr from '../../assets/icons/polkaLokr.svg'


const useStyles = makeStyles(
    (theme) => ({
        table:{
            background:'white',
            borderLeft: '1px solid #e0e0e0',
            borderRight: '1px solid #e0e0e0',
            '& .MuiTableCell-root':{
              padding:'10px'
            }
        },
        tableTitle:{
            padding: '15px',
            fontWeight: 600,
            fontSize: '13px',
            border: '1px solid #e0e0e0',
            background: '#FCFAFF',
            borderRadius: '18px 18px 0px 0px',
        },
        rowText:{
          fontSize: '11px',
          fontWeight: 500,
          lineHeight:'23.87px',
          color: '#4C4C66',
        },
        cellText:{
          fontFamily:'Poppins',
          fontWeight: 600,
          fontSize: '13px',
          color:'#2F2F4D',
        },
        nextUnlockInText:{
          fontFamily:'Poppins',
          fontWeight: 600,
          fontSize: '13px',
          lineHeight: '37.5px',
          color:'#8765F2'
        },
        icon:{
          display: 'inline-flex',
          verticalAlign: 'middle',
          marginRight: '10px'
        },
        buttonContent:{
          borderRadius: '0px 0px 18px 18px',
          border: '1px solid #e0e0e0',
          background:'white',
          textAlign: 'center',
          padding:'16px'
        },
        button:{
          background: 'rgba(232, 99, 244, 0.07)',
          border: '1px solid #BC65F2',
          borderRadius: '86px',
          color: '#BC65F2',
          padding:'22px 26px'
        }
    }))

function createData(key,icon,projectName, invested, allocation, claimed, availableToClaim, nextUnlockIn, nextUnlock, claim) {
  return {key,icon, projectName, invested, allocation, claimed, availableToClaim, nextUnlockIn, nextUnlock, claim};
}

const rows = [
  createData("0",polkaLokr,'PolkaLokr', '$10 413.31', '11 310.31 ASPIS', '10 000.31 ASPIS','1000.21 ASPIS', '30d 5h 29s', '1000.21 ASPIS', 'Claim'),
  createData("1",polkaLokr,'PolkaLokr', '$10 413.31', '11 310.31 ASPIS', '10 000.31 ASPIS','1000.21 ASPIS', '30d 5h 29s', '1000.21 ASPIS', 'Claim'),
  createData("2",polkaLokr,'PolkaLokr', '$10 413.31', '11 310.31 ASPIS', '10 000.31 ASPIS','1000.21 ASPIS', '30d 5h 29s', '1000.21 ASPIS', 'Claim'),
  createData("3",polkaLokr,'PolkaLokr', '$10 413.31', '11 310.31 ASPIS', '10 000.31 ASPIS','1000.21 ASPIS', '30d 5h 29s', '1000.21 ASPIS', 'Claim'),
  createData("4",polkaLokr,'PolkaLokr', '$10 413.31', '11 310.31 ASPIS', '10 000.31 ASPIS','1000.21 ASPIS', '30d 5h 29s', '1000.21 ASPIS', 'Claim'),
  createData("5",polkaLokr,'PolkaLokr', '$10 413.31', '11 310.31 ASPIS', '10 000.31 ASPIS','1000.21 ASPIS', '30d 5h 29s', '1000.21 ASPIS', 'Claim'),
];

export default function AllocationTable() {
    const classes = useStyles();
  return (
    <TableContainer>
        <div className={classes.tableTitle}>Allocations</div>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell className={classes.rowText}>Project Name</TableCell>
            <TableCell className={classes.rowText}>Invested</TableCell>
            <TableCell className={classes.rowText}>Allocation</TableCell>
            <TableCell className={classes.rowText}>Claimed</TableCell>
            <TableCell className={classes.rowText}>Available to claim</TableCell>
            <TableCell className={classes.rowText}>Next unlock in</TableCell>
            <TableCell className={classes.rowText}>Next unlock</TableCell>
            <TableCell className={classes.rowText}>Claim</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.key} >
              <TableCell className={classes.cellText}>
                <img className={classes.icon} src={row.icon} alt="icon"/> {row.projectName}
              </TableCell>
              <TableCell className={classes.cellText}>{row.invested}</TableCell>
              <TableCell className={classes.cellText}>{row.allocation}</TableCell>
              <TableCell className={classes.cellText}>{row.claimed}</TableCell>
              <TableCell className={classes.cellText}>{row.availableToClaim}</TableCell>
              <TableCell className={classes.nextUnlockInText}>{row.nextUnlockIn}</TableCell>
              <TableCell className={classes.cellText}>{row.nextUnlock}</TableCell>
              <TableCell className={classes.cellText}>{row.claim}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <div className={classes.buttonContent}><Button className={classes.button}>Show More</Button></div>
    </TableContainer>
  );
}