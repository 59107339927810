// Material UI imports
import {
  Container,
  Card,
  CardContent,
  Grid,
  Typography,
  Box,
  LinearProgress,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import BigNumber from 'bignumber.js'

const useStyles = makeStyles({
    card:{
        background: '#F9F6FB',
        border: '1px solid rgba(214, 210, 242, 0.5)',
        boxShadow: '0px 51px 89px -49px rgba(196, 193, 232, 0.4)',
        borderRadius: '18px'
    },
    progressText:{
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: '15px'
      },
      progressPercentage:{
        color: '#57556E',
        fontWeight:800,
        fontSize: 11,
        fontFamily:'Poppins',
        opacity: 0.9
      },
      progressTitle:{
        fontSize: 10,
        marginTop: 10
      },
      progressBarYes:{
        height: '6px',
        left: '10px',
        borderRadius: '53px',
      },
      boldText:{
        color:'#8765F2',
        fontWeight: 700,
        fontSize: '11px'
      },
      container:{
        borderBottom: '1px solid rgb(224 224 224)'
      },
      tabelTile:{
        fontSize: 13,
        fontWeight: 600,
        fontStyle: 'normal',
        fontFamily: 'Poppins',
        lineHeight: 4
      },
})

export const CurrentResults = (props) => {
  const classes = useStyles()

  return (
      <Card className={classes.card}>
        <Container className={classes.container}>
          <Typography className={classes.tabelTile}>Current results</Typography>
        </Container>
        <CardContent>
          <Grid item lg={12}>
            <div className={classes.progressText}>
              <div>
                <Typography className={classes.progressPercentage}>
                  For
                </Typography>
              </div>
              <div>
                <Typography style={{ fontFamily: 'Poppins', fontSize: '11px', color: 'BC65F2', fontWeight: 400 }}>
                  <b className={classes.boldText}>
                  { props?.proposal?.yea === null || props?.proposal?.yea === '0' ?
                    0 : 
                    (new BigNumber(props?.proposal?.yea).dividedBy('1e18').toFixed(2))
                  }
                  {
                    props?.proposal?.dao?.token?.symbol
                  }
                  </b>{'  '}
                  {
                    props?.proposal?.yea === null || props?.proposal?.yea === '0' ?
                      '0%'
                      :
                      (new BigNumber(props?.proposal?.yea).dividedBy(props?.proposal?.votingPower).multipliedBy(100).toFixed(5)) + '%'
                  }
                </Typography>
              </div>
            </div>
            <Box sx={{ width: '100%', position: 'relative', top: '9px', left: '-9px' }}>
              <LinearProgress
                value={
                  props?.proposal?.yea === null ?
                    0
                    :
                    (new BigNumber(props?.proposal?.yea).dividedBy(props?.proposal?.votingPower).multipliedBy(100).toNumber())
                }
                className={classes.progressBarYes}
                variant="determinate" />
            </Box>
          </Grid>
          <Grid item lg={12}>
            <div className={classes.progressText}>
              <div>
                <Typography className={classes.progressPercentage}>Abstain</Typography>
              </div>
              <div>
                <Typography style={{ fontFamily: 'Poppins', fontSize: '11px', color: 'BC65F2', fontWeight: 400 }}>
                  <b className={classes.boldText}>
                  { props?.proposal?.abstain === null || props?.proposal?.abstain === '0' ?
                    0 : 
                    (new BigNumber(props?.proposal?.abstain).dividedBy('1e18').toFixed(2))
                  }
                  {
                    props?.proposal?.dao?.token?.symbol
                  }
                  </b>{'  '}
                  {
                    props?.proposal?.abstain === null || props?.proposal?.abstain === '0' ?
                      '0%'
                      :
                      (new BigNumber(props?.proposal?.abstain).dividedBy(props?.proposal?.votingPower).multipliedBy(100).toFixed(5)) + '%'
                  }
                </Typography>
              </div>
            </div>
            <Box sx={{ width: '100%', position: 'relative', top: '9px', left: '-9px' }}>
              <LinearProgress
                value={
                  props?.proposal?.abstain === null ?
                    0
                    :
                    (new BigNumber(props?.proposal?.abstain).dividedBy(props?.proposal?.votingPower).multipliedBy(100).toNumber())
                }
                className={classes.progressBarYes}
                variant="determinate" />
            </Box>
          </Grid>
          <Grid item lg={12}>
            <div className={classes.progressText}>
              <div>
                <Typography className={classes.progressPercentage}>Against</Typography>
              </div>
              <div>
                <Typography style={{ fontFamily: 'Poppins', fontSize: '11px', color: 'BC65F2', fontWeight: 400 }}>
                  <b className={classes.boldText}>
                  { props?.proposal?.nay === null || props?.proposal?.nay === '0' ?
                    0 : 
                    (new BigNumber(props?.proposal?.nay).dividedBy('1e18').toFixed(2))
                  }
                  {
                    props?.proposal?.dao?.token?.symbol
                  }
                  </b>{'  '}
                  {
                    props?.proposal?.nay === null || props?.proposal?.nay === '0' ?
                      '0%'
                      :
                      (new BigNumber(props?.proposal?.nay).dividedBy(props?.proposal?.votingPower).multipliedBy(100).toFixed(5)) + '%'
                  }
                </Typography>
              </div>
            </div>
            <Box sx={{ width: '100%', position: 'relative', top: '9px', left: '-9px' }}>
              <LinearProgress
                value={
                  props?.proposal?.nay === null ?
                    0
                    :
                    (new BigNumber(props?.proposal?.nay).dividedBy(props?.proposal?.votingPower).multipliedBy(100).toNumber())
                }
                className={classes.progressBarYes}
                variant="determinate" />
            </Box>
          </Grid>
        </CardContent>
      </Card>
  )
}
