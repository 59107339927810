import { useEffect, useState } from 'react'

// Material-UI imports
import {
  Card,
  CardContent,
  Grid,
  TextField,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { NumericFormat } from 'react-number-format'
import { useWeb3React } from '@web3-react/core'
import { DaoAPI } from '../../../../api/DaoAPI'
import 'react-datepicker/dist/react-datepicker.css'
import DatePicker from 'react-datepicker'
import { useLocation } from 'react-router'


const useStyle = makeStyles((theme) => ({
  divider: {
    borderBottom: '1px solid rgb(224 224 224)',
  },
  cardTitle: {
    fontSize: 13,
    fontWeight: 600,
    fontStyle: 'normal',
    lineHeight: 4,
    margin: 0,
    paddingLeft: '16px',
  },
  cardContent: {
    borderRadius: '18px',
    border: '1px solid rgba(214, 210, 242, 0.5)',
    boxShadow: '0px 51px 89px -49px rgba(196, 193, 232, 0.4)',
    '& .MuiCardContent-root': {
      padding: 0,
    },
  },
  contentHelper: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '-25px',
    padding: '0 14px'
  },
  labelHelper: {
    fontWeight: 500,
    fontSize: '9px',
    color: '#958BAE',
  },
  contentHelper2: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    marginBottom: '-25px',
    padding: '0 14px'
  },
  boldText: {
    letterSpacing: '0.4px',
    fontSize: '12px',
    color: '#4C4C66',
  },
  spanTextColor: {
    color: '#BC65F2',
    fontSize: '12px',
    textAlign: 'right',
  },
  questionMark: {
    width: '20px'
  },
  link: {
    color: '#BC65F2'
  },
  inputContent: {
    "& .MuiInputBase-input": {
      padding: '10.5px 14px',
      background: '#FBFBFF',
    },
    "& .MuiInputBase-root": {
      borderRadius: '9px'
    },
    display: 'grid',
    fontSize: '12px',
    fontWeight: 500,
    padding: '15px 10px',
  },
  input: {
    marginTop: '15px',
  },
  exampleContent: {
    fontSize: '8px',
    height: '100%',
  },
  exampleText: {
    lineHeight: '22px',
    marginTop: '0px',
    color: '#958BAE',
    margin: '8px'
  },
  exampleTitle: {
    color: '#BC65F2',
    margin: '5px 8px'
  },
  alignItems: {
    alignItems: 'flex-end'
  },
  datePicker: {
    borderRadius: '8px',
    padding: '10.5px 14px',
    border: '1px solid #c1c1c4',
    fontSize: '12.5px',
    fontFamily: 'Poppins',
  }
}))

type State = {
  newStartDate: Date | null,
  duration: number | null,
  func: string
}

const InitialState: State = {
  newStartDate: null,
  duration: null,
  func: 'setFundraisingStartTimeAndFinishTime'
}

export const ChangeFundraisingPhase = (props) => {
  const { setIsProposalValid, setProposalError, setProposalMsg } = props;
  const classes = useStyle()
  const location = useLocation();

  const [daoConfig, setDaoConfig] = useState<any>({})
  const [fundraisingConfig, setFundraisingConfig] = useState({ ...InitialState })
  const [startDateErr, setStartDateErr] = useState(false)
  const [durationErr, setDurationErr] = useState(false)
  const { connector } = useWeb3React();

  let paths = location.pathname.split('/')
  let daoId = paths[paths.length - 1]

  useEffect(() => {
    const decode = async () => {
      try {
        setDaoConfig(await DaoAPI.getConfiguration(daoId, connector))
      } catch (e) {
        console.log('Error message:', e)
      }
    }
    decode()
      .catch(console.error)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // CHECK VALIDATION IN CREATION DAO
  const inputValidation =
    fundraisingConfig?.newStartDate &&
    fundraisingConfig?.duration && !isNaN(fundraisingConfig?.duration);

  useEffect(() => {
    setIsProposalValid(inputValidation);
    if (fundraisingConfig?.newStartDate && new Date(parseInt(daoConfig?.finishTime) * 1000) > fundraisingConfig?.newStartDate) {
      setProposalError(true);
      setProposalMsg('New start time must be later than current fundraising finish time')
    } else {
      setProposalError(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValidation, fundraisingConfig])

  const limitedValue = (inputObj) => {
    const { value } = inputObj
    if (value <= 100) return true
    return false
  }

  useEffect(() => {
    props.func(fundraisingConfig)
  })

  return (
    <Card className={classes.cardContent}>
      <div className={classes.divider}>
        <p className={classes.cardTitle}>Start new fundraising phase</p>
      </div>
      <CardContent style={{ padding: '16px' }}>
        <Grid container className={classes.alignItems}>
          <Grid item lg={6} md={6} sm={6} xs={12}>
            <div className={classes.inputContent}>
              <label className={classes.labelHelper}>
                <b className={classes.boldText}>Fundraising start date</b>
              </label>
              <DatePicker
                className={classes.datePicker}
                selected={fundraisingConfig.newStartDate ?? null}
                onChange={(value) => setFundraisingConfig({ ...fundraisingConfig, newStartDate: value})}
                minDate={new Date().setDate(new Date().getDate() + 1)}
                placeholderText="mm/dd/yyy"
                value={fundraisingConfig.newStartDate}
                error={startDateErr && fundraisingConfig.newStartDate ? !startDateErr : startDateErr}
                helperText={
                  startDateErr
                    ? fundraisingConfig.newStartDate
                      ? ''
                      : 'This field is required'
                    : null
                }
                onBlur={() => setStartDateErr(true)}
              />
            </div>
          </Grid>
          <Grid item lg={6} md={6} sm={6} xs={12}>
            <div className={classes.inputContent}>
              <label className={classes.labelHelper}>
                <b className={classes.boldText}>Duration of fundraising phase, </b><span>days</span>
              </label>
              <NumericFormat
                customInput={TextField}
                variant="outlined"
                className={classes.input}
                placeholder={`1`}
                onChange={(e: any) => { setFundraisingConfig({ ...fundraisingConfig, duration: parseInt(e.target.value) }) }}
                onBlur={() => setDurationErr(true)}
                error={durationErr && fundraisingConfig.duration !== null && fundraisingConfig.duration! >= 0 ? !durationErr : durationErr}
                helperText={
                  durationErr
                    ? fundraisingConfig.duration! >= 0 && fundraisingConfig.duration !== null
                      ? ''
                      : 'This field is required'
                    : null
                }
                valueIsNumericString={true}
                allowNegative={false}
                isAllowed={limitedValue}
              />
            </div>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}
