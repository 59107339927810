import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Grid } from '@material-ui/core'
import clsx from 'clsx'

const useStyles = makeStyles({
  root: {
    maxWidth: 1440,
  },
})

export const Content = ({ children, className }) => {
  const classes = useStyles()

  return (
    <Grid container className={clsx([classes.root, className])}>
      {children}
    </Grid>
  )
}
