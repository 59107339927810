import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
  Grid,
  Table as MuiTable,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  makeStyles


} from '@material-ui/core'
import clsx from 'clsx'
import { useClaim, useWindowHeight } from '../hooks'
import { poolsSelector } from '../redux/selectors'
import { routes, SLUG } from '../const/routes'
import { Pool } from '../models/Pool'
import { Button } from './Button'
import { UserPool } from '../models/UserPool'
import { useWeb3React } from '@web3-react/core'
import { InTime } from './InTime'
import { fetchUserPool } from '../helpers/getUserPool'
import { Modal } from './Modal'
import { formatValue } from '../helpers/formatValue'


const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 1200,
    borderRadius: '10px 10px 0 0'
  },
  table: {
    tableLayout: 'fixed'
  },
  headerRow: {
    height: 45,
    '& th': {
      boxSizing: 'border-box',
      paddingLeft: 24
    }
  },
  headerCell: {
    backgroundColor: theme.palette.third.main,
    fontFamily: theme.fontFamilies.secondary,
    fontWeight: 600,
    fontSize: 12,
    lineHeight: '150%',
    textTransform: 'uppercase',
    color: theme.palette.thirteenth.main,
    border: 'none'
  },
  body: {},
  bodyRow: {
    height: 100,
    backgroundColor: theme.palette.sixth.main,
    '&:hover': {
      backgroundColor: theme.palette.third.main,
      cursor: 'pointer'
    },
    '& th, td': {
      fontFamily: theme.fontFamilies.secondary,
      fontSize: 16,
      lineHeight: '150%',
      padding: '0 2px 0 24px',
      borderBottom: `1px solid ${theme.palette.fourteenth.main}`
    },
    '& td': {
      fontWeight: 500
    },
    '&:last-child': {
      '& th, td': {
        border: 0
      }
    }
  },
  poolImage: {
    maxWidth: 36,
    maxHeight: 36,
    margin: '0 15px 0 0'
  },
  swapCurrency: {
    color: theme.palette.primary.main,
    fontWeight: 600,
    display: 'flex',
    width: 68,
    justifyContent: 'space-between',
    alignItems: 'center',
    '& img': {
      transform: 'rotate(90deg)'
    }
  },
  idCell: {
    fontWeight: 'bold',
    fontSize: 12,
    lineHeight: '150%',
    color: theme.palette.twentyFourth.main
  },
  poolNameCell: {
    fontWeight: 600,
    '& > div': {
      display: 'flex',
      alignItems: 'center'
    }
  },
  poolName: {
    display: 'inline-block',
    fontWeight: 600
  },
  claimButton: {
    width: 93,
    fontSize: 16
  },
  unActiveClaimButton: {
    color: 'rgba(0, 0, 0, 0.26)',
    boxShadow: 'none',
    backgroundColor: 'rgba(0, 0, 0, 0.12)',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.12)'
    }
  },
  showMoreRow: {
    height: 48,
    '& > *': {
      width: '100%',
      textAlign: 'center'
    },
    '&:hover': {
      backgroundColor: theme.palette.sixth.main,
      cursor: 'inherit'
    }
  }
}))

const SHOWING_ROWS_COUNT = 10

const COLUMNS = [
  {
    label: 'Pool name',
    style: {/*width: 220*/ }
  },
  {
    label: 'Invested',
    style: {/*width: 180*/ }
  },
  {
    label: 'Allocation',
    style: {/*width: 180*/ }
  },
  {
    label: 'Claimed',
    style: {/*width: 180*/ }
  },
  {
    label: 'To claim',
    style: {/*width: 180*/ }
  },
  {
    label: 'Next unlock in',
    style: {/*width: 180*/ }
  },
  {
    label: '',
    style: {/*width: 144*/ }
  }
]


export const AllocationsTable = () => {
  const classes = useStyles()
  const navigate = useNavigate()
  const { connector, account } = useWeb3React()

  const pools: Pool[] = useSelector(poolsSelector)
  const [userPools, setUserPools] = useState<UserPool[]>()
  const [currentShowedRowCount, setCurrentListShowing] = useState(0)


  const filteredPools = useMemo(() => {
    return pools.filter((row: any) => row.totalStaked !== 0)
  }, [pools])


  useEffect(() => {
    if (!filteredPools || !filteredPools.length || !account || !connector) {
      return
    }
    fetchUserPools(filteredPools, account, connector)
  }, [filteredPools, account, connector])


  async function fetchUserPools(pools: Pool[], userAccount: string, userConnector: any) {
    const userPoolsFromPromises = await Promise.all(
      pools.map(async (pool) => fetchUserPool(pool, userAccount, userConnector))
    )
    const nextUserPools = userPoolsFromPromises.filter(item => item !== undefined) as UserPool[]
    setUserPools(nextUserPools)
  }


  const handleTimeout = useCallback(async (pool: UserPool) => {
    if (!filteredPools || !filteredPools.length || !account || !connector) {
      return
    }
    await new Promise((resolve) => setTimeout(() => resolve(true), 20000))
    await fetchUserPools(filteredPools, account, connector)
  }, [account, filteredPools, connector])

  const { claim } = useClaim()

  const handleClaim = useCallback(async (poolAddress: UserPool['address']) => {
    claim(poolAddress)
  }, [claim])

  const [isClaimDisabledOpen, setIsClaimDisabledOpen] = useState(false)
  const [claimDisabledPool, setClaimDisabledPool] = useState<UserPool>()
  const handleDisabledClaim = useCallback((row: UserPool) => {
    setIsClaimDisabledOpen(true)
    setClaimDisabledPool(row)
  }, [])

  const windowHeight = useWindowHeight()

  const modalTop = useMemo(() => {
    return (windowHeight - 100) / 2
  }, [windowHeight])

  const walletStatus = useSelector((state: any) => state.wallet.status)

  const handleWalletStatusChange = useCallback(async () => {
    if (!account || !connector) {
      return
    }
    // console.log('handleWalletStatusChange')
    await new Promise((resolve) => setTimeout(() => resolve(true), 20000))
    await fetchUserPools(filteredPools, account, connector)
  }, [account, filteredPools, connector])

  useEffect(() => {
    handleWalletStatusChange()
  }, [handleWalletStatusChange, walletStatus])


  useEffect(() => {
    setCurrentListShowing(
      filteredPools.length > SHOWING_ROWS_COUNT
        ? SHOWING_ROWS_COUNT
        : filteredPools.length
    )
  }, [filteredPools])

  const onRowClick = useCallback(
    (slug: any) => {
      navigate(routes.POOL.replace(SLUG, slug))
    },
    [navigate]
  )

  const onShowMoreClick = useCallback(() => {
    setCurrentListShowing(currentShowedRowCount + SHOWING_ROWS_COUNT)
  }, [currentShowedRowCount])

  return (
    <>
      <TableContainer className={classes.root}>
        <MuiTable className={classes.table} size='small'>
          <TableHead>
            <TableRow className={classes.headerRow}>
              {COLUMNS.map((column, i) => (
                <TableCell
                  className={classes.headerCell}
                  key={i}
                  style={column.style}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody className={classes.body}>
            {
              userPools && userPools
                .slice(0, currentShowedRowCount)
                .map((row, index) => (
                  <RowAllocation
                    key={row.address}
                    index={index + 1}
                    row={row}
                    onRowClick={onRowClick}
                    onTimeout={handleTimeout}
                    onClaim={handleClaim}
                    onDisabledClaim={() => handleDisabledClaim(row)}
                  />
                ))
            }
            {filteredPools.length > 10 &&
            filteredPools.length >= currentShowedRowCount && (
              <RowShowMore onClick={onShowMoreClick} colSpan={COLUMNS.length} />
            )}
          </TableBody>
        </MuiTable>
      </TableContainer>


      <Modal
        open={isClaimDisabledOpen}
        className={undefined}
        marginTop={modalTop}
        modalBodyClass={undefined}
        onClose={() => setIsClaimDisabledOpen(false)}
      >
        {
          claimDisabledPool && claimDisabledPool.totalAllocation === claimDisabledPool.claimed && claimDisabledPool.claimed !== '0'
            ? 'All tokens were claimed'
            : 'Sorry, the claiming is not ready yet.'
        }

      </Modal>
    </>
  )
}

type RowAllocationProps = {
  index: number,
  onRowClick: (e: string) => void,
  onTimeout: (pool: UserPool) => void
  onClaim: (poolAddress: UserPool['address']) => void
  onDisabledClaim: () => void
  row: UserPool,
}

export const RowAllocation = ({ index, row, onRowClick, onTimeout, onClaim, onDisabledClaim }: RowAllocationProps) => {
  const classes = useStyles()

  const [claimDisabled, setClaimDisabled] = useState(false)

  const onInnerClaimClick = useCallback((poolAddress: any, e: any) => {
    e.stopPropagation()
    onClaim(poolAddress)
    setClaimDisabled(true)
  }, [onClaim])
  const onDisabledClaimClick = useCallback((e: any) => {
    e.stopPropagation()
    onDisabledClaim()
  }, [onDisabledClaim])

  useEffect(() => {
    setClaimDisabled(row.allowedAllocation === '0')
  }, [row.allowedAllocation])

  const handleTimeout = useCallback(() => {
    // if (row.allowedAllocation === '0') {
    onTimeout(row)
    // }
  }, [onTimeout, row])

  const getNextAllocationTime = () => {
    if (row.totalAllocation === '0') {
      return 'is not set'
    }
    if (row.nextAllocationDate < new Date()) {
      return 'allocation done'
    }
    return <InTime
      date={row.nextAllocationDate}
      onTimeout={handleTimeout}
      timeOffset={20000}
      key={row.nextAllocationDate.getTime()}
    />
  }

  return (
    <TableRow
      className={classes.bodyRow}
      onClick={onRowClick.bind(null, row.slug)}
    >
      <TableCell style={COLUMNS[0].style} className={classes.poolNameCell}>
        <Grid>
          <img className={classes.poolImage} src={row.iconSrc} alt={row.name} />
          <span className={classes.poolName}>{row.name}</span>
        </Grid>
      </TableCell>
      <TableCell style={COLUMNS[1].style}>
        {formatValue(row.balance)} {row.swapCurrency}
      </TableCell>
      <TableCell style={COLUMNS[2].style}>
        {row.totalAllocation === '0' ? 'is not set' : `${formatValue(row.totalAllocation)} ${row.tokenSymbol}`}
      </TableCell>
      <TableCell style={COLUMNS[3].style}>
        {row.totalAllocation === '0' ? 'is not set' : `${formatValue(row.claimed)} ${row.tokenSymbol}`}
      </TableCell>
      <TableCell style={COLUMNS[4].style}>
        {
          row.totalAllocation === row.claimed && row.claimed !== '0'
            ? `${formatValue(row.allowedAllocation)} ${row.tokenSymbol}`
            :
            row.totalAllocation === '0'
              ? 'is not set'
              : `${formatValue(row.allowedAllocation)} ${row.tokenSymbol}`
        }
      </TableCell>
      <TableCell style={COLUMNS[5].style}>
        {
          getNextAllocationTime()
        }
      </TableCell>
      <TableCell style={COLUMNS[6].style}>
        <Button
          type={claimDisabled ? 'secondary' : 'primary'}
          className={classes.claimButton}
          disabledColor={claimDisabled}
          onClick={(e: any) => claimDisabled ? onDisabledClaimClick(e) : onInnerClaimClick(row.address, e)}
        >
          Claim
        </Button>
      </TableCell>

    </TableRow>
  )
}

export const RowShowMore = ({ colSpan, onClick }: any) => {
  const classes = useStyles()

  return (
    <TableRow className={clsx([classes.bodyRow, classes.showMoreRow])}>
      <TableCell colSpan={colSpan}>
        <Button type='sixth' onClick={onClick} className={null}>
          Show more
        </Button>
      </TableCell>
    </TableRow>
  )
}
