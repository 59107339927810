import { useMemo, useState, useEffect } from 'react'

import { useWeb3React } from '@web3-react/core'

// Material Imports
import Card from '@material-ui/core/Card'
import Grid from '@material-ui/core/Grid'
import {
  Box,
  CardContent,
  TextField,
  Radio,
  FormControlLabel,
  RadioGroup
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

// Components
import { Button } from '../../Button'
import { ErrorMessage } from '../../ErrorMessage/ErrorMessage'
import { NumericFormat } from 'react-number-format';
import { DataGrid, GridColDef } from '@material-ui/data-grid';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { useAspisSupportedTokens, useDefiProtocols } from '../../../hooks/useChain';

const useStyles = makeStyles((theme) => ({
  content: {
    border: '1px solid rgba(214, 210, 242, 0.5)',
    boxShadow: '0px 51px 89px -49px rgba(196, 193, 232, 0.4)',
    borderRadius: '18px',
    marginBottom: '50px'
  },
  inputContent: {
    display: 'grid',
    fontSize: '12px',
    fontWeight: 500,
    padding: '15px 10px',
    "& .MuiOutlinedInput-input": {
      padding: '10.5px 14px'
    },
    "& .MuiInputBase-input": {
      background: '#FBFBFF',
      fontFamily: 'Poppins',
      fontSize: '14px',
      fontWeight: 500,
      display: 'flex'
    },
    "& .MuiInputBase-root": {
      borderRadius: '9px'
    },
    '& .MuiFormHelperText-root.Mui-error': {
      fontSize: '10px',
      fontFamily: 'Poppins',
      margin: '0 3px',
      position: 'absolute',
      bottom: '-18px'
    },
    "& .Mui-disabled": {
      borderRadius: '10px',
      color: '#6F5E9A',
      background: 'rgba(226, 221, 237, 0.7)',
      borderColor: 'transparent'
    },
  },
  input: {
    marginTop: '15px'
  },
  boldText: {
    letterSpacing: '0.4px',
    fontSize: '12px',
    color: '#4C4C66',
  },
  labelHelper: {
    fontWeight: 500,
    fontSize: '9px',
    color: '#958BAE',
  },
  alignItems: {
    alignItems: 'flex-end',
  },
  CsvButton: {
    background: 'rgba(232, 99, 244, 0.07)',
    border: '1px solid #BC65F2',
    borderRadius: '9px',
    fontFamily: 'Poppins',
    fontSize: '12px',
    color: '#BC65F2',
    fontWeight: 700,
  },
  contentHelper: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '-25px',
    padding: '0 14px',
    [theme.breakpoints.down('xs')]: {
      display: 'block'
    }
  },
  spanText: {
    fontSize: '12px',
    textAlign: 'right',
    color: '#4C4C66',
    opacity: '0.31',
  },
  spanTextColor: {
    color: '#BC65F2',
    fontSize: '12px',
    textAlign: 'right',
  },
  link: {
    color: '#BC65F2',
  },
  questionMark: {
    width: '20px',
  },
  radioContent: {
    '& .MuiFormControlLabel-label': {
      background: '#FBFBFF;',
      borderRadius: '9px',
      border: '1px solid #FBFBFB',
      width: '90%',
      '& .MuiTypography-body1': {
        fontWeight: 500,
        fontSize: '13px',
        paddingBottom: '3px'
      }
    },
    width: '90%',
    padding: '0 15px',
    display: 'flex',
    margin: '10px 0'
  },
  radioButton: {
    '& .MuiFormControlLabel-root':{
      background: '#FBFBFF;',
      borderRadius: '9px',
      border: '1px solid #FBFBFB',
      width: '90%',
      '& .MuiTypography-body1':{
        fontWeight: 500,
        fontSize: '13px',
        paddingBottom: '3px'
      }
    },
    width: '50%',
    padding: '0 15px',
    display: 'flex',
    margin: '10px 0',
    justifyContent: 'space-between'
  },
  formControlLabel: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '13px',
    lineHeight: '21px',
    color: '#44435E',
    position: 'relative',
    left: '-15px',
  },
  managerWallet: {
    background: '#FBFBFF',
    border: '1px solid #F7F1FF',
    borderRadius: '9px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '40px',
    paddingLeft: '10px',
  },
  managerWalletChip: {
    color: '#8765F2',
    background: 'rgba(137, 99, 244, 0.07)',
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: 12,
    padding: 15,
  },
  alignItemsEnd: {
    alignItems: 'end'
  },
  alignItemsCenter: {
    alignItems: 'center'
  },
  divider: {
    border: '1px solid #EDEEFF',
    margin: '12px -16px'
  },
  errorBox: {
    textAlign: 'right'
  },
  csvContent: {
    display: 'flex',
    marginTop: '35px',
    marginBottom: '15px'
  },
  inputCSV: {
    width: '100%',
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: 'transparent',
      background: 'rgb(236 157 243 / 7%)',
      borderRadius: '10px',
    },
    '& .MuiInputBase-root.Mui-disabled': {
      borderRadius: '10px',
      color: '#6F5E9A',
      background: 'rgba(226, 221, 237, 0.7)',
      borderColor: 'transparent',
    },
    '& .MuiOutlinedInput-root': {
      fontSize: '14px',
      lineHeight: '20px'
    }
  },
  walletAddressesDisplay: {
    background: '#fcf7fe',
    paddingTop: '10px',
    borderRadius: '18px',
    paddingBottom: '10px',
    fontSize: '14px',
    '&limaker': {
      background: 'red',
      color: 'yellow'
    }
  },
  errorMessage: {
    fontSize: '14px',
    color: '#da3e63',
    margin: '0'
  },
  protocolsTable: {
    height: '327px',
    width: '100%',
    marginBottom: '30px',
    marginTop: '20px',
    borderRadius: '18px',
    '& .MuiDataGrid-cell:focus': {
      outline: 'none'
    },
    '& .MuiDataGrid-columnHeader:focus': {
      outline: 'none'
    },
    '& .MuiDataGrid-footerContainer': {
      display: 'none'
    }
  },
  defiIcon: {
    width: '40%'
  },
  buttonMargin: {
    marginTop: '0px',
    [theme.breakpoints.down('xs')]: {
      marginTop: '16px'
    },
  },
  autocomplete: {
    marginBottom: '10px'
  }
}))

export const Controlling = (props) => {
  const { handleBack, createDao, stepperValues, setStepperValues } = props

  const classes = useStyles()
  const { account } = useWeb3React()

  const [initialLPTokenPriceErr, setInitialLPTokenPriceErr] = useState(false)
  const [supportedDefiProtocols, setSupportedDefiProtocols] = useState<any>([])
  const [supportedTokens, setSupportedTokens] = useState<any>([])
  const [selectedTokens, setSelectedTokens] = useState<any>([])

  const aspisSupportedDefiProtocols: any = useDefiProtocols()
  const aspisSupportedTokens: any = useAspisSupportedTokens([])

  useEffect(() => {
    setSupportedDefiProtocols(aspisSupportedDefiProtocols)
  }, [aspisSupportedDefiProtocols])

  useEffect(() => {
    setSupportedTokens(aspisSupportedTokens)

    if (aspisSupportedTokens.length) {
      const defaultTokens = stepperValues.supportedDepositTokens.map((supportedAddress) => {
        return aspisSupportedTokens.find(t => t.address === supportedAddress);
      });

      onTokensChange(defaultTokens)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [aspisSupportedTokens, stepperValues.supportedDepositTokens])

  const accountErrorMessage = useMemo(() => {
    if (!account) return 'Wallet is locked, please unlock first'
  }, [account])

  const enableNextButton =
    stepperValues.defiProtocols.length !== 0 && stepperValues.isDirectTransferAllowed !== undefined &&
    stepperValues.initialPrice !== undefined && !isNaN(stepperValues.initialPrice) && stepperValues.initialPrice <= 1000000 && stepperValues.initialPrice > 0

  const isFormValid = () => {
    if (enableNextButton) {
      return true
    } else {
      return false
    }
  }

  //Defi protocols table
  const columns: GridColDef[] = [
    {
      field: "protocolIcon",
      headerName: "Icon",
      width: 105,
      renderCell: (params) => <img className={classes.defiIcon} src={params?.row?.icon} alt="protocolName" />
    },
    {
      field: 'protocolName',
      headerName: 'Protocol name',
      width: 180,
      editable: false,
    },
    {
      field: 'protocolAddress',
      headerName: 'Protocol Address',
      width: 380,
      editable: false,
    },
  ];

  const onProtocolSelectionHandler = (protocols) => {
    const selectedDefiProtocols = protocols.reduce((result, id) => {
      const protocol = supportedDefiProtocols.find((row) => row.id === id);
      if (protocol) {
        result.push(protocol.protocolAddress);

        if (protocol.dependencies) {
          protocol.dependencies.forEach(dep => result.push(dep));
        }
      }
      return result;
    }, []);
    setStepperValues({ ...stepperValues, defiProtocols: selectedDefiProtocols })
  };

  const onTokensChange = (tokens) => {
    setSelectedTokens(tokens)

    const selectedAddresses = tokens.map(t => t.address)
    setStepperValues({ ...stepperValues, supportedTradingTokens: selectedAddresses })
  }

  return (
    <>
      <Card className={classes.content}>
        <CardContent>
          <Grid>
            <Grid container className={classes.alignItemsEnd}>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <div >
                <label className={classes.labelHelper}>
                  <b className={classes.boldText}>
                    Allowed protocols to trade at during fundraising phase
                  </b>
                </label>
                <DataGrid
                  className={classes.protocolsTable}
                  rows={supportedDefiProtocols}
                  columns={columns}
                  checkboxSelection
                  disableSelectionOnClick
                  onSelectionModelChange={(protocols) => onProtocolSelectionHandler(protocols)}
                />
              </div>
              <div>
              </div>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <div>
                <label className={classes.labelHelper}>
                  <b className={classes.boldText}>
                    Allowed assets to trade by the manager
                  </b>
                  <Autocomplete multiple
                    className={classes.autocomplete}
                    options={supportedTokens}
                    getOptionLabel={(option: any) => option.symbol}
                    defaultValue={[]}
                    value={selectedTokens}
                    onChange={(_, values) => onTokensChange(values)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        label="Multiple values"
                        placeholder="Favorites"
                      />
                    )}
                  />
                </label>
              </div>
            </Grid>
            <Grid container className={classes.alignItems}>
            <Grid item lg={12} md={8} sm={8} xs={12}>
                <label className={classes.labelHelper}>
                  <b className={classes.boldText}>
                  Can withdraw funds from the vault with direct transfer,&nbsp;
                  </b>
                  based on voting results
                </label>
              </Grid>
              <Grid item lg={12} md={8} sm={8} xs={12}>
                <RadioGroup row className={classes.radioButton}>
                <Grid item lg={6} md={8} sm={8} xs={12}>
                  <FormControlLabel
                    control={
                      <Radio 
                        value="Yes"
                        checked={stepperValues.isDirectTransferAllowed === 1}
                        onChange={(e) => setStepperValues({ ...stepperValues, isDirectTransferAllowed: 1 })}/>
                      }
                    label="Yes"
                  /></Grid>
                  <Grid item lg={6} md={8} sm={8} xs={12}>
                   <FormControlLabel
                    control={
                     <Radio 
                      value="No"
                      checked={stepperValues.isDirectTransferAllowed === 0}
                      onChange={(e) => setStepperValues({ ...stepperValues, isDirectTransferAllowed: 0 })}/>
                    }
                    label="No"
                  /></Grid>
                </RadioGroup>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item lg={12} md={8} sm={8} xs={12}>
                <label className={classes.labelHelper}>
                  <b className={classes.boldText}>Initial deposit</b>
                </label>
                <hr className={classes.divider} />
              </Grid>
            </Grid>

            <Grid container>
              <Grid item lg={6} md={8} sm={8} xs={12}>
                <label className={classes.labelHelper}>
                  <b className={classes.boldText}>
                    Initial price per LP token in $USD
                  </b>
                </label>
                <div className={classes.inputContent}>
                  <NumericFormat
                    customInput={TextField}
                    variant="outlined"
                    required
                    thousandSeparator=','
                    valueIsNumericString={true}
                    allowNegative={false}
                    value={stepperValues.initialPrice}
                    onChange={(e) => setStepperValues({ ...stepperValues, initialPrice: parseFloat(e.target.value.replace(/,/g, "")) })}
                    onBlur={() => setInitialLPTokenPriceErr(true)}
                    error={initialLPTokenPriceErr && stepperValues.initialPrice > 0 && stepperValues.initialPrice <= 1000000 ? !initialLPTokenPriceErr : initialLPTokenPriceErr}
                    helperText={
                      initialLPTokenPriceErr ?
                        (stepperValues.initialPrice === undefined ? 'This field is required' : '') ||
                        (stepperValues.initialPrice <= 1000000 ? "" : "Please add value between 1 and 1000000") ||
                        (stepperValues.initialPrice > 0 ? "" : "Please add value between 1 and 1000000") : null
                    }
                    className={classes.input}
                    placeholder="1000"
                  />
                </div>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Grid container justifyContent='space-between'>
        <Grid item lg={2} md={3} sm={3} xs={12}>
          <Button type="third" onClick={() => handleBack()}>Return</Button>
        </Grid>
        <Grid item container lg={2} md={3} sm={3} xs={12} justifyContent="flex-end">
          <Button
            type="primary"
            onClick={() => createDao()}
            disabled={!isFormValid() || !account}
            className={classes.buttonMargin}>Finish</Button>
        </Grid>
      </Grid>
      {
        accountErrorMessage && (
          <Box my={1} className={classes.errorBox}>
            <ErrorMessage message={accountErrorMessage} />
          </Box>
        )
      }
    </>
  )
}
