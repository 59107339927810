import BigNumber from "bignumber.js";
import { BigNumber as EthersBigNumber } from "ethers";

export const _10e18: BigNumber = new BigNumber(10).pow(18);
export const MAX_BIGNUMBER: BigNumber = new BigNumber("0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff", 16);

export function toBN(bn: EthersBigNumber): BigNumber {
    return new BigNumber(bn.toHexString(), 16);
}

export function toLowBN(bn: EthersBigNumber, decimals = 18): BigNumber {
    return toBN(bn).dividedBy(new BigNumber(10).pow(decimals));
}

export function numberToTransactionalNumber(amount: number, decimals: number = 18): string {
    return new BigNumber(amount, 10).multipliedBy(new BigNumber(10).pow(decimals)).toFixed(0);
}

