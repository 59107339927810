import {
  FETCH_USER_INFO_REQUEST,
  FETCH_USER_INFO_SUCCESS,
  FETCH_USER_INFO_ERROR,
  CLEAR_USER_INFO,
} from '../types/user.types'

export const fetchUserInfoRequest = () => {
  return {
    type: FETCH_USER_INFO_REQUEST,
  }
}

export const fetchUserInfoSuccess = ({ userInfo }) => {
  return {
    type: FETCH_USER_INFO_SUCCESS,
    payload: {
      userInfo,
    },
  }
}

export const fetchUserInfoError = () => {
  return {
    type: FETCH_USER_INFO_ERROR,
  }
}

export const clearUserInfo = () => {
  return {
    type: CLEAR_USER_INFO,
  }
}
