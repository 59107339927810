import { useEffect, useState } from 'react'

// Material-UI imports
import {
  Card,
  CardContent,
  Grid,
  TextField
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { NumericFormat } from 'react-number-format'
import { useLocation } from 'react-router'
import { DaoAPI } from '../../../../api/DaoAPI'
import { useWeb3React } from '@web3-react/core'

const useStyle = makeStyles((theme) => ({
  divider: {
    borderBottom: '1px solid rgb(224 224 224)',
  },
  cardTitle: {
    fontSize: 13,
    fontWeight: 600,
    fontStyle: 'normal',
    lineHeight: 4,
    margin: 0,
    paddingLeft: '16px',
  },
  cardContent: {
    borderRadius: '18px',
    border: '1px solid rgba(214, 210, 242, 0.5)',
    boxShadow: '0px 51px 89px -49px rgba(196, 193, 232, 0.4)',
    '& .MuiCardContent-root': {
      padding: 0,
    },
  },
  alignItems: {
    alignItems: 'flex-end'
  },
  contentHelper: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 14px',
    [theme.breakpoints.down('xs')]:{
      display:'block'
     }
  },
  labelHelper: {
    fontWeight: 500,
    fontSize: '9px',
    color: '#958BAE',
  },
  boldText: {
    letterSpacing: '0.4px',
    fontSize: '12px',
    color: '#4C4C66',
  },
  spanTextColor: {
    color: '#BC65F2',
    fontSize: '8px',
    textAlign: 'right',
  },
  inputContent: {
    "& .MuiInputBase-input": {
      padding: '10.5px 14px',
      background: '#FBFBFF',
    },
    "& .MuiInputBase-root": {
      borderRadius: '9px'
    },
    display: 'grid',
    fontSize: '12px',
    fontWeight: 500,
    padding: '15px 10px',
  },
  input: {
    marginTop: '30px',
  },
}))


export const ChangeTokenLockUpPeriod = (props: any) => {
  const {setIsProposalValid, setProposalError, setProposalMsg} = props;

  const classes = useStyle()
  const location = useLocation();
  const { connector } = useWeb3React();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [daoConfig, setDaoConfig] = useState<any>({})
  const [lockup, setLockUp] = useState<any>({
    hours: undefined,
    func: 'changeLockupPeriod'
  })

  let paths = location.pathname.split('/');
  let daoId = paths[paths.length - 1];

  const inputValidation = lockup?.hours !== undefined && !isNaN(lockup?.hours);

  useEffect(() => {
    const decodeLockUp = async () => {
      try {
        setDaoConfig(await DaoAPI.getConfiguration(daoId, connector))
      } catch (e) {
        console.log('Error message:', e)
      }
    }
    decodeLockUp()
      .catch(console.error)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(()=>{
    setIsProposalValid(inputValidation)
    if(parseInt(daoConfig?.lockLimit) === lockup?.hours){
      setProposalError(true);
      setProposalMsg('You cannot create a proposal with the same token lock-up period that is already present.')
    }else{
      setProposalError(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[inputValidation, lockup])

  useEffect(() => {
    props.func(lockup)
   })

  return (
      <Card className={classes.cardContent}>
        <div className={classes.divider}>
          <p className={classes.cardTitle}>Change token lock-up period</p>
        </div>
        <CardContent style={{ padding: '16px' }}>
          <Grid container className={classes.alignItems}>
            <div className={classes.contentHelper}>
              <div className={classes.labelHelper}>
                <b className={classes.boldText}>Token lock-up period</b>
              </div>
              <span className={classes.spanTextColor}>Period after deposit, during which it is impossible to withdraw funds</span>
            </div>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <div className={classes.inputContent}>
                <NumericFormat
                  customInput={TextField}
                  variant="outlined"
                  className={classes.input}
                  onChange={(e) => setLockUp({ ...lockup, hours: parseInt(e.target.value.replace(/[%\s]/g, '')) })}
                  placeholder={`${isNaN(daoConfig?.lockLimit) ? 0 : daoConfig?.lockLimit} ${daoConfig?.lockLimit < 2 ? 'Hour' : 'Hours'}`}
                  thousandSeparator=","
                  valueIsNumericString={true}
                  allowNegative = {false}
                />
              </div>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
  )
}
