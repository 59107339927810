/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-throw-literal */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router'
import { useWeb3React } from '@web3-react/core'
import moment from 'moment'
import { formatJsonRpcError, formatJsonRpcResult } from '@json-rpc-tools/utils'

import { SignClientTypes } from '@walletconnect/types'
import { getSdkError } from '@walletconnect/utils'
import { createWeb3Wallet, IWeb3Wallet } from '../../helpers/walletConnect';

// Material UI Imports
import { Button } from '../Button'
import { makeStyles } from '@material-ui/core/styles'
import { Grid, TextField } from '@material-ui/core'

// Assets
import successIcon from '../../assets/icons/success.svg'
import noSucces from '../../assets/icons/notSuccess.svg'
import walletConnectImg from '../../assets/icons/walletConnectTransparent.svg'
import successAlertImg from '../../assets/images/depositModal.svg'
import depositModalImg from '../../assets/images/depositModal.svg'

import { DaoAPI, ReturnCode } from '../../api/DaoAPI'
import { VotingAPI } from '../../api/VotingAPI'
import { Signer } from '../../api/Signer'

// Components
import Swal from 'sweetalert2'
import { ModalLoading } from '../../components/ModalLoading'
import { ModalSuccess } from '../../components/ModalSuccess'
import { ModalError } from '../../components/ModalError'
import { TransactionHash } from '../ExplorerLinks/ExplorerLinks'
import { decodeApprove } from '../../helpers/abiDecoder'
import { MAX_UINT } from '../../const/ethConst'

const useStyle = makeStyles((theme) => ({
  root: {
    maxWidth: '65%',
    margin: 'auto',
    [theme.breakpoints.down('xs')]: {
      maxWidth: '100%'
    }
  },
  walletImg: {
    display: 'flex',
    justifyContent: 'center',
    margin: '45px 0px'
  },
  text: {
    textAlign: 'center',
    width: '30%',
    margin: 'auto',
    fontSize: '13px',
    [theme.breakpoints.down('sm')]: {
      width: '75%'
    }
  },
  inputContent: {
    display: 'flex',
    marginTop: '30px',
    '& .MuiOutlinedInput-input': {
      padding: '13.5px 14px'
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderRadius: '9px'
    },
    '& .MuiOutlinedInput-multiline': {
      padding: 0
    }
  },
  messageContent: {
    textAlign: 'center',
    margin: '40px 0px',
    width: '100%',
    "& span": {
      fontSize: '13px'
    },
    "& p": {
      fontSize: '11px',
      color: '#979797'
    }
  },
  networkContent: {
    display: 'flex',
    alignItems: 'center',
    color: '#2F2F4D',
    fontSize: '13px',
    border: '1px solid rgba(214, 210, 242, 0.5)',
    boxShadow: '0px 51px 89px rgb(196 193 232 / 40%)',
    borderRadius: '18px',
    padding: '10px'
  },
  label: {
    position: 'relative',
    top: '27px',
    fontSize: '13px',
    color: '#2F2F4D'
  },
  buttonContent: {
    width: '35%',
    margin: 'auto',
    borderRadius: '7px',
    marginBottom: '10px',
    marginTop: '10px',
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  transactionButton: {
    width: '20%',
    borderRadius: '7px',
    marginBottom: '10px',
    marginTop: '10px',
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  }
}))

export const WalletConnectComponent = (props: any) => {
  const classes = useStyle()
  const location = useLocation()
  const navigate = useNavigate()
  const votingAddress = props?.dao?.data?.subgraph?.daoCreationLog?.voting

  let paths = location.pathname.split('/')
  let daoId = paths[paths.length - 1]

  const { account, chainId, connector: library } = useWeb3React()
  const accountAddress: string = account!;

  const [connector, setConnector] = useState<IWeb3Wallet | undefined>(undefined)
  const [peerData, setPeerData] = useState<SignClientTypes.Metadata | null>(null)

  const [successTxnDetails, setSuccessTxnDetails] = useState<any>({})
  const [decodedApproveData, setDecodeApproveData] = useState<any>([])

  const [approveTxn, setApproveTxn] = useState<any>()
  const [txnPayload, setTxnPayload] = useState<any>()

  const [proposalTitle, setProposalTitle] = useState<string>('')
  const [proposalDescription, setProposalDescription] = useState<string>('')
  const [wcUri, setWcUri] = useState('')

  const [approveLoader, setApproveLoader] = useState(false)
  const [isDepositing, setDepositing] = useState(false)
  const [successAlert, setSuccessAlert] = useState(false)
  const [successApprove, setSuccessAprove] = useState(false)
  
  const [errorAlert, setErrorAlert] = useState(false)
  const [errorText, setErrorText] = useState('')

  const [creatingProposal, setCreatingProposal] = useState(false)
  const [defiAlert, setDefiAlert] = useState(false)
  const [nonDefiAlert, setNonDefiAlert] = useState(false)
  const [proposalCreated, setProposalCreated] = useState(false)

  // Error validations
  const [proposalTitleErr, setProposalTitleErr] = useState(false)
  const [proposalDescriptionErr, setProposalDescriptionErr] = useState(false)

  useEffect(() => {
    createWeb3Wallet().then(wallet => {
      const sessions = Object.values(wallet.getActiveSessions());
      if (sessions.length > 0) {
        setWeb3Wallet(wallet)
      }
    })
  }, [])

  const wcConnectV2 = React.useCallback(async (uri) => {
    const web3wallet = await createWeb3Wallet()
    await web3wallet.pair({ uri })

    await setWeb3Wallet(web3wallet)

    setWcUri('');
  }, [chainId, location?.state?.dao?.data?.subgraph?.id]);

  async function setWeb3Wallet(web3wallet: IWeb3Wallet) {
    web3wallet.on('session_proposal', async (proposal: SignClientTypes.EventArguments['session_proposal']) => {
      await web3wallet.approveSession({
        id: proposal.id,
        namespaces: {
          eip155: {
            accounts: [`eip155:${chainId}:${location?.state?.dao?.data?.subgraph?.id}`, `eip155:1:${location?.state?.dao?.data?.subgraph?.id}`],
            chains: [`eip155:${chainId}`, `eip155:1`],
            events: ['accountsChanged', 'chainChanged'],
            methods: ['personal_sign', 'eth_signTransaction', 'eth_signTypedData', 'eth_sendTransaction', 'eth_signTypedData_v4']
          }
        }
      })

      setPeerData(proposal.params.proposer.metadata)
    })

    web3wallet.on('session_request', async (event: SignClientTypes.EventArguments['session_request']) => {
      const request = event.params.request

      console.log('WalletConnect request received', request);

      // Suggest creating proposal in certain cases
      if (moment().unix() <= props?.daoConfig?.finishTime) {
        const [protocol] = request.params
        const selectedProtocols = await DaoAPI.getDefiProtocols(props?.supportedDefiProtocols, props?.daoConfig?.configAddress, library)

        if (request.method !== 'eth_signTypedData_v4' && request.method !== 'personal_sign' && !request?.params[0]?.data?.includes('0x095ea7b3')) {
          if (!(selectedProtocols.includes(protocol.to.toLowerCase()))) {
            setDefiAlert(true)
          }
        }
      } else {
        if (props?.trustedTransfers?.length >= 0 &&
            props?.txnPayload?.params[0]?.to &&
            !props?.trustedTransfers?.includes(props?.txnPayload?.params[0]?.to)) {
          setNonDefiAlert(true)
        }
      }

      if (request.method === 'eth_sendTransaction' && !request.params[0].data.includes('0x095ea7b3')) {
        setTxnPayload({ id: event.id, topic: event.topic, ...request });
      } else if (request.method === 'personal_sign') {
        setApproveTxn({ id: event.id, topic: event.topic, ...request });
      } else if (request.method === 'eth_signTypedData_v4' || request.params[0].data.includes('0x095ea7b3')) {
        setTxnPayload(undefined)
        setApproveTxn({ id: event.id, topic: event.topic, ...request })

        if (request.params[0].data === undefined) {
          setDecodeApproveData(JSON.parse(request.params[1]))
        } else {
          setDecodeApproveData(decodeApprove(request.params[0].data))
        }
      }
    })

    web3wallet.on('auth_request', () => {
      console.log("auth_request")
    })

    web3wallet.on('session_delete', () => {
      wcDisconnect();
    })

    setConnector(web3wallet);
  }

  const wcDisconnect = React.useCallback(async () => {
    const sessions = await connector?.getActiveSessions()
    if (sessions && Object.values(sessions).length) {
      await connector?.disconnectSession({ topic: Object.values(sessions)[0].topic, reason: getSdkError('USER_DISCONNECTED') })
    }
    setConnector(undefined)
  }, [connector])

  const confirmTransaction = async () => {
    try {
      if (txnPayload?.method === 'eth_sendTransaction') {
        let result, returnCode
        const { from, to, value, data } = txnPayload.params[0]

        setDepositing(true)

        if (!value) {
          [returnCode, result] = await DaoAPI.encodeDAOCall(accountAddress, from, [to, `0`, data], library)
        } else {
          [returnCode, result] = await DaoAPI.encodeDAOCall(accountAddress, from, [to, BigInt(value).toString() , data], library)
        }

        if (returnCode === ReturnCode.EstimateError) {
          let errorText: string
          if (result.message.includes('0x743f26e1')) {
            errorText = 'Protocol not supported'
          } else if (result.message.includes('0x6a172882')) {
            errorText = 'Destination token not supported'
          } else if (result.message.includes('0x6ab850c4')) {
            errorText = 'Trade exceeds price impact tolerance'
          } else {
            errorText = 'Unknown error. Please share transaction data with our tech support'
          }
          setErrorText(errorText)
          setDepositing(false)
          setErrorAlert(true)
        } else if (returnCode === ReturnCode.Success && result.status) {
          setDepositing(false)
          setSuccessAlert(true)
          setTxnPayload(undefined)
          setSuccessTxnDetails(result)

          respondSuccess(result.transactionHash);
        } else {
          setSuccessAlert(false)
          setDepositing(false)
        }
      } else if (approveTxn?.method === 'eth_sendTransaction') {
        setApproveLoader(true)
        try {
          let token: string
          let spender: string

          if (approveTxn.formatedParams) {
            token = approveTxn.formatedParams.domain.verifyingContract
            spender = approveTxn.formatedParams.message.spender
          }
          else {
            token = approveTxn.params[0].to
            spender = decodedApproveData.inputs
          }

          const [returnCode, result] = await DaoAPI.encodeApprovalCall(accountAddress, props?.dao?.data?.subgraph?.id, [
            token,
            `0x${spender[0] !== '0' ? spender[0] : spender.substring(2)}`,
            MAX_UINT    
          ], library)

          if (returnCode === ReturnCode.EstimateError) {
            let errorText: string
            if (result.message.includes('0x743f26e1')) {
              errorText = 'Protocol not supported'
            } else {
              errorText = 'Unknown error. Please share transaction data with our tech support'
            }
            setErrorText(errorText)
            setApproveLoader(false)
            setErrorAlert(true)
          } else if (returnCode === ReturnCode.Success && result.status) {
            setApproveLoader(false)
            setSuccessAprove(true)
            setApproveTxn(undefined)

            respondSuccess(result.transactionHash);
          } else {
            setApproveLoader(false)
            setSuccessAprove(false)
          }
        } catch (error) {
          setApproveLoader(false)
          setDepositing(false)
        }
      } else if (approveTxn?.method === 'eth_signTypedData_v4') {
        const signature = await Signer.signPermit2ForDao(library.provider, accountAddress, decodedApproveData)
        respondSuccess(signature);
        setApproveTxn(undefined);
      } else if (approveTxn?.method === 'personal_sign') {
        const signature = await Signer.personalSign(library.provider, approveTxn.params[0], accountAddress)
        respondSuccess(signature);
        setApproveTxn(undefined);
      }
    } catch (error) {
      return error
    }
  }

  const respondSuccess = (txHash) => {
    const id = txnPayload?.id ?? approveTxn?.id
    const topic = txnPayload?.topic ?? approveTxn?.topic
    
    const response = formatJsonRpcResult(id, txHash)
    console.log("Responding with", response)
    connector?.respondSessionRequest({ topic: topic, response })
  }

  const rejectTransaction = () => {
    const id = txnPayload?.id ?? approveTxn?.id
    const topic = txnPayload?.topic ?? approveTxn?.topic
    const response = formatJsonRpcError(id, getSdkError('USER_REJECTED').message)
    connector?.respondSessionRequest({ topic: topic, response })

    setDepositing(false)
    setTxnPayload(undefined)
    setApproveTxn(undefined)
    setDecodeApproveData(undefined)
  }

  const initiateVoting = async () => {
    try {
      let proposal
      setCreatingProposal(true)

      let addressToAdd = txnPayload?.params[0]?.to ?
        txnPayload?.params[0]?.to :
        (
          approveTxn?.params[0]?.to ?
            approveTxn?.params[0]?.to
            :
            JSON.parse(approveTxn.params[1])
        )

      if (proposalTitle === '') {
        setProposalTitleErr(true)
        throw 'Voting header is required!'
      }
      if (proposalDescription === '') {
        setProposalDescriptionErr(true)
        throw 'Voting description is required!'
      }

      if (moment().unix() <= props?.daoConfig?.finishTime) {
        proposal = await VotingAPI.addToDefiProtocols({
          daoAddress: props?.dao?.data?.subgraph?.id,
          _defProtocols: [addressToAdd?.domain?.verifyingContract === undefined ? addressToAdd : addressToAdd?.domain?.verifyingContract],
        }, library)
      } else {
        proposal = await VotingAPI.addNewAllowlistedAddress({
          daoAddress: props?.dao?.data?.subgraph?.id,
          address: [addressToAdd?.domain?.verifyingContract === undefined ? addressToAdd : addressToAdd?.domain?.verifyingContract],
        }, library)
      }

      const newVote = await VotingAPI.newVote({
        address: votingAddress,
        script: proposal,
        metadata: { proposalTitle, proposalDescription },
        account,
      }, library)
      if (proposal) {
        setCreatingProposal(false)
        setProposalCreated(true)
      }
    }
    catch (error) {
      setCreatingProposal(false)
      if (typeof (error) === 'string') {
        Swal.fire({
          icon: 'error',
          text: error
        })
      }
    }
  }

  return (
    <Grid container className={classes.root}>
      {
        connector === undefined ?
          (<ConnectWallet wcUri={wcUri} setWcUri={setWcUri} wcConnect={wcConnectV2} />)
          :
          (
            <Grid container spacing={2}>
              <Grid item lg={12} className={classes.messageContent}>
                <img src={successIcon} alt="succes" /> <br />
                <span>
                  Successfuly connected <br />
                  Now you can initiate transaction from dApp
                </span>
                <p>
                  Go to the tab with the app where you've connected this DAO wallet and RELOAD PAGE
                </p>
              </Grid>
              {/* { defiAlert && 
                 <Grid item lg={12} className={classes.messageContent}>
                 <img src={errorIcon} alt="error" /> <br />
                 <span>The dao cannot perform transactions with this protocol during fundraising phase</span>
                 </Grid>
               } */}
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <div className={classes.networkContent}>
                  <img width={30} src={peerData?.icons[0]} alt="peerIcon" /> &nbsp;{' '}
                  {peerData?.name}
                </div>
              </Grid>
              {
                defiAlert || nonDefiAlert ? (
                  <Voting
                    setProposalTitle={setProposalTitle}
                    setProposalTitleErr={setProposalTitleErr}
                    proposalTitle={proposalTitle}
                    proposalTitleErr={proposalTitleErr}
                    setProposalDescription={setProposalDescription}
                    setProposalDescriptionErr={setProposalDescriptionErr}
                    proposalDescription={proposalDescription}
                    proposalDescriptionErr={proposalDescriptionErr}
                    initiateVoting={initiateVoting}
                    test={"runs when in fundraise"}
                  />
                ) :
                  txnPayload ? (
                    <TransactionPayload
                      txnPayload={txnPayload}
                      trustedTransfers={props.trustedTransfers}
                      confirmTransaction={confirmTransaction}
                      rejectTransaction={rejectTransaction}
                      defiAlert={defiAlert} />
                  ) : approveTxn ? (
                    <ApprovalPayload
                      approveTxn={approveTxn}
                      confirmTransaction={confirmTransaction}
                      rejectTransaction={rejectTransaction}
                    />
                  ) : null
              }
              <DisconnectWallet wcDisconnect={wcDisconnect} />
            </Grid>
          )
      }

      {/* Loading and Success */}
      <ModalLoading
        open={isDepositing}
        modalImg={depositModalImg}
        title={'Transaction in progress'}
        subTitle={'Transaction in progress'}
      />
      <ModalSuccess
        open={successAlert}
        // onCancel={() => navigate(`/funds/${daoId}`)}
        onCancel={() => setSuccessAlert(false)}
        modalImg={successAlertImg}
        title={'Transaction completed'}
        subTitle={Object.keys(successTxnDetails).length === 0 && successTxnDetails.constructor === Object ?
          null
          :
          (<TransactionHash chainId={chainId} txnHash={successTxnDetails?.transactionHash} />)}
      />
      <ModalError
        open={errorAlert}
        onCancel={() => setErrorAlert(false)}
        modalImg={successAlertImg}
        title={'Transaction will fail'}
        subTitle={errorText ?? 'Please provide transaction data to our tech support'}
      />

      {/* Create proposal modals*/}
      <ModalLoading
        open={creatingProposal}
        onCancel={undefined}
        modalImg={successAlertImg}
        title={'Proposal creation in progress'}
        subTitle={'Please be patient until Proposal will be created'}
      />
      <ModalSuccess
        open={proposalCreated}
        onCancel={() => {
          navigate(`/funds/${daoId}`)
          setProposalCreated(false)
        }}
        modalImg={successAlertImg}
        title={'Proposal has been created'}
        subTitle={'You can start to explore new opportunities'}
      />

      {/* Approve modals*/}
      <ModalLoading
        open={approveLoader}
        onCancel={undefined}
        modalImg={successAlertImg}
        title={'Approving in progress'}
        subTitle={'Open wallet and confirm the transaction'}
      />
      <ModalSuccess
        open={successApprove}
        // onCancel={() => { navigate(`/funds/${daoId}`) }}
        onCancel={() => { setSuccessAprove(false) }}
        modalImg={successAlertImg}
        title={'Approved sucessfully'}
        subTitle={'Now you can make swaps with these pairs'}
      />
    </Grid>
  )
}

const ConnectWallet = (props: any) => {
  const classes = useStyle()

  return (
    <Grid container>
      <Grid item lg={12} md={12} sm={12} xs={12} className={classes.walletImg}>
        <img src={walletConnectImg} alt="walletConnect" />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <p className={classes.text}>
          Connect your DAO to a dApp via the WalletConnect and initiate
          transaction
        </p>
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <TextField
          className={classes.inputContent}
          variant="outlined"
          placeholder="Enter connection link here"
          value={props.wcUri}
          onChange={(e) => props.setWcUri(e.target.value)}
        />
      </Grid>
      <Grid item lg={12} style={{ display: 'grid' }} className={classes.messageContent}>
        <Button
          type="secondary"
          className={classes.buttonContent}
          onClick={() => props.wcConnect(props.wcUri)}>
          Connect
        </Button>
      </Grid>
    </Grid>
  )
}

const DisconnectWallet = (props: any) => {
  const classes = useStyle()

  return (
    <Grid item lg={12} md={12} sm={12} xs={12} style={{ display: 'grid' }}>
      <Button
        type="secondaryOutlined"
        className={classes.buttonContent}
        onClick={() => props?.wcDisconnect()}>
        Disconnect
      </Button>
    </Grid>
  )
}

const TransactionPayload = (props: any) => {
  const classes = useStyle()
  return (
    <Grid container spacing={2}>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <label className={classes.label}>Method</label>
        <TextField
          disabled
          className={classes.inputContent}
          variant="outlined"
          value={props?.txnPayload?.method}
        />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <label className={classes.label}>DAO Address</label>
        <TextField
          disabled
          className={classes.inputContent}
          variant="outlined"
          value={props?.txnPayload?.params[0]?.from}
        />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <label className={classes.label}>
          Target Address (dApp side)
        </label>
        <TextField
          disabled
          className={classes.inputContent}
          variant="outlined"
          value={props?.txnPayload?.params[0]?.to}
        />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <label className={classes.label}>Data</label>
        <TextField
          multiline
          minRows={3}
          disabled
          className={classes.inputContent}
          variant="outlined"
          value={props?.txnPayload?.params[0]?.data}
        />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <label className={classes.label}>Tx value</label>
        <TextField
          disabled
          className={classes.inputContent}
          variant="outlined"
          value={parseInt(props?.txnPayload?.params[0]?.value ?? 0, 16) / 10 ** 18}
        />
      </Grid>
      <Grid container>
        <Grid item lg={12} md={12} sm={12} xs={12} className={classes.messageContent}>
          <img src={successIcon} alt="succes" /> <br />
          <span>
            Target Address is allowlisted <br />
            you can confirm transaction
          </span>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12} style={{ display: 'flex', gap: '15px', justifyContent: 'center' }}>
          <Button
            type="secondary"
            className={classes.transactionButton}
            onClick={props?.confirmTransaction}>
            Confirm
          </Button>
          <Button
            type="secondaryOutlined"
            className={classes.transactionButton}
            onClick={props?.rejectTransaction}>
            Reject
          </Button>
        </Grid>
      </Grid>
    </Grid>
  )
}

const SignaturePayload = (props: any) => {

}

const ApprovalPayload = (props: any) => {
  const classes = useStyle()

  return (
    <Grid item lg={12} md={12} sm={12} xs={12} style={{ display: 'grid' }}>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <label className={classes.label}>Method</label>
        <TextField
          disabled
          className={classes.inputContent}
          variant="outlined"
          value={props?.approveTxn?.method}
        />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <label className={classes.label}>DAO Address</label>
        <TextField
          disabled
          className={classes.inputContent}
          variant="outlined"
          value={
            props?.approveTxn?.formatedParams?.message?.owner ||
            props?.approveTxn?.params[0]?.from
          }
        />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <label className={classes.label}>
          Target Address (dApp side)
        </label>
        <TextField
          disabled
          className={classes.inputContent}
          variant="outlined"
          value={
            props?.approveTxn?.formatedParams?.domain?.verifyingContract ||
            props?.approveTxn?.params[0]?.to
          }
        />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} style={{ display: 'flex', gap: '15px', justifyContent: 'center' }}>
        <Button
          type="secondary"
          className={classes.transactionButton}
          onClick={props?.confirmTransaction}>
          Confirm
        </Button>
        <Button
          type="secondaryOutlined"
          className={classes.transactionButton}
          onClick={props?.rejectTransaction}>
          Reject
        </Button>
      </Grid>
    </Grid>
  )
}

const Voting = (props: any) => {
  const classes = useStyle()
  return (
    <Grid container>
      <Grid item lg={12} md={12} sm={12} xs={12} className={classes.messageContent}>
        <img src={noSucces} alt="succes" /> <br />
        <span>
          Target address is not allowlisted <br />
          you must initiate voting to add this address to the list
        </span>
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <label className={classes.label}>Voting header*</label>
        <TextField
          className={classes.inputContent}
          variant="outlined"
          onChange={(e) => { props?.setProposalTitle(e.target.value) }}
          value={props?.proposalTitle}
          error={props?.proposalTitleErr}
          onFocus={() => props?.setProposalTitleErr(false)}
          helperText={props?.proposalTitleErr ? 'This field is required' : ''} />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <label className={classes.label}>
          Voting description
        </label>
        <TextField
          multiline
          minRows={3}
          className={classes.inputContent}
          variant="outlined"
          onChange={(e) => {
            props?.setProposalDescription(e.target.value)
          }}
          value={props?.proposalDescription}
          error={props?.proposalDescriptionErr}
          onFocus={() => props?.setProposalDescriptionErr(false)}
          helperText={props?.proposalDescriptionErr ? 'This field is required' : ''}
        />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} style={{ display: 'grid' }}>
        <Button type="secondary" className={classes.buttonContent} onClick={props?.initiateVoting}>
          Initiate Voting
        </Button>
      </Grid>
    </Grid>
  )
}
