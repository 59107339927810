import { makeStyles } from '@material-ui/core/styles';

import ArbitrumPng from '../assets/icons/Arbitrum.png'
import BscPng from '../assets/icons/Bsc.png'
import EthPng from '../assets/icons/Eth.png'
import PolygonPng from '../assets/icons/Matic.png'

export const ChainIcon = ({ chainId, styles = {} }) => {
  const classes = useStyles(styles);

    const blockChainIcon = {
      "1": EthPng,
      '56': BscPng,
      '137': PolygonPng,
      '42161': ArbitrumPng,
    }[chainId]

  return (
    <div className={classes.blockChainIconContent}>
      <img
        src={blockChainIcon}
        className={classes.blockChainImg}
        alt="icon"
      />
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  blockChainIconContent:{
    display: 'flex',
    justifyContent: 'flex-end',
    alignSelf: 'center',
    marginBottom: '6px'
  },
  blockChainImg:{
    width:'30px',
    height:'30px'
  },
}))