import {
  takeLatest,
  takeEvery,
  put,
  call,
  all,
  select,
  delay,
} from 'redux-saga/effects'
import { poolsActions } from '../actions'
import { WalletAPI } from '../../api/WalletAPI'
import {
  FETCH_POOLS_REQUEST,
  FETCH_POOLS_SUCCESS,
  UPDATE_POOL,
  UPDATE_POOLS_STATUSES,
} from '../types/pools.types'

import {
  fetchPoolsSuccess,
  setPools,
  updatePool,
} from '../actions/pools.actions'
import { initializeTimers, stopToUpdateTimers } from '../actions/timers.actions'
import { poolsSelector } from '../selectors'
import { poolSelector } from '../selectors/pools.selectors'
import {
  APPROVE_SUCCESS,
  CLAIM_SUCCESS,
  SWAP_SUCCESS,
} from '../wallet/wallet.types'
import { getPoolStatus } from '../../helpers/getPoolStatus'

function* fetchPoolsRequestWorker({ payload }) {
  try {
    const { userAddress, provider, limit, offset } = payload

    yield put(stopToUpdateTimers())
    let pools = yield call(WalletAPI.getPools, limit, offset)
    pools = yield all(
      pools.map((pool) => getUpdatePool(userAddress, pool, provider))
    )

    yield put(fetchPoolsSuccess({ pools }))
  } catch (error) {
    console.log({ error })
    yield put(poolsActions.fetchPoolsError())
  }
}

function* fetchPoolsSuccessWorker({ payload }) {
  try {
    const { pools } = payload
    const timers = pools.map((pool) => {
      return {
        poolId: pool.address,
        // time: pool.investStartTime
      }
    })

    yield put(initializeTimers({ timers }))
  } catch (error) {
    console.log({ error })
    yield put(poolsActions.fetchPoolsError())
  }
}

function* updatePoolsStatusesWorker({ payload }) {
  try {
    const { poolsIds } = payload
    const pools = yield select(poolsSelector)

    const updatedPools = pools.map((pool) => {
      if (poolsIds.includes(pool.address)) {
        const status = getPoolStatus(pool)
        return {
          ...pool,
          status,
        }
      }

      return pool
    })

    yield put(setPools({ pools: updatedPools }))
  } catch (error) {
    console.log({ error })
    yield put(poolsActions.fetchPoolsError())
  }
}

function* updatePoolWorker({ payload }) {
  try {
    const { userAddress, poolAddress, provider } = payload
    const pool = yield select(poolSelector(poolAddress))
    const pools = yield select(poolsSelector)

    const updatedPool = yield call(getUpdatePool, userAddress, pool, provider)
    const updatedPools = pools.map((item) =>
      item.address !== pool.address ? item : updatedPool
    )
    yield put(setPools({ pools: updatedPools }))
  } catch (error) {
    console.log({ error })
    yield put(poolsActions.fetchPoolsError())
  }
}
// TODO update later
function* getUpdatePool(userAddress, pool, provider) {
  let fromContract = {}
  yield delay(1)

  // if (provider) {
  //   const depositTokenAddress = yield call(
  //     getDepositToken,
  //     pool.address,
  //     provider
  //   )
  //   const poolFromContract = yield call(
  //     getPoolFromContract,
  //     pool.address,
  //     depositTokenAddress,
  //     provider
  //   )
  //   const balance = yield call(
  //     getUserPoolBalance,
  //     userAddress,
  //     pool.address,
  //     depositTokenAddress,
  //     provider
  //   )
  //
  //   const feePercent = yield call(getFeePercent, pool.address, provider)
  //   fromContract = {
  //     ...poolFromContract,
  //     balance: +balance,
  //     feePercent,
  //   }
  // }
  //
  // const nowTime = getNowTimeInSec()
  // let status = PoolStatuses.Live
  //
  // if (nowTime < +pool.investStartTime) {
  //   status = PoolStatuses.Upcoming
  // } else if (nowTime > +pool.investFinishTime) {
  //   status = PoolStatuses.Closed
  // }

  return {
    ...pool,
    ...fromContract,
    // status,
  }
}

function* requestSuccessWorker({ payload }) {
  try {
    const { userAddress, poolAddress, provider } = payload
    yield put(updatePool({ userAddress, poolAddress, provider }))
  } catch (error) {
    console.log({ error })
  }
}

export function* poolsWatcher() {
  yield takeLatest(FETCH_POOLS_REQUEST, fetchPoolsRequestWorker)
  yield takeEvery(FETCH_POOLS_SUCCESS, fetchPoolsSuccessWorker)
  yield takeEvery(UPDATE_POOLS_STATUSES, updatePoolsStatusesWorker)
  yield takeEvery(UPDATE_POOL, updatePoolWorker)
  yield takeEvery(
    [SWAP_SUCCESS, APPROVE_SUCCESS, CLAIM_SUCCESS],
    requestSuccessWorker
  )
}
