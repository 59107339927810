import { useEffect, useState } from 'react'

// Material-UI imports
import {
  Card,
  CardContent,
  Grid,

  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { decodeDAOParams, decodeApprove } from '../../../helpers/abiDecoder'
import { useLocation } from 'react-router-dom'
import { useSingleDao } from '../../../hooks/useDaos'
import { useWeb3React } from '@web3-react/core'
import { ChipLabel } from '../../Chip'
import { DaoAPI } from '../../../api/DaoAPI'
import BigNumber from 'bignumber.js'
import { toUSD } from '../../../helpers/currencyHelper'


const useStyle = makeStyles((theme) => ({
  divider: {
    borderBottom: '1px solid rgb(224 224 224)'
  },
  cardTitle: {
    fontSize: 13,
    fontWeight: 600,
    fontStyle: 'normal',
    lineHeight: 4,
    margin: 0,
    paddingLeft: '16px'
  },
  cardContent: {
    borderRadius: '18px',
    border: '1px solid rgba(214, 210, 242, 0.5)',
    boxShadow: '0px 51px 89px -49px rgba(196, 193, 232, 0.4)',
    '& .MuiCardContent-root': {
      padding: 0
    }
  },
  title: {
    margin: 7,
    fontSize: '11px',
    fontWeight: 500,
    lineHeight: '23.87px'
  },
  chipContent: {
    padding: '4px',
    background: '#FDFBFF',
    border: '1px solid #EEE7F8',
    borderRadius: '9px',
    width: '53%'
  },
  fundManagerChip: {
    color: '#8765F2',
    background: 'rgba(137, 99, 244, 0.07)',
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: 15,
    padding: 20
  },
  copyAddressButton: {
    background: '#EBE9F0',
    width: '40px',
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: 12,
    color: '#958BAE',
    marginLeft: '10px'
  },
  justifyContent: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  tableTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: '10px',
    color: '#4C4C66'
  },
  portfolioTable: {
    '& .MuiTableCell-root': {
      borderBottom: 'none',
      fontFamily: 'Poppins',
      fontWeight: 500,
      fontSize: '17px',
      color: '#44435E'
    },
    '& .MuiTableCell-head':{
      padding: '5px 17px',
      fontFamily: 'Poppins',
      fontSize: '10px',
      color: '#4C4C66'
    },
    '& .MuiTableHead-root':{
      borderBottom: '1px solid #ebecf3'
    }
  },
  tableIcon: {
    position: 'relative',
    top: '7px',
    right: '7px'
  },

  root: {
    height: 'fit-content',
    padding: '5px 8px 5px 5px',
    border: `1px solid ${theme.palette.twentySecond.main}`,
    borderRadius: 8,
    backgroundColor: theme.palette.common.white,
    justifyContent: 'space-between',
  },
  inputWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: 'calc(100% - 160px)',
  },
  input: {
    fontFamily:'Poppins',
    height: 28,
    width: '100%',
    fontSize: '1rem',
    padding: 0,
    border: 'none',
    background: 'none',
    '&:focus': {
      outline: 'none',
    },
    '@media screen and (max-width: 460px)': {
      fontSize: 22,
      height: 22,
    },
  },
  selectToken: {
    borderRadius: "8px",
    border: '1px solid #dddfeb',
    height: '30px',
    '& .MuiSelect-select.MuiSelect-select': {
      display: 'flex',
      alignItems: 'center'
    }
  },
  img:{
    verticalAlign: 'bottom',
    width: '27px',
    marginRight: '6px'
  },
  tableContainer:{
    border: '1px solid #dddfeb',
    borderRadius: '10px'
  }
}))

export const VoteForCreateAdHocTransaction = (props: any) => {
  const classes = useStyle()
  const location = useLocation()
  const { chainId, account, connector } = useWeb3React()

  const [adHocTxn, setAdHocTxn] = useState<any>({
    to: "",
    tokenAddress: "",
    amount: "",
  })

  const paths = location.pathname.split('/')
  const daoAddress = paths[paths.length - 1]
  
  const dao = useSingleDao(daoAddress, chainId)
  const [tokenMetada, setTokenMetadata] = useState<any>()

  useEffect(() => {
    const decodeDirectTransactionParams = async () => {
      try {
        const result = decodeDAOParams(props?.action)
        const [recipient, amount, data ] = result.inputs
        
        if(data === '0x') {
          setTokenMetadata((await DaoAPI.getTokenMetaData('0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE', account, chainId, connector)))
          setAdHocTxn({
            to: recipient,
            amount: amount.toString(),
            tokenAddress: "0xe",
          })
        } else {
          setTokenMetadata((await DaoAPI.getTokenMetaData('0x' + recipient, account, chainId, connector)))
          const transfer = decodeApprove(result.inputs[2])
          const [tokenAddress, amount] = transfer.inputs

          setAdHocTxn({
            to: `0x${tokenAddress}`,
            tokenAddress: `0x${recipient}`,
            amount: amount.toString(),
          })
        }
      
      } catch (e) {
        console.log('Error message:', e)
      }
    }
    decodeDirectTransactionParams()
      .catch(console.error)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[adHocTxn.tokenAddress])

  return (
      <Card className={classes.cardContent}>
        <div className={classes.divider}><p className={classes.cardTitle}>Create ad-hoc transaction</p></div>
        <CardContent style={{ padding: '16px' }}>
          <p className={classes.title}>Recipient Address</p>
          <Grid container>
            {adHocTxn.tokenAddress === '0xe'? 
            <Grid item lg={12} md={12} sm={12} xs={12}>
            <ChipLabel type="fundManagerChip" label={'0x' + adHocTxn.to} ></ChipLabel>
            </Grid>
          :  
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <ChipLabel type="fundManagerChip" label={adHocTxn.to} ></ChipLabel>
            </Grid>
          }
          </Grid>
          <p className={classes.title}>DAO's portfolio</p>
          <TableContainer className={classes.tableContainer}>
            <Table className={classes.portfolioTable}>
              <TableHead>
                <TableRow>
                  <TableCell>Assets</TableCell>
                  <TableCell>Balance</TableCell>
                  <TableCell>Value</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell><img src={`https://cdn.jsdelivr.net/gh/atomiclabs/cryptocurrency-icons@1a63530be6e374711a8554f31b17e4cb92c25fa5/svg/color/${tokenMetada?.symbol}.svg`.toLowerCase()} className={classes.img} alt=''/>{tokenMetada?.symbol}</TableCell>
                    <TableCell align="left">{new BigNumber(adHocTxn.amount).dividedBy(`1e${tokenMetada?.decimals}`).toFixed()}</TableCell>
                    <TableCell align="left">${toUSD(new BigNumber(adHocTxn?.amount).dividedBy(`1e${tokenMetada?.decimals}`).toNumber(), dao?.data?.rates[tokenMetada?.symbol]).toFixed(2)}</TableCell>
                  </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>
  )
}
