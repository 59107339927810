/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, useEffect } from 'react'
import { useLocation } from 'react-router'
import web3 from 'web3'

// Material-UI imports
import {
  Card,
  CardContent,
  Grid,
  Badge,
  TextField,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import questionMark from '../../../assets/icons/questionMark.svg'

import { DaoAPI } from '../../../api/DaoAPI'
import { decodeDaoConfigParams } from '../../../helpers/abiDecoder'
import { NumericFormat } from 'react-number-format'
import { useWeb3React } from '@web3-react/core'

const useStyle = makeStyles((theme) => ({
  divider: {
    borderBottom: '1px solid rgb(224 224 224)'
  },
  cardTitle: {
    fontSize: 13,
    fontWeight: 600,
    fontStyle: 'normal',
    lineHeight: 4,
    margin: 0,
    paddingLeft: '16px'
  },
  cardContent: {
    borderRadius: '18px 0px 0px 0px',
    border: '1px solid rgba(214, 210, 242, 0.5)',
    boxShadow: '0px 51px 89px -49px rgba(196, 193, 232, 0.4)',
    '& .MuiCardContent-root': {
      padding: 0
    }
  },
  contentHelper: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '-25px',
    padding: '0 14px'
  },
  labelHelper: {
    fontWeight: 500,
    fontSize: '9px',
    color: '#958BAE',
  },
  contentHelper2: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    marginBottom: '-25px',
    padding: '0 14px'
  },
  boldText: {
    letterSpacing: '0.4px',
    fontSize: '12px',
    color: '#4C4C66',
  },
  spanTextColor: {
    color: '#BC65F2',
    fontSize: '12px',
    marginLeft: '5px'
  },
  questionMark: {
    width: '20px'
  },
  link: {
    color: '#BC65F2'
  },
  inputContent: {
    "& .MuiInputBase-input": {
      padding: '10.5px 14px',
      background: '#FBFBFF',
    },
    "& .MuiInputBase-root": {
      borderRadius: '9px'
    },
    display: 'grid',
    fontSize: '12px',
    fontWeight: 500,
    padding: '15px 10px',
  },
  input: {
    marginTop: '15px',
  },
  exampleContent: {
    fontSize: '8px',
    height: '100%',
    border: '1px solid rgba(214, 210, 242, 0.5)',
    borderTop: 'none',
    borderRadius: '2px 0px 18px 18px',
    background: 'white',
    width: '100%',
    paddingTop: '15px',
  },
  exampleText: {
    lineHeight: '22px',
    marginTop: '0px',
    color: '#958BAE',
    margin: '8px',
  },
  exampleTitle: {
    color: '#BC65F2',
    margin: '5px 8px'
  },
  cardContentSecondary: {
    borderRadius: '0px 18px  0px 0px',
    border: '1px solid rgba(214, 210, 242, 0.5)',
    boxShadow: '0px 51px 89px -49px rgba(196, 193, 232, 0.4)',
    width: '100%',
  },
}))

export const VoteForChangeRageQuitFine = (props: any) => {
  const classes = useStyle()
  const { hexToNumber } = web3.utils
  const { connector } = useWeb3React()

  const location = useLocation();
  const daoAddress = location.state.state.data.subgraph.id

  const [daoFees, setDaoFees] = useState<any>({})
  const [proposedRageFee, setProposedRageFee] = useState<any>('')

  useEffect(() => {
    const decodeRageFee = async () => {
      try {
        setDaoFees(await DaoAPI.getDaoFees(daoAddress, connector))
        const result = decodeDaoConfigParams(props?.action)
        setProposedRageFee(hexToNumber(result.inputs[0]._hex) / 100)
      } catch (e) {
        console.log('Error message:', e)
      }
    }
    decodeRageFee()
      .catch(console.error)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const limitedValue = (inputObj) => {
    const { value } = inputObj
    if (value <= 100) return true
    return false
  }

  return (
    <>
      <Grid container style={{ marginBottom: '16px' }}>
        <Grid item lg={6} md={6} sm={6} xs={12}>
          <Card className={classes.cardContent}>
            <div className={classes.divider}>
              <p className={classes.cardTitle}>Proposed rage quit fine</p>
            </div>
            <CardContent style={{ padding: '16px' }}>
              <Grid container>
                <Grid item lg={12} md={8} sm={8} xs={12}>
                  <div className={classes.contentHelper2}>
                    <div className={classes.labelHelper}>
                      <b className={classes.boldText}>Rage quit fine,</b>  % of withdrawn assets
                    </div>
                  </div>
                </Grid>
                <Grid item lg={12} md={8} sm={8} xs={12}>
                  <div className={classes.inputContent}>
                    <NumericFormat
                      disabled
                      value={proposedRageFee}
                      customInput={TextField}
                      variant="outlined"
                      className={classes.input}
                      suffix={'%'}
                      valueIsNumericString={true}
                      allowNegative={false}
                      isAllowed={limitedValue}
                    />
                  </div>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        <Grid item lg={6} md={6} sm={6} xs={12}>
          <Card className={classes.cardContentSecondary}>
            <div className={classes.divider}>
              <p className={classes.cardTitle}>Current rage quit fine</p>
            </div>
            <CardContent style={{ padding: '16px' }}>
              <Grid container>
                <Grid item lg={12} md={8} sm={8} xs={12}>
                  <div className={classes.contentHelper2}>
                    <div className={classes.labelHelper}>
                      <b className={classes.boldText}>Rage quit fine,</b>  % of withdrawn assets
                    </div>
                  </div>
                </Grid>
                <Grid item lg={12} md={8} sm={8} xs={12}>
                  <div className={classes.inputContent}>
                    <NumericFormat
                      disabled
                      value={daoFees?.rageQuitFee / 100}
                      customInput={TextField}
                      variant="outlined"
                      className={classes.input}
                      suffix={'%'}
                      valueIsNumericString={true}
                      allowNegative={false}
                      isAllowed={limitedValue}
                    />
                  </div>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <div className={classes.exampleContent}>
            <span className={classes.spanTextColor}>
              <Badge overlap="rectangular">
                <img className={classes.questionMark} src={questionMark} alt="questionMark" />
              </Badge>{' '}
              <a href="" className={classes.link}> Learn more withdrawal windows</a>
            </span>
            <p className={classes.exampleTitle}>
              Fine for removing capital outside of withdrawal window
            </p>
            <p className={classes.exampleText}>
              Example: To align the interests of the manager and the investors, 
              there are 2 periods following each other: 
              “Withdrawal period” and “Freeze period”, 
              which are constantly following each other. 
              If the user wants to burn his LP tokens during the “Freeze period”, 
              he needs to pay “Rage Quit Fine”. If the user burns his LP tokens in the “Withdrawal period”, 
              a rage quit fine will not be applied.
            </p>
          </div>

        </Grid>
      </Grid>
    </>
  )
}
