/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from 'react'

// Material-UI imports
import {
  Button,
  Card,
  CardContent,
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  RadioGroup
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import { CopyToClipboardBtn } from '../../../CopyToClipboard'
import { DaoAPI } from '../../../../api/DaoAPI'
import { useWeb3React } from '@web3-react/core'

const useStyle = makeStyles((theme) => ({
  divider: {
    borderBottom: '1px solid rgb(224 224 224)',
  },
  cardTitle: {
    fontSize: 13,
    fontWeight: 600,
    fontStyle: 'normal',
    lineHeight: 4,
    margin: 0,
    paddingLeft: '16px',
  },
  cardContent: {
    borderRadius: '18px',
    border: '1px solid rgba(214, 210, 242, 0.5)',
    boxShadow: '0px 51px 89px -49px rgba(196, 193, 232, 0.4)',
  },
  cardContentInside:{
    padding: '16px',
    height: '198px',
    overflow: 'overlay'
  },
  fundManagerChip: {
    color: '#8765F2',
    background: 'rgba(137, 99, 244, 0.07)',
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: 15,
    borderRadius: '86px',
    paddingRight: '10px',
    '& .MuiTypography-body1': {
      fontFamily: 'Poppins',
      fontSize: '15px',
      fontWeight: 500,
      letterSpacing: 0
    }
  },
  loadBtnContent: {
    display: 'flex',
    justifyContent: 'center',
    padding: '16px',
    borderTop: '1px solid rgba(214, 210, 242, 0.5);',
  },
  loadBtn: {
    background: 'rgba(232, 99, 244, 0.07)',
    border: '1px solid #BC65F2',
    borderRadius: '5px',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '21px',
    textAlign: 'center',
    color: '#BC65F2',
  },
  content: {
    marginBottom: '15px',
  },
}))

export const DeleteAllowlistedAddress = (props: any) => {
  const {func, daoId, setIsProposalValid} = props;
  const classes = useStyle()
  
  const [addresses, setAddresses] = useState([])
  const { connector } = useWeb3React()

  const [deleteAllowListedAddress, setAllowListedAddress] = useState<any>({
    address: [],
    func: 'deleteAllowlistedAddress'
  })

  const inputValidation = deleteAllowListedAddress?.address.length > 0 
  
  useEffect(()=>{
    setIsProposalValid(inputValidation)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[inputValidation])

  useEffect(() => {
    const fetchAddresses = async () => {
      setAddresses(await DaoAPI.getTrustedTransfers(daoId, connector))
    }

    func(deleteAllowListedAddress)

    fetchAddresses().catch(console.error)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [daoId, deleteAllowListedAddress])

  return (
      <Card className={classes.cardContent}>
        <div className={classes.divider}>
          <p className={classes.cardTitle}>Delete allowed address / protocol</p>
        </div>
        <CardContent className={classes.cardContentInside}>
          {
            addresses.map((item) => (
              <div className={classes.content} key={item}>
                <FormControl>
                  <RadioGroup>
                    <FormControlLabel
                      className={classes.fundManagerChip}
                      value={item}
                      control={
                        <Checkbox
                          onChange={(e) => {
                            if(e.target.checked) {
                              setAllowListedAddress({ ...deleteAllowListedAddress, address: [...deleteAllowListedAddress.address, e.target.value] }) 
                            } else {
                              setAllowListedAddress({ ...deleteAllowListedAddress, address: deleteAllowListedAddress.address.filter(el => el !== item) }) 
                             }
                        }} />
                      }
                      label={item}
                    />
                  </RadioGroup>
                </FormControl>
                <CopyToClipboardBtn props={item} />
              </div>
            ))
          }
        </CardContent>
      </Card>
  )
}
