import { FETCH_DAO_REQUEST, FETCH_SINGLE_DAO_REQUEST } from '../types/dao.types'

export const getAllDaos = (limit, offset, chainId, connector) => {
  return {
    type: FETCH_DAO_REQUEST,
    limit,
    offset,
    chainId,
    connector,
  }
}

export const getSingleDao = (id, chainId) => {
  return {
    type: FETCH_SINGLE_DAO_REQUEST,
    id,
    chainId,
  }
}
