import { put, delay, takeEvery } from 'redux-saga/effects'
import { FETCH_USER_INFO_REQUEST } from '../types/user.types'
import { LOG_OUT } from '../types/account.types'
import { UserRoles } from '../../const/userRoles'
import {
  clearUserInfo,
  fetchUserInfoError,
  fetchUserInfoSuccess,
} from '../actions/user.actions'

function* fetchUserInfoRequestWorker() {
  try {
    yield delay(300)
    // TODO: WTF
    yield put(fetchUserInfoSuccess({ userInfo: { role: UserRoles.Admin } }))
  } catch (error) {
    console.log({ error })
    yield put(fetchUserInfoError())
  }
}

function* logOutWorker() {
  try {
    yield put(clearUserInfo())
  } catch (error) {
    console.log({ error })
  }
}

export function* userWatcher() {
  yield takeEvery(FETCH_USER_INFO_REQUEST, fetchUserInfoRequestWorker)
  yield takeEvery(LOG_OUT, logOutWorker)
}
