import React, { useMemo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Grid, Modal as MuiModal } from '@material-ui/core'
import { useWindowHeight } from '../../hooks'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    overflow: 'auto',
    backgroundColor: theme.palette.thirtySeventh.main,
    '& > div': {
      '&:first-child': {
        backgroundColor: 'transparent !important',
      },
    },
  },
  modalBody: {
    padding: '10px 10px 10px 10px',
    backgroundColor: theme.palette.common.white,
    display: 'flex',
    alignItems: 'center',
    maxWidth: 320,
    margin: '0 auto',
    height: 'fit-content',
    borderRadius: 12,
    '&:focus': {
      outline: 'none',
    },
  },
}))

export const Modal = ({
  className,
  modalBodyClass,
  marginTop,
  children,
  ...rest
}) => {
  const classes = useStyles()
  const windowHeight = useWindowHeight()

  const modalBodyStyle = useMemo(() => {
    return {
      marginTop: marginTop ?? (windowHeight - 370) / 2,
    }
  }, [windowHeight, marginTop])

  return (
    <MuiModal className={`${classes.root} ${className}`} {...rest}>
      <Grid
        container
        style={modalBodyStyle}
        className={`${classes.modalBody} ${modalBodyClass}`}
      >
        {children}
      </Grid>
    </MuiModal>
  )
}
