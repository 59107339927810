import { useLocation } from 'react-router'
import { store } from '../../redux/store'
import { CreateProposal } from '../../components/votings/proposals/CreateProposal'
import { Container } from '@material-ui/core'

export const VotingPage = (props) => {
  const location = useLocation()
  const state = store.getState()
  const dao = state.daos.dao

  return (
    <Container>
      <CreateProposal
        dao={dao?.data?.subgraph}
        logo={dao?.img}
        supportedTokens={location?.state?.supportedTokens}
        lpTotalSupply={location?.state?.lpTotalSupply}
      />
    </Container>
  )
}
