import BigNumber from 'bignumber.js'
import { toUSD } from '../helpers/currencyHelper'
import { getNetworkConfig } from '../const/networks'
import { ETH_TOKEN_ADDRESS } from '../const/ethConst'

export const calculateTotalBalance = (daos, rates, chainId) => {
  if (!chainId) return
  daos?.forEach((dao, index) => {
    let totalDeposits = 0
    dao?.balances?.forEach((deposit) => {
      const decimals =
        deposit?.token?.id === ETH_TOKEN_ADDRESS
          ? '18'
          : deposit?.token?.decimals
      const symbol =
        deposit?.token?.id === ETH_TOKEN_ADDRESS
          ? getNetworkConfig(chainId)?.params?.nativeCurrency?.symbol
          : deposit?.token?.symbol

      if (!symbol) return
      totalDeposits += toUSD(
        new BigNumber(deposit.balance).dividedBy(`1e${decimals}`).toNumber(),
        rates[symbol.toUpperCase()]
      )
    })
    daos[index].totalBalance = totalDeposits
  })
}

export const calculatePercentage = (daos) => {
  if (daos?.length !== 0) {
    daos?.forEach((element) => {
      element.totalBalancePct = new BigNumber(element.totalBalance)
        .dividedBy(element.maxCap)
        .multipliedBy(100)
    })
  } else return 0
}
