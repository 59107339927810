/* eslint-disable react-hooks/exhaustive-deps */
import { useInfiniteQuery } from 'react-query'

// Helpers, Routes & Components
import { DaoAPI } from '../../../api/DaoAPI'
import { makeStyles } from '@material-ui/core/styles'
import { getNetworkConfig } from '../../../const/networks'
import { ButtonsLoader } from '../../../components/Loaders'
import { FundsGrid } from './FundsGrid'
import { useMediaQuery } from '@material-ui/core'
import { FundsTable } from './FundsTable'

const useStyles = makeStyles((theme) => ({
  viewMoreBtn: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '86px',
    color: '#BC65F2',
    border: '1px solid #BC65F2',
    width: '156px',
    height: '38px',
    margin: '-70px auto 0',
    cursor: 'pointer',
    [theme.breakpoints.down('md')]: {
      margin: '0px auto 40px',
    },
  },
  loaderWrapper: {
    marginTop: '-100px',
    padding: '0 10px',
    [theme.breakpoints.down('md')]: {
      marginTop: '0',
    },
  },
  fundsContainer: {
    flexWrap: 'nowrap',
    overflow: 'overlay',
    display: 'block',
  },
}))

export const FundsContent = ({ filterNameInput: query }) => {
  const classes = useStyles()
  const isMobileSize = useMediaQuery((theme) => theme.breakpoints.down('md'))

  let itemsPerPage = isMobileSize ? 6 : 9

  // Get all daos list
  async function getDaos({ pageParam = 1 }) {
    const fetchedDaos = await DaoAPI.getAllDaos(pageParam, itemsPerPage, query)

    const modifiedRecords = fetchedDaos?.records.map(
      async (element): Promise<any> => {
        const network = getNetworkConfig(element.chainId)

        const library = { provider: network.params.rpcUrls[0] }

        const lpPricesResp = await DaoAPI.getLpPrices(element.id, library)
        const daoConfiguration = await DaoAPI.getConfiguration(
          element.id,
          library
        )
        const fees = await DaoAPI.getDaoFees(element.id, library)

        return {
          ...element,
          fees,
          daoConfig: daoConfiguration,
          lpPrices: lpPricesResp.data,
          lpPrice: lpPricesResp.data.length> 0 ?  Number(
            lpPricesResp.data[lpPricesResp.data.length - 1].price
          ).toFixed(2) : null,
          maxCap: daoConfiguration?.maxCap,
          depositRange: {
            minimum: daoConfiguration?.minDeposit,
            maximum: daoConfiguration?.maxDeposit,
          },
        }
      }
    )

    const processed = await Promise.all(modifiedRecords)

    return { ...fetchedDaos, records: processed }
  }

  const {
    data: daos,
    fetchNextPage,
    hasNextPage,
    isFetching,
  } = useInfiniteQuery({
    queryKey: ['daos', query],
    queryFn: getDaos,
    getNextPageParam: (lastPage) => {
      if (Number(lastPage.totalPages) === Number(lastPage.page)) {
        return null
      }

      return Number(lastPage.page) + 1
    },
  })

  return (
    <>
      {isMobileSize ? (
        <FundsGrid
          daos={daos}
          fundsContainer={classes.fundsContainer}
          fundsCard={classes.fundsCard}
        />
      ) : (
        <FundsTable daos={daos} />
      )}
      {hasNextPage ? (
        !isFetching ? (
          <div
            className={classes.viewMoreBtn}
            onClick={() => !isFetching && fetchNextPage()}
          >
            View more funds
          </div>
        ) : (
          <div className={classes.loaderWrapper}>
            <ButtonsLoader />
          </div>
        )
      ) : null}
    </>
  )
}
