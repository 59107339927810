import { initializeConnector } from '@web3-react/core'
import { WalletConnect as WalletConnectV2 } from '@web3-react/walletconnect-v2'
import { useEnvNetowrks } from '../../hooks/useChain'

export const [walletConnectV2, hooks] = initializeConnector<WalletConnectV2>(
    (actions) => {
        const chains = useEnvNetowrks()
        return new WalletConnectV2({
            actions,
            options: {
                projectId: '03ffba50a91695b225039734af4d099e',
                chains: chains.map(c => parseInt(c.params.chainId)),
                optionalChains: [],
                showQrModal: true,
            },
        })
    }
)