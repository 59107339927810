import { call, delay, put, takeEvery } from 'redux-saga/effects'
import * as walletApi from '../../api/wallet'
import { getDepositToken } from '../../api/wallet'
import {
  APPROVE_REQUEST,
  CLAIM_REQUEST,
  LOCK_IN_REQUEST,
  SWAP_REQUEST,
} from './wallet.types'
import {
  approveError,
  approveSuccess,
  claimError,
  claimSuccess,
  lockInError,
  lockInSuccess,
  resetError,
  swapError,
  swapSuccess,
} from './wallet.actions'
import { extractErrorMessage } from '../../helpers/extractErrorMessage'

function* swapRequestWorker({ payload }) {
  try {
    const { userAddress, poolAddress, amount, provider, poolId, password } =
      payload
    yield call(
      walletApi.swap,
      userAddress,
      poolAddress,
      amount,
      provider,
      poolId,
      password
    )
    yield put(swapSuccess({ userAddress, poolAddress, provider }))
  } catch (error) {
    const message = extractErrorMessage(error)
    console.error(message)
    yield put(swapError(message))
  }
}

function* approveRequestWorker({ payload }) {
  try {
    const { userAddress, poolAddress, amount, provider } = payload

    const depositTokenAddress = yield call(
      getDepositToken,
      poolAddress,
      provider
    )

    yield call(
      walletApi.approve,
      userAddress,
      poolAddress,
      depositTokenAddress,
      amount,
      provider
    )
    yield put(approveSuccess({ userAddress, poolAddress, provider }))
  } catch (error) {
    const message = extractErrorMessage(error)
    console.error(message)
    yield put(approveError(message))
  }
}

function* claimRequestWorker({ payload }) {
  try {
    const { userAddress, poolAddress, provider } = payload

    yield call(walletApi.claim, poolAddress, provider)
    yield put(claimSuccess({ userAddress, poolAddress, provider }))
  } catch (error) {
    const message = extractErrorMessage(error)
    console.error(message)
    yield put(claimError(message))
  }
}

function* lockInRequestWorker(/*{ payload }*/) {
  try {
    // const { userAddress, amount, provider } = payload
    yield delay(1000)
    yield put(lockInSuccess())
  } catch (error) {
    const message = extractErrorMessage(error)
    console.error(message)
    yield put(lockInError(message))
  }
}

function* resetErrorWorker() {
  yield put(resetError())
}

export function* walletWatcher() {
  yield takeEvery(SWAP_REQUEST, swapRequestWorker)
  yield takeEvery(APPROVE_REQUEST, approveRequestWorker)
  yield takeEvery(CLAIM_REQUEST, claimRequestWorker)
  yield takeEvery(LOCK_IN_REQUEST, lockInRequestWorker)

  yield takeEvery(
    [SWAP_REQUEST, APPROVE_REQUEST, CLAIM_REQUEST, LOCK_IN_REQUEST],
    resetErrorWorker
  )
}
