import Web3 from 'web3'
const web3 = new Web3(Web3.givenProvider)

export class Signer {
    static async signPermit2ForDao(provider, accountAddress, permitData) {
        const method = 'eth_signTypedData_v4';

        const signedData = await provider.request({ method, params: [accountAddress, permitData] });

        const signature = web3.eth.abi.encodeParameters(["address", "uint160", "uint48", "uint48", "address", "uint256", "bytes"],
            [permitData.message.details.token, permitData.message.details.amount, permitData.message.details.expiration, permitData.message.details.nonce,
                permitData.message.spender, permitData.message.sigDeadline, signedData]);

        return signature;
    }

    static async personalSign(provider, message, accountAddress) {
        const method = 'personal_sign';

        const signedData = await provider.request({ method, params: [message, accountAddress] });

        return signedData;
    }
}