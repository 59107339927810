import { ethers } from 'ethers'
import { metaMask } from './connectors/metamaskConnector'
import { walletConnectV2 } from './connectors/walletConnectV2Connector'

export const getInjectedConnector = () => {
  return metaMask
}

export const getWalletConnectConnector = () => {
  return walletConnectV2
}

export const getLibrary = (provider) => {
  const ethersProvider = new ethers.providers.Web3Provider(provider)

  return ethersProvider
}

export const setCurrentConnectionName = (connectionName) => {
  localStorage.setItem('using_wallet', connectionName)
}

export const getCurrentConnectionName = () => {
  return localStorage.getItem('using_wallet')
}

export const clearWalletCache = () => {
  localStorage.clear()
}
