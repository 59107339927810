import { put, takeEvery, select } from 'redux-saga/effects'
import {
  addRequestActionType,
  removeRequestActionType,
  setLoadingFalse,
  setLoadingTrue,
  setRequestActionTypes,
} from '../actions/loading.actions'
import { poolsTypes } from '../types'
import * as walletTypes from '../wallet/wallet.types'
import { requestsSelector } from '../selectors'
import {
  ADD_REQUEST_ACTION_TYPE,
  REMOVE_REQUEST_ACTION_TYPE,
  SET_REQUEST_ACTION_TYPES,
} from '../types/loading.types'
import * as userTypes from '../types/user.types'

export function* addRequestActionTypeWorker({ payload }) {
  try {
    const { type } = payload
    const types = yield select(requestsSelector)

    const newTypes = new Set(types)

    yield put(setRequestActionTypes({ types: newTypes.add(type) }))
  } catch (error) {
    return error
  }
}

export function* removeRequestActionTypeWorker({ payload }) {
  try {
    const { type } = payload
    const types = yield select(requestsSelector)
    const newTypes = new Set(types)

    newTypes.delete(type)

    yield put(setRequestActionTypes({ types: newTypes }))
  } catch (error) {
    return error
  }
}

export function* setRequestActionTypesWorker() {
  try {
    const types = yield select(requestsSelector)

    types.size > 0 ? yield put(setLoadingTrue()) : yield put(setLoadingFalse())
  } catch (error) {
    console.log({ error })
  }
}

export function* fetchRequestWorker({ type }) {
  try {
    yield put(addRequestActionType({ type }))
  } catch (error) {
    console.log({ error })
  }
}

export function* endRequestWorker({ type }) {
  try {
    yield put(
      removeRequestActionType({
        type: type.replace(/_ERROR$|_SUCCESS$/, '_REQUEST'),
      })
    )
  } catch (error) {
    console.log({ error })
  }
}

export function* loadingWatcher() {
  yield takeEvery(
    [
      poolsTypes.FETCH_POOLS_REQUEST,
      userTypes.FETCH_USER_INFO_REQUEST,
      walletTypes.SWAP_REQUEST,
      walletTypes.APPROVE_REQUEST,
      walletTypes.CLAIM_REQUEST,
      walletTypes.LOCK_IN_REQUEST,
    ],
    fetchRequestWorker
  )

  yield takeEvery(
    [
      poolsTypes.FETCH_POOLS_SUCCESS,
      poolsTypes.FETCH_POOLS_ERROR,
      userTypes.FETCH_USER_INFO_SUCCESS,
      userTypes.FETCH_USER_INFO_ERROR,
      walletTypes.SWAP_SUCCESS,
      walletTypes.SWAP_ERROR,
      walletTypes.APPROVE_SUCCESS,
      walletTypes.APPROVE_ERROR,
      walletTypes.CLAIM_SUCCESS,
      walletTypes.CLAIM_ERROR,
      walletTypes.LOCK_IN_SUCCESS,
      walletTypes.LOCK_IN_ERROR,
    ],
    endRequestWorker
  )

  yield takeEvery(ADD_REQUEST_ACTION_TYPE, addRequestActionTypeWorker)
  yield takeEvery(REMOVE_REQUEST_ACTION_TYPE, removeRequestActionTypeWorker)
  yield takeEvery(SET_REQUEST_ACTION_TYPES, setRequestActionTypesWorker)
}
