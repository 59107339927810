import { useEffect, useState } from 'react'

import { useWeb3React } from '@web3-react/core'

import { NetworkId } from '../models/Network'

import { SUPPORTED_TOKENS } from '../const/aspisSupportedTokensList'
import { SUPPORTED_PROTOCOLS } from '../const/aspisSupportedProtocolsList'
import { mainnetNetworks, testnetNetworks } from '../const/networks'

export const useEnvNetowrks = () => {
  return process.env.REACT_APP_ENV === 'prod'
    ? mainnetNetworks
    : testnetNetworks
}

export const useChain = () => {
  const { chainId: wcChainId } = useWeb3React()

  const [chainId, setChainId] = useState(window?.ethereum?.networkVersion)

  if (!chainId) {
    if (wcChainId) {
      setChainId(wcChainId)
    }
  }

  useEffect(() => {
    if (window?.ethereum) {
      window.ethereum.on('chainChanged', function (networkId) {
        const decimalId = parseInt(networkId)
        if (Object.values(NetworkId).includes(decimalId)) {
          setChainId(decimalId)
        }
      })
    }
  }, [chainId])

  return chainId
}

export const useAspisSupportedTokens = (tokensFromSmartContract, chainId) => {
  const web3 = useWeb3React()

  const [supportedTokens, setSupportedTokens] = useState([])

  if (!chainId) {
    chainId = web3.chainId
  }

  useEffect(() => {
    if (SUPPORTED_TOKENS[chainId]) {
      setSupportedTokens(SUPPORTED_TOKENS[chainId])
    }
  }, [chainId])

  if (tokensFromSmartContract?.length !== 0) {
    let res = []
    tokensFromSmartContract?.forEach((g) => {
      supportedTokens.forEach((a) => {
        if (g.toLowerCase() === a.address.toLocaleLowerCase()) {
          res.push(a)
        }
      })
    })
    return res
  }

  return supportedTokens
}

export const useDefiProtocols = () => {
  const [defiProtocols, setDefiProtocols] = useState([])

  const { chainId } = useWeb3React()

  useEffect(() => {
    if (SUPPORTED_PROTOCOLS[chainId]) {
      setDefiProtocols(SUPPORTED_PROTOCOLS[chainId])
    }
  }, [chainId])

  return defiProtocols
}
