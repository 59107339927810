/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-throw-literal */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router'

import { DaoAPI } from '../../api/DaoAPI'
import { VotingAPI } from '../../api/VotingAPI'
import BigNumber from "bignumber.js";

import { makeStyles } from '@material-ui/core/styles'
import { Grid, MenuItem, Select, TextField } from '@material-ui/core'

import { Button } from '../Button'
import { NumericFormat } from 'react-number-format'
import Swal from 'sweetalert2'

import transferIcon from '../../assets/images/sendToken.svg'
import errorIcon from '../../assets/icons/notSuccess.svg'
import successAlertImg from '../../assets/images/depositModal.svg'

import { ModalLoading } from '../ModalLoading'
import { ModalSuccess } from '../ModalSuccess'
import { ErrorMessage } from '../ErrorMessage/ErrorMessage'
import web3 from 'web3'
import { useSelector } from 'react-redux'
import { useWeb3React } from '@web3-react/core'

const useStyle = makeStyles((theme) => ({
  root: {
    maxWidth: '65%',
    margin: 'auto',
    [theme.breakpoints.down('xs')]:{
      maxWidth: '100%'
     }
  },
  walletImg: {
    display: 'flex',
    justifyContent: 'center',
    margin: '45px 0px'
  },
  text: {
    textAlign: 'center',
    width: '30%',
    margin: 'auto',
    fontSize: '13px',
    [theme.breakpoints.down('sm')]: {
      width: '75%'
    }
  },
  inputContent: {
    display: 'flex',
    marginTop: '30px',
    '& .MuiOutlinedInput-input': {
      padding: '13.5px 14px'
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderRadius: '9px'
    },
    '& .MuiOutlinedInput-multiline': {
      padding: 0
    }
  },
  messageContent: {
    textAlign: 'center',
    margin: '15px 0px',
    width: '100%',
    "& span": {
      fontSize: '13px'
    },
    "& p": {
      fontSize: '11px',
      color: '#979797'
    }
  },
  label: {
    position: 'relative',
    top: '27px',
    fontSize: '13px',
    color: '#2F2F4D'
  },
  buttonContent: {
    width: '35%',
    margin: 'auto',
    borderRadius: '7px',
    marginBottom: '10px',
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  selectDropdown: {
    '&:focus': {
      backgroundColor: 'transparent',
      borderRadius: '8px',
    },
    display: 'flex',
    alignItems: 'center',
    border: '1px solid rgba(0, 0, 0, 0.23)',
    borderRadius: '8px',
    height:'29px',
    paddingLeft: '16px'
  },
  contentHelper:{
    display: 'grid',
    marginTop: '16px'
  },
  addons: {
    display:'flex',
    alignItems: 'center'
  },
  maxAndCurrencyWrapper: {
    display: 'flex',
    color: theme.palette.twentieth.main,
    fontWeight: 600,
    fontSize: 14,
    lineHeight: '150%',
    alignItems: 'center',
    marginRight: 10
  },
  maxButton: {
    height: 44,
    padding: 5,
    float: 'right',
    width: '10%',
    position: 'relative',
    top:'-45px',
    [theme.breakpoints.down('xs')]:{
      width: '20%',
     }
  },
}))

export const TransferFund = (props) => {
  const classes = useStyle()
  const location = useLocation()
  const navigate = useNavigate()
  const { connector } = useWeb3React()
  const votingAddress = useSelector((state:any) => state?.daos?.dao?.data?.subgraph?.daoCreationLog?.voting)

  const { trustedTransfers } = props

  const [isTokenTrusted, setTrustedToken] = useState(false)
  const [maxBalance, setMaxBalance] = useState(0)
  const [toggleMax, setToggleMax] = useState(false)
  const [assets] = useState(location?.state?.assets)

  const [creatingProposal, setCreatingProposal] = useState(false)
  const [createdProposal, setCreatedProposal] = useState(false)

  const [proposalTitleErr, setProposalTitleErr] = useState(false)
  const [proposalDescriptionErr, setProposalDescriptionErr] = useState(false)
  const [insufficientFundsMessage, setInsufficientFundsMessage] = useState('')
  const [invalidAddressMessage, setInvalidAddressMessage] = useState('')
  const [tokenErrorMessage, setTokenErrorMessage] = useState('')
   
  const [proposalDetails, setProposal] = useState<any>({
    title: null,
    description: null,
  })
  const [transferDetails, setTransferDetails] = useState<any>({
    recipientAddress: null,
    amount: null,
    isTrustedTransfer: false,
    token: null,
  })

  useEffect(() => {
    if (trustedTransfers?.includes(transferDetails?.recipientAddress?.toLowerCase()) &&
      transferDetails?.amount > 0 &&
      transferDetails?.token !== null) {
      setTransferDetails({ ...transferDetails, isTrustedTransfer: true })
    }
    else {
      setTransferDetails({ ...transferDetails, isTrustedTransfer: false })
    }

    if (trustedTransfers.includes(transferDetails?.token?.address?.toLowerCase())) {
      setTrustedToken(true)
    } else setTrustedToken(false)

  }, [transferDetails?.recipientAddress, transferDetails?.amount, transferDetails?.token])

  const initiateVoting = async () => {
    try {
      const { recipientAddress, amount, token } = transferDetails
      const addresses: any[] = []
      const { title, description } = proposalDetails

      if (!isTokenTrusted && !transferDetails.isTrustedTransfer) {
        addresses.push(transferDetails.token.address, transferDetails.recipientAddress)
      } else {
        if (!isTokenTrusted) {
          addresses.push(transferDetails.token.address)
        }
        else {
          addresses.push(transferDetails.recipientAddress)
        }
      }

      if(token === null){
        setTokenErrorMessage('Please select a token')
        throw 'Token cannot be null'
      } else{setTokenErrorMessage('')}
  
      if(amount > token.balance || amount === null){
        setInsufficientFundsMessage('Insufficient funds')
        throw 'Insufficient funds'
      } else {setInsufficientFundsMessage('') }
  
      if (!web3.utils.isAddress(recipientAddress) || recipientAddress === null){
        setInvalidAddressMessage('Invalid Address')
        throw 'Invalid address'
      }else{setInvalidAddressMessage('')}

      if (title === null) {
        setProposalTitleErr(true)
        throw 'Voting header is required!'
      }
      if (description === null) {
        setProposalDescriptionErr(true)
        throw 'Voting description is required!'
      }

      setCreatingProposal(true)

      if (token.address === '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE') {
        const directAssetTransfer = VotingAPI.directAssetTransfer(props?.account, props?.dao?.data?.subgraph?.id, [
          recipientAddress,
          (new BigNumber(amount).multipliedBy('1e18')).toFixed().toString(),
          [],
        ])
        const newVote = await VotingAPI.newVote({
          address: votingAddress,
          script: directAssetTransfer,
          metadata: { proposalTitle: title, proposalDescription: description },
          account: props?.account,
        }, connector)
        if(newVote) {
          setCreatedProposal(true)
        }
      } 
      else {
        const { to, data } = await DaoAPI.agentTransfer(token.address, recipientAddress, (new BigNumber(amount).multipliedBy(`1e${token.decimals}`)).toFixed().toString(), connector)
        const directAssetTransfer = VotingAPI.directAssetTransfer(props?.account, props?.dao?.data?.subgraph?.id, [
          token.address,
          `0`,
          data,
        ])
        const newVote = await VotingAPI.newVote({
          address: votingAddress,
          script: directAssetTransfer,
          metadata: { proposalTitle: title, proposalDescription: description },
          account: props?.account,
        }, connector)
        if(newVote) {
          setCreatedProposal(true)
        }
      }
    }
    catch (error) {
      setCreatingProposal(false)
      console.log(error)
      if (typeof (error) === 'string') {
        Swal.fire({
          icon: 'error',
          text: error
        })
      }
    }
  }
  const toggleMaxAmountDeposit = async () => {
      setMaxBalance(transferDetails?.token?.balance)
      setToggleMax(true)
  }

  return (
    <Grid container className={classes.root}>
      <Grid item lg={12} md={12} sm={12} xs={12} className={classes.walletImg}>
        <img src={transferIcon} alt="transfer" />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <p className={classes.text}>Send tokens to another account</p>
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <label className={classes.label}>Token</label>
        <Select
          classes={{ root: classes.selectDropdown }}
          className={classes.inputContent}
          disableUnderline
          value={transferDetails.token}
          error={tokenErrorMessage.length > 0}
          onChange={(e) =>
            setTransferDetails({ ...transferDetails, token: e.target.value })
          }
        >
          {assets.map((item: any) => {
            return <MenuItem value={item}>{item.symbol}</MenuItem>
          })}
        </Select>
        {tokenErrorMessage !== '' ? (
          <ErrorMessage message={tokenErrorMessage} />
        ) : null}
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <label className={classes.label}>Recipient address</label>
        <TextField
          className={classes.inputContent}
          variant="outlined"
          placeholder="0x"
          error={invalidAddressMessage.length > 0}
          onChange={(e) => {
            setTransferDetails({
              ...transferDetails,
              recipientAddress: e.target.value.toLowerCase(),
            })
          }}
        />
        {invalidAddressMessage !== '' ? (
          <ErrorMessage message={invalidAddressMessage} />
        ) : null}
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <label className={classes.label}>Amount</label>
        <NumericFormat
          className={classes.inputContent}
          placeholder="1.21"
          customInput={TextField}
          variant="outlined"
          value={toggleMax ?transferDetails.amount = maxBalance : transferDetails.amount}
          allowNegative={false}
          valueIsNumericString={true}
          error={insufficientFundsMessage.length > 0}
          onChange={(e) =>{
            setToggleMax(false)
            setTransferDetails({...transferDetails, amount: parseFloat(e.target.value)})}
          }
        />
        <Button className={classes.maxButton} onClick={toggleMaxAmountDeposit}>Max</Button>
        {insufficientFundsMessage !== '' ? (
          <ErrorMessage message={insufficientFundsMessage} />
        ) : null}
      </Grid>
        <VotingComponent
          setProposal={setProposal}
          proposalDetails={proposalDetails}
          initiateVoting={initiateVoting}
        />

      <ModalLoading
        open={creatingProposal}
        onCancel={undefined}
        modalImg={successAlertImg}
        title={'Proposal creation in progress'}
        subTitle={'Please be patient until Proposal will be created'}
      />
      <ModalSuccess
        open={createdProposal}
        onCancel={() => {
          navigate(`/funds/${props?.dao?.data?.subgraph?.id}`)
          setCreatedProposal(false)
        }}
        modalImg={successAlertImg}
        title={'Proposal has been created'}
        subTitle={'You can start to explore new opportunities'}
      />
    </Grid>
  )
}

const VotingComponent = (props) => {
  const classes = useStyle()

  return (
    <Grid container>
      <Grid item lg={12} md={12} sm={12} xs={12} className={classes.messageContent}>
        <img src={errorIcon} alt="error" /> <br />
        <span>You must initiate voting to perform this transaction </span>
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <label className={classes.label}>Voting header</label>
        <TextField
          className={classes.inputContent}
          variant='outlined'
          placeholder='Enter header here'
          onChange={(e) => props.setProposal({ ...(props.proposalDetails), title: e.target.value })} />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <label className={classes.label}>Voting description</label>
        <TextField
          className={classes.inputContent}
          multiline
          minRows={3}
          variant='outlined'
          placeholder='Describe this transaction'
          onChange={(e) => props.setProposal({ ...(props.proposalDetails), description: e.target.value })} />
      </Grid>

      <Grid item lg={12} md={12} sm={12} xs={12} className={classes.contentHelper}>
        <Button
          type='secondary'
          className={classes.buttonContent}
          onClick={props.initiateVoting}>
          Initiate voting
        </Button>
      </Grid>
    </Grid>
  )
}
