import { useState } from 'react'
import { useWeb3React } from '@web3-react/core'

// Material Imports
import Card from '@material-ui/core/Card'
import Grid from '@material-ui/core/Grid'
import { TextField, CardContent } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import InfoIcon from '@material-ui/icons/Info';

import daoLogo from '../../../assets/icons/aspisIdo.svg'
import { Button } from '../../Button'
import { WalletGuard } from '../../WalletGuard'

const useStyles = makeStyles((theme) => ({
  content: {
    border: '1px solid rgba(214, 210, 242, 0.5)',
    boxShadow: '0px 51px 89px -49px rgba(196, 193, 232, 0.4)',
    borderRadius: '18px',
    marginBottom: '50px',
  },
  displayImg: {
    display: 'inline-flex',
    flexDirection: 'column-reverse',
    alignItems: 'center',
    padding: '20px',
    background: '#F4F4FA',
    borderRadius: '13px',
  },
  image: {
    width: '65%',
    marginBottom: '16px',
  },
  loadImgBtn: {
    background: '#BC65F2',
    borderRadius: '7px',
    color: 'white',
    fontFamily: 'Poppins',
    width: '127px',
    height: '36px',
    fontSize: '11px',
    fontWeight: 700,
  },
  inputContent: {
    display: 'grid',
    fontSize: '12px',
    fontWeight: 500,
    padding: '6px 10px',
    '& .MuiOutlinedInput-multiline': {
      padding: '0',
    },
    '& .MuiInputBase-input': {
      padding: '10.5px 14px',
      background: '#FBFBFF',
      fontFamily: 'Poppins',
       fontSize: '14px',
       fontWeight: 500
    },
    '& .MuiInputBase-root': {
      borderRadius: '9px',
    },
    '& .MuiFormHelperText-root.Mui-error':{
      fontSize: '10px',
      fontFamily:'Poppins',
      margin: '0 3px',
      position: 'absolute',
      bottom: '-18px'
    }
  },
  input: {
    marginTop: '15px',
  },
  labelHelper: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '10px',
    color: '#4C4C66',
    marginTop: '16px',
  },
  imgContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  infoDisplay:{
    display: 'flex',
    background: 'rgb(232, 244, 253)',
    borderRadius: '8px',
    border: '1px solid #a4dcfe',
    padding: '5px',
    marginTop: '10px',
    alignItems:'center',
    '& span':{
      fontSize: '8px',
      marginLeft: '5px',
    },
    '& svg':{
      color:'#2196f3'
    }
  },
  buttonMargin: {
    marginTop:'0px',
    [theme.breakpoints.down('xs')]: {
      marginTop: '16px'
    },
  }
}))

export const SpecifyGeneralInfo = (props) => {
  const { account } = useWeb3React()
  const classes = useStyles()

  const { handleBack, handleNext } = props;
  const { stepperValues, setStepperValues } = props

  const [daoNameError, setDaoNameError] = useState(false)
  const [tokenNameError, setTokenNameError] = useState(false)
  const [descriptionError, setDescriptionError] = useState(false)

  const enabledNextButton = stepperValues.name !== undefined && stepperValues.symbol !== undefined && stepperValues.description !== undefined && account;

  return (
    <>
    <form>
      <Card className={classes.content}>
        <CardContent>
          <Grid container>
            <Grid item lg={8} md={8} sm={8} xs={12}>
              <div className={classes.inputContent}>
                Vault Name
                <TextField
                  variant="outlined"
                  inputProps={{ maxLength: 20 }}
                  placeholder='JupiterVault'
                  className={classes.input}
                  value={stepperValues.name || ''}
                  onChange={(e) => setStepperValues({ ...stepperValues, name: e.target.value })}
                  required
                  onBlur={()=> setDaoNameError(true)}
                  error={daoNameError && !stepperValues.name}
                  helperText={daoNameError ? (stepperValues.name? "": "This field is required"):null} 
                />
              </div>
              <div className={classes.inputContent}>
                Token Name
                <TextField
                  variant="outlined"
                  required
                  className={classes.input}
                  placeholder='LP_JPT'
                  value={stepperValues.symbol || ''}
                  onChange={(e) => setStepperValues({ ...stepperValues, symbol: e.target.value })}
                  onBlur={()=> setTokenNameError(true)}
                  error={tokenNameError && !stepperValues.symbol}
                  helperText={tokenNameError ? (stepperValues.symbol? "": "This field is required"):null} 
                />
                <label className={classes.labelHelper}>
                  For funds it is recommended to use LP prefix. Example:
                  LP_ASPIS
                </label>
              </div>
            </Grid>
            <Grid
              item
              lg={4}
              md={4}
              sm={4}
              xs={12}
              className={classes.imgContent}
            >
              <div className={classes.displayImg}>
              <div className={classes.infoDisplay}><InfoIcon fontSize="small"/><span>Square icon is recommended</span></div>
                <Button
                  className={classes.loadImgBtn}
                  component="label"
                  color="primary"
                >
                  {' '}
                  Load Vault Symbol
                  <input
                    type="file"
                    accept="image/"
                    onChange={(e: any) => setStepperValues({ ...stepperValues, selectedImg: e.target.files[0] })}
                    hidden
                  />
                </Button>
                {stepperValues.selectedImg ? (
                  <img
                    className={classes.image}
                    src={URL.createObjectURL(stepperValues.selectedImg)}
                    alt="daologo"
                  />
                ):(  <img
                  className={classes.image}
                  src={daoLogo}
                  alt="daologo"
                />)}
              </div>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <div className={classes.inputContent}>
                Description
                <TextField
                  variant="outlined"
                  multiline
                  placeholder='Jupiter Vault is an asset management DAO which aim is to maximise generated income and minimise market risk exposure to its participants...'
                  className={classes.input}
                  onChange={(e) => setStepperValues({ ...stepperValues, description: e.target.value })}
                  value={stepperValues.description || ''}
                  required
                  onBlur={()=> setDescriptionError(true)}
                  error={descriptionError && !stepperValues.description}
                  helperText={descriptionError ? (stepperValues.description? "": "This field is required"):null} 
                />
              </div>
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <div className={classes.inputContent}>
                Twitter
                <TextField
                  variant="outlined"
                  placeholder='Twitter'
                  className={classes.input}
                  onChange={(e) => setStepperValues({ ...stepperValues, twitterLink: e.target.value })}
                  value={stepperValues.twitterLink || ''}
                />
              </div>
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <div className={classes.inputContent}>
                Telegram
                <TextField
                  variant="outlined"
                  placeholder='Telegram'
                  className={classes.input}
                  onChange={(e) => setStepperValues({ ...stepperValues, telegramLink: e.target.value })}
                  value={stepperValues.telegramLink || ''}
                />
              </div>
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <div className={classes.inputContent}>
                Blog
                <TextField
                  variant="outlined"
                  placeholder='Blog'
                  className={classes.input}
                  onChange={(e) => setStepperValues({ ...stepperValues, mediumLink: e.target.value })}
                  value={stepperValues.mediumLink || ''}
                />
              </div>
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <div className={classes.inputContent}>
                Docs
                <TextField
                  variant="outlined"
                  placeholder='Docs'
                  className={classes.input}
                  onChange={(e) => setStepperValues({ ...stepperValues, docsLink: e.target.value })}
                  value={stepperValues.docsLink || ''}
                />
              </div>
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <div className={classes.inputContent}>
                Website
                <TextField
                  variant="outlined"
                  placeholder='Website'
                  className={classes.input}
                  onChange={(e) => setStepperValues({ ...stepperValues, websiteLink: e.target.value })}
                  value={stepperValues.websiteLink || ''}
                />
              </div>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      </form>
      <Grid container justifyContent='space-between'>
      <Grid item lg={2} md={3} sm={3} xs={12}>
      <Button type="third" onClick={() => handleBack()}>Return</Button>
      </Grid>
      <Grid item container lg={2} md={3} sm={3} xs={12} justifyContent="flex-end" className={classes.buttonMargin}>
      <Button type="primary"
        onClick={() => handleNext()} disabled={!enabledNextButton}>Next</Button>
      <WalletGuard alt={<div style={{ color: 'red' }}>Connect wallet to proceed</div>}>
      </WalletGuard>
      </Grid>
      </Grid>  
    </>
  )
}

