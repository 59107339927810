import { FC, useMemo, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { usePools, usePoolsRequest } from '../hooks'

import { makeStyles } from '@material-ui/core/styles'
import { Grid } from '@material-ui/core'
import { Container } from '@material-ui/core';
import InfiniteScroll from 'react-infinite-scroll-component'

import { routes } from '../const/routes'
import { PoolStatuses } from '../const/poolStatuses'
import { getPoolStatus } from '../helpers/getPoolStatus'

import arrowSrc from '../assets/icons/arrow.png'
import clsx from 'clsx'

import { Content } from './index'
import { Pool } from '../models/Pool'
import { PoolCard } from './PoolCard'


type Props = { type: PoolStatuses, sectionPoolsHeaderContent, sectionPoolBackground }

const useStyles = makeStyles(
  (theme) => ({
    upcomingContainer: {
      backgroundColor: theme.palette.sixth.main
    },
    upcomingPoolsCardContainer: {
      padding: 0,
      backgroundColor: theme.palette.sixth.main
    },
    liveContainer: {
      backgroundColor: theme.palette.white.main
    },
    livePoolsCardContainer: {
      padding: 0,
      backgroundColor: theme.palette.white.main,
      
    },
    closedContainer: {
      
    },
    closedPoolsCardContainer: {
      padding: 0,
     
    },
    headerContent: {
      padding: '60px 0 0 0'
    },
    poolCards: {
      flexWrap: 'wrap',
      alignItems: 'flex-start',
      '& > *': {
      paddingRight: 16,
      display: 'flex',
      },
      '@media screen and (min-width: 1200px)': {
        '&::-webkit-scrollbar': {
          display: 'none'
        },
        scrollbarWidth: 'none'
      }
    },
    header: {
      alignItems: 'center',
      display: 'flex',
      margin: '0 0 32px 0',
      justifyContent: 'space-between',
      flexWrap: 'nowrap'
    },
  content: {
    margin: '100px 0 100px 0',
    display: 'flex',
    justifyContent: 'space-between',
    // justifyContent: 'justify-between',
    '@media screen and (max-width: 644px)': {
      margin: '50px 0 50px 0',
    },
    '@media screen and (max-width: 320px)': {
      margin: '36px 0 50px 0',
    },
    // display : 'row',
  },
  title: {
    display: 'inline-grid',
    fontSize: 22,
    fontWeight: 600,
    paddingLeft: 0
  },
  pos: {
    marginBottom: 12,
  },
  primaryCard: {
    backgroundColor: '#FFFFFF',
    borderRadius: 12,
    height: '100%'
  }, 
  textSm: {
    fontSize: 12,
    fontWeight: 'bold',
    padding: 7,
    display: 'inline-flex',
    justifyContent: 'flex-start',
    width: '100%'
  },
  title1:{
    fontFamily: theme.fontFamilies.fourth,
    color: theme.palette.primary.main,
    margin: '0 0 20px 0',
  },
  link: {
    // margin: '36px 0 0 0',
   fontFamily: theme.fontFamilies.primary,
    color: '#BC65F2',
    margin: '0 0 20px 0',
    border: '1px solid #BC65F2',
    background: 'rgba(232, 99, 244, 0.07);',
    width:160,
    padding: '8px 20px 8px 20px',
    display: 'flex',
    justifyContent: 'space-between',
    textDecoration: 'none',
    borderRadius: 25,
    alignItems: 'center',
    '&:hover': {
      textDecoration: 'underline'
    }
  },
  '@media screen and (max-width: 425px)': {
    link: {
      width: 140
    }
  },
  footerContent: {
    padding: '0 0 64px 0',
    '& > a': {
      fontWeight: 'bold',
      fontSize: 18,
      lineHeight: '22px',
      color: theme.palette.primary.main,
      textDecoration: 'none'
    },
    justifyContent: 'center'
  },
  '@media screen and (max-width: 320px)': {
    footerContent: {
      padding: '0 0 44px 0'
    }
  },
  '@media screen and (max-width: 644px)': {
    footerContent: {
      padding: '0 0 44px 0'
    }
  },
  titleContent:{
    display: 'inline-flex',
    justifyContent: 'space-between',
    width: '100%'
  }

}));

export const SectionPools: FC<Props> = ({ type, sectionPoolsHeaderContent, sectionPoolBackground }) => {
  const [limit, setLimit] = useState(4)
  const classes = useStyles()
  const location = useLocation();
  const offset = 3
  usePoolsRequest(limit, offset)
  const pools: Pool[] = usePools()

  let isAllProjectsPage = location.pathname.includes('/dao/projects');

  const recentPools = useMemo(() => {
    return pools
      .filter((pool) => getPoolStatus(pool) === type)
      .slice(-3).reverse()
      .map((pool) =>
        <PoolCard
          key={pool.address}
          access={pool.whitelistRequired ? 'Private' : 'Public'}
          pool={pool}
        />
      )
  }, [type, pools])

  const allPools = useMemo(() => {
    return pools
      .map((pool) =>
        <PoolCard
          key={pool.address}
          access={pool.whitelistRequired ? 'Private' : 'Public'}
          pool={pool}
        />
      )
  }, [pools])

  const fetchMorePools = () => {
      setLimit(limit+offset);
  };

  return (
    <>
      {!isAllProjectsPage ? (
        <>
          <div className={`${sectionPoolBackground}`}>
            <Container
              className={clsx({
                [classes.upcomingContainer]: type === PoolStatuses.Upcoming,
                [classes.closedContainer]: type === PoolStatuses.Closed,
                [classes.liveContainer]: type === PoolStatuses.Live
              })}
            >
              <Content className={`${classes.headerContent} ${sectionPoolsHeaderContent}`}>
                <Grid container className={classes.header}>
                  <div className={classes.titleContent}>
                    <span className={classes.title}>
                      {
                        {
                          [PoolStatuses.Upcoming]: 'Upcoming Pools',
                          [PoolStatuses.Live]: 'Live Pools',
                          // [PoolStatuses.Closed]: 'DAO Projects'
                        }[type]
                      }
                    </span>
                    {/* <Link to={routes.CLOSED_POOLS} className={classes.link}>
                      View all pools
                      <img style={{ width: 18, height: 18 }} src={arrowSrc} alt='arrow' />
                    </Link> */}
                  </div>
                </Grid>
              </Content>
            </Container>

            <Container
              className={clsx({
                [classes.upcomingPoolsCardContainer]: type === PoolStatuses.Upcoming,
                [classes.closedPoolsCardContainer]: type === PoolStatuses.Closed,
                [classes.livePoolsCardContainer]: type === PoolStatuses.Live
              })}
            >
              <Content className={undefined}>
                <Grid container className={classes.poolCards}>
                  {recentPools}
                </Grid>
              </Content>
            </Container>

            <Container
              className={clsx({
                [classes.upcomingContainer]: type === PoolStatuses.Upcoming,
                [classes.closedContainer]: type === PoolStatuses.Closed,
                [classes.liveContainer]: type === PoolStatuses.Live
              })}
            >
            </Container>
          </div>
        </>
      ) : (
        <>
          <div className={`${sectionPoolBackground}`}>
            <Container
              className={clsx({
                [classes.upcomingContainer]: type === PoolStatuses.Upcoming,
                [classes.closedContainer]: type === PoolStatuses.Closed,
                [classes.liveContainer]: type === PoolStatuses.Live
              })}
            >
              <Container className={`${classes.headerContent} ${sectionPoolsHeaderContent}`}>
                <Grid container className={classes.header}>
                  <div className={classes.titleContent}>
                    <span className={classes.title}>
                      {
                        {
                          [PoolStatuses.Upcoming]: 'Upcoming Pools',
                          [PoolStatuses.Live]: 'Live Pools',
                          [PoolStatuses.Closed]: 'DAO Projects'
                        }[type]
                      }
                    </span>
                    <Link to={routes.CLOSED_POOLS} className={classes.link}>
                      View all pools
                      <img style={{ width: 18, height: 18 }} src={arrowSrc} alt='arrow' />
                    </Link>
                  </div>
                </Grid>
              </Container>
            </Container>

            <Container
              className={clsx({
                [classes.upcomingPoolsCardContainer]: type === PoolStatuses.Upcoming,
                [classes.closedPoolsCardContainer]: type === PoolStatuses.Closed,
                [classes.livePoolsCardContainer]: type === PoolStatuses.Live
              })}
            >
              <Content className={undefined}>
                <InfiniteScroll
                  dataLength={allPools.splice(limit).length}
                  next={fetchMorePools}
                  hasMore={true}
                  loader={undefined}>
                  <Grid container className={classes.poolCards}>
                    {allPools}
                  </Grid>
                </InfiniteScroll>
              </Content>
              {allPools.length === 4 ? (<span>Loading ...</span>) : (<span>No more pools to show</span>)}
            </Container>

            <Container
              className={clsx({
                [classes.upcomingContainer]: type === PoolStatuses.Upcoming,
                [classes.closedContainer]: type === PoolStatuses.Closed,
                [classes.liveContainer]: type === PoolStatuses.Live
              })}
            >
            </Container>
          </div>
        </>
      )}
    </>
  )
}
