/* eslint-disable react-hooks/exhaustive-deps */
import { useNavigate } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'

import { routes, ID } from '../../../const/routes'
import { MAX_UINT } from '../../../const/ethConst'
import { moneyFormatter } from '../../../helpers/moneyFormatter'
import error404 from '../../../assets/icons/icon-not-found.jpg'
import { ChainIcon } from '../../../components/ChainIcon'
import { getNetworkConfig } from '../../../const/networks'
import { memo, useEffect, useState } from 'react'
import { DaoAPI } from '../../../api/DaoAPI'
import { useAspisSupportedTokens } from '../../../hooks/useChain'

const useStyles = makeStyles((theme) => ({
  title: {
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'flex-start !important',
    cursor: 'pointer',
  },
  logo: {
    width: '37px',
    height: '37px',
    borderRadius: '50%',
  },
  name: {
    color: '#2F2F4D',
    paddingLeft: '10px',
  },
  icon: {
    width: '25px',
    marginRight: '5px',
    height: '25px',
  },
  tokens: {
    '&>div': {
      display: 'flex',
      padding: '0 10px',
      '&::-webkit-scrollbar': {
        display: 'none',
      },
    },
  },
  topTrend: {
    color: '#4BCD87',
  },
  bottomTrend: {
    color: '#CD4B6C',
  },
  pagination: {
    justifyContent: 'center',
    margin: '20px auto !important',
    display: 'flex',
  },
}))


export const Row = memo(({ item }) => {
  const classes = useStyles()
  const navigate = useNavigate()

  const [supportedTokens, setSupportedTokens] = useState([])

  const change24 =
    item?.APies?.length > 0
      ? Number(item?.APies[item?.APies.length - 1].twentyFourHours).toFixed(2)
      : 0
  const changeTotal =
    item?.APies?.length > 0
      ? Number(item?.APies[item?.APies.length - 1].total).toFixed(2)
      : 0
  const getTrend = (val) => (val >= 0 ? 'topTrend' : 'bottomTrend')

  useEffect(() => {
    if (!item) return

    const network = getNetworkConfig(item.chainId)
    const library = { provider: network.params.rpcUrls[0] }

    const fetchTokenData = async () => {
      // Tokens
      setSupportedTokens(await DaoAPI.getDepositTokens(item.id, library))
    }

    fetchTokenData()
  }, [item.chainId, item.id])


  const tokens = useAspisSupportedTokens(supportedTokens, item.chainId)

  return (
    <>
      {/* Fund name */}
      <div
        className={classes.title}
        onClick={() => navigate(  
          {
            pathname: routes.FUNDS.replace(ID, item.id),
            search: `?chainId=${item.chainId}`,
          }
        )}
      >
        <img
          className={classes.logo}
          src={item.logo}
          width={75}
          alt="cardLogo"
        />
        <div className={classes.name}>
          {item.name.length > 15
            ? `${item.name.substring(0, 15)}...`
            : item.name}
        </div>
      </div>
      {/* Chain */}
      <div>
        <ChainIcon chainId={item.chainId} />
      </div>
      {/* Deposit Range */}
      <div>
        {item?.depositRange?.minimum === '0' &&
        item?.depositRange?.maximum === MAX_UINT ? (
          <span className={classes.spanText}>Any</span>
        ) : (
          <span className={classes.spanText}>
            ${moneyFormatter(item?.depositRange?.minimum)} - $
            {moneyFormatter(item?.depositRange?.maximum)}
          </span>
        )}
      </div>
      {/* Accepted Tokens */}
      <div className={classes.tokens}>
        <div>
          {tokens.slice(0, 5).map((item, key) => (
            <div key={item?.symbol}>
              <img
                src={`https://cdn.jsdelivr.net/gh/atomiclabs/cryptocurrency-icons@1a63530be6e374711a8554f31b17e4cb92c25fa5/svg/color/${item?.symbol}.svg`.toLowerCase()}
                className={classes.icon}
                alt=""
                title={item?.symbol}
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null
                  currentTarget.src = error404
                }}
              />
            </div>
          ))}
          {tokens.length > 5 && '...'}
        </div>
      </div>
      {/* Smart Token Price */}
      <div>{item.lpPrice ? `${item.lpPrice} $` : '-'}</div>
      {/* Change 24h/Total */}
      <div>
        <span className={classes[getTrend(change24)]}>{change24}%</span>
        <span className={classes[getTrend(changeTotal)]}>({changeTotal}%)</span>
      </div>
      {/* Withdrawal Windows */}
      <div>
        {item.fees?.rageQuitFee > 0 ? (
          <span className={classes.spanText}>
            {item.daoConfig?.withdrawlWindow / 24} day every <br />{' '}
            {item.daoConfig?.freezePeriod / 24} days
          </span>
        ) : (
          <span className={classes.spanText}>Anytime</span>
        )}
      </div>
      {/* Fundraising */}
      <div>
        <Label daoConfig={item.daoConfig} />
      </div>
    </>
  )
})

const useLabelStyles = makeStyles((theme) => ({
  opened: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'rgba(26, 213, 152, 1)',
    color: '#ffffff',
    paddingLeft: '20px',
    borderRadius: '25px',
    fontSize: '12px',
    height: '23px',
    width: '70px',
    '&::before': {
      content: '"•"',
      display: 'block',
      position: 'absolute',
      left: '9px',
      top: 'auto',
      bottom: 'auto',
      fontSize: '14px',
    },
  },
  closed: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(164, 222, 255, 0.3)',
    color: '#6E87E1',
    fontSize: '12px',
    height: '23px',
    paddingLeft: '10px',
    paddingRight: '10px',
    borderRadius: '25px',
    width: '70px',
  },
}))

const Label = ({ daoConfig }) => {
  const classes = useLabelStyles()

  if (!daoConfig) {
    return <div> - </div>
  }

  const opened = new Date().valueOf() / 1000 < Number(daoConfig?.finishTime)
  const statusClass = opened ? 'opened' : 'closed'

  return (
    <div className={classes[statusClass]}>{opened ? 'Live now' : 'Closed'}</div>
  )
}
