import { useEffect, useState } from 'react'

// Material-UI imports
import {
  Card,
  CardContent,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Checkbox
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useLocation } from 'react-router'
import Web3 from 'web3'
import { useWeb3React } from '@web3-react/core'
import { useDefiProtocols } from '../../../../hooks/useChain'
import { DaoAPI } from '../../../../api/DaoAPI'
import { CopyToClipboardBtn } from '../../../CopyToClipboard'


const useStyle = makeStyles((theme) => ({
  root: {
    height: 'fit-content',
    padding: '5px 8px 5px 5px',
    border: `1px solid ${theme.palette.twentySecond.main}`,
    borderRadius: 8,
    backgroundColor: theme.palette.common.white,
    justifyContent: 'space-between',
  },
  inputWrapper: {
    '& > *': {
      fontWeight: 'bold',
      lineHeight: '100%',
    },
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: 'calc(100% - 160px)',
  },
  input: {
    width: "100%",
    '& .MuiOutlinedInput-input':{
      padding:'12.5px 14px'
    },
    '& .MuiOutlinedInput-notchedOutline':{
      borderRadius: '10px'
    },
  },
  divider: {
    borderBottom: '1px solid rgb(224 224 224)'
  },
  fundManagerChip: {
    color: '#8765F2',
    background: 'rgba(137, 99, 244, 0.07)',
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: 15,
    borderRadius: '86px',
    paddingRight: '10px',
    '& .MuiTypography-body1': {
      fontFamily: 'Poppins',
      fontSize: '15px',
      fontWeight: 500,
      letterSpacing: 0
    }
  },
  chipContent: {
    padding: '4px',
    background: '#FDFBFF',
    border: '1px solid #EEE7F8',
    borderRadius: '9px'
  },
  copyAddressButton: {
    background: '#EBE9F0',
    width: '40px',
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: 12,
    color: '#958BAE',
    marginLeft: '10px'
  },
  justifyContent: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  title: {
    fontWeight: 500,
    margin: '14px 0 5px 0',
    fontSize: '1rem',
    color: 'rgba(0, 0, 0, 0.54)',
    lineHeight: '23.87px'
  },
  errorMessage:{
    fontSize: '14px',
    color: '#da3e63',
    // margin: '0'
  },
  loadBtnContent: {
    display: 'flex',
    justifyContent: 'center',
    padding: '16px',
    borderTop: '1px solid rgba(214, 210, 242, 0.5);',
  },
  loadBtn: {
    background: 'rgba(232, 99, 244, 0.07)',
    border: '1px solid #BC65F2',
    borderRadius: '5px',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '21px',
    textAlign: 'center',
    color: '#BC65F2',
  },
  inputCSV:{
    width:'100%',
    '& .MuiOutlinedInput-notchedOutline':{
      borderColor: 'transparent',
      background: 'rgb(236 157 243 / 7%)',
      borderRadius: '10px',
    }
  },
  walletAddressesDisplay:{
    background: '#fcf7fe',
    paddingTop: '10px',
    borderRadius: '18px',
    paddingBottom: '10px',
    fontSize:'14px',
      '&limaker':{
      background:'red',
      color:'yellow'
    }
  },
  cardTitle: {
    fontSize: 13,
    fontWeight: 600,
    fontStyle: 'normal',
    lineHeight: 4,
    margin: 0,
    paddingLeft: '16px',
  },
  cardContent: {
    borderRadius: '18px',
    border: '1px solid rgba(214, 210, 242, 0.5)',
    boxShadow: '0px 51px 89px -49px rgba(196, 193, 232, 0.4)',
  },
  cardContentInside:{
    padding: '16px',
    height: '198px',
    overflow: 'overlay'
  },
  content: {
    marginBottom: '15px',
  }
}))

export const AddNewAllowlistedAddress = (props: any) => {
  const {func, setIsProposalValid, setProposalError, setProposalMsg} = props;
  
  const classes = useStyle()
  const location = useLocation();
  const { connector } = useWeb3React();

  const [currentProtocols, setCurrentProtocols] = useState<any>([]);
  const [supportedProtocols, setSupportedProtocols] = useState<any>([]);
  const defiProtocols: any = useDefiProtocols();

  const [newAllowlistedAddress, setAllowListed] = useState<any>({
    address: [],
    func: 'addNewAllowlistedAddress'
  })

  useEffect(() => {
    const decodeAddresses = async () => {
      try {
        setSupportedProtocols(defiProtocols)
        setCurrentProtocols(await DaoAPI.getTrustedTransfers(daoId, connector))
      } catch (e) {
        console.log('Error message:', e)
      }
    }
    decodeAddresses()
      .catch(console.error)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defiProtocols])

  let paths = location.pathname.split('/');
  let daoId = paths[paths.length - 1];

  const inputValidation = newAllowlistedAddress?.address.length > 0 &&
    newAllowlistedAddress?.address.every((walletAddress)=>Web3.utils.isAddress(walletAddress));
    
  const proposalValidation = currentProtocols.some((currentwhitelistedTokens: string) => {
    return newAllowlistedAddress?.address.some((WhitelistedTokens:string) => {
      return currentwhitelistedTokens.toLowerCase() === WhitelistedTokens.toLowerCase()
    }) 
  });
  
  useEffect(()=>{
    setIsProposalValid(inputValidation)
    if(proposalValidation){
      setProposalError(true);
      setProposalMsg('You cannot create a proposal with the same allowlist address that is already present.')
    }else{
      setProposalError(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[inputValidation, newAllowlistedAddress])

  useEffect(() => {
    func(newAllowlistedAddress)
  })

  return (
    <>
        <Card className={classes.cardContent}>
          <div className={classes.divider}>
            <p className={classes.cardTitle}>Add new allowed protocol</p>
          </div>
          <CardContent className={classes.cardContentInside}>
          {
            supportedProtocols.map((item) => (
              <div className={classes.content} key={item.protocolAddress}>
                <FormControl>
                  <RadioGroup>
                    <FormControlLabel
                      className={classes.fundManagerChip}
                      value={item.protocolAddress}
                      control={
                        <Checkbox
                          onChange={(e) => {
                            if(e.target.checked) {
                              setAllowListed({ ...newAllowlistedAddress, address: [...newAllowlistedAddress.address, e.target.value] }) 
                            } else {
                              setAllowListed({ ...newAllowlistedAddress, address: newAllowlistedAddress.address.filter(el => el !== item.protocolAddress) }) 
                             }
                          }}
                          defaultChecked={currentProtocols.includes(item.protocolAddress)}
                          disabled={currentProtocols.includes(item.protocolAddress)}
                        />
                      }
                      label={item.protocolAddress}
                    />
                  </RadioGroup>
                </FormControl>
                <CopyToClipboardBtn props={item.protocolAddress} />
              </div>
            ))
          }
          </CardContent>
        </Card>
    </>
  )
}
