import { useEffect } from 'react'
import { useLocation } from 'react-router'
import { Routes, Route, Navigate } from 'react-router-dom'
import { useWalletConnectAttempt, useAccount, useUserInfo } from './hooks'
import VoiceflowScript from './components/VoiceflowScript'
import Cookie3Script from './components/Cookie3Script'

// Styles
import './App.css'

// Constants
import { routes } from './const/routes'

// Components
import { Header, TermsAndConditions } from './components'
import { VoteForProposal } from './components/votings/proposals/VoteForProposal'

// Pages
import { DaoCreationPage } from './pages/DaoCreationPage'
import { DaoFundSingePage } from './pages/DaoFundSinglePage'
import { DaoRulesPage } from './pages/DaoRulesPage'
import { PageMain, Pools } from './pages'
import { VotingPage } from './pages/VotingPage'
import { CabinetPage } from './pages/Cabinet'
import { Funds } from './pages/PagePools/Funds'
import { ProposalsPage } from './pages/ProposalsPage'
import { CreateTransaction } from './pages/WalletConnect'
import useModals from './hooks/useModals'

export function App() {
  const { pathname } = useLocation()
  useWalletConnectAttempt()
  useAccount()
  useUserInfo()

  // Modals
  const { renderModal } = useModals()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return (
    <>
      <Header />
      <TermsAndConditions />
      <Routes>
        <Route path={routes.CREATE_DAO} element={<DaoCreationPage />} />
        <Route path={routes.POOLS}>
          <Route path={routes.ALL_FUNDS} element={<Funds />} />
          <Route path={routes.ALL_POOLS} element={<Pools />} />
        </Route>
        <Route path={routes.MAIN} element={<PageMain />} />
        <Route path={routes.FUNDS} element={<DaoFundSingePage />} />
        <Route path={routes.RULES} element={<DaoRulesPage />} />
        <Route path={routes.CREATE_VOTING} element={<VotingPage />} />
        <Route path={routes.VOTING} element={<VoteForProposal />} />
        <Route path={routes.CABINET} element={<CabinetPage />} />
        <Route path={routes.PROPOSALS} element={<ProposalsPage />} />
        <Route path={routes.WALLETCONNECT} element={<CreateTransaction />} />
        <Route path="/" element={<Navigate to={routes.MAIN} />} />
      </Routes>
      {/* <Footer /> */}
      <VoiceflowScript />
      <Cookie3Script />
      {renderModal && renderModal()}
    </>
  )
}
