import { useEffect, useState } from 'react'

// Web3 Imports
import { useWeb3React } from '@web3-react/core'

//Material UI imports
import { Grid, withStyles } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import {Tooltip} from '@material-ui/core'

import { VotingAPI, VoterState } from '../../api/VotingAPI'

// Components
import { Button } from '../Button'
import { ModalLoading } from '../ModalLoading'
import { ModalSuccess } from '../ModalSuccess'

import moment from 'moment'
import ModalImg from '../../assets/images/createDaoModal.svg'
import BigNumber from 'bignumber.js'
import { ErrorMessage } from '../ErrorMessage/ErrorMessage'
import { useSelector } from 'react-redux'

const useStyles = makeStyles({
  buttonContent:{
    marginTop:'15px'
  }
})

const TooltipMessage = withStyles({
  tooltip: {
    color: "red",
    backgroundColor: "white",
    padding:'13px',
    fontFamily:'Poppins',
    fontSize:'11px'
  }
})(Tooltip);

export const VotingButtons = (props) => {
  // const { votingId, daoId, proposal } = props
  const { votingId, proposal } = props
  const classes = useStyles() 
  const { account, connector } = useWeb3React()
  const votingAddress = useSelector((state:any) => state?.daos?.dao?.data?.subgraph?.daoCreationLog?.voting)
  
  const [voting, setVoting] = useState(false)
  const [canVote, setCanVote] = useState()
  const [successfulVote, setSuccess] = useState(false)
  const [hideVotingButtons, setHideButtons] = useState(false)
  
  const [executing, setExecuting] = useState(false)
  const [successfulExecution, setSuccessfulExecution] = useState(false)

  const closeSucessModal = () => { 
    setSuccess(false)
    setSuccessfulExecution(false);
    setHideButtons(true)
  }

  const vote = async (decision) => {
    try {
      setVoting(true)
      const vote = await VotingAPI.vote({
        address: votingAddress,
        votingId,
        decision,
        account
      }, connector)
      if (vote) {
        setVoting(false)
        setSuccess(true)
        setHideButtons(true)
      }
    }
    catch (error) {
      setVoting(false)
    }
  }

  const execute = async (id: number) => {
    try {
      setExecuting(true)
      const execute = await VotingAPI.executeVote({
        address: votingAddress,
        votingId,
        account
      }, connector)
      if (execute) {
        setExecuting(false)
        setSuccessfulExecution(true)
      }
    }
    catch (error) {
      setExecuting(false)
    }
  }

  useEffect(() => {
    if(account){
      const fetchSubgraphData = async () => {
        try {
          setCanVote(await VotingAPI.getCanVote({
              address: votingAddress,
              votingId: votingId,
              account
            }, connector)
          )
        } catch (e) {
          console.log('Error message:', e)
        }
      }
      fetchSubgraphData()
      .catch(console.error)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account])

  const voted = proposal?.voters.some((item:any) => item?.voter?.id.toLowerCase() === account?.toLowerCase());

  return (
    <>
      {
        voted ?
        <ErrorMessage message={"You have already voted"} />:
        null
      }
      {
        (moment().isBetween(
          moment.unix(proposal.startDate),
          moment.unix(proposal.endDate))) &&
        (new BigNumber(0)
          .plus(proposal.yea)
          .dividedBy(proposal.votingPower)
          .multipliedBy(100)
          .gt(new BigNumber(proposal.supportRequiredPct)
          .dividedBy('1e16'))) &&
        (new BigNumber(0)
            .plus(proposal.yea)
            .plus(proposal.nay)
            .plus(proposal.abstain)
            .dividedBy(proposal.votingPower)
            .multipliedBy(100)
            .gt(new BigNumber(proposal.participationRequiredPct)
              .dividedBy('1e16'))) && 
        !proposal?.executed &&
        canVote &&
        <Grid spacing={1} className={classes.buttonContent}>
          <Grid item lg={12} md={12} xs={12}>
            <Button type="primary" onClick={() => execute(votingId)}>Execute</Button>
          </Grid>
        </Grid>
      }
      {
        proposal?.executed || voted ? 
          null
          :
          (
            moment()
            .isBetween(
              moment.unix(proposal.startDate),
              moment.unix(proposal.endDate)
            )) ?
            hideVotingButtons ? 
              null 
              :
              canVote ?
                <Grid container spacing={1} className={classes.buttonContent}>
                  <Grid item lg={4} md={4} xs={12}>
                    <Button type="primary" onClick={() => vote(VoterState.Yea)} disableTouchRipple>For</Button>
                  </Grid>
                  <Grid item lg={4} md={4} xs={12}>
                    <Button type="secondary" onClick={() => vote(VoterState.Nay)}>Against</Button>
                  </Grid>
                  <Grid item lg={4} md={4} xs={12}>
                    <Button type="third" onClick={() => vote(VoterState.Abstain)}>Abstain</Button>
                  </Grid>
                </Grid> :
                <Grid container spacing={1} className={classes.buttonContent}>
                  <Grid item md={4} xs={12}>
                    <TooltipMessage title="You are not eligible to vote for this proposal">
                      <Button disableRipple type="primary">For</Button>
                    </TooltipMessage>
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <TooltipMessage title="You are not eligible to vote for this proposal">
                      <Button disableRipple type="secondary">Against</Button>
                    </TooltipMessage>
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <TooltipMessage title="You are not eligible to vote for this proposal">
                      <Button disableRipple type="third">Abstain</Button>
                    </TooltipMessage>
                  </Grid>
                </Grid> 
            :
            null
      }

      <ModalLoading
        open={voting || executing}
        onCancel={undefined}
        modalImg={ModalImg}
        title={`${voting ? 'Voting' : 'Execution'} in progress`}
        subTitle={`Please be patient until ${voting ? 'voting' : 'executing'} is complete`}
      />
      <ModalSuccess
        open={successfulVote || successfulExecution}
        onCancel={closeSucessModal}
        modalImg={ModalImg}
        title={`You have successfuly ${successfulVote ? 'voted' : 'executed'}`}
        subTitle={`Feel free to explore while we sync in your ${successfulVote ? 'vote' : 'execution'}`}
      />
    </>  
  )
}