import { Container, Paper, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'

import { useWeb3React } from '@web3-react/core'
import moment from 'moment'

import { TransactionHistoryButton } from './ExplorerLinks/ExplorerLinks'

const useStyles = makeStyles ({
  root:{
    boxShadow: '0px 51px 89px -49px rgba(196, 193, 232, 0.4)',
    borderRadius: 18,
    border: '1px solid #e0e0e0',
  '& .MuiTableHead-root' : {
    borderTop: '1px solid #e0e0e0'
  }
  },
  tabelTile:{
    fontSize: 13,
    fontWeight: 600,
    fontStyle: 'normal',
    fontFamily: 'Poppins',
    lineHeight: 4
  },
  tableBodyCellGreen:{
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: 13,
    color:'#5DD293'
  },
  tableIcon:{
    width: 22,
    paddingRight: 5
  },
})

const tableCellTitle = {
  fontSize: 11,
  fontWeight: 500,
  fontStyle: 'normal',
  fontFamily: 'Poppins',
  lineHeight: 1,
  color:'#4C4C66'
};

const tableBodyCell ={
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: 13
};

export default function TransactionHistoryTable({ column, data, dao }: { column: any; data: any, dao: any }) {

  const classes = useStyles();
  const { chainId } = useWeb3React()

  dao?.deposits?.sort(function (x, y) {
    return y.createdAt - x.createdAt
  })

  return (
    <TableContainer className={classes.root} component={Paper}>
      <Container>
        <Typography className={classes.tabelTile}>
          Deposits history
        </Typography>
      </Container>
      <Table>
        <TableHead>
          <TableRow>
            {
              column.map((item: any, key: any) => (
                <TableHeadItem key={key} item={item} />
              ))
            }
          </TableRow>
        </TableHead>
        <TableBody>
          {
            dao?.deposits.map((item: any, key: any) => (
              <TableItem item={item} dao={dao} key={key} />
            ))
          }
        </TableBody>
      </Table>
      <TransactionHistoryButton
        chainId={chainId}
        daoId={dao?.id} />
    </TableContainer>
  )
}

const TableHeadItem = ({ item }: {item:any}) => <TableCell align="center" style={tableCellTitle} key={item.heading}>{item.heading}</TableCell>

const TableItem = ({item, dao} : {item:any, dao:any}) => 
(
  <TableRow key={item}>
    <TableCell align="center" style={tableBodyCell}>{item.transaction.slice(0, 5) + '...' + item.transaction.slice(61)}</TableCell>
    <TableCell align="center" style={tableBodyCell}>{moment(item.createdAt * 1000).fromNow()}</TableCell>
    <TableCell align="center" style={tableBodyCell}>{item.sender.slice(0, 5) + '...' + item.sender.slice(37)}</TableCell>
    <TableCell align="center" style={tableBodyCell}>{dao?.id.slice(0, 5) + '...' + dao?.id.slice(37)}</TableCell>
    <TableCell align="center" style={tableBodyCell}>
      {(parseInt(item.amount) / Math.pow(10, parseInt(item.token.decimals))).toLocaleString('fullwide', { useGrouping: false })} {item.token.symbol}
    </TableCell>
  </TableRow>
)
