import { GetPoolsResponse } from './../models/GetPoolsResponse';
import axios from 'axios';
// import { v4 as uuidv4 } from 'uuid';

export class WalletAPI {
  static async getPools(limit: number, offset: number): Promise<GetPoolsResponse> {
    const response = await axios.get('/pools/list',{params:{limit, offset}})
    return response.data.records
  }


  static async postLogin() {
    const token = localStorage.getItem('token');
    if (token) {
      return token
    }
    const response = await axios.post('/login', { password: '123', email: 'rem.kvant@gmail.com' })
    localStorage.setItem('token', response.data.token)
    return response.data.token;
  }

  static async postPools() {
    const token = localStorage.getItem('token');

    const response = await axios.post('/pools', {
      "chainCode": "eth",
      "id": "d59afd14-5afb-4b43-a38a-753b81dd9f58",
      "name": "test1",
      "slug": "test1",
      "address": "0x13ef25B54ADb76B916425A05D2f6dF4104c7D203",
      "requireWhiteList": false,
      "requireCountryValidation": false,
      "poolType": "dothemoon",
      "status": "upcoming",
      "iconSrc": "https://assets.coingecko.com/coins/images/12509/small/linear.jpg?1606884470",
      "access": ["aaa"], 
      "swapCurrency": "USDC", 
      "swapRatio": "1 DTM", 
      "securityChecks": ["aaa"], 
      "audit": "TBD", 
      "projectName": "TBD", 
      "projectLinks": {aaa: "aaa"}, 
      "projectDescription": "TBD", 
      "rewards": "TBD", 
      "swapped": "TBD", 
      "userMinInvest": 100,
      "userMaxInvest": 1000000,
      "investLimit": 100000,
      "investStartTime": "2021-06-21T05:27:12.989Z", 
      "investFinishTime": "2021-06-21T05:27:12.989Z",
      "restrictedCountries": ["us"],
      "startTimeUtc": "2021-06-21T05:27:12.989Z",

      "claimStartTime": "2021-06-21T05:27:12.989Z",
      "claimVerifyAddress": "0x13ef25B54ADb76B916425A05D2f6dF4104c7D203",
      "claimAllocationAddress": "0x13ef25B54ADb76B916425A05D2f6dF4104c7D203",
      "claimAllocationAmount": "claimAllocationAmount",
      "feePercent": 100,
      "feeAddress": "0x13ef25B54ADb76B916425A05D2f6dF4104c7D203",
      "receiverAddress": "receiverAddress",
      "tokenAddress": "tokenAddress",
      "whitelistRequired": true
    }, { headers: { Authorization: `Bearer ${token}` } })
    return response.data.records
  }
}
